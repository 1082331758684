export default () => ({
  drawer: null,
  loadingQueue: {},
  token: '',
  user: null,
  showNotification: true,
  news: {},
  screenControl: null,
  isLightVersion: false,
  drawerWidth: 0,
})
