export default class CompanyService {
  constructor(axios) {
    this.axios = axios
  }

  async init() {
    return await this.axios.$get('/company-setting/init');
  }

  async update(param) {
    return await this.axios.$post('/company-setting/update', param);
  }

  async getAddress(zipcode) {
    if (zipcode) {
      const res = await this.axios.$get(`/company-setting/getAddress?zipCode=${zipcode}`);
      if (res.results == null) return;
      // 該当住所があればaddressに代入する
      return res.results[0]['address1'] + res.results[0]['address2'] + res.results[0]['address3'];
    };
  }
}