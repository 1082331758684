import { Auth } from "aws-amplify"

export default function ({ $axios, store, redirect, $config, app }) {
  let co2 = sessionStorage.getItem('co2')
  let token

  if (co2 && !token) {
    token = (JSON.parse(co2))?.app?.token
  }
  $axios.onRequest((config) => {
    config.baseURL = $config.apiUrl
    if (token || store.app.token) {
      config.headers.Authorization = `Bearer ${token}` || `Bearer ${store.app.token}`
    }
    const lang = localStorage.getItem('language')
    config.headers["Custom-Accept-Language"] = lang ?? "ja"
    config.headers["Custom-Aws-Region"] = $config.cognitoRegion
    config.headers["Custom-Aws-Cognito-Client-Id"] = $config.cognitoUserPoolClientId
    config.headers["Custom-Aws-Cognito-User-Pool-Id"] = $config.cognitoUserPoolId
  })

  $axios.onError(async (error) => {
    const code = parseInt(error.response && error.response.status)
    if (code === 401) {
      try {
        const data = await Auth.currentSession()
        token = data.accessToken.jwtToken
        store.commit('app/SET_TOKEN', token)
        error.config.headers.Authorization = token
        return await $axios.request(error.config);
      } catch (error) {
        console.error(error)
        store.commit('app/SET_TOKEN', '')
        sessionStorage.clear()
        $axios.setToken(false)
        window.location.reload()
      }
    } else if (code === 403) {
      return redirect('/unauthorized')
    } else if (code >= 400 && code < 500) {
      return Promise.reject(error)
    } else if (code >= 500) {
      return redirect('/error')
    }
  })
}
