import { Auth } from 'aws-amplify'
export default async function ({ route, store, redirect, $api }) {
  if (store.state.app.token) {
    if (route.name === 'index') {
      return redirect(`/dashboard`)
    } else {
      const currentUserInfo = await Auth.currentUserInfo()
      if (!['unauthorized', null].includes(route.name) && currentUserInfo && Object.keys(currentUserInfo).length) {
        // set user
        store.commit('app/SET_STATE', {
          name: 'user',
          value: {
            userId: currentUserInfo.username,
            username: currentUserInfo.attributes['custom:user_name_kanji'],
            userAuthority: currentUserInfo.attributes['custom:user_authority_code'],
            organizationId: currentUserInfo.attributes['custom:organization_id'],
          }
        })
        // get screenControl
        if (!store.state.app.screenControl) {
          const { result } = await $api.common.getScreenControl()
          const res = result.reduce((acc, item) => {
            if (!acc[item.userAuthority]) acc[item.userAuthority] = [item]
            else acc[item.userAuthority].push(item)
            return acc
          }, {})
          store.commit('app/SET_STATE', { name: 'screenControl', value: res })
        }
        // get light version flag
        if (!store.state.app.isLightVersion) {
          const { result } = await $api.common.getLightVersion()
          store.commit('app/SET_STATE', { name: 'isLightVersion', value: result.lightweight_flag === '1' })
        }
      }
    }
    // error page has route.name is null
    if (['dashboard', 'unauthorized', null].includes(route.name)) return
    if (route.path.includes('/setting/mst/') && !store.getters['app/checkShowMenu']('/setting/mst/')) {
      return redirect(`/unauthorized`)
    } else if (route.path.includes('/setting') && !store.getters['app/checkShowMenu']('/setting')) {
      return redirect(`/unauthorized`)
    } else if (route.path.includes('/product-management') && !store.getters['app/checkShowMenu']('/product-management/')) {
      return redirect(`/unauthorized`)
    } else if (!store.getters['app/checkShowMenu'](route.path)) {
      return redirect(`/unauthorized`)
    }
  } else if (route.name !== 'index' && !store.state.app.token) {
    redirect('/')
  }
}
