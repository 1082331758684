const getOrCreateLegendList = (chart, id) => {
  const legendContainer = document.getElementById(id);
  legendContainer.style.marginTop = '10px'
  let listContainer = legendContainer.querySelector('ul');

  if (!listContainer) {
    listContainer = document.createElement('ul');
    listContainer.classList.add('label-list')
    listContainer.style.display = 'grid';
    listContainer.style.gap = '5px';
    listContainer.style.gridTemplateRows = 'repeat(8, auto)';
    listContainer.style.gridAutoFlow = 'column';
    listContainer.style.margin = 0;
    listContainer.style.padding = 0;

    legendContainer.appendChild(listContainer);
  }

  return listContainer;
};

// https://www.chartjs.org/docs/latest/samples/legend/html.html
const htmlLegendPlugin = id => ({
  id: 'htmlLegend',
  afterUpdate(chart, options) {
    const ul = getOrCreateLegendList(chart, id);

    // Remove old legend items
    while (ul.firstChild) {
      ul.firstChild.remove();
    }

    // Reuse the built-in legendItems generator
    const items = chart.options.legend.labels.generateLabels(chart);
    let fills = [
      '#8dd3c7',
      '#ffffb3',
      '#bebada',
      '#fb8072',
      '#80b1d3',
      '#fdb462',
      '#b3de69',
      '#fccde5',
      '#d9d9d9',
      '#bc80bd',
      '#ccebc5',
      '#ffed6f',
      '#7ef078',
      '#68d3f7',
      '#78f0c0',
    ]
    const hasFill = items[0]?.fillStyle !== 'rgba(0,0,0,0.1)'
    if (hasFill && chart.data.datasets && chart.data.datasets[0]) {
      chart.data.datasets[0].backgroundColor = fills
    }

    items.forEach((item, index) => {
      const meta = chart.getDatasetMeta(0)
      const li = document.createElement('li');
      li.classList.add('custom-label')
      li.onclick = (e) => {
        const hidden = !item.hidden
        meta.data[index].hidden = hidden
        chart.update();
      };

      // Color box
      const boxSpan = document.createElement('span');
      // boxSpan.style.background = hasFill ? item.fillStyle : fills[index];
      boxSpan.style.background = item.fillStyle;
      boxSpan.style.borderColor = item.strokeStyle;
      boxSpan.style.borderWidth = item.lineWidth + 'px';
      boxSpan.style.borderStyle = 'solid'
      boxSpan.style.display = 'inline-block';
      boxSpan.style.height = '15px';
      boxSpan.style.marginTop = '3px';
      boxSpan.style.marginRight = '10px';
      boxSpan.style.width = '40px';
      boxSpan.style.flexShrink = 0;

      // Text
      const textContainer = document.createElement('p');
      textContainer.style.color = item.fontColor;
      textContainer.style.fontSize = '12.5px';
      textContainer.style.maxWidth = '23ch';
      textContainer.style.margin = 0;
      textContainer.style.padding = 0;
      textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

      const text = document.createTextNode(item.text);
      textContainer.appendChild(text);

      li.appendChild(boxSpan);
      li.appendChild(textContainer);
      ul.appendChild(li);
    });
  }
});

export default htmlLegendPlugin