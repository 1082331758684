export default {
  code: "en",
  messages: {
    alpha: "{_field_} can only use alphabetic characters",
    alpha_num: "Enter alphanumeric characters for {_field_}.",
    alpha_num_2: "Enter alphanumeric characters for {_field_}.",
    alpha_dash: "{_field_} can only contain alphanumeric characters, hyphens and underscores",
    alpha_spaces: "{_field_} can only contain alphabets and spaces",
    between: "{_field_} must be between {min} and {max}",
    confirmed: "{_field_} does not match",
    digits: "{_field_} must be {length} digits",
    Dimensions:
      "{_field_} must be within {width}px width and {height}px height",
    email: "The email address format is incorrect",
    excluded: "{_field_} is an invalid value",
    ext: "{_field_} is not a valid file type",
    image: "{_field_} is not a valid image format",
    integer: "{_field_} can only be integers",
    is: "{_field_} does not match",
    length: "{_field_} must be {length} characters",
    max_value: "{_field_} must be less than or equal to {max}",
    custom_max_value: "{_field_} must be less than or equal to {max}",
    max: "{_field_} must be within {length} characters",
    mimes: "{_field_} is not a valid file type",
    min_value: "{_field_} must be greater than or equal to {min}",
    custom_min_value: "{_field_} must be greater than or equal to {min}",
    min: "{_field_} must be at least {length} characters",
    numeric: "{_field_} can only contain numbers",
    oneOf: "{_field_} is not a valid value",
    regex: "{_field_} has bad format",
    required: "{_field_} is missing",
    required_if: "{_field_} is required",
    size: "{_field_} must be within {size}KB",
    number_full_width: "Please enter a positive number for {_field_}.",
    length_decimal: "{_field_} must have {max} decimal digits or less.",
    integer_only: "Enter only positive numbers in {_field_}.",
    length_comma: "The integer in {_field_} must be {max} digits or less.",
    numeric_right: 'Decimal must be {scale} digits or less. ',
    column_unique: (fieldName, placeholders) => {
      if (typeof placeholders.key === 'object')
        return `Identical ${Object.values(placeholders.key).join('/')} exists. `
      return `${fieldName} is duplicated. `
    },
    kana: 'Enter katakana characters for {_field_}. ',
    password: 'Use at least one number, one special character (@$!%*?&#), one uppercase letter, one lowercase letter. ',
    hankaku_num: '{_field_} can only use half-width alphanumeric characters',
    time_valid: 'The start and end times are not valid. ',
    time_valid_reduction: (fieldName, data) => {
      if (data.type === "end") {
        return `${fieldName} must be rather than ${data.time}.`
      } else {
        return `${data.time} must be rather than ${fieldName}.`
      }
    },
    news_base_org_valid: 'Please select a target base or target affiliation. ',
    hankaku_num_2: '{_field_} can only use half-width alphanumeric characters',
    custom_electric_companyNo: 'Cannot set an electric utility registration number starting with "A"',
  },
}