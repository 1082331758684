export default {
  doSetCodeStore: function (context) {
    const _scopeList = [
      { label: "SCOPE1", value: 1 },
      { label: "SCOPE2", value: 2 },
      { label: "SCOPE3", value: 3 },
    ]
    const _divisionList = [
      { label: "拠点", value: "0" },
      { label: "事業部", value: "1" },
    ]

    const _categoryList = [
      {
        value: "01",
        label: this.app.i18n.messages[this.app.i18n.locale].CATEGORY_LIST["01"].label,
        icon: "mdi-basket",
        title: this.app.i18n.messages[this.app.i18n.locale].CATEGORY_LIST["01"].title,
        caption: this.app.i18n.messages[this.app.i18n.locale].CATEGORY_LIST["01"].caption,
      },
      {
        value: "02",
        label: this.app.i18n.messages[this.app.i18n.locale].CATEGORY_LIST["02"].label,
        icon: "mdi-office-building",
        title: this.app.i18n.messages[this.app.i18n.locale].CATEGORY_LIST["02"].title,
        caption: this.app.i18n.messages[this.app.i18n.locale].CATEGORY_LIST["02"].caption,
      },
      {
        value: "03",
        label: this.app.i18n.messages[this.app.i18n.locale].CATEGORY_LIST["03"].label,
        icon: "mdi-shovel",
        title: this.app.i18n.messages[this.app.i18n.locale].CATEGORY_LIST["03"].title,
        caption: this.app.i18n.messages[this.app.i18n.locale].CATEGORY_LIST["03"].caption,
      },
      {
        value: "04",
        label: this.app.i18n.messages[this.app.i18n.locale].CATEGORY_LIST["04"].label,
        icon: "mdi-dump-truck",
        title: this.app.i18n.messages[this.app.i18n.locale].CATEGORY_LIST["04"].title,
        caption: this.app.i18n.messages[this.app.i18n.locale].CATEGORY_LIST["04"].caption,
      },
      {
        value: "05",
        label: this.app.i18n.messages[this.app.i18n.locale].CATEGORY_LIST["05"].label,
        icon: "mdi-trash-can",
        title: this.app.i18n.messages[this.app.i18n.locale].CATEGORY_LIST["05"].title,
        caption: this.app.i18n.messages[this.app.i18n.locale].CATEGORY_LIST["05"].caption
      },
      {
        value: "06",
        label: this.app.i18n.messages[this.app.i18n.locale].CATEGORY_LIST["06"].label,
        icon: "mdi-airplane",
        title: this.app.i18n.messages[this.app.i18n.locale].CATEGORY_LIST["06"].title,
        caption: this.app.i18n.messages[this.app.i18n.locale].CATEGORY_LIST["06"].caption,
      },
      {
        value: "07",
        label: this.app.i18n.messages[this.app.i18n.locale].CATEGORY_LIST["07"].label,
        icon: "mdi-train-car",
        title: this.app.i18n.messages[this.app.i18n.locale].CATEGORY_LIST["07"].title,
        caption: this.app.i18n.messages[this.app.i18n.locale].CATEGORY_LIST["07"].caption,
      },
      {
        value: "08",
        label: this.app.i18n.messages[this.app.i18n.locale].CATEGORY_LIST["08"].label,
        icon: "mdi-car-clock",
        title: this.app.i18n.messages[this.app.i18n.locale].CATEGORY_LIST["08"].title,
        caption: this.app.i18n.messages[this.app.i18n.locale].CATEGORY_LIST["08"].caption,
      },
      {
        value: "09",
        label: this.app.i18n.messages[this.app.i18n.locale].CATEGORY_LIST["09"].label,
        icon: "mdi-dump-truck",
        title: this.app.i18n.messages[this.app.i18n.locale].CATEGORY_LIST["09"].title,
        caption: this.app.i18n.messages[this.app.i18n.locale].CATEGORY_LIST["09"].caption,
      },
      {
        value: "10",
        label: this.app.i18n.messages[this.app.i18n.locale].CATEGORY_LIST["10"].label,
        icon: "mdi-cogs",
        title: this.app.i18n.messages[this.app.i18n.locale].CATEGORY_LIST["10"].title,
        caption: this.app.i18n.messages[this.app.i18n.locale].CATEGORY_LIST["10"].caption,
      },
      {
        value: "11",
        label: this.app.i18n.messages[this.app.i18n.locale].CATEGORY_LIST["11"].label,
        icon: "mdi-human-dolly",
        title: this.app.i18n.messages[this.app.i18n.locale].CATEGORY_LIST["11"].title,
        caption: this.app.i18n.messages[this.app.i18n.locale].CATEGORY_LIST["11"].caption,
      },
      {
        value: "12",
        label: this.app.i18n.messages[this.app.i18n.locale].CATEGORY_LIST["12"].label,
        icon: "mdi-trash-can",
        title: this.app.i18n.messages[this.app.i18n.locale].CATEGORY_LIST["12"].title,
        caption: this.app.i18n.messages[this.app.i18n.locale].CATEGORY_LIST["12"].caption,
      },
      {
        value: "13",
        label: this.app.i18n.messages[this.app.i18n.locale].CATEGORY_LIST["13"].label,
        icon: "mdi-car-clock",
        title: this.app.i18n.messages[this.app.i18n.locale].CATEGORY_LIST["13"].title,
        caption: this.app.i18n.messages[this.app.i18n.locale].CATEGORY_LIST["13"].caption,
      },
      {
        value: "14",
        label: this.app.i18n.messages[this.app.i18n.locale].CATEGORY_LIST["14"].label,
        icon: "mdi-storefront",
        title: this.app.i18n.messages[this.app.i18n.locale].CATEGORY_LIST["14"].title,
        caption: this.app.i18n.messages[this.app.i18n.locale].CATEGORY_LIST["14"].caption,
      },
      {
        value: "15",
        label: this.app.i18n.messages[this.app.i18n.locale].CATEGORY_LIST["15"].label,
        icon: "mdi-cash-multiple",
        title: this.app.i18n.messages[this.app.i18n.locale].CATEGORY_LIST["15"].title,
        caption: this.app.i18n.messages[this.app.i18n.locale].CATEGORY_LIST["15"].caption,
      }
    ]

    const _operatorList = [
      { label: "+", value: "00" },
      { label: "-", value: "01" },
      { label: "×", value: "02" },
      { label: "÷", value: "03" },
    ]

    context.commit("SET_SCOPE_LIST", _scopeList)
    context.commit("SET_DIVISION_LIST", _divisionList)
    context.commit("SET_CATEGORY_LIST", _categoryList)
  },

  doSetTargetMonth: function (context, value) { context.commit("SET_TARGET_MONTH", value) },
  /** 拠点組織一覧にセット */
  doSetUnitList: function (context, value) { context.commit("SET_UNIT_LIST", value) },
  doSetBaseOrganizationList: function (context, value) { context.commit("SET_BASE_ORGANIZATION_LIST", value) },
  doSetOrganizationList: function (context, value) { context.commit("SET_ORGANIZATION_LIST", value) },
  doSetBaseList: function (context, value) { context.commit("SET_BASE_LIST", value) },
  doSetEmissionActivityList: function (context, value) { context.commit("SET_EMISSION_ACTIVITY_LIST", value) },
  doSetElectricCompanyList: function (context, value) { context.commit("SET_ELECTRIC_COMPANY_LIST", value) },
  doSetEmissionCoefficientList: function (context, value) { context.commit("SET_EMISSION_COEFFICIENT_LIST", value) },
  doSetEmissionIntensityList: function (context, value) { context.commit("SET_EMISSION_INTENSITY_LIST", value) },
  doSetTkgBasicUnitList: function (context, value) { context.commit("SET_TKG_BASIC_UNIT_LIST", value) },
  doSetCarFuelEconomyList: function (context, value) { context.commit("SET_CAR_FUEL_ECONOMY_LIST", value) },

  doSetDivisionTypeList: function (context, value) { context.commit("SET_DIVISION_TYPE_LIST", value) },
  doSetDivisionList: function (context, divisionList) { context.commit("SET_DIVISION_LIST", divisionList) },

  doSetLocationList: function (context, locationList) {
    context.commit("SET_LOCATION_LIST", locationList)
  },
  doSetLocationList: function (context) {
    const _locationList = [
      {
        value: "01",
        label: "本社",
        invalid: "0",
      },
      {
        value: "02",
        label: "大分工場",
        invalid: "0",
      },
      {
        value: "03",
        label: "研究開発センター",
        invalid: "0",
      },
    ]
    context.commit("SET_LOCATION_LIST", _locationList)
  },
  doSetDeptList: function (context, locationList) {
    const _deptList = [
      {
        loc_id: "01",
        dept_id: "honbu",
        value: "honbu",
        label: "管理本部",
        invalid: "0",
      },
      {
        loc_id: "01",
        dept_id: "daiichi",
        value: "daiichi",
        label: "第一事業部",
        invalid: "0",
      },
      {
        loc_id: "02",
        dept_id: "seizoubu",
        value: "seizoubu",
        label: "製造事業部",
        invalid: "1",
      },
      {
        loc_id: "03",
        dept_id: "kaihatsu",
        value: "kaihatsu",
        label: "研究開発事業部",
        invalid: "0",
      },
    ]
    context.commit("SET_DEPT_LIST", _deptList)
  },
  doSetHasMeisaiUpdateData: function (context, value) { context.commit("SET_MEISAI_UPDATE_DATA", value) },
  updateLocationList: function (context, { index, label }) {
    context.commit("UPDATE_LOCATION_LIST", { index, label })
  },
  updateDeptList: function (context, { index, label }) {
    context.commit("UPDATE_DEPT_LIST", { index, label })
  },
  updateLocationListInvalid: function (context, index) {
    context.commit("UPDATE_LOCATION_LIST_INVALID", index)
  },
  updateDeptListInvalid: function (context, index) {
    context.commit("UPDATE_DEPT_LIST_INVALID", index)
  },
  addLocationList: function (context, value) {
    context.commit("ADD_LOCATION_LIST", value)
  },
  addDeptList: function (context, value) {
    context.commit("ADD_DEPT_LIST", value)
  },
}
