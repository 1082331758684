export default class EmissionVersionManagement {
  constructor(axios) {
    this.axios = axios
  }

  getList() {
    return this.axios.$get('/api/v1/emission-intensity-exchange/versions')
  }

  update(payload) {
    return this.axios.$post('api/v1/emission-intensity-exchange/exchange', payload)
  }
}