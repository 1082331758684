export default {
  appname: "co2",
  menu: {
    "user-name-label": "User：",
    "customDashboardName": "Dashboard",
    "dashboard": "",
    "dashboard-analytics": "Emission data analysis",
    "activity-board": "Activity history",
    "activity-input": "Input of activity amount",
    "setting": "Setting",
    "setting-process": "Activities and processes",
    "setting-organization": "Organization information",
    "setting-company": "Company information",
    "setting-fiscal-year": "Fiscal year information",
    "setting-user": "User registration",
    "setting-mst-retail-electricity-providers": "Retail electricity supplier master",
    "setting-mst-retail-electricity-providers-emission": "Retail electricity supplier emission factor master",
    "setting-master-maintenance": "Master maintenance",
    "setting-mst-master-register-csv-export": "Master csv export/import",
    "setting-masterregistercsvexport": "Master registration/csv export screen",
    "setting-mst-emissions-unit-value-master": "Emission intensity master for users",
    'setting-mst-transport-master': 'Transport master',
    "setting-news": "Information",
    "setting-emission-version-management": "Emission unit conversion process",
    "alert-setting": "Input status/alert settings",
    "setting-industry-templates-master": "Template information by industry",
    "page-top": "To the top of the page",
    "setting-product-management": "Product management information",
    "product-management-production": "Input product information",
    "product-management-activity": "Enter details for each product",
    "product-management-simulation": "Products emmisions simulation",
    "emissions-calculation-guide": "Emissions calculation guide ",
    'reduction-target-input': 'Reduction target input',
    "data-extraction": 'Data extraction',
    logout: "Log out",
  },
  common: {
    company: "Whole company",
    location: "Base",
    affiliation: "Affiliation",
    wholeCompany: "Whole company",
    dept: "Division",
    selectAll: "Select all",
    filter: "Filter",
    save: "Save",
    category: {
      "01": "Category 1",
      "02": "Category 2",
      "03": "Category 3",
      "04": "Category 4",
      "05": "Category 5",
      "06": "Category 6",
      "07": "Category 7",
      "08": "Category 8",
      "09": "Category 9",
      "10": "Category 10",
      "11": "Category 11",
      "12": "Category 12",
      "13": "Category 13",
      "14": "Category 14",
      "15": "Category 15",
      other: "Other",
    },
    confirm: {
      warning: 'Note',
      confirm: 'Check',
      remove_confirm: 'Delete {name}. Are you sure?',
      remove_data: 'Delete data. Are you sure?',
      remove_unsaved_row: 'Delete blank row. Are you sure?',
      unsaved_data: 'Content not saved. Are you sure? <br>Clear content and go to next page. <br>"No": Return page {screenName}.',
      remove_existed_electric_company_confirm: 'Emission factor is already registered. Are you sure that the emission factor will be deleted?',
      remove_amount_used: 'Registered usage will be deleted. Are you sure?',
      closecurrenBase: 'This base is currently closed, is this correct?',
      inputConfirm: 'Activity cannot be entered for future years after the applicable end date, is this correct?',
      YES: "Yes",
      NO: "No",
      CANCEL: "Cancel",
      remove_amount: 'Delete',
      confirmText: 'There is. Is it OK?',
    },
    form: {
      noData: 'No matches found'
    },
    search: 'Search',
    yes: 'Yes',
    no: 'No',
    solve: 'Processing',
    ofBasic: 'Reason for',
    months: {
      Jan: '1',
      Feb: '2',
      Mar: '3',
      Apr: '4',
      May: '5',
      Jun: '6',
      Jul: '7',
      Aug: '8',
      Sep: '9',
      Oct: '10',
      Nov: '11',
      Dec: '12',
    },
    name: '',
    language: 'Language'
  },
  activity: {
    templateImport: "Template import",
    excelExport: "Download Excel",
    excelImport: "Import Excel",
    tempSave: "Save",
    monthlyUpdate: "Monthly update",
    targetMonth: "Subject year and month",
    divisionType: "Category",
    divisionTypeLocation: "Base",
    divisionTypeDepartment: "Division",
    tempTotalScore: "Whole company",
    selectScope: "Scope",
    filter: {
      targetMonthF: "Subject year and month From",
      targetMonthT: "Subject year and month To",
      inputDivision: "Subject category",
      inputSelectScope: "Subject scope",
    },
    input: {
      common: {
        activityName: "Name of activity",
        fuelType: "Fuel type",
        fuelUsage: "Fuel consumption",
        energyType: "Energy type",
        energyUsage: "Energy consumption",
        electricityUsage: "Electricity consumption",
        electricCompany: "Electric power company",
        electricPlan: "Electricity plan",
        hfcUsage: "HFC usage",
        weight: "Weight",
        machineModel: "Model",
        purchaseAmount: "Amount purchased",
        purchaseMoney: "Purchase amount",
        money: "Amount",
        departmentName: "Department name",
        productName: "Name of product",
        commercial: 'commercial',
        for_family: 'for family',
        for_private_family: 'private use',
        fuel_law: 'Fuel law',
        typical_value: 'Typical value',
        electricCompanyOrsupplyMenu: 'Electricity power company/Supply menu',
        calculation_target: 'Subject of calculation',
        calculation_method: 'Calculation method',
        activity_values: 'Consumption',
        emissions_intensity: 'Emission intensity',
        emission_amount: "Emissions",
        including_base: '(Include affiliations belonging to the base)',
        including_subordinates: '(Include affiliations belonging to subordinates)',
        rate: 'Percentage',
        amountCollected: 'Amount collected and properly disposed',
        apporionment_byconsignor: 'Allocation by shipper',
        refrigerantgas: 'Refrigerant gas',
        only: 'only',
        exceed_description_1: 'Due to the large number of cases, up to {record_limit} cases are shown.',
        exceed_description_2: 'To view all the entries, click on the View All button.',
      },
      capital: {
        capitalGoodsName: "Name of capital goods",
        capitalDivision: "Capital goods",
      },
      transport: {
        maximumPayload: "Maximum loading capacity",
        cargoWeight: "Cargo weight",
        transportDistance: "Transport distance",
        accumulationRate: "Loading rate",
        transportMode: "Transport mode",
      },
      trash: {
        disposalMethod: "Countermeasure",
        transportStage: "Transport stage",
      },
      business_trip: {},
      workCome: {
        traffic_class: "Transport category",
        traffic_allowance: "Transport allowance",
      },
      rease: {
        buildingUse: "Building use",
      },
      emission_factor_during_use: 'Emission factor in use (annual leakage rate)',
      global_warming_factor: 'Global warming factor',
      weight_of_cargo: 'cargo weight',
      business_activities_subject_to_accounting: 'Business activity subject to calculation',
      select_calculationMethod: 'Please select the method of calculation',
      fuel: 'Fuel consumption {fuelName}',
      raitoLoading: 'Loading rate {truckLoadRatioName}'
    },
    button: {
      search: "search",
      clear: "Search cancel",
      addInput: "Entry field",
      removeInput: "Clear entry field",
      add: "Add",
      update: "Update",
      show_all: 'View All'
    },
    board: {
      unInput: "Not entered",
    },
    category_1: {
      register_procurement: 'Procured item master registration',
      procurement_id: 'Procured item ID',
      procurement_name: 'Procured item name',
      transport_activity: 'Transport activity',
      confirm_create_procurment: 'Not registered name in master. <br>Do you want to register name in master?'
    },
    import_transport: {
      search_criteria: '[Search condition]',
      no_route: 'No route information.',
      headers: {
        activity_name: 'Activity name',
        supplier: 'Vender',
        shipping_from: 'Shipper/Consignee',
        memo: 'Memo',
      },
      load_ratio: {
        title: 'Loading rate setting',
        route: 'Route',
        load_ratio_setting: 'Loading rate setting',
        description: 'Please set truck loading rate.',
        truck_load_ratio: 'Truck loading rate'
      }
    },
    excel: {
      confirm_import: 'Replaces all data in {baseOrgName} in {targetMonth}. Is it OK?',
      savedData: 'Batch input Excel import has ended.'
    },
    guide: {
      emission_amount: 'CO2 emissions',
      unit: 'Unit',
      emission_intensity: 'Emission intensity',
      usage: 'Amount to use',
      example: "Ex:",
      activity: 'Activity',
      scope_one: {
        title: 'SCOPE1 Calculation method for (direct greenhouse gas emissions)',
        usage: 'Amount to use',
        activity_unit: 'kl,t,Nm3 etc.',
        description: 'Scope 1 targets "greenhouse gases that are directly emitted by the company". Calculate CO2 emissions for each fuel.',
        gasoline_usage: 'Use of gasoline',
        combustion: 'Combustion in the furnace',
        boiler: 'Use of boiler',
        gasoline: 'Gasoline',
        kerosene: 'Light oil',
        city_gas: 'City Gas',
        etc: "...etc",
        by_fuel: 'For each fuel',
        subtitle_one: 'Calculate CO2 emissions for each fuel',
        subtitle_two: 'Calculate SCOPE1 by totaling CO2 emissions for each fuel.',
        footer_description: 'Enter the amount of city gas used in Nm3 (normal lube). <br>Nm3 is the value obtained by multiplying m3 by 0.967.',
      },
      scope_two: {
        title: 'SCOPE2 Calculation method for (indirect emissions associated with the use of electricity and heat)',
        electricity_usage: 'Electricity usage',
        heat_usage: 'Heat usage',
        activity_unit: 'kl,t etc.',
        subtitle_one: '(1) Calculate CO2 emissions from electricity',
        subtitle_two: '(2) Calculate heat CO2 emissions',
        subtitle_three: 'Calculate SCOPE2 by adding CO2 emissions from electricity and CO2 emissions from heat',
        description: 'Scope 2 targets "greenhouse gases emitted outside the company to produce the energy used by the company". This applies to electricity and heat produced by other companies.',
        electricity_description_one: 'Calculate CO2 emissions for each electricity supply source',
        electricity_description_two: 'Calculate the CO2 emissions of electricity by totaling the CO2 emissions of each electricity supply source',
        heat_one: '1.Calculating CO2 Emissions from "Industrial Steam"',
        heat_two: '2.Calculation of CO2 emissions from "steam (other than industrial use)"',
        heat_total: 'CO2 emissions of “industrial steam” and “steam (Non-industrial)” total CO2 emissions',
        industrial_vapor: 'Industrial steam',
        vapor: 'Vapor (Non-industrial)',
        heat: 'Thermal',
        electricity: 'Electrical',
        by_electric_company: 'By electricity supply source',
        company_one: 'Company A',
        company_two: 'Company B',
        company_three: 'Company E',
      },
      scope_three: {
        million: 'One million yen, ten thousand yen',
        yen: 'circle',
        person: 'Man',
        by_fuel: "In the case of ",
        by_fuel_consumption: "In the case of ",
        fuel_consumption: "Fuel consumption",
        fuel_usage: "Fuel consumption",
        fuel_unit: "kl,l,t,kg",
        times: 'times',
        category_one: {
          description: 'If you select "Based on the input-output table", the calculation is as follows.',
          title: 'Category 1 “Purchased goods and services” calculation method',
          subtitle_one: 'Calculation method (1) Calculated based on “procurement volume”',
          procurement: 'Procurement volume',
          price: 'Amount of money',
          subtitle_two: 'Calculation method (2) Calculated based on the “amount”',
          footer_description: 'If you have obtained the basic unit by yourself, use "activity amount x basic unit".<br>Also, if you know the amount of emissions in advance, use "direct manual input".',
        },
        category_two: {
          description: 'When performing "calculation based on the price of purchased capital goods", the calculation is as follows.<br>If construction or manufacturing takes place over multiple years, the final year is counted together.',
          title: 'Calculation method for category 2 “Emissions from the construction and manufacture of own capital goods”',
          capital_investment_per_year: "Annual capital investment",
          footer_description: 'Calculate by accumulating emissions from each process and information obtained from suppliers<br>It is also possible.',
        },
        category_three: {
          title: 'Calculation method for Category 3 “Fuel and energy activities not included in SCOPE 1 and 2”',
          description: "Category 3 is “Energy used by the company [SCOPE1,SCOPE2] emissions from external mining and refining of these fuels”.",
          example_title: "Related to fuel and electricity used in the company",
          coal_mining: "coal mining",
          oil_mining: "oil extraction",
          oil_refining: "petroleum refining",
          etc: "...etc",
          fuel_procurement: "Amount of fuel procured",
          fuel_procurement_unit: "kWh,MJ,GJ",
        },
        category_four: {
          title: 'Category 4 Calculation method for “transportation and delivery (upstream)”',
          subtitle_one: 'Calculation method (1) “Fuel method”',
          subtitle_two: 'Calculation method (2) "Fuel efficiency method"',
          subtitle_three: 'Calculation method (3) “ton-kilometer method”',
          delivery_distance: "Transportation distance",
          delivery_ton_kilo: "Transportation ton-kilometer",
          ton_kilo_fuel_emission_intensity: "Ton-kilometer method<br>Unit consumption of fuel",
          ton_kilo_emission_intensity_by_vehicle: "Ton-kilometer method<br>Emissions intensity by means of transport",
          ton_kilo_description: "Transportation ton-kilometer=Cargo weight x transport distance (for each transport)",
          by_truck: "For trucks",
          by_others: "For railways, ships, and aviation",
          description: "Category 4 applies when transported. However, only when the company is the shipper, the delivery (downstream) from the company is also included.",
          attention: "For details on the scope of calculation for Category 4, see section 10 of the Operation Manual.. Please refer to.",
          attention: "Please refer to “10. the Operation Manual” for details of scope of calculation for Category 4.",
        },
        category_five: {
          title: 'How to Calculate Category 5 “Waste from Operations”',
          example_title: "Waste generated by the company",
          waste_disposal: "Waste disposal",
          recycling: "Recycling process",
          etc: "...etc",
          waste_amount: "Amount of waste",
          footer_description: 'If you have obtained the basic unit by yourself, use "activity amount x basic unit".<br>Also, if you know the amount of emissions in advance, use "direct manual input".',
        },
        category_six: {
          title: 'Category 6 How "business trip" is calculated',
          subtitle_one: 'Calculation method (1) Calculated from “travel distance, payment amount, etc.”',
          subtitle_two: 'Calculation method (2) Calculated from the number of employees',
          step_one: "1.Calculation of",
          co2_one: 'Of automobile fuel use',
          step_two: "2.Calculation of ",
          co2_two: 'In transportation',
          step_three: "3.Calculation of “emissions from accommodation”",
          co2_three: 'In accommodation',
          step_four: "Four.Calculated from",
          co2_four: 'Business trip days',
          step_five: "Five.Sum 1 to 4",
          transport_cost: "Transportation expenses paid",
          number_of_stays: "Number of stays",
          stays: 'overnight stay',
          number_of_days: 'Business trip days',
          days: 'person-day',
          migration_length: 'Moving distance',
          number_of_employee: "Number of employees",
        },
        category_seven: {
          title: 'Category 7 How to calculate "employer commute"',
          subtitle_one: 'Calculation method (1) Calculated from “commuting allowance”',
          subtitle_two: 'Calculation method (2) Calculated from the number of employees',
          description: 'Category 7 covers “employer commuting emissions”.<br>However, commuting in company-owned vehicles is not included.',
          step_one: "1.Calculation of ",
          step_two: "2.Calculation of ",
          step_three: "3.Calculate  (optional)",
          step_four: 'Four.Sum 1 to 3',
          transport_cost: "Transportation expenses paid",
          fuel_procurement: "Amount of fuel procured",
          fuel_procurement_unit: "kWh,Mj etc.",
          power_usage: "Electricity usage",
          fuel: 'Of automobile fuel use',
          emission_factor: "CO2 emission factor",
          migration_length: 'Moving distance',
          commuter: "Commuter",
          teleworker: "Telework worker",
          number_of_employee: "Number of employees",
        },
        category_eight: {
          title: 'How to calculate Category 8 “Upstream Leased Assets”',
          subtitle_one: 'Calculation method (1) When the consumption of each energy type can be grasped for each leased asset',
          subtitle_two: 'Calculation method (2) When the consumption ratio of each energy type cannot be grasped',
          two_description: '(Calculated by weighted average of building use and energy type)',
          subtitle_three: 'Calculation method (3) Calculated from “floor area” (for buildings)',
          amount_by_type: 'Energy type',
          energy_usage: 'Energy usage',
          avarage_by_type: 'Weighted average by energy type',
          floor_space: 'Floor area of building',
        },
        category_nine: {
          title: 'Category 9Calculation method for “transportation and delivery (downstream)”',
          subtitle_one: 'Calculation method (1) “Fuel method”',
          subtitle_two: 'Calculation method (2) "Fuel efficiency method"',
          subtitle_three: 'Calculation method (3) “ton-kilometer method”',
          fuel_consumption: "Fuel consumption",
          fuel_usage: "Fuel consumption",
          delivery_distance: "Transportation distance",
          delivery_ton_kilo: "Transportation ton-kilometer",
          ton_kilo_fuel_emission_intensity: "Ton-kilometer method<br>Unit consumption of fuel",
          ton_kilo_emission_intensity_by_vehicle: "Ton-kilometer method<br>Emissions intensity by means of transport",
          ton_kilo_description: "Transportation ton-kilometer=Cargo weight x transport distance (for each transport)",
          by_truck: "For trucks",
          by_others: "For railways, ships, and aviation",
          description: 'Category 9 covers "emissions during transportation to customers." This applies to the case of "transportation by another company and the other company as the shipper."',
          attention: 'For details on the scope of calculation for Category 9, see section 10 of the Operation Manual.. Please refer to "Master Maintenance Transportation Master Input Items".',
        },
        category_ten: {
          title: 'How to Calculate Category 10 “Processing of Products Sold”',
          subtitle_one: 'Calculation method (1) “Emissions data associated with processing can be obtained from sales destinations”',
          subtitle_two: 'Calculation method (2) “Energy consumption data associated with processing can be obtained from the purchaser.”',
          subtitle_three: 'Calculation method (3) “We are unable to obtain energy consumption data, etc. associated with processing from our customers.”',
          amount: "Sales volume of intermediate products",
          fuel_unit: "kl,l,t,kg, ect",
          energy_usage: "energy consumption",
          intermediate_product: 'Accompanying the processing of intermediate products',
          description: 'If the energy consumption data associated with processing cannot be obtained from the purchaser,<br>Calculated from sales volume.',
        },
        category_eleven: {
          title: 'Category 11 How to Calculate “Use of Products Sold”',
          subtitle_one: 'Direct use stage emissions',
          subtitle_two: 'Indirect use stage emissions',
          case_one: "For products that use fuel",
          case_two: "For products that use electricity",
          case_three: "For products that emit gas",
          case_four: "If the product is fuel",
          case_five: "Greenhouse gas-containing products that emit greenhouse gases when used",
          assumed_usage_in_life: "Assumed lifetime<br>Number of uses",
          number_of_sales: "Number of sales",
          fuel_usage_per_time: "Per use<br>fuel consumption",
          power_usage_per_time: "Electricity usage per use",
          greenhouse_gas_emissions: "Greenhouse gas<br>emissions",
          global_warming_potential: "Global warming potential",
          fuel_sales: "Fuel sales volume",
          greenhouse_gas_per_product: "Around the product<br>Greenhouse gas<br> content",
          emission_in_life: "Of lifetime use<br>emission rate",
          percentage_usage_in_life: "Percentage of expected lifetime usage",
          fuel_unit: "kl,l,t,kg etc",
        },
        category_twelve: {
          title: 'How to Calculate Category 12 “Disposal of Sold Products”',
          example_title: "Waste from sales destinations",
          waste_disposal: "Waste disposal",
          recycling: "Recycling process",
          etc: "...etc",
          waste_amount: "Amount of waste",
        },
        category_thirteen: {
          title: 'How Category 13 – Leased Assets (Downstream) – is calculated',
          subtitle_one: 'Calculation method (1) When consumption by energy type for each leased asset can be ascertained',
          subtitle_two: 'Calculation method (2) When the consumption ratio of each energy type cannot be determined',
          two_description: '(Calculated by weighted average of building use and energy type)',
          subtitle_three: 'Calculation method (3) Calculated from “floor area” (for buildings)',
          amount_by_type: 'Energy type',
          energy_usage: 'Energy usage',
          avarage_by_type: 'Weighted average by energy type',
          floor_space: 'Floor area of building',
        },
        category_fourteen: {
          title: 'Category 14 How "franchise" is calculated',
          description: 'If the reporting company is the franchise owner, Scope 1 in the franchise member (business operator that has signed a franchise agreement), 2 emissions are included in the scope of calculation.',
        },
        category_fifteen: {
          title: 'How is Category 15 “Investments” calculated?',
          description: 'If possible, use calculation method (1). If you cannot obtain emissions data by investment, please use calculation method (2).',
          subtitle_one: 'Calculation method (1) Calculate emissions of SCOPE 1 and 2 by accumulating them according to the investment equity ratio',
          subtitle_two: 'Calculation method (2) Estimation using economic data',
          case_one: '1.Calculate emissions from stock investment',
          case_two: '2.Calculating emissions from bond investments',
          case_three: '3.Calculate project emissions',
          case_four: 'Four.Calculate emissions from management investment and other investments (optional)',
          total: "Five. add up 1 to 4",
          stock_investment: 'Stock investment',
          bond_investment: 'Bond investment',
          project: 'Of the project',
          managed_investment: 'Management investment and<br>Other investments, etc.',
          managed_investment_target: "Company's SCOPE1,2の",
          stock_investment_ratio: 'Shareholding ratio',
          bond_investment_ratio: "Percentage of investee's total capital",
          project_ratio: 'Project<br>Percentage of investment amount',
          managed_investment_ratio: 'Customer percentage of total business',
          investment: 'Of the investment department',
          stock_value: 'Stock investment amount',
          bond_value: 'Debt investment amount',
          project_value: 'Project<br>total investment',
          other_value: 'Investment amount',
        },
      }
    },
    chart: {
      noData: "No suitable data available."
    }
  },
  // registRequest: {
  //   image: {
  //     fileBtn: "画像を追加",
  //     removeBtn: "表示中の画像を削除",
  //   },
  //   item: {
  //     currentBtn: "現在日時を設定",
  //     moneysuffix: "枚",
  //     summaryhint: "金額：[0]円",
  //   },
  //   tags: {
  //     addBtn: "タグを追加",
  //   },
  //   bundle: {
  //     addBtn: "同梱品を追加",
  //   },
  // },
  // printDocument: {
  //   criteria: {
  //     title: "印刷条件",
  //     category: "カテゴリ",
  //     additionalTitle: "追加条件",
  //     idF: "拾得物ID(From)",
  //     idT: "拾得物ID(To)",
  //     pickedDateF: "拾得日(From)",
  //     pickedDateT: "拾得日(To)",
  //     name: "名称",
  //     place: "拾得場所",
  //     storageLocation: "保管場所",
  //     storageTermF: "保管期限(From)",
  //     storageTermT: "保管期限(To)",
  //   },
  // },
  // item: {
  //   image: {
  //     title: "画像",
  //     image: "画像",
  //   },
  //   lost_property: {
  //     title: "物品情報",
  //     title_bundle: "内容物",
  //     management_title: "管理情報",
  //     item_id: "拾得物ID",
  //     item_id_suffix: "（自動採番）",
  //     picked_date: "拾得日",
  //     picked_time: "拾得時刻",
  //     acceptance_date: "受理日",
  //     acceptance_time: "受理時刻",
  //     picked_place: "拾得場所",
  //     item_category: "分類",
  //     item_category_label: "分類",
  //     item_sub_category: "中分類",
  //     item_sub_category_label: "中分類",
  //     item_class: "小分類",
  //     item_class_label: "小分類",
  //     is_valuables: "貴重品",
  //     item_valuables_flg_label: "貴重品",
  //     item_report_flg: "届出対象",
  //     item_report_flg_label: "届出対象",
  //     facility_right_claim: "施設権利",
  //     facility_right_claim_label: "施設権利",
  //     item_name: "品名",
  //     item_description: "詳細",
  //     storage_loc: "保管場所",
  //     storage_loc_label: "保管場所",
  //     storage_term: "保管期限",
  //     item_report_date: "警察届出日",
  //     property_remark: "備考",
  //     item_status: "状況",
  //     item_status_label: "状況",
  //     item_status_date: "状況更新日",
  //     item_staff_name: "対応者",
  //     print_no: "印刷番号",
  //     delete_flg: "削除フラグ",
  //     last_updated: "最終更新日",
  //     last_update_user_name: "最終更新者",
  //   },
  //   finder: {
  //     title: "拾得者情報",
  //     finder_name: "拾得者氏名",
  //     finder_type: "拾得者種別",
  //     finder_type_label: "拾得者種別",
  //     right_claim: "権利主張",
  //     right_claim_label: "権利主張",
  //     announcement_concent: "告知同意",
  //     announcement_concent_label: "告知同意",
  //     finder_tel: "拾得者電話番号",
  //     finder_zipcode: "拾得者郵便番号",
  //     finder_address: "拾得者住所",
  //     finder_remark: "拾得者備考",
  //   },
  //   owner: {
  //     title: "引渡情報",
  //     owner_name: "遺失者氏名",
  //     owner_tel: "遺失者電話番号",
  //     owner_zipcode: "遺失者郵便番号",
  //     owner_address: "遺失者住所",
  //     owner_remark: "遺失者備考",
  //     return_staff_name: "返却担当者",
  //     return_method: "返還方法",
  //     return_method_label: "返還方法",
  //     due_date: "返却予定日",
  //     returned_date: "返却完了日",
  //   },
  //   cash: {
  //     title: "金種情報",
  //     bill10000: "1万円札",
  //     bill5000: "5千円札",
  //     bill2000: "2千円札",
  //     bill1000: "1千円札",
  //     coin500: "500円硬貨",
  //     coin100: "100円硬貨",
  //     coin50: "50円硬貨",
  //     coin10: "10円硬貨",
  //     coin5: "5円硬貨",
  //     coin1: "1円硬貨",
  //     other: "その他有価証券",
  //   },
  //   keyword: {
  //     title: "検索用タグ",
  //     tagname: "タグ",
  //   },
  //   bundle: {
  //     title: "同梱情報",
  //     bundleid: "同梱品ID",
  //   },
  //   edit: {
  //     inputs: {
  //       current_date_btn: "現在日時を設定",
  //       add_image_btn: "画像を追加",
  //       add_keyword_btn: "タグを追加",
  //       add_bundle_btn: "同梱品を追加",
  //       money_suffix: "枚",
  //       other_suffix: "円",
  //       summary_hint: "金額：[0]円",
  //     },
  //   },
  //   list: {
  //     current: "表示条件：[0]",
  //     criteria_btn: "表示条件",
  //     criteria: {
  //       title: "SCOPE3　絞り込み",
  //       category: "カテゴリ",
  //       additional_title: "追加条件",
  //       id: "ID",
  //       status: "状況",
  //       picked_date_f: "拾得日From",
  //       picked_date_t: "拾得日To",
  //       name: "名称",
  //       item_valuables_flg: "貴重品",
  //       item_report_flg: "届出対象",
  //       place: "拾得場所",
  //       storage_location: "保管場所",
  //       storage_term_f: "保管期限From",
  //       storage_term_t: "保管期限To",
  //       due_date_f: "返却予定日From",
  //       due_date_t: "返却予定日To",
  //       freeword: "フリーワード",
  //       output_file_type: "出力帳票",
  //       exclude_printed: "印刷済み",
  //       created_f: "登録日From",
  //       created_t: "登録日To",
  //     },
  //   },
  //   detail: { title: "詳細" },
  // },
  // request: {
  //   image: {
  //     title: "参考画像",
  //     image: "参考画像",
  //   },
  //   request: {
  //     title: "探索対象",
  //     client_title: "依頼者情報",
  //     management_title: "管理情報",
  //     return_title: "返還情報",
  //     request_id: "探索依頼ID",
  //     lost_date: "紛失日",
  //     lost_feature: "詳細",
  //     lost_place: "紛失場所",
  //     requester_name: "依頼者氏名",
  //     requester_tel: "電話番号",
  //     requester_zipcode: "郵便番号",
  //     requester_address: "住所",
  //     requester_remark: "備考",
  //     found_company_id: "発見企業ID",
  //     found_office_id: "発見事業所ID",
  //     found_item_id: "発見拾得物ID",
  //     request_status: "状況",
  //     request_status_label: "状況",
  //     request_status_date: "状況更新日",
  //     request_staff_name: "対応者",
  //     request_contact_type: "連絡要否",
  //     request_contact_type_label: "連絡要否",
  //     request_contact_term: "連絡期限",
  //     last_updated: "最終更新日",
  //     last_update_user_name: "最終更新者",
  //   },
  //   list: {
  //     current: "表示条件：[0]",
  //     criteria_btn: "表示条件",
  //     criteria: {
  //       created_f: "登録日From",
  //       created_t: "登録日To",
  //       title: "表示条件",
  //       freeword: "フリーワード",
  //       id: "ID",
  //       status: "状況",
  //       lost_date_f: "紛失日From",
  //       lost_date_t: "紛失日To",
  //       request_contact_term_f: "連絡期限From",
  //       request_contact_term_t: "連絡期限To",
  //     },
  //   },
  //   detail: { title: "詳細" },
  // },
  maintenance: {
    org: {
      "base_name": "Base name",
      "start_month": "Application start date",
      "end_month": "Application end date",
      "electric_company_no": "Electric utility registration number",
      "work_style_city_code": "City category code by work arrangements",
      "display_order": "Display order",
      "organization_id": "Organization code",
      "parrent_id": "Parent affiliation",
      "organization_name": "Affiliation name",
      "affiliation_base": "Affiliation base",
      "display_order": "Display order",
      "baseOfList": "Base list ",
      "baseOfName": "Base name",
      warning_message_1: '{organizationName} has already been entered. Are you sure you want to change the base of the registered activity data?',
      warning_message_2: 'Do you want to update the application end date to "{baseEndMonth}" for the organizations belonging to this base? <br /> "Yes": Update the application end date of the above organization. <br /> "No": Do not update the application end date of the above organization.'
    },
    company: {
      title: "Company setting",
      detail_title: "Corporate information",
      company_id: "Company ID",
      company_name: "Company name",
      company_contact_name: "Company contact name",
      company_contact_tel: "Company contact phone number",
      company_contact_fax: "Company contact fax number",
      company_contact_email: "Company contact email address",
      updated: "Last update",
      company_name_label: "Company name",
      company_name_label_kana: "Company name(katakana)",
      zip_code: "Postal code",
      address_city: "Located Prefecture Municipality",
      address_house_number: "Location number",
      specific_emitter_code: "Specific emitter code",
      capital_formation_code: "Capital formation sector code",
      organization1_name: "Organizational hierarchy 1 name",
      organization2_name: "Organizational hierarchy 2 name",
      organization3_name: "Organizational hierarchy 3 name",
      organization4_name: "Organizational hierarchy 4 name",
      franchise_flag_name: "Franchise development",
      business_year_start_at: "Start month of fiscal year",
      organizationName: "Affiliation name",
      noparent_organization: 'No parent organization',
      organizationOfList: 'Affiliation list',
    },
    // company_settings: {
    //   detail_title: "操作設定",
    //   label: {
    //     default_storage_selected: "保管場所初期値",
    //     default_user_input: "対応者初期値",
    //     default_facility_right_claim: "施設権利初期値",
    //     update_place_hisstory: "拾得場所自動更新",
    //     default_finder_type: "拾得者種別初期値",
    //     default_item_complete_status: "拾得物完了登録初期値",
    //     finder_input_always: "拾得者情報入力",
    //     item_insert_transition: "拾得物登録時移動先",
    //     item_update_transition: "拾得物更新時移動先",
    //     request_insert_transition: "探索依頼登録時移動先",
    //     request_update_transition: "探索依頼更新時移動先",
    //   },
    // },
    // office: {
    //   title: "事業所設定",
    //   office_list_title: "事業所一覧",
    //   place_list_title: "拾得場所一覧",
    //   sl_list_title: "保管場所一覧",
    //   detail_title: "事業所情報",
    //   doc_info_title: "届出書類印字設定",
    //   doc_informant_title: "届出者情報",
    //   office_id: "事業所ID",
    //   office_name: "事業所名",
    //   send_to: "届出先",
    //   informant_name: "届出者名",
    //   informant_address: "届出者住所",
    //   informant_tel: "届出者電話番号",
    //   informant_other_contact: "届出者その他連絡先",
    //   rep_post: "代表者役職",
    //   rep_name: "代表者名",
    //   office_storage_loc_name: "保管場所名称",
    //   office_storage_loc_address: "保管場所所在地",
    //   office_storage_loc_tel: "保管場所電話番号",
    //   office_storage_loc_other_contact: "保管場所その他連絡先",
    //   picked_placeType: "拾得場所区分",
    //   cities_code: "市区町村コード",
    //   office_picked_place_name: "拾得場所施設名",
    //   office_picked_place_address: "拾得場所所在地",
    //   default_finder: "拾得者初期値",
    //   informant_picked_place_flg: "届出時拾得場所",
    //   updated: "最終更新日",
    //   doc_info: "帳票設定",
    //   place_count: "拾得場所 : 保管場所",
    //   modify_buttons: "",
    // },
    user: {
      title: "User list",
      detail_title: "User information",
      office_id: "Business office ID",
      office_name: "Business office",
      user_id: "User ID",
      user_name: "User name",
      role_id_list: "Authority",
      updated: "Last updated",
      modify_buttons: "",
      user_password: "Password",
      confirm_password: "Password (verify)",
    },
    edit: {
      password: {
        title: "Change Password",
        old_password: "Current Password",
        new_password: "New Password",
        confirmed_password: "New password (verify)",
        change_success: 'Password changed',
        change_failed: 'Password change failed',
      },
    },
  },
  // registReceipt: {
  //   search: {
  //     title: "更新対象",
  //     sequenceNo: "印刷整理番号",
  //   },
  //   edit: {
  //     title: "届出情報",
  //     staff_name: "担当者名",
  //     receipt_no: "受理番号",
  //     receipt_date: "受理日",
  //     receipt_time: "受理時刻",
  //   },
  //   list: {
  //     title: "対象一覧",
  //   },
  // },
  item: {
    list: {
      criteria: {
        title: "SCOPE3 Filter",
      },
    },
  },
  message: {
    noOptionsText: "No {0} was found.",
    noChildrenText: "No {0} was found.",
    removeInputListItem: "Cancel entry field. Are you sure?",
    emissionsTooHigh: "Emission is too large. Check the amount of activity",
    amount_used_toohigh: 'Usage is too large. Please check',
    error: 'Sorry, error occurred',
    contactAddress: 'Contact the system administrator.',
    addedData: 'Data has been added.',
    savedData: 'Data has been saved successfully.',
    updatedData: 'Data updated.',
    deletedDate: 'Data has been deleted',
    helpMessage1: 'Electricity consumption supplied by others',
    helpMessage2: 'Heat consumption supplied by others',
  },
  api: {
    register_success: 'Registered.',
    update_success: 'Updated.',
    remove_success: 'Deleted.',
    error: 'Some error occurred. Try again later.',
    duplicated_error: 'The {name} already exists. Please enter another {name}.',
    add_success: 'Added.',
  },
  scope: {
    1: 'SCOPE1 Direct greenhouse gas emissions',
    2: 'SCOPE2 Indirect emissions from electricity and heat use',
    3: 'SCOPE3 Other indirect emissions',
  },
  'fiscal_year_info': {
    headers: {
      'fiscal_year': 'Fiscal year',
      'emission_target_value': 'Emission targets',
      'personnel': 'Number of employees',
      'business_days': 'Number of working days',
      'category6_calculation_flag': 'Calculation method for category 6 business travel',
      'calculation_basedon_number_of_employees': 'Calculation on number of employees basis',
      'calculation_basedon_distance_paymentamount': 'Calculation on distance travelled, amount paid, etc.',
    },
    remove_row_message: 'Delete fiscal year information. Are you sure?',
    change_calculation_error: 'Activity amount already entered using last calculation method.',
    unit: {
      people: 'Person',
      day: 'Days',
    }
  },
  buttons: {
    add: 'Add',
    edit: 'Modification',
    update: 'Update',
    add_row: 'Add row',
    regenerate_password: 'Reissue password',
    save: 'Save',
    search: 'Search',
    csvExport: 'csv export',
    csvImport: 'csv import',
    detail: 'Details',
    import_transport_activty: 'Transport activity import',
    import: 'Import',
    setting: 'Setting',
    back: 'Return',
    delete: 'Delete',
    register: 'Register',
    exportData: 'Data export',
    change: 'Change',
    movie: 'Watch video',
    imageUpload: 'Upload an image',
    apply: 'Apply'
  },
  user: {
    remove_user: 'Delete user ({userId}). Are you sure?',
    regenerate_password_confirm: 'Reissue password for user ({userId}). Are you sure?',
    register_success: 'Following user registration completed.',
    regenerate_password_success: 'Following user password re-issued.',
    form: {
      userId: 'User ID',
      password: 'Password',
      temp_password: 'Temporary password'
    },
    loged_in: "Logged-in user cannot be deleted.",
  },
  login: {
    not_authorized_error: 'Incorrect ID or password.',
    input_new_password: 'Please enter new password.',
    setting: 'Setting',
    login: "Login"
  },
  master_maintenance: {
    explainText: 'When updating master, export csv file to modify and import it.',
    explainText1: '*All items update with CSV content.',
    selectFirstValue: 'Please select the target master',
    confirmUploadFIle: 'Import {fileName}. Are you sure?',
    retail_electricity_providers: {
      headers: {
        electricCompanyNo: 'Electric utility registration number',
        electricCompanyName: 'Electric utility name',
        electricCompany: 'Electricity supplier',
        displayOrder: 'Display order',
        memo: 'Memo',
        displayFlag: 'Not displayed',
        calculationMethod: 'Calculation method',
        supplyMenu: 'Supply menu',
        emissionFactor: 'Emission factor'
      },
      errors: {
        checkSupplyMenu: "Same electricity supplier without and with supply menu cannot be mixed.",
        checkDuplicate: 'Same electricity utility/method of calculation/supply menu exists.',
      },

      duplicated_error: 'Duplicate electricity utility registration number.'

    },
    fileTypeCheck: 'Please select csv file format.',
    fileNameCheck: 'File name is incorrect.',
    dataFileCheck: 'Csv file has no content. Check the file contents.',
    firtCheckData: 'Empty data is imported. Are you sure that the master will be cleared of all items?',
    checkNumberHeaders: 'Csv file does not match the master, check the number of items in the csv data. Subject data: {countRows} line 1.',
    checkNumberRecords: 'The csv contents do not match the master. Check the number of items in the csv data. Target data: Line {countRows}',
    checkHeaderName: 'Csv file does not match the master, check the csv header.',
    checkFirts: 'Importing file now...',
    add_description: 'Row cannot be added during search. To add rows, cancel the search once.',
    emission_unit_value_master: {
      headers: {
        usage: 'Purpose',
        emissionintensity: 'Emission intensity',
        unit: 'Unit',
        basis: 'Basis',
        category: 'Category to be used',
      },
    },
    transport_master: {
      headers: {
        transport_name: 'Name of transport activity',
        transport_id: 'Transport activity ID',
        procurement_shipping: 'Procurement / shipping',
        suppliers: 'Suppliers',
        location: 'Consignee/shipper (Place)',
        memo: 'Memo',
        route: 'route'
      },
      dialog: {
        transport_activity: 'Transport activity',
        route_detail: 'Route details',
        transport_emission: 'Emissions from transport',
        route_name: 'Name of route',
        input_method: 'Input method',
        calculation_method: 'Calculation method',
        fuel_type: 'Fuel type',
        fuel_consumption: 'Fuel consumption',
        transport_agency: 'Transport organization',
        transport_class: 'Transport category',
        transport_distance: 'Transportation distance',
        cargo_volume: 'Cargo weight',
        car_business_private: 'Commercial/private use',
        understand_fuel: 'Fuel identification'
      }
    },
    news_providers: {
      headers: {
        news_start_date: 'Start period',
        news_end_date: 'End period',
        news_content: 'Content',
        delivery_base: 'Subject site',
        delivery_organization: 'Subject affiliation',
        division: 'Category',
      },
      news_text: "Information",
    }
  },
  news: {
    news: 'Information',
    emergency: 'Emergency',
    important: 'Important',
    normal: 'Normal',
    counter: 'Pieces'
  },
  emission_version_management: {
    headers: {
      tableName: 'Table Name',
      version: 'Version',
      updateDate: 'Update Date',
      updateName: 'Update Name',
    },
    update_history: '<<History>>',
    emission_intensity: 'Emission Intensity',
    retail_electric: 'Retail Electric',
    global_warming_potential: 'Global Warming Potential',
    template: 'Industry Template',
    reference_page: 'Reference Page',
    go_to_other_page: '(transition to an external site)',
    version: 'Version',
    revised_version: 'Revised edition',
    descript1: 'Ministry of the Environment Green Value Chain Platform',
    descript2: 'Ministry of the Environment page related to emission factors by electric power supplier',
    emission_intensity_warning: 'Bulk replace emissions intensity data with the selected version. Is it OK?',
    retail_electric_warning: 'Bulk replace retail utility data with the selected version. Is it OK?',
    global_warming_potential_warning: 'Bulk replace the global warming potential data with the selected version. Is it OK?',
    template_warning: 'Batch replace the template data with the selected version. Is it OK?',
    saved_data: 'Changed version of {tableName}.'
  },
  user_registration: {
    user_id: "User ID",
    user_name_kanji: "User name",
    user_name_kana: "User name (katakana)",
    authority: "Authority",
    organization: "Organization",
    email: "Email address",
    update_self: 'If changed your authority, you will be automatically logged out.<br>Please log in again.'
  },
  emissions_cal_guide: {
    title: 'Title',
    url: 'URL',
    overview: 'Overview',
    thumbnail: 'Thumbnail image',
    imageUpload: 'File Name',
    display: 'Display',
    description: '[OVERVIEW]',
    remark: 'jpg and png files under 1MB can be used'
  },
  industry_templates_master: {
    search_condition: {
      industry: "Business type"
    },
    category_dialog: {
      template: "Template",
      detail: "Detail",
      category: "Category",
    },
    headers: {
      scope3_category: "SCOPE3 category",
      activity_name: "Activity name",
      calculation_method: "Calculation method",
      download_target: "Subject of import",
      update_name: "Updater",
    },
    change_industry: {
      confirm: 'Already exists a template for {industryName}, do you want to continue?',
    },
    not_editable: "Application to sites/affiliations has been closed. Templates cannot be edited.",
    select_all: "Capture target: All capture",
    cancel_all: "Capture target: All release",
  },
  product_production: {
    manufacture_month: "Manufacturing control date",
    products: "product",
    headers: {
      product_name: "Product name",
      product_production_id: "Management ID",
      production_count: "Number of production",
      production_description: "(for apportionment of activity amount)",
      procure_year: "Procurement date",
      procurement_name: "Procured goods",
      activity_amount: "Activity",
      emission_amount: "Emissions",
      activity_year: "Activity date",
      activity_name: "Activity name",
      emission_rate: "Emission rate",
      emissions_per_product: 'Emissions per product',
      unit: "Production unit",
    },
    msg: {
      deleteConfirmMsg: 'Delete {productName}:{productControlId}. Is it OK?',
      productDetailMsg: 'Detailed input for each product',
      choose: 'Choose',
      noData: 'No Data Available',
    },
    titles: {
      procurement: '　　Supply　　',
      manufacturing: '　　Manufacture　　',
      distribution: '　　Distribution　　',
      utilization: '　　Utilization　　',
      wasteRecycling: 'Waste Recycling',
      procurement_first: "<Purchased products/services Scope 3 Category 1>",
      manufacturing_first: "<Direct emissions Scope 1>",
      manufacturing_second: "<Indirect emissions Scope 2>",
      distribution_first: "<Transportation (Upstream) Scope 3 Category 4>",
      distribution_second: "<Transportation (Downstream) Scope 3 Category 9>",
      utilization_first: "<Product processing Scope 3 category 10>",
      utilization_second: "<Product use Scope 3 Category 11>",
      wasteRecycling_first: "<Product Disposal Scope 3 Category 12>",
    },
    re_import: 'Re-import',
    removed: 'Activity Removed',
    activity_modal: {
      pattern_one: 'Use the data entered on the activity amount input screen',
      pattern_two: 'Enter data directly',
      headers: {
        productActionName: 'Product Action Name',
        activityVolume: 'Activity Volume',
        evidence: 'Evidence',
        memo: 'Memo',
        updateDate: 'UpdateDate',
        updateName: 'UpdateName',
      },
      button: {
        input: "Input"
      }
    },
    activity_model_title: 'Product data selection'
  },
  product_simulation: {
    headers: { simulationId: "Simulation No.", },
    msg: {
      deleteConfirmMsg: 'Delete {productName}:{simulationControlId}. Is it OK?',
      dataIsExisted: 'The same product name has already been registered. Please change.',
      addNew: 'Set new product'
    },
    add_details: "Add details",
    add: "Simulations",
  },
  unauthorized: {
    description: 'Not authorized.'
  },
  dashboard: {
    list: 'List',
    barGraph: 'Bar graph',
    yenGraph: 'Pie graph',
    reductionTrend: "Reduction trend",
    pie_by_chart: {
      breakdown_of_emissions: "Break down of emissions",
    },
    totalEmissions: "Total emissions",
    Scope1: {
      title: "Direct emissions from in-house fuel use & etc",
      descript: "",
    },
    Scope2: {
      title: "Indirect emissions from the use of electricity & steam",
    },
    button: {
      baseDisplay: "Base",
      affiliationDisplay: "Affiliation",
    },
    title: {
      base: "Emissions by base",
      affiliation: "Emissions by affiliation",
      category: "Emissions by category",
      action: "Emissions by activity",
    },
    msg: {
      noData: "No activity data available",
    },
  },
  dashboardAnalytisc: {
    by_scope: {
      titles: {
        total_score: 'Accumulated overall CO2 emissions value',
        objective_score: 'Target value for overall CO2 emissions',
        upstream: 'Up Stream',
        downstream: 'Down Stream',
        own_company: 'Own Company',
      },
      descriptions: {
        scope_one: 'Direct emissions from own fuel use and industrial processes',
        scope_two: 'Indirect emissions from electricity and heat purchased by own company',
      }
    },
    by_bar_chart: {
      titles: {
        rate_per_month: 'Overall CO2 emissions trends',
        rate_by_fuel: 'Changes in emissions by fuel',
        rate_by_base: 'Changes in emissions by site',
        breakdown_of_emissions_by_fuel: "Trends in emissions breakdown by fuel",
      },
      checkbox_label: 'Show forecast',
      month: 'Month',
      rate_by_year: 'Display year-by-year trend graph',
    },
    by_pie_chart: {
      titles: {
        // chart_by_scope: 'Breakdown by SCOPE',
        // scope_three_all_org: 'Breakdown by company-wide SCOPE3',
        by_dept: 'Breakdown by affiliation',
        by_dept_scope: 'Breakdown of SCOPE by affiliation',
        by_loc: 'Breakdown by site',
        by_loc_scope: 'Breakdown of SCOPE by site',
        by_dept_table: 'CO2 emissions by organization (highest level)',
        by_loc_table: 'CO2 emissions by site'
      },
      checkbox_label: 'Show forecasts',
    },
    by_product: {
      headers: {
        productName: 'Product name',
        productId: 'Product ID',
        productionControlId: 'Management ID',
        manufactureMonth: 'Manufacturing control month',
        productionCountUnit: 'CO2 emissions per product',
        procurement: 'Procurement',
        manufacture: 'Manufacturing',
        distribution: 'Distribution',
        utilization: 'Use',
        recycle: 'Waste/Recycling',
      },
      display_year: 'Display year:',
      year: 'Year',
      product_manufacture_month: 'Manufacturing control month',
      product_name: 'Product name',
      all_year: 'All years'
    },
    by_reduction: {
      condition: {
        dateDisplay: "Date",
        reductionTargetClassification: "Classification",
        baseName: "Base",
        orgName: "Affiliation",
        productName: "Product",
        baseYear: "Base year",
        baseMonth: "Base month"
      },
      radioItem: {
        nendo: "Year",
        month: "Month"
      },
      classificationsItem: {
        companyWide: "Company-wide",
        base: "Base",
        affiliation: "Affiliation",
        product: "Product",
      },
      chart: {
        actual: "Actual",
        target: "Target",
      }
    },
    emission_amount: 'CO2 emissions',
    year: 'Year',
    selected_year: 'Display fiscal year',
    start_month: 'Display start month',
    end_month: 'Display end month',
    month: 'Month',
    no_subordinate_organizations: 'No subordinate organization',
    // list: 'List',
    barGraph: 'Monthly transition graph',
    yenGraph: 'Base・Affiliation breakdown graph',
    chartUnit: 'Unit:t-CO2',
    reductionTrend: "Reduction trend graph",
    eachProduct: "List of emissions by product",
  },
  detailScreen: {
    usage: 'Amount to use',
    basis_for_amount_used: 'Basis of usage',
    inputdate: 'Date of entry',
    enter_er: 'Input person',
    memo: 'Memo',
    add_input_line: '+ Add input row',
    detail_copy: 'Detail copy',
    quantity: 'Material volume',
  },
  scope3AddDialog: {
    cannot: 'Can not'
  },
  pageNotFound: {
    description: 'The specified page does not exist.'
  },
  procurelabelName: 'Procurement name',
  SCOPE_ONE_INPUT_TABLE_HEADER: {
    productActionName: "Activity name",
    activityName: "Calculation target",
    calc_source: "Calculation method",
    activity_values: "Activity",
    coef: "Emission intensity",
    score: "Emissions",
  },
  SCOPE_ONE_INPUT_TABLE_HEADER_2: {
    S1_G0003: {
      productActionName: "Activity name",
      emissionActivity: 'Emission activity/category',
      activity_values: "Activity",
      emissionIntensity: "Emission intensity",
      gwp: "Gwp",
      emissionVolume: "Emissions",
      SUBCOLUMNS: {
        calorificValue: "Calorific Volume",
        recoveryVolume: "Recovery Volume",
        residualVolume: "Residual Volume",
        percentageOfOneyear: "Percentage Of One Year",
        activityVolume: "Activity Volume",
      }
    }
  },
  SCOPE_ONE_ADD_DIALOG: {
    S1_G0003: {
      productActionName: "Activity name",
      greenhouseGasType: "Greenhouse Gas Type",
      greenhouseGas: "Greenhouse Gas",
      emissionActivity: "Emission Activity",
      calculationMethod: "Calculation Method",
      inputField1Name: "Item name for value 1",
      inputField2Name: "Item name for value 2",
      memo: "Memo",
    }
  },
  SCOPE_TWO_INPUT_TABLE_HEADER: {
    activity_name: "Calculation target",
    calc_source: "Calculation method",
    electric_company: "Power company/supply menu",
    activity_values: "Activity",
    coef: "Emission intensity",
    score: "Emissions",
  },
  SCOPE_THREE_INPUT_TABLE_HEADER: {
    other: {
      activity_name: "Activity name",
      calc_source: "Calculation method",
      activity_values: "Activity",
      coef: "Emission intensity",
      score: "Emissions",
    },
    DB1: {
      activityName: "Activity name",
      productionControlId: "Management ID",
      calculationMethod: "Calculation method",
      activity_values: "Activity",
      emissionVolume: "Emissions",
    },
    DB2: {
      activityName: "Activity name",
      calculationMethod: "Calculation method",
      activity_values: "Activity",
      emissionIntensity: "Emission intensity",
      emissionVolume: "Emissions",
      remark: "Enter your actual activity",
    },
    DB3: {
      activityName: "Activity name",
      calculationMethod: "Calculation method",
      activity_values: "Activity",
      emissionIntensity: "Emission intensity",
      emissionVolume: "Emissions",
      remark: "Enter your actual activity",
    },
    DB4: {
      activityName: "Activity name",
      calculationMethod: "Calculation method",
      activity_values: "Activity",
      emissionVolume: "Emissions",
      remark: "Enter your actual activity",
    },
    DB5: {
      productName: "Procurement name",
      departmentName: "&nbsp;&nbsp;Department name<br/>(Items in the Input-Output Table)",
      calculationMethod: "Calculation method",
      activity_values: "Activity",
      emissionIntensity: "Emission intensity",
      emissionVolume: "Emissions",
    },
    DB0: {
      activityName: "Activity name",
      calculationMethod: "Calculation method",
      activity_values: "Activity",
      emissionVolume: "Emissions",
    },
    DB8: {
      activityName: "Activity name",
      calculationMethod: "Calculation method",
      calculationTargetName: "Waste type",
      activity_values: "Activity",
      emissionIntensity: "Emission intensity",
      emissionVolume: "Emissions",
    },
    S3_C14: {
      ACTIVITY_HEADERS: {
        productActionName: "Activity name",
        calculationMethodName: "Calculation method",
        activityValues: "Activity",
        emissionVolume: "Emissions",
      },
      SUBCOLUMNS: {
        activityVolume: "Emissions"
      },
      ADD_DIALOG: {
        productActionName: "Activity name",
        calculationMethod: "Input method",
        inputField1Name: "Item name of activity amount",
        inputField2Name: "Emission item name",
        memo: "Memo",
      }
    },
    S3_C06: {
      ACTIVITY_HEADERS: {
        productActionName: "Activity name",
        calculationMethodName: "Calculation method",
        activityValues: "Activity",
        emissionUnit: "Emission intensity",
        emissionVolume: "Emissions",
      },
      SUBCOLUMNS: {
        activityVolume1: "Passenger-km",
        activityVolume2: "Fuel consumption",
        activityVolume3: "Moving distance",
        fuelEconomy: "Fuel consumption",
        activityVolume4: "Payment amount",
        activityVolume5: "Number of nights",
        activityVolume6: "Man days",
        activityVolume7: "Number of employees",
        activityVolume8: "Emissions",
      },
      ADD_DIALOG: {
        productActionName: "Activity name",
        calculationMethod: "Input method",
        calculationTarget: "Calculation method (traffic classification)",
        fuelMethod: "Automobile calculation method",
        fuelMethodItem1: "Fuel law",
        fuelMethodItem2: "Fuel consumption method",
        fuel: "Fuel type",
        fuelConsumption: "Classification of transportation",
        fuelCode: "Understand fuel consumption",
        fuelCodeItem1: "Can not",
        fuelCodeItem2: "Can",
        transExps: "Calculation method (traffic classification)",
        travelDays: "Business trip type",
        inputField1Name: "Item name with value 1",
        inputField2Name: "Item name for value 2",
        memo: "Memo",
        understandingFuelConsumption: "understand fuel consumption",
      },
      DETAIL_DIALOG: {
        '0125': {
          createDate: "Input date",
          createName: "Enterer",
          activityVolume: "Number of people",
          activityUnit: "Person",
          transportDistance: "Moving distance",
          evidence: "Basis of activity",
          activityDetailMemo: "Memo",
          remove: "Delete",
          action: "Detail copy",
        },
        '0108': {
          createDate: "Input date",
          createName: "Enterer",
          activityVolume: "Amount to use",
          evidence: "Basis of activity",
          activityDetailMemo: "Memo",
          remove: "Delete",
          action: "Detail copy",
        },
        '0109': {
          createDate: "Input date",
          createName: "Enterer",
          activityVolume: "Moving distance",
          evidence: "Basis of activity",
          activityDetailMemo: "Memo",
          remove: "Delete",
          action: "Detail copy",
        },
        '0127': {
          createDate: "Input date",
          createName: "Enterer",
          activityVolume: "Number of people",
          allowance: "Payment amount",
          evidence: "Basis of activity",
          activityDetailMemo: "Memo",
          remove: "Delete",
          action: "Detail copy",
        },
        '0128': {
          createDate: "Input date",
          createName: "Enterer",
          activityVolume: "Number of people",
          dayCount: "Number of days",
          dayCountUnit: "days",
          evidence: "Basis of activity",
          activityDetailMemo: "Memo",
          remove: "Delete",
          action: "Detail copy",
        },
        '0129': {
          createDate: "Input date",
          createName: "Enterer",
          activityVolume: "Number of people",
          dayCount: "Number of days",
          evidence: "Basis of activity",
          activityDetailMemo: "Memo",
          remove: "Delete",
          action: "Detail copy",
        }
      }
    },
    S3_C07: {
      ACTIVITY_HEADERS: {
        productActionName: "Activity name",
        calculationMethodName: "Calculation method",
        activityValues: "Activity",
        emissionUnit: "Emission intensity",
        emissionVolume: "Emissions",
      },
      SUBCOLUMNS: {
        activityVolume1: "Passenger-km",
        activityVolume2: "Fuel consumption",
        activityVolume3: "Moving distance",
        fuelEconomy: "Fuel consumption",
        activityVolume4: "Payment amount",
        fuelActivityVolume: "Fuel consumption",
        activityVolume5: "Electricity usage",
        activityVolume6: "Number of employees",
        businessDaysYear: "Annual business days",
        businessDaysYearUnit: "days",
        activityVolume7: "Emissions",
      },
      ADD_DIALOG: {
        productActionName: "Activity name",
        calculationMethod: "Input method",
        calculationTarget: "Calculation method (traffic classification)",
        fuelMethod: "Automobile calculation method",
        fuelMethodItems1: "Fuel law",
        fuelMethodItems2: "Fuel consumption method",
        fuel: "Fuel type",
        fuelConsumption: "Classification of transportation",
        fuelCode: "Understand fuel consumption",
        fuelCodeItem1: "Can not",
        fuelCodeItem2: "Can",
        transExps: "Calculation method (traffic classification)",
        telework: "Types of fuel other than electricity",
        electricCodesRes: "Electricity calculation method",
        retailElectricCompanies: "Power company",
        supplyCompanies: "Supply menu",
        inputField1Name: "Item name with value 1",
        inputField2Name: "Item name for value 2",
        memo: "Memo",
        understandingFuelConsumption: "understand fuel consumption",
        electricLabel: "Electricity"
      },
      DETAIL_DIALOG: {
        '0125': {
          createDate: "Input date",
          createName: "Enterer",
          activityVolume: "Number of people",
          activityUnit: "Person",
          transportDistance: "Moving distance",
          evidence: "Basis of activity",
          activityDetailMemo: "Memo",
          remove: "Delete",
          action: "Detail copy",
        },
        '0108': {
          createDate: "Input date",
          createName: "Enterer",
          activityVolume: "Amount to use",
          evidence: "Basis of activity",
          activityDetailMemo: "Memo",
          remove: "Delete",
          action: "Detail copy",
        },
        '0109': {
          createDate: "Input date",
          createName: "Enterer",
          activityVolume: "Moving distance",
          evidence: "Basis of activity",
          activityDetailMemo: "Memo",
          remove: "Delete",
          action: "Detail copy",
        },
        '0127': {
          createDate: "Input date",
          createName: "Enterer",
          activityVolume: "Number of people",
          allowance: "Payment amount",
          evidence: "Basis of activity",
          activityDetailMemo: "Memo",
          remove: "Delete",
          action: "Detail copy",
        },
        '0131': {
          createDate: "Input date",
          createName: "Enterer",
          fuelActivityVolume: "Fuel consumption other than electricity",
          activityVolume: "Electricity usage",
          evidence: "Basis of activity",
          activityDetailMemo: "Memo",
          remove: "Delete",
          action: "Detail copy",
        }
      }
    },
    S3_C08: {
      ACTIVITY_HEADERS: {
        productActionName: "Activity name",
        calculationMethodName: "Calculation method",
        activityValues: "Activity",
        emissionUnit: "Emission intensity",
        emissionVolume: "Emissions",
      },
      SUBCOLUMNS: {
        activityVolume1: "Amount to use",
        activityVolume2: "Energy consumption",
        activityVolume3: "Building area",
        distribution: "Apportionment",
        activityVolume4: "Emissions",
      },
      ADD_DIALOG: {
        productActionName: "Activity name",
        calculationMethod: "Input method",
        gasTypes: "Gas type",
        actTypes: "Activity type",
        codesBuildingPurpose: "Building use",
        electricityUser: "Electric utility figures",
        electricityUserItem1: "Typical value",
        electricityUserItem2: "Individual electric utility",
        retailElectricCompanies: "Power company",
        inputField1Name: "Item name with value 1",
        inputField2Name: "Item name for value 2",
        memo: "Memo",
      }
    },
    S3_C13: {
      ACTIVITY_HEADERS: {
        productActionName: "Activity name",
        calculationMethodName: "Calculation method",
        activityValues: "Activity",
        emissionUnit: "Emission intensity",
        emissionVolume: "Emissions",
      },
      SUBCOLUMNS: {
        activityVolume1: "Amount to use",
        activityVolume2: "Energy consumption",
        activityVolume3: "Building area",
        distribution: "Apportionment",
        activityVolume4: "Emissions",
      },
      ADD_DIALOG: {
        productActionName: "Activity name",
        calculationMethod: "Input method",
        gasTypes: "Gas type",
        actTypes: "Activity type",
        codesBuildingPurpose: "Building use",
        electricityUser: "Electric utility figures",
        electricityUserItem1: "Typical value",
        electricityUserItem2: "Individual electric utility",
        retailElectricCompanies: "Power company",
        inputField1Name: "Item name with value 1",
        inputField2Name: "Item name for value 2",
        memo: "Memo",
      }
    },
    S3_C02: {
      ACTIVITY_HEADERS: {
        productActionName: "Activity name",
        calculationMethodName: "Calculation method",
        activityValues: "Activity",
        emissionUnit: "Emission intensity",
        emissionVolume: "Emissions",
      },
      SUBCOLUMNS: {
        activityVolume1: "Supplier capital goods-related Scope 1 and Scope 2 emissions",
        rawActivityVolume: "Raw material input or price",
        rawTransportActivityVolume: "Transportation volume of raw materials",
        capitalWasteActivityVolume: "Weight of waste associated with capital goods",
        activityVolume2: "Emission intensity",
      },
      INTENSITY_COLUMNS: {
        rawEmissionIntensity1: "Emission intensity",
        rawEmissionIntensity2: "Emission intensity",
        rawTransportEmissionIntensity: "Emission intensity",
        capitalWasteEmissionIntensity: "Emission intensity",
      },
      ADD_DIALOG: {
        productActionName: "Activity name",
        calculationMethod: "Input method",
        goodsCriteria: "Criteria for capital goods",
        capitals: "Types of industries in the capital formation sector subject to calculation",
        memo: "Memo",
      }
    },
    S3_C03: {
      ACTIVITY_HEADERS: {
        productActionName: "Activity name",
        calculationTargetName: "Fuel type",
        calculationMethodName: "Calculation method",
        activityValues: "Activity",
        emissionUnit: "Emission intensity",
        emissionVolume: "Emissions",
      },
      SUBCOLUMNS: {
        activityVolume: "Emission Volume"
      },
      ADD_DIALOG: {
        productActionName: "Activity name",
        calculationMethod: "Input method",
        fuelUnits071: "Fuel",
        fuelUnits070: "Fuel",
        memo: "Memo",
      }
    },
    S3_C11: {
      ACTIVITY_HEADERS: {
        productActionName: "Activity name",
        productionControlId: "Management ID",
        calculationMethodName: "Calculation method",
        activityValues: "Activity",
        emissionVolume: "Emissions",
      },
      SUBCOLUMNS: {
        activityVolume1: "Assumed lifetime use count",
        expectedLifetimeUsagePer: "Percentage of expected lifetime usage",
        saleCount1: "Number of sales",
        perLessonUsage1: "Fuel usage per use",
        perLessonUsage2: "Electricity usage per use",
        emissionIntensity1: "Emission intensity",
        activityVolume2: "Greenhouse gas emissions",
        gwp: "Global warming potential",
        activityVolume3: "Fuel sales volume",
        emissionIntensity2: "Emission intensity",
        activityVolume4: "Greenhouse gas content per product",
        saleCount2: "Number of sales",
        emissionLifetimeUse: "Emission rate for lifetime use",
        emissionIntensity3: "Emission intensity",
        activityVolume5: "Emissions",
      },
      ADD_DIALOG: {
        productActionName: "Activity name",
        productionControlId: "Management ID",
        calculationMethod: "Input method",
        calculationTarget: "Fuel type",
        inputField1Name: "Item name with value 1",
        inputField2Name: "Item name for value 2",
        memo: "Usage scenario",
      }
    },
    S3_C12: {
      ACTIVITY_HEADERS: {
        productActionName: "Activity name",
        productionControlId: "Management ID",
        calculationMethodName: "Calculation method",
        calculationTargetName: "Waste type",
        activityValues: "Activity",
        emissionUnit: "Emission intensity",
        emissionVolume: "Emissions",
      },
      SUBCOLUMNS: {
        activityVolume1: "Amount of waste",
        activityVolume2: "Amount recycled",
        activityVolume3: "Emissions",
      },
      ADD_DIALOG: {
        productActionName: "Activity name",
        productionControlId: "Management ID",
        calculationMethod: "Input method",
        calcMethods: "Calculation method (waste disposal method)",
        transNecMethods: "Necessity of transportation",
        secondTrashType: "Waste type",
        thirdTrashType: "Waste type",
        firstTrashType: "Waste type",
        fourthTrashType: "Waste type",
        inputField1Name: "Item name with value 1",
        inputField2Name: "Item name for value 2",
        memo: "Memo",
      },
      DETAIL_DIALOG: {
        '0123': {
          createDate: "Input date",
          createName: "Enterer",
          activityVolume: "Amount of waste",
          evidence: "Basis for waste amount",
          activityDetailMemo: "Memo",
          remove: "Delete",
          action: "Detail copy",
        },
        '0124': {
          createDate: "Input date",
          createName: "Enterer",
          activityVolume: "Amount of waste",
          evidence: "Basis for waste amount",
          activityDetailMemo: "Memo",
          remove: "Delete",
          action: "Detail copy",
        },
        '0121': {
          createDate: "Input date",
          createName: "Enterer",
          activityVolume: "Amount recycled",
          evidence: "Basis for recycling amount",
          activityDetailMemo: "Memo",
          remove: "Delete",
          action: "Detail copy",
        }
      },
      CALCULATION_DISPOSAL_METHOD_CODE: {
        INCINERATION: "Incineration",
        LANDFILL_DISPOSAL: "Landfill disposal",
        RECYLING: "Recycling",
        UNKNOWN_PROCESSING_METHOD: "Unknown processing method",
      }
    },
  },
  SCOPE_THREE_DETAIL_TABLE_HEADER: {
    FUEL_METHOD: {
      createDate: "Input date",
      createName: "Enterer",
      value: "Fuel consumption",
      shipperDistribution: "Apportionment by consignor",
      evidence: "Basis of activity",
      activityDetailMemo: "Memo",
      remove: "Delete",
      action: "Detail copy",
    },
    FUEL_CONSUMPTION_METHOD: {
      createDate: "Input date",
      createName: "Enterer",
      transportDistance: "Transportation distance",
      shipperDistribution: "Apportionment by consignor",
      evidence: "Basis of activity",
      activityDetailMemo: "Memo",
      remove: "Delete",
      action: "Detail copy",
    },
    TON_KILOMETER_METHOD: {
      createDate: "Input date",
      createName: "Enterer",
      value: "Cargo weight",
      shipperDistribution: "Apportionment by consignor",
      transportDistance: "Transportation distance",
      evidence: "Basis of activity",
      activityDetailMemo: "Memo",
      remove: "Delete",
      action: "Detail copy",
    },
    BASIC_EMISSION_COEFFICIENT: {
      createDate: "Input date",
      createName: "Enterer",
      value: "Fuel consumption",
      shipperDistribution: "Apportionment by consignor",
      evidence: "Basis of activity",
      activityDetailMemo: "Memo",
      remove: "Delete",
      action: "Detail copy",
    },
    FILLING_RECOVERY: {
      createDate: "Input date",
      createName: "Enterer",
      value: "Loading",
      recoveryVolume: "Amount collected/appropriately treated",
      evidence: "Basis of activity",
      activityDetailMemo: "Memo",
      remove: "Delete",
      action: "Detail copy",
    },
    LEAKAGE_RATE: {
      createDate: "Input date",
      createName: "Enterer",
      value: "Amount of refrigerant contained in operating equipment",
      recoveryVolume: "Amount collected/appropriately treated",
      evidence: "Basis of activity",
      activityDetailMemo: "Memo",
      remove: "Delete",
      action: "Detail copy",
    },
    DB5: {
      createDate: "Input date",
      createName: "Enterer",
      value: "Quantity",
      evidence: "Basis of activity",
      activityDetailMemo: "Memo",
      remove: "Delete",
      action: "Detail copy",
    }
  },
  PRODUCT_ACTIVITY_TABLE_HEADER: {
    procurement: {
      productName: "Product name",
      productionControlId: "Management ID",
      activityMonth: "Procurement management date",
      productActionName: "Activity name",
      activityVolume: "Activity",
      activityVolumeUnit: "Activity unit",
      emissionVolume: "Emissions",
      action_3: "Activity",
    },
    manufacturing: {
      productName: "Product name",
      productionControlId: "Management ID",
      activityMonth: "Activity management date",
      productActionName: "Activity name",
      activityVolume: "Activity",
      activityVolumeUnit: "Activity unit",
      emissionVolume: "Emissions",
      action_3_title1: "Activity",
      action_3_title2: "Activity",
    },
    distribution: {
      productName: "Product name",
      productionControlId: "Management ID",
      activityMonth: "Activity management date",
      productActionName: "Activity name",
      activityVolume: "Activity",
      activityVolumeUnit: "Activity unit",
      emissionVolume: "Emissions",
      action_3: "Activity",
    },
    utilization: {
      productName: "Product name",
      productionControlId: "Management ID",
      activityMonth: "Activity management date",
      productActionName: "Activity name",
      activityVolume: "Actual emissions",
      emissionVolume: "Emissions",
    },
    wasteRecycling: {
      productName: "Product name",
      productionControlId: "Management ID",
      activityMonth: "Activity management date",
      productActionName: "Activity name",
      activityVolume: "Actual emissions",
      emissionVolume: "Emissions",
    }
  },
  SIMULATION_PAGE: {
    productName: "Product Name",
    simulationButton: "Simulation",
    TABLE_HEADER: {
      productName: "Product Name",
      productSimulationNo: "Simulation No",
      productionCount: "Quantity",
      productionCountUnit: "Unit",
      detailButton: 'Detail'
    }
  },
  SIMULATION_PRODUCT_ACTIVITY_TABLE_HEADER: {
    procurement: {
      HEADERS: {
        productSimulationNo: "Simulation No",
        departmentName: "&nbsp;&nbsp;Department name<br/>(Items in the Input-Output Table)",
        calculationMethod: "Calculation Method",
        activityVolume: "Activity",
        emissionIntensity: "Emission Intensity",
        emissionVolume: "Emissions",
      },
      SUBCOLUMNS: {
        activityVolume: "",
        emissionAmount: "Emissions"
      },
      BUTTONS: {
        add: "Activity"
      }
    },
    manufacturing: {
      HEADERS: {
        productSimulationNo: "Simulation No",
        calculationTarget: "Target",
        activityVolume: "Activity",
        emissionIntensity: "Emission Intensity",
        emissionVolume: "Emissions",
      },
      SUBCOLUMNS: {
      }
    },
    manufacturing2: {
      HEADERS: {
        productSimulationNo: "Simulation No",
        calculationTarget: "Target",
        calculationMethod: "Calculation method",
        activityVolume: "Activity",
        activityVolumeUnit: "1",
        emissionIntensity: "Emission Intensity",
        emissionVolume: "Emissions",
      },
      SUBCOLUMNS: {
        activityVolume: ""
      }
    },
    distribution: {
      HEADERS: {
        productSimulationNo: "Simulation No",
        calculationMethod: "Calculation method",
        activityVolume: "Activity",
        emissionIntensity: "Emission Intensity",
        emissionVolume: "Emissions",
      },
      SUBCOLUMNS: {
        fuelEconomy: "Fuel Consumption",
        fuelEconomyBasicunit: "Fuel Unit"
      }
    },
    utilization: {
      HEADERS: {
        productSimulationNo: "Simulation No",
        calculationMethod: "Calculation method",
        activityVolume: "Activity",
        emissionVolume: "Emissions",
      },
      SUBCOLUMNS: {
        distribution: "Distribution",
      }
    },
    wasteRecycling: {
      HEADERS: {
        productSimulationNo: "Simulation No",
        calculationMethod: "Calculation method",
        wasteType: "Waste Type",
        activityVolume: "Activity",
        emissionIntensity: "Emission Intensity",
        emissionVolume: "Emissions",
      },
      SUBCOLUMNS: {
      }
    }
  },
  SIMULATION_ACTIVITY_INPUT_DIALOG: {
    S1_C00: {
      ADD_DIALOG: {
        calculationTarget: "Target",
      }
    },
    S2_C00: {
      ADD_DIALOG: {
        calculationTarget: "Emission activity",
        calculationMethod: "Calculation method",
        electricCompanies: "Electric Company",
        electricSupplyMenu: "Supply Menu",
      }
    },
    S3_C01: {
      ADD_DIALOG: {
        calculationMethod: "Input Method",
        calculationTarget: "Calculation method",
        departmentName: "Sector name (input-output table item)",
        inputField1Name: "Item name with value 1",
        inputField2Name: "Item name with value 2",
      }
    },
    S3_C04: {
      ADD_DIALOG: {
        inputMethods: "Input Method",
        calculationMethods: "Calculation method",
        fuelTypes: "Fuel Type",
        transportMeans: "Transportation",
        transportKbn: "Classification of transportation",
        bizDomesticKbn: "Commercial or private use",
        fuelGrasps: "Fuel Consumption",
        truckRates: "Truck Load",
      }
    },
    S3_C10: {
      ADD_DIALOG: {
        calculationMethod: "Input Method",
        gasTypes: "Gas Type",
        calculationTarget: "Activity Type"
      }
    },
    S3_C11: {
      ADD_DIALOG: {
        calculationMethod: "Input Method",
        calculationTarget: "Fuel Type",
        inputField1Name: "Item name with value 1",
        inputField2Name: "Item name with value 2",
      }
    },
    S3_C12: {
      ADD_DIALOG: {
        calculationMethod: "Input Method",
        calcMethods: "Calculation method(Waste treatment method)",
        transNecMethods: "Necessity Of Transportation",
        secondTrashType: "Waste Type",
        thirdTrashType: "Waste Type",
        firstTrashType: "Waste Type",
        fourthTrashType: "Waste Type",
        inputField1Name: "Item name with value 1",
        inputField2Name: "Item name with value 2",
      },
    },
  },
  CALCULATION_METHOD: {
    UNKNOWN_PROCESSING_METHOD: "Unknown processing method",
  },
  USER_TABLE_HEADER: {
    userName: "User ID",
    userNameKanji: "Family name",
    userNameKana: "Full name in kana",
    organizationName: "Organization",
    userAuthorityName: "Authority",
    email: "Mail address",
  },
  BUTTON_CHOICE: {
    YES: "yes",
    NO: "no",
    CANCEL: "cancel"
  },
  CATEGORY_LIST: {
    "01": {
      label: "Category 1",
      title: "Purchased goods and services",
      caption: "Emissions from activities up to manufacturing of raw materials, parts, purchased goods, sales-related  materials, etc",
    },
    "02": {
      label: "Category 2",
      title: "Capital goods",
      caption: "Emissions from construction and manufacturing of the reporting company's capital goods",
    },
    "03": {
      label: "Category 3",
      title: "Fuel and energy related activities not included in Scope 1 or 2",
      caption: "Emissions from procurement of fuel used in power generation, etc., for electricity and heat procured from other companies",
    },
    "04": {
      label: "Category 4",
      title: "Transportation and delivery (upstream)",
      caption: "Emissions from distribution of raw materials, parts, purchased goods, sales-related materials, etc., up to delivery to the reporting company ",
    },
    "05": {
      label: "Category 5",
      title: "Waste generated in operations",
      caption: "Emissions from transportation and processing of waste generated by the reporting company"
    },
    "06": {
      label: "Category 6",
      title: "Business travel",
      caption: "Emissions from business travel by employees",
    },
    "07": {
      label: "Category 7",
      title: "Employee commuting",
      caption: "Emissions from transportation of employees when commuting to and from the place of business",
    },
    "08": {
      label: "Category 8",
      title: "Leased assets (upstream)",
      caption: "Emissions from operation of assets leased to the reporting company (excluding emissions calculated under Scope 1 or 2)",
    },
    "09": {
      label: "Category 9",
      title: "Transportation and delivery (downstream)",
      caption: "Emissions from transport, storage, cargo handling, and retail sales of products ",
    },
    "10": {
      label: "Category 10",
      title: "Processing of sold products",
      caption: "Emissions from processing of intermediate products by the reporting company",
    },
    "11": {
      label: "Category 11",
      title: "Use of sold products",
      caption: "Emissions from use of products by users (consumers and companies)",
    },
    "12": {
      label: "Category 12",
      title: "End-of-life treatment of sold products",
      caption: "Emissions from transportation and processing of products upon disposal by users (consumers and companies)",
    },
    "13": {
      label: "Category 13",
      title: "Leased assets (downstream)",
      caption: "Emissions from operation of assets leased to other entities",
    },
    "14": {
      label: "Category 14",
      title: "Franchises",
      caption: "Emissions from franchise members",
    },
    "15": {
      label: "Category 15",
      title: "Investments",
      caption: "Emissions from operation of investments",
    }
  },
  PRODUCT_ACTIVITY: {
    TAB_LIST: {
      procurement: "Mining and cleaning coals",
      manufacturing: "Production",
      distribution: "Transportation",
      utilization: "Use of products",
      wasteRecycling: "Dispocal of products",
    }
  },
  EMISSIONS_CALCULATION_GUIDE: {
    TAB_LIST: {
      video_page_link: 'Video Page Link',
      site_page_link: 'Site Page Link'
    },
    errMessenger: {
      sizeFileCheck: 'Maximum upload file size: 1MB',
      fileTypeCheck: 'Invalid file format',
      fileNameDuplicateCheck: 'File name uploaded',
      fileNameLengthCheck: 'Please upload an image must be within 100 characters'
    }
  },
  REDUCTION_TARGET_INPUT: {
    CATEGORY: {
      reduceTargetCategory: 'Please select a reduction target category:',
      dateDisplay: 'Year Display',
    },
    FILTER: {
      baseYear: 'Base year',
      targetYear: 'Target year',
      baseYearMonth: 'Base month',
      targetYearMonth: 'Target month',
      target: "Target",
    },
    VALUES: {
      year: 'Year',
      month: 'Month',
      baseYear: 'Base Year',
      targetYear: 'Target Year',
      baseYearMonth: 'Base Month',
      targetYearMonth: 'Target Month',
      baseAnnualEmission: 'Base Year Emissions',
      organization: 'organization',
      affiliation: 'affiliation',
      products: 'products',
      targetValue: 'Target Value',
      unit: 'Unit',
      reductionValue: 'Reduction Value',
      base: 'Base',
      organization: 'Affiliation',
      productName: 'Product Name',
    },
    msg: {
      deleteConfirmMsg: 'Delete one line. Is it OK?',
      duplicateError: 'Base {timeType}/Target {timeType}{targetName} has already been existed.',
      baseIdName: '/Base',
      organizationIdName: '/Affiliation',
      productIdName: '/Product',
    },
  },
  ALERT_SETTING: {
    TAB_LIST: {
      input_status: 'Input Status',
      alert_setting: 'Alert Setting'
    },
    INPUT_STATUS_HEADER: {
      base: "Base",
      organization: "Organization",
      scope: "Scope",
      category: "Category",
      categoryGroup: "Category Group",
      inputThisMonth: "Input This Month",
      emissionThisMonth: 'Emission This Month',
      inputLastMonth: 'Input Last Month',
      emissionLastMonth: 'Emission Last Month',
      input2MonthAgo: 'Input 2 Months Ago',
      emission2MonthAgo: 'Emission 2 Months Ago',
      input3MonthAgo: 'Input 3 Months Ago',
      emission3MonthsAgo: 'Emission 3 Month Ago',
      input4MonthAgo: 'Input 4 Months Ago',
      emission4MonthsAgo: 'Emission 4 Month Ago',
      input5MonthAgo: 'Input 5 Months Ago',
      emission5MonthsAgo: 'Emission 5 Month Ago',
      input6MonthAgo: 'Input 6 Months Ago',
      emission6MonthsAgo: 'Emission 6 Month Ago',
      input7MonthAgo: 'Input 7 Months Ago',
      emission7MonthsAgo: 'Emission 7 Month Ago',
      input8MonthAgo: 'Input 8 Months Ago',
      emission8MonthsAgo: 'Emission 8 Month Ago',
      input9MonthAgo: 'Input 9 Months Ago',
      emission9MonthsAgo: 'Emission 9 Month Ago',
      input10MonthAgo: 'Input 10 Months Ago',
      emission10MonthsAgo: 'Emission 10 Month Ago',
      input11MonthAgo: 'Input 11 Months Ago',
      emission11MonthsAgo: 'Emission 11 Month Ago',
      input12MonthAgo: 'Input 12 Months Ago',
      emission12MonthsAgo: 'Emission 12 Month Ago',
    },
    ALERT_SETTING_LIST: {
      alertSet: "Alert Setting",
      alertStartDay: "Alert Start Date",
      comparisonBaseMonth: "Comparison Base Month",
      comparisonTarget: "Targets for Comparison",
      allowableValue: "Tolerance",
    },
    msg: {
      alertSetMsg: "If you set an alert, it will be displayed as a notification if there is a significant difference in the number of entries or the amount of emissions in the current month (compared to the base month).",
      alertStartDayMsg: "After the date you set, the alert will appear in your notifications.",
      comparisonBaseMonthMsg: "Set the month that serves as the base for alerts.",
      comparisonTargetMsg: "Select the target of the alert.",
      allowableValueMsg: "Displays an alert if the difference is greater than the tolerance.",
      unitMsg: "matter or t-co2",
      dayMsg: "Day",
    },
    BUTTON: {
      detail: "Detail",
      save: "Save",
    },
    LABEL: {
      alertSetYesLabel: "yes",
      alertSetNoLabel: "no",
      dayLabel: "day 1",
      monthLabel: "last month",
      targetLabel: "Number of entries",
    }
  },
  DATA_EXTRACTION: {
    TITTLE: {
      setDisplayConditions: "Please set display conditions",
      selectItemToDisplayed: "Please select the items to be displayed and the display order",
      selectItemtToDisplayOrderAggregationUnit: "Please select items to be displayed, display order, and aggregation unit",
      subject: "Subject",
      selectValue: "Select value",
      conditions: "Conditions",
      inputValue: "Input value",
      displayItem: "Display item",
      displayOrder: "Display order",
      aggregationUnit: "Aggregation unit",
      notGroupMsg: "Aggregation unit is not set. Please select from the displayed items.",
      incorrectWhere: "The data cannot be retrieved correctly. Please review the conditions."
    },
    msg: {
    },
    Button: {
      detail: "Detail",
      total: "Total➝",
      clearAll: "Clear all",
      add: "Add➝",
      remove: "←Remove",
      ascending: "Ascending➝",
      descending: "Descending➝",
      patternRegistration: "Register",
      registeredPatternDisplay: "All pattern",
      extractionResultDisplay: "Extraction result display",
      excelExport: "Export data to Excel",
      pdflExport: "Export data to PDF",
    },
    Data_extraction_display: "Data extraction display",
    fileName: "carbonnoteDataExtraction_",
    PATTERN_DISPLAY: {
      HEADERS: {
        select: "Select",
        patternName: "Pattern name",
        registeredDate: "Registered date",
      },
      msg: {
        patternListDispplay: "Pattern list dispplay"
      },
      Button: {
        select: "Select",
        close: "Close",
      }
    },
    ORDER_BY: {
      ascending: "ASC",
      descending: "DESC"
    }
  },
}

