import Vue from "vue"
// import { NuxtAppOptions } from "@nuxt/types"
import VueI18n from "vue-i18n"

// User lang files
import jaLocale from "@/lang/ja"
import enLocale from "@/lang/en"

// Element-ui built-in fang files
// import elementJaLocale from 'element-ui/lib/locale/lang/ja'
// import elementEnLocale from 'element-ui/lib/locale/lang/en'

Vue.use(VueI18n)

const messages = {
  ja: {
    ...jaLocale,
    // ...elementJaLocale
  },
  en: {
    ...enLocale,
    // ...elementEnLocale
  },
}

export default ({ app }) => {
  let locale
  const lang = localStorage.getItem("language")
  if (!lang) {
    locale = "ja"
  } else {
    if (["en", "ja"].includes(lang)) {
      locale = lang
    } else {
      locale = "ja"
    }
  }
  app.i18n = new VueI18n({
    locale: locale,
    fallbackLocale: locale,
    messages,
  })
}

export const i18n = () => {
  let locale
  const lang = localStorage.getItem("language")
  if (!lang) {
    locale = "ja"
  } else {
    if (["en", "ja"].includes(lang)) {
      locale = lang
    } else {
      locale = "ja"
    }
  }
  return new VueI18n({
    locale: locale,
    fallbackLocale: locale,
    messages,
  })

}
