export default {
  SET_FILTER_CRITERIA: (state, value) => (state.filterCriteria = value),
  SET_SCOPE_ONE_ACTIVITY_INPUT_LIST: (state, value) => (state.scopeOneActivityInputList = value),
  SET_SCREEN_ITEM_LIST: (state, value) => (state.screenItemList = value),
  SET_SCREEN_RELATION_LIST: (state, value) => (state.screenRelationList = value),
  SET_CATEGORY_GROUP_LIST: (state, value) => (state.categoryGroupList = value),
  SET_GLOBAL_WARMING_POTENTIAL_LIST: (state, value) => (state.globalWarmingPotentialList = value),
  SET_STATE(state, payload) {
    state[payload.key] = payload.value
  }
}
