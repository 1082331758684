import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2b3884f1 = () => interopDefault(import('..\\pages\\alert-setting.vue' /* webpackChunkName: "pages/alert-setting" */))
const _17d8bb26 = () => interopDefault(import('..\\pages\\dashboard.vue' /* webpackChunkName: "pages/dashboard" */))
const _0b86f432 = () => interopDefault(import('..\\pages\\dashboard_bk.vue' /* webpackChunkName: "pages/dashboard_bk" */))
const _251089c2 = () => interopDefault(import('..\\pages\\dashboard-analytics.vue' /* webpackChunkName: "pages/dashboard-analytics" */))
const _10619ffc = () => interopDefault(import('..\\pages\\data-extraction.vue' /* webpackChunkName: "pages/data-extraction" */))
const _28b20fc9 = () => interopDefault(import('..\\pages\\emissions-calculation-guide.vue' /* webpackChunkName: "pages/emissions-calculation-guide" */))
const _6f5e54cc = () => interopDefault(import('..\\pages\\error.vue' /* webpackChunkName: "pages/error" */))
const _0cf61e8e = () => interopDefault(import('..\\pages\\new-dashboard.vue' /* webpackChunkName: "pages/new-dashboard" */))
const _5ecbc17c = () => interopDefault(import('..\\pages\\pageNotFound.vue' /* webpackChunkName: "pages/pageNotFound" */))
const _dff162f4 = () => interopDefault(import('..\\pages\\reduction-target-input.vue' /* webpackChunkName: "pages/reduction-target-input" */))
const _46152f52 = () => interopDefault(import('..\\pages\\unauthorized.vue' /* webpackChunkName: "pages/unauthorized" */))
const _72b80826 = () => interopDefault(import('..\\pages\\activity\\input.vue' /* webpackChunkName: "pages/activity/input" */))
const _b2983ce4 = () => interopDefault(import('..\\pages\\product-management\\activity.vue' /* webpackChunkName: "pages/product-management/activity" */))
const _24614df6 = () => interopDefault(import('..\\pages\\product-management\\categoryone.js' /* webpackChunkName: "pages/product-management/categoryone" */))
const _bbde8710 = () => interopDefault(import('..\\pages\\product-management\\production.vue' /* webpackChunkName: "pages/product-management/production" */))
const _0c84ca74 = () => interopDefault(import('..\\pages\\product-management\\simulation.vue' /* webpackChunkName: "pages/product-management/simulation" */))
const _53c3dc6d = () => interopDefault(import('..\\pages\\setting\\alert-setting.vue' /* webpackChunkName: "pages/setting/alert-setting" */))
const _491f4b8b = () => interopDefault(import('..\\pages\\setting\\company.vue' /* webpackChunkName: "pages/setting/company" */))
const _7773d5ec = () => interopDefault(import('..\\pages\\setting\\emission-version-management.vue' /* webpackChunkName: "pages/setting/emission-version-management" */))
const _c5e7a90c = () => interopDefault(import('..\\pages\\setting\\fiscal-year.vue' /* webpackChunkName: "pages/setting/fiscal-year" */))
const _63ab2513 = () => interopDefault(import('..\\pages\\setting\\industry-templates-master.vue' /* webpackChunkName: "pages/setting/industry-templates-master" */))
const _84aae696 = () => interopDefault(import('..\\pages\\setting\\news.vue' /* webpackChunkName: "pages/setting/news" */))
const _c6d955d6 = () => interopDefault(import('..\\pages\\setting\\organization.vue' /* webpackChunkName: "pages/setting/organization" */))
const _737ee47d = () => interopDefault(import('..\\pages\\setting\\process.vue' /* webpackChunkName: "pages/setting/process" */))
const _5aa4b1ad = () => interopDefault(import('..\\pages\\setting\\user.vue' /* webpackChunkName: "pages/setting/user" */))
const _20a62f8c = () => interopDefault(import('..\\pages\\setting\\mst\\emissions-unit-value-master.vue' /* webpackChunkName: "pages/setting/mst/emissions-unit-value-master" */))
const _5a34d876 = () => interopDefault(import('..\\pages\\setting\\mst\\master-register-csv-export.vue' /* webpackChunkName: "pages/setting/mst/master-register-csv-export" */))
const _6f505e98 = () => interopDefault(import('..\\pages\\setting\\mst\\retail-electricity-providers.vue' /* webpackChunkName: "pages/setting/mst/retail-electricity-providers" */))
const _4167c54c = () => interopDefault(import('..\\pages\\setting\\mst\\retail-electricity-providers-emission.vue' /* webpackChunkName: "pages/setting/mst/retail-electricity-providers-emission" */))
const _8e82c3bc = () => interopDefault(import('..\\pages\\setting\\mst\\transport-master.vue' /* webpackChunkName: "pages/setting/mst/transport-master" */))
const _f6ea9138 = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages/index" */))
const _7795d548 = () => interopDefault(import('~/pages/pageNotFound.vue' /* webpackChunkName: "" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/alert-setting",
    component: _2b3884f1,
    name: "alert-setting"
  }, {
    path: "/dashboard",
    component: _17d8bb26,
    name: "dashboard"
  }, {
    path: "/dashboard_bk",
    component: _0b86f432,
    name: "dashboard_bk"
  }, {
    path: "/dashboard-analytics",
    component: _251089c2,
    name: "dashboard-analytics"
  }, {
    path: "/data-extraction",
    component: _10619ffc,
    name: "data-extraction"
  }, {
    path: "/emissions-calculation-guide",
    component: _28b20fc9,
    name: "emissions-calculation-guide"
  }, {
    path: "/error",
    component: _6f5e54cc,
    name: "error"
  }, {
    path: "/new-dashboard",
    component: _0cf61e8e,
    name: "new-dashboard"
  }, {
    path: "/pageNotFound",
    component: _5ecbc17c,
    name: "pageNotFound"
  }, {
    path: "/reduction-target-input",
    component: _dff162f4,
    name: "reduction-target-input"
  }, {
    path: "/unauthorized",
    component: _46152f52,
    name: "unauthorized"
  }, {
    path: "/activity/input",
    component: _72b80826,
    name: "activity-input"
  }, {
    path: "/product-management/activity",
    component: _b2983ce4,
    name: "product-management-activity"
  }, {
    path: "/product-management/categoryone",
    component: _24614df6,
    name: "product-management-categoryone"
  }, {
    path: "/product-management/production",
    component: _bbde8710,
    name: "product-management-production"
  }, {
    path: "/product-management/simulation",
    component: _0c84ca74,
    name: "product-management-simulation"
  }, {
    path: "/setting/alert-setting",
    component: _53c3dc6d,
    name: "setting-alert-setting"
  }, {
    path: "/setting/company",
    component: _491f4b8b,
    name: "setting-company"
  }, {
    path: "/setting/emission-version-management",
    component: _7773d5ec,
    name: "setting-emission-version-management"
  }, {
    path: "/setting/fiscal-year",
    component: _c5e7a90c,
    name: "setting-fiscal-year"
  }, {
    path: "/setting/industry-templates-master",
    component: _63ab2513,
    name: "setting-industry-templates-master"
  }, {
    path: "/setting/news",
    component: _84aae696,
    name: "setting-news"
  }, {
    path: "/setting/organization",
    component: _c6d955d6,
    name: "setting-organization"
  }, {
    path: "/setting/process",
    component: _737ee47d,
    name: "setting-process"
  }, {
    path: "/setting/user",
    component: _5aa4b1ad,
    name: "setting-user"
  }, {
    path: "/setting/mst/emissions-unit-value-master",
    component: _20a62f8c,
    name: "setting-mst-emissions-unit-value-master"
  }, {
    path: "/setting/mst/master-register-csv-export",
    component: _5a34d876,
    name: "setting-mst-master-register-csv-export"
  }, {
    path: "/setting/mst/retail-electricity-providers",
    component: _6f505e98,
    name: "setting-mst-retail-electricity-providers"
  }, {
    path: "/setting/mst/retail-electricity-providers-emission",
    component: _4167c54c,
    name: "setting-mst-retail-electricity-providers-emission"
  }, {
    path: "/setting/mst/transport-master",
    component: _8e82c3bc,
    name: "setting-mst-transport-master"
  }, {
    path: "/",
    component: _f6ea9138,
    name: "index"
  }, {
    path: "*",
    component: _7795d548,
    name: "pageNotFound"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
