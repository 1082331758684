export default {
  getFilterCriteriaStr: function (state, getters, rootState) {
    const conditionFilterStrArray = []

    if (state.filterCriteria) {
      const _crt = state.filterCriteria
      const _targetMonth = _crt.targetMonth
      const _targetMonthF = _crt.targetMonthF
      const _targetMonthT = _crt.targetMonthT
      const _selectDivision = _crt.selectDivision
      const _division = _crt.division
      const _selectScopeList = _crt.selectScopeList
      const _selectedCategoryList = _crt.selectedCategoryList

      if (!_targetMonthF && !_targetMonthT && _targetMonth) {
        conditionFilterStrArray.push(_targetMonth)
      }

      if (_targetMonthF || _targetMonthT) {
        let _conditionStr = ""

        if (_targetMonthF) {
          _conditionStr += _targetMonthF
        }
        _conditionStr += "〜"
        if (_targetMonthT) {
          _conditionStr += _targetMonthT
        }
        conditionFilterStrArray.push(_conditionStr)
      }

      if (_division) {
        let _conditionStr = ""
        if (_division === "0") {
          _conditionStr = "拠点別"
        } else if (_division === "1") {
          _conditionStr = "事業部別"
        } else if (_division) {
          _conditionStr = "全社"
        }
        conditionFilterStrArray.push(_conditionStr)
      }

      if (_selectDivision) {
        let _selectDivisionObj = {}

        if (_division === "0") {
          _selectDivisionObj = rootState.codes.locationList.find(
            (item) => item.value === _selectDivision
          )
        } else if (_division === "1") {
          _selectDivisionObj = rootState.codes.deptList.find(
            (item) => item.value === _selectDivision
          )
        }

        conditionFilterStrArray.push(_selectDivisionObj["label"])
      }

      if (_selectScopeList.length && rootState.codes.scopeList.length) {
        if (_selectScopeList.length === rootState.codes.scopeList.length) {
          conditionFilterStrArray.push("全スコープ")
        } else {
          for (let i = 0; i < _selectScopeList.length; i++) {
            let _scope = rootState.codes.scopeList.find(
              (item) => item.value === _selectScopeList[i]
            )

            conditionFilterStrArray.push(_scope["label"])
          }
        }
      }

      if (_selectedCategoryList.length && rootState.codes.categoryList.length) {
        if (
          _selectedCategoryList.length === rootState.codes.categoryList.length
        ) {
          conditionFilterStrArray.push("全カテゴリ")
        } else {
          for (let i = 0; i < _selectedCategoryList.length; i++) {
            let _category = rootState.codes.categoryList.find(
              (item) => item.value === _selectedCategoryList[i]
            )

            conditionFilterStrArray.push(_category["label"])
          }
        }
      }

      let conditionStr = "絞り込み条件："
      for (let i = 0; i < conditionFilterStrArray.length; i++) {
        if (conditionStr.length !== 0 && i !== length - 1 && i !== 0) {
          conditionStr += ", "
        }
        conditionStr += conditionFilterStrArray[i]
      }
      return conditionStr
    }
  },
}
