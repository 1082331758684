export default class ReductionTargetInputService {
  constructor(axios) {
    this.axios = axios
  }
  init() {
    return this.axios.$get(`/api/v1/reduction-target-input/init`)
  }

  searchRedutionTarget(params) {
    return this.axios.$get(`/api/v1/reduction-target-input/search`, { params: params })
  }

  update(payload) {
    return this.axios.$post('/api/v1/reduction-target-input/update', payload);
  }

  getEmissionVolumn(params) {
    return this.axios.$get('/api/v1/reduction-target-input/objective-volume', { params: params });
  }

  checkDuplicate(params) {
    return this.axios.$get('/api/v1/reduction-target-input/check-duplicate', { params: params });
  }
}