export default class CommonService {
  constructor(axios) {
    this.axios = axios
  }

  getScreenControl() {
    return this.axios.$get('/screen/control/authorities');
  }

  getLightVersion() {
    return this.axios.$get('/api/v1/management/flag');
  }

}