export default class AlertSettingService {
  constructor(axios) {
    this.axios = axios
  }

  list(searchText, page) {
    return this.axios.$get(`/alert-setting/list-input-history?searchText=${searchText}&page=${page}`);
  }

  getAlertSettingInit() {
    return this.axios.$get(`/alert-setting/init`);
  }

  update(payload) {
    return this.axios.$post(`/alert-setting/update-alert-setting`, payload);
  }

  getAlertSetting() {
    return this.axios.$get(`/alert-setting/list-alert-setting`);
  }

  runBatch() {
    return this.axios.$get(`/alert-setting/scheduled`);
  }
}