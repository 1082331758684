export default class ProductActivity {
  constructor(axios) {
    this.axios = axios
  }

  init() {
    return this.axios.$get(`/api/v1/simulation-product/init`)
  }

  getProducts(params) {
    return this.axios.$get(`/api/v1/simulation-product/products`, { params })
  }

  update(payload) {
    return this.axios.$post('/api/v1/simulation-product/update', payload)
  }

  getCodeMethod(tabName, scopeId, categoryId) {
    let url
    if (scopeId === '1') {
      url = `/api/v1/simulation-product/${tabName}/scope1?scopeId=${scopeId}&categoryId=${categoryId}`
    } else if (scopeId === '2') {
      url = `/api/v1/simulation-product/${tabName}/scope2?scopeId=${scopeId}&categoryId=${categoryId}`
    } else {
      url = `/api/v1/simulation-product/${tabName}/scope3/category${categoryId}?scopeId=${scopeId}&categoryId=${categoryId}`
    }
    return this.axios.$get(url)
  }

  getDepartmentName(params) {
    return this.axios.$get(`/api/v1/simulation-product/procurement/category01/departments`, { params })
  }

  checkExistedName(productName) {
    return this.axios.$get(`/api/v1/simulation-product/duplicate-name?productName=${productName}`)
  }

}