export default class NewsProvidersService {
  constructor(axios) {
    this.axios = axios
  }

  list(params) {
    return this.axios.$get('/news/init', { params: params })
  }

  filter(params) {
    return this.axios.$get('/news/filter', { params: params })
  }

  update(payload) {
    return this.axios.$post('/news/update', payload)
  }

}