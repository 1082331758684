export default class ProductActivity {
  constructor(axios) {
    this.axios = axios
  }

  targetMonths() {
    return this.axios.$get(`/api/v1/setting/product-detail/targetMonths`)
  }

  list({ targetMonth, baseId, orgId, code, page }) {
    return this.axios.$get(`/api/v1/setting/product-detail/products`, {
      params: {
        targetMonth, baseId, orgId, code, page
      }
    })
  }

  change({ targetMonth, baseId, orgId, scopeId, categoryId, processId }) {
    return this.axios.$get(`/api/v1/setting/product-detail/change/activity-month`, {
      params: {
        targetMonth, baseId, orgId, scopeId, categoryId, processId
      }
    })
  }

  update(payload) {
    return this.axios.$post('/api/v1/setting/product-detail/update', payload)
  }

  getBaseOrg(params) {
    return this.axios.$get('api/v1/setting/product-detail/getBaseOrgs', { params })
  }

  importData(params) {
    return this.axios.$get('/api/v1/setting/product-detail/get-new-production-name', { params })
  }

  listActivity(params) {
    return this.axios.$get('api/v1/setting/product-detail/get-list-activity', { params })
  }
}