import { Auth } from 'aws-amplify'
export default {
  doSetDrawer: (context, idtoken) => context.commit("SET_DRAWER", idtoken),
  doAddLoadingQueue: function (context, { key, value }) {
    context.commit("ADD_LOADING_QUEUE", {
      key: key,
      value: value,
    })
  },
  doRemoveLoadingQueue: function (context, key) {
    context.commit("REMOVE_LOADING_QUEUE", { key: key })
  },
  async getNews({ commit }) {
    const today = new Date()
    const date =
      today.getFullYear() +
      '-' +
      (today.getMonth() + 1) +
      '-' +
      today.getDate()
    const time =
      today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds()
    const paramFull = {
      systemDate: date + ' ' + time,
    }
    const resNew = await this.$api.dashboard.getNewsInit(paramFull)
    commit('SET_STATE', {
      name: 'news',
      value: {
        emergency: resNew.result.urgentNews,
        important: resNew.result.importantNews,
        normal: resNew.result.normalNews,
        newsSize: resNew.result.newsSize
      },
    })
  },
  doLogout() {
    sessionStorage.removeItem('co2')
    this.$axios.setToken(false)
    Auth.signOut()
      .then(() => {
        window.location.reload()
      })
      .catch((err) => {
        console.error(err)
      })
  }
}
