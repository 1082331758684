export default class EmissionsUnitValueMaster {
  constructor(axios) {
    this.axios = axios
  }

  list(page) {
    return this.axios.$get(`/emi/intensity/users/init?page=${page}`)
  }
  update(payload) {
    return this.axios.$post(`/emi/intensity/users/update`, payload)
  }
  filter(searchTerm, page) {
    return this.axios.$get(`/emi/intensity/users/filter?filterQuote=${searchTerm}&page=${page}`)
  }
}