export default {
  code: "ja",
  messages: {
    alpha: "{_field_}はアルファベットのみ使用できます",
    alpha_num: "{_field_}は英数字を入力してください。",
    alpha_num_2: "{_field_}は英数字を入力してください。",
    alpha_dash: "{_field_}は英数字とハイフン、アンダースコアのみ使用できます",
    alpha_spaces: "{_field_}はアルファベットと空白のみ使用できます",
    between: "{_field_}は{min}から{max}の間でなければなりません",
    confirmed: "{_field_}が一致しません",
    digits: "{_field_}は{length}桁の数字でなければなりません",
    dimensions:
      "{_field_}は幅{width}px、高さ{height}px以内でなければなりません",
    email: "メールアドレスのフォーマットに誤りがあります",
    excluded: "{_field_}は不正な値です",
    ext: "{_field_}は有効なファイル形式ではありません",
    image: "{_field_}は有効な画像形式ではありません",
    integer: "{_field_}は整数のみ使用できます",
    is: "{_field_}が一致しません",
    length: "{_field_}は{length}文字でなければなりません",
    max_value: "{_field_}は{max}以下でなければなりません",
    custom_max_value: "{_field_}は{max}以下でなければなりません",
    max: "{_field_}は{length}文字以内にしてください",
    mimes: "{_field_}は有効なファイル形式ではありません",
    min_value: "{_field_}は{min}以上の数値でなければなりません",
    custom_min_value: "{_field_}は{min}以上の数値でなければなりません",
    min: "{_field_}は{length}文字以上で入力してください",
    numeric: "{_field_}は数字のみ使用できます",
    oneOf: "{_field_}は有効な値ではありません",
    regex: "{_field_}のフォーマットが正しくありません",
    required: "{_field_}が未入力です",
    custom_required_product: "{_field_}が未入力です",
    required_if: "{_field_}は必須項目です",
    size: "{_field_}は{size}KB以内でなければなりません",
    number_full_width: "{_field_}は正数を入力してください。",
    length_decimal: "{_field_}は小数部が{max}桁以下でなければなりません。",
    integer_only: "{_field_}は正数のみを入力してください。",
    length_comma: "{_field_}の整数は{max}桁以下でなければなりません。",
    numeric_right: '小数点以下は{scale}桁以下でなければなりません。',
    column_unique: (fieldName, placeholders) => {
      if (typeof placeholders.key === 'object')
        return `同一の${Object.values(placeholders.key).join('/')}が存在しています。`
      return `${fieldName}が重複しています。`
    },
    kana: '{_field_}はカタカナ文字を入力してください。',
    password: '数値、特殊文字（@$!%*?&#）、大文字、小文字をそれぞれ最低1文字以上使用してください。',
    hankaku_num: '{_field_}は半角英数のみ使用できます',
    time_valid: '開始時間と終了時間が正しくありません。',
    time_valid_reduction: (fieldName, data) => {
      if (data.type === "end") {
        return `${fieldName}は${data.time}より未来を選択して下さい。`
      } else {
        return `${data.time}は${fieldName}より未来を選択して下さい。`
      }
    },
    news_base_org_valid: '対象拠点または対象所属を選択してください。',
    hankaku_num_2: '{_field_}は半角英数のみ使用できます',
    min_month: '{_field_}は{from}以降で入力してください。',
    date_format: '{_field_}は{DATE_FORMAT}の形式で入力してください。',
    month_format: '{_field_}は{MONTH_FORMAT}の形式で入力してください。',
    from_current_month: '{_field_}は現在の年月、またはそれ以前の年月を設定してください。',
    max_month: '{_field_}は{to}以前で入力してください。',
    date_order: '日付の前後関係が不正です。',
    month_order: '対象年月の前後関係が不正です。',
    time_format: '{_field_}は00:00の形式で入力してください。',
    max_digits: '{_field_}は{max}文字以下でなければなりません。',
    collection_max_length: '${_field_}は{maxLength}つまでしか選択することができません。',
    tel_format: '{_field_}を正しく入力してください。',
    zipcode_format: '{_field_}は000-0000の形式で入力してください。',
    uppercase: '{_field_}は大文字を1文字以上含んでください',
    lowercase: '{_field_}は小文字を1文字以上含んでください',
    number: '{_field_}は数字を1文字以上含んでください',
    special_char: '{_field_}は記号を1文字以上含んでください',
    exis_item: '既に追加されています',
    not_zero: 'ゼロは入力できません。',
    fraction: '{_field_}は数値または分数を入力してください。',
    fraction_numerator: '分母にゼロは入力できません。',
    fraction_determinator: '分子にゼロは入力できません。',
    fraction_value: '１より大きい値は入力できません。',
    fraction_value_2: '１より大きい値は入力できません。',
    number_max: '{_field_}は{max}桁以内の正数を入力してください。',
    number_length: '{_field_}は{length}桁の数値を入力してください。',
    number_comma_full_width: '{_field_}は正数を入力してください',
    duplicate_product: '同一の製品等名、管理IDが存在します。確認してください。',
    custom_electric_companyNo: '"A" から始まる電気事業者登録番号は設定できません',
  },
}
