export default class FiscalYearService {
  constructor(axios) {
    this.axios = axios
  }

  list() {
    return this.axios.$get('/fiscal-year/list');
  }

  update(payload) {
    return this.axios.$post('/fiscal-year/update', payload)
  }

  checkCalculationMethodChangeable(payload) {
    return this.axios.$get('/fiscal-year/category-6', {
      params: payload
    })
  }

}