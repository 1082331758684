export default {
  doSetActivityFilterCriteria: function (context, filterCriteria) {
    context.commit("SET_FILTER_CRITERIA", filterCriteria)
  },
  doSetScopeOneActivityInputList: function (
    context,
    scopeOneActivityInputList
  ) {
    context.commit(
      "SET_SCOPE_ONE_ACTIVITY_INPUT_LIST",
      scopeOneActivityInputList
    )
  },
  doSetScreenItemList: function (context, value) { context.commit("SET_SCREEN_ITEM_LIST", value) },
  doSetScreenRelationList: function (context, value) { context.commit("SET_SCREEN_RELATION_LIST", value) },
  doSetCategoryGroupList: function (context, value) { context.commit("SET_CATEGORY_GROUP_LIST", value) },
  doSetglobalWarmingPotentialList: function (context, value) { context.commit("SET_GLOBAL_WARMING_POTENTIAL_LIST", value) },
}
