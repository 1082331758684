export default class RetailElectricityProvidersEmission {
  constructor(axios) {
    this.axios = axios
  }

  list(page) {
    return this.axios.$get(`/emission-co/init?page=${page}`)
  }

  filter(searchTerm, page) {
    return this.axios.$get(`/emission-co/filter?filterQuote=${searchTerm}&page=${page}`)
  }

  update(payload) {
    return this.axios.$post('/emission-co/update', payload)
  }

  check(companyNo, retailElectricCalculationMethod, supplyMenuCode) {
    return this.axios.$get('/emission-co/check', {
      params: {
        compNo: companyNo,
        calcMethod: retailElectricCalculationMethod,
        supplyMenu: supplyMenuCode
      }
    })
  }
}
