export default class ProductManagementProductsService {
  constructor(axios) {
    this.axios = axios
  }
  getBaseOrgs(params) {
    return this.axios.$get(`/api/v1/setting/product-management/getBaseOrgs`, { params: params })
  }
  async getProducts(params) {
    return await this.axios.$get('api/v1/setting/product-management/productions', { params: params })
  }
  async update(payload) {
    return await this.axios.$post('api/v1/setting/product-management/update', payload);
  }
  async countProductsActivity(params) {
    return await this.axios.$get('api/v1/setting/product-management/count', { params: params })
  }
}