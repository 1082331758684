import Vue from "vue"
import Offset from "@/components/vendor/helper/Offset"
import Card from "@/components/vendor/material/Card"
import Chart from "@/components/vendor/material/Chart"
import Alert from "@/components/vendor/material/Alert"
import StatsCard from "@/components/vendor/material/StatsCard"
import Table from "@/components/vendor/material/Table"

Vue.component(Offset.name, Offset)
Vue.component(Card.name, Card)
Vue.component(Chart.name, Chart)
Vue.component(Alert.name, Alert)
Vue.component(StatsCard.name, StatsCard)
Vue.component(Table.name, Table)
