import Vue from "vue"

export default {
  SET_DRAWER: (state, value) => (state.drawer = value),
  SET_LOADING_QUEUE: (state, value) => (state.loadingQueue = value),
  ADD_LOADING_QUEUE: (state, { key, value }) =>
    Vue.set(state.loadingQueue, key, value),
  REMOVE_LOADING_QUEUE: (state, { key }) => Vue.delete(state.loadingQueue, key),
  SET_STATE: (state, payload) => {
    state[payload.name] = payload.value
  },
  SET_TOKEN: (state, value) => {
    state.token = value
  },
  TOGGLE_NOTIFICATION: (state) => {
    state.showNotification = !state.showNotification
  }
}
