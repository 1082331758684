export default class DataExtraction {
  constructor(axios) {
    this.axios = axios
  }

  init() {
    return this.axios.$get(`/api/v1/export-data/init`)
  }

  register(payload) {
    return this.axios.$post(`/api/v1/export-data/register-pattern`, payload)
  }

  getValueList(itemName) {
    return this.axios.$get(`/api/v1/export-data/select?itemName=${itemName}`)
  }

  selectPattern() {
    return this.axios.$get(`/api/v1/export-data/select-patterns`)
  }

  getData(payload) {
    return this.axios.$post('/api/v1/export-data/view-template', payload)
  }

  removePattern(payload) {
    return this.axios.$put('/api/v1/export-data/delete', payload)
  }

  getFile(payload) {
    return this.axios.$post('/api/v1/export-data/file', payload, { responseType: 'blob' })
  }
}