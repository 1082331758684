export default class EmissionsCalculationGuideService {
  constructor(axios) {
    this.axios = axios
  }
  getInit() {
    return this.axios.$get('/external/link/init');
  }
  add(formData) {
    return this.axios.$post('/external/link/add', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
    });
  }
  update(formData) {
    return this.axios.$put('/external/link/update', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
    });
  }
  delete(linkCategory, seqNo) {
    return this.axios.$delete(`/external/link/delete?linkCategory=${linkCategory}&seqNo=${seqNo}`)
  }
  checkFileNameDuplicate(fileName) {
    return this.axios.$get(`external/link/check-image-exist?thumbnailFileName=${fileName}`)
  }
}