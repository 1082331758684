export default class RetailElectricityProviders {
  constructor(axios) {
    this.axios = axios
  }

  list(page) {
    return this.axios.$get(`/retail/electric/init?page=${page}`)
  }

  filter(searchTerm, page) {
    return this.axios.$get(`/retail/electric/filter?filterQuote=${searchTerm}&page=${page}`)
  }

  update(payload) {
    return this.axios.$post('/retail/electric/update', payload)
  }

  checkRemovable(id) {
    return this.axios.$get(`/retail/electric/check?compNo=${id}`)
  }

  checkDuplicatePrimaryKey(id) {
    return this.axios.$get(`/retail/electric/check/duplicate?compNo=${id}`)
  }

}