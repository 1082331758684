export default {
  checkShowMenu: (state, getters) => to => {
    if (!to) return true
    const lightVersionBlockUrls = [
      '/setting/industry-templates-master',
      '/setting/mst/emissions-unit-value-master',
      '/setting/mst/transport-master',
      '/product-management/',
    ]
    if (state.isLightVersion && lightVersionBlockUrls.includes(to)) {
      return false
    }
    // remove '/'
    const link = to.slice(1)

    if (getters.currentUserAuthority && getters.currentUserAuthority[link]) {
      return getters.currentUserAuthority[link].menuHiddenFlag === '0'
    }
    return true
  },
  currentUserAuthority: (state) => {
    if (!state.screenControl || !state.user) return null
    return state.screenControl[state.user.userAuthority]?.reduce((acc, item) => {
      acc[item.screenId] = item
      return acc
    }, {})
  },
  checkActiveButton: (state, getters) => path => {
    if (!path || !getters.currentUserAuthority) return true
    // remove '/'
    const link = path.slice(1)
    let page
    const authorityPages = Object.keys(getters.currentUserAuthority)
    if (path.includes('/setting/mst/') && authorityPages.includes('setting/mst/')) {
      page = getters.currentUserAuthority['setting/mst/']
    } else if (path.includes('/setting') && authorityPages.includes('setting')) {
      page = getters.currentUserAuthority['setting']
    } else if (authorityPages.includes(link)) {
      page = getters.currentUserAuthority[link]
    }

    if (page) {
      return page.screenUpdateFlag === '1'
    }
    return true
  }
}
