export default {
  appname: "co2",
  menu: {
    "user-name-label": "ユーザー：",
    "customDashboardName": "ダッシュボード",
    "dashboard": "",
    "dashboard-analytics": "排出量データ分析",
    "activity-board": "活動履歴",
    "activity-input": "活動量入力",
    "setting": "各種設定",
    "setting-process": "活動・プロセス",
    "setting-organization": "組織情報",
    "setting-company": "企業情報",
    "setting-fiscal-year": "年度情報",
    "setting-user": "ユーザー登録",
    "setting-mst-retail-electricity-providers": "小売電気事業者マスタ",
    "setting-mst-retail-electricity-providers-emission": "小売電気事業者排出係数マスタ",
    "setting-master-maintenance": "マスタメンテナンス",
    "setting-mst-master-register-csv-export": "各種マスタcsv出力/取込",
    "setting-masterregistercsvexport": "各種マスタ登録/csv出力画面",
    "setting-mst-emissions-unit-value-master": "ユーザー用排出原単位マスタ",
    'setting-mst-transport-master': '輸送マスタ',
    "setting-news": "お知らせ情報",
    "setting-emission-version-management": "排出原単位切替処理",
    "alert-setting": "入力状況/アラート設定",
    "setting-industry-templates-master": "業種別テンプレート情報",
    "page-top": "ページ上部へ",
    "setting-product-management": "製品管理情報",
    "product-management-production": "製品情報入力",
    "product-management-activity": "製品ごと詳細入力",
    "product-management-simulation": "製品排出量シミュレーション",
    "manufacturesFirstRows": "製品排出量シミュレーションタブー",
    "emissions-calculation-guide": "排出量算定ガイド ",
    "reduction-target-input": '削減目標入力',
    "data-extraction": "データ抽出",

    logout: "ログアウト",
  },
  common: {
    company: "全社",
    location: "拠点",
    affiliation: "所属",
    wholeCompany: "全社",
    dept: "事業部",
    selectAll: "全選択",
    filter: "絞り込み",
    save: "保存",
    category: {
      "01": "カテゴリ1",
      "02": "カテゴリ2",
      "03": "カテゴリ3",
      "04": "カテゴリ4",
      "05": "カテゴリ5",
      "06": "カテゴリ6",
      "07": "カテゴリ7",
      "08": "カテゴリ8",
      "09": "カテゴリ9",
      "10": "カテゴリ10",
      "11": "カテゴリ11",
      "12": "カテゴリ12",
      "13": "カテゴリ13",
      "14": "カテゴリ14",
      "15": "カテゴリ15",
      other: "その他",
    },
    confirm: {
      warning: '注意',
      confirm: '確認',
      remove_confirm: '{name}を削除します。よろしいですか？',
      remove_data: 'データを削除します。よろしいですか？',
      remove_unsaved_row: '空白行を削除します。よろしいですか？',
      unsaved_data: '入力した内容は保存されませんが、よろしいですか？<br>「はい」　：入力した内容を破棄して画面遷移します。<br>「いいえ」：{screenName}画面に戻ります。',
      remove_existed_electric_company_confirm: '登録されている排出係数があります。排出係数も削除されますがよろしいですか？',
      remove_amount_used: '削除される使用量があります。よろしいですか？',
      closecurrenBase: '現在、終了している拠点ですがよろしいですか？',
      inputConfirm: '適用終了年月より未来は活動量入力ができませんが、よろしいですか？',
      YES: "はい",
      NO: "いいえ",
      CANCEL: "キャンセル",
      remove_amount: '削除される',
      confirmText: 'があります。よろしいですか？',
      deleteLink: '選択中のリンク情報を削除します。よろしいですか。'
    },
    form: {
      noData: '一致するものがありません。'
    },
    search: '検索',
    yes: 'あり',
    no: 'なし',
    solve: '処理',
    ofBasic: 'の根拠',
    months: {
      Jan: '1月',
      Feb: '2月',
      Mar: '3月',
      Apr: '4月',
      May: '5月',
      Jun: '6月',
      Jul: '7月',
      Aug: '8月',
      Sep: '9月',
      Oct: '10月',
      Nov: '11月',
      Dec: '12月',
    },
    name: 'さん',
    language: '言語'
  },
  activity: {
    templateImport: "テンプレート取込",
    excelExport: "一括入力Excel出力",
    excelImport: "一括入力Excel取込",
    tempSave: "保存",
    monthlyUpdate: "月次更新",
    targetMonth: "対象年月",
    divisionType: "カテゴリ",
    divisionTypeLocation: "拠点",
    divisionTypeDepartment: "事業部",
    tempTotalScore: "全社",
    selectScope: "スコープ",
    filter: {
      targetMonthF: "対象年月From",
      targetMonthT: "対象年月To",
      inputDivision: "対象区分",
      inputSelectScope: "対象スコープ",
    },
    input: {
      common: {
        activityName: "活動名",
        fuelType: "燃料種類",
        fuelUsage: "燃料使用量",
        energyType: "エネルギー種別",
        energyUsage: "エネルギー消費量",
        electricityUsage: "電力使用量",
        electricCompany: "電力会社",
        electricPlan: "電力プラン",
        hfcUsage: "HFCの使用量",
        weight: "重量",
        machineModel: "機種",
        purchaseAmount: "購入量",
        purchaseMoney: "購入金額",
        money: "金額",
        departmentName: "部門名",
        productName: "製品名",
        commercial: '営業用',
        for_family: '実家用',
        for_private_family: '自家用',
        fuel_law: '燃料法',
        typical_value: '代表値',
        electricCompanyOrsupplyMenu: '電力会社/供給メニュー',
        calculation_target: '算定対象',
        calculation_method: '算定方法',
        activity_values: '使用量',
        emissions_intensity: '排出原単位',
        emission_amount: "排出量",
        including_base: '（拠点に属する所属分を含める）',
        including_subordinates: '（配下の所属分を含める）',
        rate: '割合',
        amountCollected: '回収・適正処理量',
        apporionment_byconsignor: '荷主別按分',
        refrigerantgas: '冷媒ガス',
        only: 'のみ',
        exceed_description_1: '件数が多い為、{record_limit}件まで表示しています。',
        exceed_description_2: '全件表示するには、全件表示ボタンをクリックして下さい。',
      },
      capital: {
        capitalGoodsName: "資本財名",
        capitalDivision: "資本財",
      },
      transport: {
        maximumPayload: "最大積載量",
        cargoWeight: "貨物重量",
        transportDistance: "輸送距離",
        accumulationRate: "積載率",
        transportMode: "輸送モード",
      },
      trash: {
        disposalMethod: "処理方法",
        transportStage: "輸送段階",
      },
      business_trip: {},
      workCome: {
        traffic_class: "交通区分",
        traffic_allowance: "交通費支給額",
      },
      rease: {
        buildingUse: "建物用途",
      },
      emission_factor_during_use: '使用時排出係数(年間漏洩率)',
      global_warming_factor: '地球温暖化係数',
      weight_of_cargo: '貨物重量',
      business_activities_subject_to_accounting: '算定の対象となる事業活動',
      select_calculationMethod: '算定の方法を選択してください',
      fuel: '燃費が{fuelName}',
      raitoLoading: '積載率{truckLoadRatioName}',
      activity_model_title: '製品データ選択'
    },
    button: {
      search: "検索",
      clear: "検索解除",
      addInput: "入力欄",
      removeInput: "入力欄削除",
      add: "追加",
      update: "更新",
      show_all: '全件表示'
    },
    board: {
      unInput: "未入力",
    },
    category_1: {
      register_procurement: '調達品マスタ登録',
      procurement_id: '調達品ID',
      procurement_name: '調達品名',
      transport_activity: '輸送活動',
      confirm_create_procurment: 'マスタに登録されていない名称です。<br>マスタ登録を行いますか？'
    },
    import_transport: {
      search_criteria: '【検索条件】',
      no_route: '経路情報がありません。',
      headers: {
        activity_name: '活動名',
        supplier: '取引先',
        shipping_from: '発送元/発送先',
        memo: 'メモ',
      },
      load_ratio: {
        title: '積載率設定',
        route: '経路',
        load_ratio_setting: '積載率設定',
        description: 'トラック積載率を設定してください。',
        truck_load_ratio: 'トラック積載率'
      }
    },
    excel: {
      confirm_import: '{targetMonth}の{baseOrgName}のデータを全て取替えます。よろしいですか？',
      savedData: '一括入力Excel取込が終了しました。'
    },
    guide: {
      emission_amount: 'CO2排出量',
      unit: '単位',
      emission_intensity: '排出原単位',
      usage: '使用量',
      example: "例）",
      activity: '活動量',
      scope_one: {
        title: 'SCOPE1(温室効果ガス直接排出)の計算方法',
        usage: '使用量',
        activity_unit: 'kl,t,Nm3等',
        description: 'SCOPE1の対象になるのは、「自社で直接排出している温室効果ガス」です。燃料ごとにCO2排出量を計算します。',
        gasoline_usage: 'ガソリンの使用',
        combustion: '炉での燃焼',
        boiler: 'ボイラーの使用',
        gasoline: 'ガソリン',
        kerosene: '軽油',
        city_gas: '都市ガス',
        etc: "・・・等",
        by_fuel: '各燃料ごと',
        subtitle_one: '各燃料ごとにCO2排出量を算出',
        subtitle_two: '各燃料ごとのCO2排出量を合算してSCOPE1を算出。',
        footer_description: '都市ガスの使用量はNm3（ノーマルリューベ）で入力します。<br>m3に0.967を乗算した値がNm3となります。',
      },
      scope_two: {
        title: 'SCOPE2(電気・熱の使用に伴う間接排出)の計算方法',
        electricity_usage: '電気使用量',
        heat_usage: '熱使用量',
        activity_unit: 'kl,tなど',
        subtitle_one: '①電気のCO2排出量を算出',
        subtitle_two: '②熱のCO2排出量を算出',
        subtitle_three: '電気のCO2排出量と熱のCO2排出量を合算してSCOPE2を算出',
        description: 'SCOPE2の対象になるのは、「自社で使用しているエネルギーを作る為に社外で排出される温室効果ガス」です。他社で製造される電気、熱が該当します。',
        electricity_description_one: '電気の供給元ごとにCO2排出量を算出',
        electricity_description_two: '電気の供給元ごとのCO2排出量を合算して電気のCO2排出量を算出',
        heat_one: '1.「産業用蒸気」のCO2排出量を算出',
        heat_two: '2.「蒸気(産業用以外)」のCO2排出量を算出',
        heat_total: '「産業用蒸気」のCO2排出量と「蒸気(産業用以外)」のCO2排出量を合算',
        industrial_vapor: '産業用蒸気',
        vapor: '蒸気(産業用以外)',
        heat: '熱の',
        electricity: '電気の',
        by_electric_company: '電気の供給元ごと',
        company_one: 'A社',
        company_two: 'B社',
        company_three: 'E社',
      },
      scope_three: {
        million: '百万円,万円',
        yen: '円',
        person: '人',
        by_fuel: "「燃料法」の場合",
        by_fuel_consumption: "「燃費法」の場合",
        fuel_consumption: "燃費",
        fuel_usage: "燃料使用量",
        fuel_unit: "kl,l,t,kg",
        times: '回',
        category_one: {
          description: '「産業連関表ベース」を選択した場合、以下のように計算します。',
          title: 'カテゴリ1「購入した製品・サービス」の計算方法',
          subtitle_one: '算定方法①「調達量」を元に算出',
          procurement: '調達量',
          price: '金額',
          subtitle_two: '算定方法②「金額」を元に算出',
          footer_description: '独自に原単位を入手している場合は、「活動量×原単位」を使用してください。<br>また、排出量をあらかじめ把握している場合は、「直接手入力」を使用してください。',
        },
        category_two: {
          description: '「購入した資本財の価格に基づく算定」を行う場合、以下のように計算します。<br>複数年に渡って建設や製造されている場合には、最終年にまとめて計上します。',
          title: 'カテゴリ2「自社の資本財の建設・製造に伴う排出」の計算方法',
          capital_investment_per_year: "年間設備投資額",
          footer_description: '各工程ごとの排出量や、サプライヤーから入手した情報を積み上げて算出する<br>ことも可能です。',
        },
        category_three: {
          title: 'カテゴリ3「SCOPE1、2に含まれない燃料及びエネルギー活動」の計算方法',
          description: "カテゴリ3は「自社で使用するエネルギー(「SCOPE1」「SCOPE2」)の燃料を、外部で採掘や精製する際の排出量」が該当します。",
          example_title: "自社で使用している燃料、電気に関わる",
          coal_mining: "石炭の採掘",
          oil_mining: "石油の採掘",
          oil_refining: "石油の精製",
          etc: "・・・等",
          fuel_procurement: "燃料の調達量",
          fuel_procurement_unit: "kWh,MJ,GJ",
        },
        category_four: {
          title: 'カテゴリ4 「輸送、配送（上流）」の計算方法',
          subtitle_one: '算定方法①「燃料法」',
          subtitle_two: '算定方法②「燃費法」',
          subtitle_three: '算定方法③「トンキロ法」',
          delivery_distance: "輸送距離",
          delivery_ton_kilo: "輸送トンキロ",
          ton_kilo_fuel_emission_intensity: "トンキロ法<br>燃料使用原単位",
          ton_kilo_emission_intensity_by_vehicle: "トンキロ法<br>輸送機関別<br>排出原単位",
          ton_kilo_description: "輸送トンキロ=貨物重量×輸送距離（各輸送毎）",
          by_truck: "トラックの場合",
          by_others: "鉄道、船舶、航空の場合",
          description: "カテゴリ4の対象になるのは、「一次サプライヤーから自社まで」を「他社配送」で輸送した場合です。ただし、「自社が荷主」の場合に限り、自社からの配送（下流）も対象になります。",
          attention: "カテゴリ4の算定範囲について、詳しくは操作マニュアル「10. マスタメンテナンス　輸送マスタ　入力項目について」をご参照ください。",
        },
        category_five: {
          title: 'カテゴリ5「事業から出る廃棄物」の計算方法',
          example_title: "自社から出た廃棄物の",
          waste_disposal: "廃棄物処理",
          recycling: "リサイクル処理",
          etc: "・・・等",
          waste_amount: "廃棄物の量",
          footer_description: '独自に原単位を入手している場合は、「活動量×原単位」を使用してください。<br>また、排出量をあらかじめ把握している場合は、「直接手入力」を使用してください。',
        },
        category_six: {
          title: 'カテゴリ6 「出張」の計算方法',
          subtitle_one: '算定方法①「移動距離、支給額など」から算出',
          subtitle_two: '算定方法②「従業員数」から算出',
          step_one: "1.「自動車移動等に伴う燃料使用量の排出量」を算出",
          co2_one: '自動車燃料使用の',
          step_two: "2.「交通機関における排出量」を算出",
          co2_two: '交通機関における',
          step_three: "3.「宿泊における排出量」を算出",
          co2_three: '宿泊における',
          step_four: "4.「出張日数」から算出",
          co2_four: '出張日数',
          step_five: "5.1～4を合算",
          transport_cost: "交通費支給額",
          number_of_stays: "宿泊数",
          stays: '人泊',
          number_of_days: '出張日数',
          days: '人日',
          migration_length: '移動距離',
          number_of_employee: "従業員数",
        },
        category_seven: {
          title: 'カテゴリ7 「雇用者の通勤」の計算方法',
          subtitle_one: '算定方法①「通勤支給額」から算出',
          subtitle_two: '算定方法②「従業員数」から算出',
          description: 'カテゴリ7の対象になるのは、「雇用者の通勤における排出」です。<br>ただし、自社保有車両での通勤は含みません。',
          step_one: "1.「自動車移動等に伴う燃料使用量の排出量」を算出",
          step_two: "2.「通勤者の排出量」を算出",
          step_three: "3.「テレワーク従事者の排出量」を算出（任意）",
          step_four: '4.1～3を合算',
          transport_cost: "交通費支給額",
          fuel_procurement: "燃料の調達量",
          fuel_procurement_unit: "kWh,Mj等",
          power_usage: "電気使用量",
          fuel: '自動車燃料使用の',
          emission_factor: "CO2排出係数",
          migration_length: '移動距離',
          commuter: "通勤者",
          teleworker: "テレワーク従事者",
          number_of_employee: "従業員数",
        },
        category_eight: {
          title: 'カテゴリ8「リース資産（上流）」の計算方法',
          subtitle_one: '算定方法① リース資産ごとにエネルギー種別の消費量が把握できる場合',
          subtitle_two: '算定方法② エネルギー種別の消費割合が把握できない場合',
          two_description: '（建物用途とエネルギー種別の加重平均で算出）',
          subtitle_three: '算定方法③「床面積」から算出（建物の場合）',
          amount_by_type: 'エネルギー種別の',
          energy_usage: 'エネルギー使用量',
          avarage_by_type: 'エネルギー種別に加重平均した',
          floor_space: '建物の床面積',
        },
        category_nine: {
          title: 'カテゴリ9 「輸送、配送（下流）」の計算方法',
          subtitle_one: '算定方法①「燃料法」',
          subtitle_two: '算定方法②「燃費法」',
          subtitle_three: '算定方法③「トンキロ法」',
          fuel_consumption: "燃費",
          fuel_usage: "燃料使用量",
          delivery_distance: "輸送距離",
          delivery_ton_kilo: "輸送トンキロ",
          ton_kilo_fuel_emission_intensity: "トンキロ法<br>燃料使用原単位",
          ton_kilo_emission_intensity_by_vehicle: "トンキロ法<br>輸送機関別<br>排出原単位",
          ton_kilo_description: "輸送トンキロ=貨物重量×輸送距離（各輸送毎）",
          by_truck: "トラックの場合",
          by_others: "鉄道、船舶、航空の場合",
          description: "カテゴリ9の対象になるのは、「販売先への輸送における排出」です。「他社輸送かつ他社が荷主」の場合が該当します。",
          attention: "カテゴリ9の算定範囲について、詳しくは操作マニュアル「10. マスタメンテナンス　輸送マスタ　入力項目について」をご参照ください。",
        },
        category_ten: {
          title: 'カテゴリ10「販売した製品の加工」の計算方法',
          subtitle_one: '算定方法①「販売先から加工に伴う排出量データを入手できる」',
          subtitle_two: '算定方法②「販売先から加工に伴うエネルギー消費データを入手できる」',
          subtitle_three: '算定方法③「販売先から加工に伴うエネルギー消費データ等を入手できない」',
          amount: "中間商品の販売量",
          fuel_unit: "kl,l,t,kg等",
          energy_usage: "エネルギー消費量",
          intermediate_product: '中間製品の加工に伴う',
          description: '販売先から加工に伴うエネルギー消費データ等を入手できない場合は、<br>販売量から計算を行います。',
        },
        category_eleven: {
          title: 'カテゴリ11 「販売した製品の使用」の計算方法',
          subtitle_one: '直接使用段階排出',
          subtitle_two: '間接使用段階排出',
          case_one: "燃料を使用する製品の場合",
          case_two: "電気を使用する製品の場合",
          case_three: "ガスを排出する製品の場合",
          case_four: "製品が燃料の場合",
          case_five: "温室効果ガス含有製品で、使用時に温室効果ガスを排出するもの",
          assumed_usage_in_life: "想定生涯<br>使用回数",
          number_of_sales: "販売数",
          fuel_usage_per_time: "1回使用辺りの<br>燃料使用量",
          power_usage_per_time: "1回使用辺りの電気使用量",
          greenhouse_gas_emissions: "温室効果ガス<br>排出量",
          global_warming_potential: "地球温暖化係数",
          fuel_sales: "燃料販売量",
          greenhouse_gas_per_product: "製品辺りの<br>温室効果ガス<br>含有量",
          emission_in_life: "生涯使用の<br>排出率",
          percentage_usage_in_life: "想定生涯使用回数の割合",
          fuel_unit: "kl,l,t,kg等",
        },
        category_twelve: {
          title: 'カテゴリ12「販売した製品の廃棄」の計算方法',
          example_title: "販売先から出た廃棄物の",
          waste_disposal: "廃棄物処理",
          recycling: "リサイクル処理",
          etc: "・・・等",
          waste_amount: "廃棄物の量",
        },
        category_thirteen: {
          title: 'カテゴリ13「リース資産（下流）」の計算方法',
          subtitle_one: '算定方法① リース資産ごとにエネルギー種別の消費量が把握できる場合',
          subtitle_two: '算定方法② エネルギー種別の消費割合が把握できない場合',
          two_description: '（建物用途とエネルギー種別の加重平均で算出）',
          subtitle_three: '算定方法③「床面積」から算出（建物の場合）',
          amount_by_type: 'エネルギー種別の',
          energy_usage: 'エネルギー使用量',
          avarage_by_type: 'エネルギー種別に加重平均した',
          floor_space: '建物の床面積',
        },
        category_fourteen: {
          title: 'カテゴリ14 「フランチャイズ」の計算方法',
          description: '報告事業者がフランチャイズ主宰者である場合、フランチャイズ加盟者（フランチャイズ契約を締結している事業者）におけるScope1,2の排出量が算定対象範囲になります。',
        },
        category_fifteen: {
          title: 'カテゴリ15「投資」の計算方法',
          description: '可能である場合は算定方法①を使用してください。投資別の排出量データを入手できない場合は算定方法②を使用してください。',
          subtitle_one: '算定方法① SCOPE1,2の排出量を投資持分比率に応じて積み上げで算出',
          subtitle_two: '算定方法② 経済データを用いて推計',
          case_one: '1.株式投資における排出量を算出',
          case_two: '2.債券投資における排出量を算出',
          case_three: '3.プロジェクトにおける排出量を算出',
          case_four: '4.管理投資及びその他投資分等の排出量を算出（任意）',
          total: "5. 1～4を合算する",
          stock_investment: '株式投資の',
          bond_investment: '債券投資の',
          project: 'プロジェクトの',
          managed_investment: '管理投資および<br>その他投資分等の',
          managed_investment_target: '自社のSCOPE1,2の',
          stock_investment_ratio: '株式保有割合',
          bond_investment_ratio: '投資先の総資本に対する割合',
          project_ratio: 'プロジェクト<br>出資額の割合',
          managed_investment_ratio: '業務全体に対する顧客割合',
          investment: '投資部門の',
          stock_value: '株式投資額',
          bond_value: '債権投資額',
          project_value: 'プロジェクト<br>総投資額',
          other_value: '投資額',
        },
      }
    },
    chart: {
      noData: "適切なデータはありません。"
    }
  },
  // registRequest: {
  //   image: {
  //     fileBtn: "画像を追加",
  //     removeBtn: "表示中の画像を削除",
  //   },
  //   item: {
  //     currentBtn: "現在日時を設定",
  //     moneysuffix: "枚",
  //     summaryhint: "金額：[0]円",
  //   },
  //   tags: {
  //     addBtn: "タグを追加",
  //   },
  //   bundle: {
  //     addBtn: "同梱品を追加",
  //   },
  // },
  // printDocument: {
  //   criteria: {
  //     title: "印刷条件",
  //     category: "カテゴリ",
  //     additionalTitle: "追加条件",
  //     idF: "拾得物ID(From)",
  //     idT: "拾得物ID(To)",
  //     pickedDateF: "拾得日(From)",
  //     pickedDateT: "拾得日(To)",
  //     name: "名称",
  //     place: "拾得場所",
  //     storageLocation: "保管場所",
  //     storageTermF: "保管期限(From)",
  //     storageTermT: "保管期限(To)",
  //   },
  // },
  // item: {
  //   image: {
  //     title: "画像",
  //     image: "画像",
  //   },
  //   lost_property: {
  //     title: "物品情報",
  //     title_bundle: "内容物",
  //     management_title: "管理情報",
  //     item_id: "拾得物ID",
  //     item_id_suffix: "（自動採番）",
  //     picked_date: "拾得日",
  //     picked_time: "拾得時刻",
  //     acceptance_date: "受理日",
  //     acceptance_time: "受理時刻",
  //     picked_place: "拾得場所",
  //     item_category: "分類",
  //     item_category_label: "分類",
  //     item_sub_category: "中分類",
  //     item_sub_category_label: "中分類",
  //     item_class: "小分類",
  //     item_class_label: "小分類",
  //     is_valuables: "貴重品",
  //     item_valuables_flg_label: "貴重品",
  //     item_report_flg: "届出対象",
  //     item_report_flg_label: "届出対象",
  //     facility_right_claim: "施設権利",
  //     facility_right_claim_label: "施設権利",
  //     item_name: "品名",
  //     item_description: "詳細",
  //     storage_loc: "保管場所",
  //     storage_loc_label: "保管場所",
  //     storage_term: "保管期限",
  //     item_report_date: "警察届出日",
  //     property_remark: "備考",
  //     item_status: "状況",
  //     item_status_label: "状況",
  //     item_status_date: "状況更新日",
  //     item_staff_name: "対応者",
  //     print_no: "印刷番号",
  //     delete_flg: "削除フラグ",
  //     last_updated: "最終更新日",
  //     last_update_user_name: "最終更新者",
  //   },
  //   finder: {
  //     title: "拾得者情報",
  //     finder_name: "拾得者氏名",
  //     finder_type: "拾得者種別",
  //     finder_type_label: "拾得者種別",
  //     right_claim: "権利主張",
  //     right_claim_label: "権利主張",
  //     announcement_concent: "告知同意",
  //     announcement_concent_label: "告知同意",
  //     finder_tel: "拾得者電話番号",
  //     finder_zipcode: "拾得者郵便番号",
  //     finder_address: "拾得者住所",
  //     finder_remark: "拾得者備考",
  //   },
  //   owner: {
  //     title: "引渡情報",
  //     owner_name: "遺失者氏名",
  //     owner_tel: "遺失者電話番号",
  //     owner_zipcode: "遺失者郵便番号",
  //     owner_address: "遺失者住所",
  //     owner_remark: "遺失者備考",
  //     return_staff_name: "返却担当者",
  //     return_method: "返還方法",
  //     return_method_label: "返還方法",
  //     due_date: "返却予定日",
  //     returned_date: "返却完了日",
  //   },
  //   cash: {
  //     title: "金種情報",
  //     bill10000: "1万円札",
  //     bill5000: "5千円札",
  //     bill2000: "2千円札",
  //     bill1000: "1千円札",
  //     coin500: "500円硬貨",
  //     coin100: "100円硬貨",
  //     coin50: "50円硬貨",
  //     coin10: "10円硬貨",
  //     coin5: "5円硬貨",
  //     coin1: "1円硬貨",
  //     other: "その他有価証券",
  //   },
  //   keyword: {
  //     title: "検索用タグ",
  //     tagname: "タグ",
  //   },
  //   bundle: {
  //     title: "同梱情報",
  //     bundleid: "同梱品ID",
  //   },
  //   edit: {
  //     inputs: {
  //       current_date_btn: "現在日時を設定",
  //       add_image_btn: "画像を追加",
  //       add_keyword_btn: "タグを追加",
  //       add_bundle_btn: "同梱品を追加",
  //       money_suffix: "枚",
  //       other_suffix: "円",
  //       summary_hint: "金額：[0]円",
  //     },
  //   },
  //   list: {
  //     current: "表示条件：[0]",
  //     criteria_btn: "表示条件",
  //     criteria: {
  //       title: "SCOPE3　絞り込み",
  //       category: "カテゴリ",
  //       additional_title: "追加条件",
  //       id: "ID",
  //       status: "状況",
  //       picked_date_f: "拾得日From",
  //       picked_date_t: "拾得日To",
  //       name: "名称",
  //       item_valuables_flg: "貴重品",
  //       item_report_flg: "届出対象",
  //       place: "拾得場所",
  //       storage_location: "保管場所",
  //       storage_term_f: "保管期限From",
  //       storage_term_t: "保管期限To",
  //       due_date_f: "返却予定日From",
  //       due_date_t: "返却予定日To",
  //       freeword: "フリーワード",
  //       output_file_type: "出力帳票",
  //       exclude_printed: "印刷済み",
  //       created_f: "登録日From",
  //       created_t: "登録日To",
  //     },
  //   },
  //   detail: { title: "詳細" },
  // },
  // request: {
  //   image: {
  //     title: "参考画像",
  //     image: "参考画像",
  //   },
  //   request: {
  //     title: "探索対象",
  //     client_title: "依頼者情報",
  //     management_title: "管理情報",
  //     return_title: "返還情報",
  //     request_id: "探索依頼ID",
  //     lost_date: "紛失日",
  //     lost_feature: "詳細",
  //     lost_place: "紛失場所",
  //     requester_name: "依頼者氏名",
  //     requester_tel: "電話番号",
  //     requester_zipcode: "郵便番号",
  //     requester_address: "住所",
  //     requester_remark: "備考",
  //     found_company_id: "発見企業ID",
  //     found_office_id: "発見事業所ID",
  //     found_item_id: "発見拾得物ID",
  //     request_status: "状況",
  //     request_status_label: "状況",
  //     request_status_date: "状況更新日",
  //     request_staff_name: "対応者",
  //     request_contact_type: "連絡要否",
  //     request_contact_type_label: "連絡要否",
  //     request_contact_term: "連絡期限",
  //     last_updated: "最終更新日",
  //     last_update_user_name: "最終更新者",
  //   },
  //   list: {
  //     current: "表示条件：[0]",
  //     criteria_btn: "表示条件",
  //     criteria: {
  //       created_f: "登録日From",
  //       created_t: "登録日To",
  //       title: "表示条件",
  //       freeword: "フリーワード",
  //       id: "ID",
  //       status: "状況",
  //       lost_date_f: "紛失日From",
  //       lost_date_t: "紛失日To",
  //       request_contact_term_f: "連絡期限From",
  //       request_contact_term_t: "連絡期限To",
  //     },
  //   },
  //   detail: { title: "詳細" },
  // },
  maintenance: {
    org: {
      "base_name": "拠点名称",
      "start_month": "適用開始年月",
      "end_month": "適用終了年月",
      "electric_company_no": "電気事業者登録番号",
      "work_style_city_code": "勤務形態別都市区分コード",
      "display_order": "表示順",
      "organization_id": "組織コード",
      "parrent_id": "親組織",
      "organization_name": "組織名称",
      "affiliation_base": "所属拠点",
      "display_order": "表示順",
      "baseOfList": "拠点一覧 ",
      "baseOfName": "拠点名",
      warning_message_1: '{organizationName}はすでに活動量入力されています。登録済み活動量の拠点を変更しますがよろしいですか？',
      warning_message_2: 'この拠点に所属している組織も適用終了年月を"{baseEndMonth}"で更新しますか？<br />「はい」　　：上記の組織の適用終了年月を更新します。<br />「いいえ」　：上記の組織の適用終了年月を更新しません。'
    },
    company: {
      title: "企業設定",
      detail_title: "企業情報",
      company_id: "企業ID",
      company_name: "企業名",
      company_contact_name: "企業担当者名",
      company_contact_tel: "企業担当者電話番号",
      company_contact_fax: "企業担当者FAX番号",
      company_contact_email: "企業担当者メールアドレス",
      updated: "最終更新日",
      company_name_label: "企業名称",
      company_name_label_kana: "企業名称カナ",
      zip_code: "郵便番号",
      address_city: "所在地県市町村",
      address_house_number: "所在地番号",
      specific_emitter_code: "特定排出者コード",
      capital_formation_code: "資本形成部門コード",
      organization1_name: "組織階層１名称",
      organization2_name: "組織階層２名称",
      organization3_name: "組織階層３名称",
      organization4_name: "組織階層４名称",
      franchise_flag_name: "フランチャイズ展開",
      business_year_start_at: "年度の開始月",
      organizationName: "組織名",
      noparent_organization: '親組織なし',
      organizationOfList: '組織一覧',
    },
    // company_settings: {
    //   detail_title: "操作設定",
    //   label: {
    //     default_storage_selected: "保管場所初期値",
    //     default_user_input: "対応者初期値",
    //     default_facility_right_claim: "施設権利初期値",
    //     update_place_hisstory: "拾得場所自動更新",
    //     default_finder_type: "拾得者種別初期値",
    //     default_item_complete_status: "拾得物完了登録初期値",
    //     finder_input_always: "拾得者情報入力",
    //     item_insert_transition: "拾得物登録時移動先",
    //     item_update_transition: "拾得物更新時移動先",
    //     request_insert_transition: "探索依頼登録時移動先",
    //     request_update_transition: "探索依頼更新時移動先",
    //   },
    // },
    // office: {
    //   title: "事業所設定",
    //   office_list_title: "事業所一覧",
    //   place_list_title: "拾得場所一覧",
    //   sl_list_title: "保管場所一覧",
    //   detail_title: "事業所情報",
    //   doc_info_title: "届出書類印字設定",
    //   doc_informant_title: "届出者情報",
    //   office_id: "事業所ID",
    //   office_name: "事業所名",
    //   send_to: "届出先",
    //   informant_name: "届出者名",
    //   informant_address: "届出者住所",
    //   informant_tel: "届出者電話番号",
    //   informant_other_contact: "届出者その他連絡先",
    //   rep_post: "代表者役職",
    //   rep_name: "代表者名",
    //   office_storage_loc_name: "保管場所名称",
    //   office_storage_loc_address: "保管場所所在地",
    //   office_storage_loc_tel: "保管場所電話番号",
    //   office_storage_loc_other_contact: "保管場所その他連絡先",
    //   picked_placeType: "拾得場所区分",
    //   cities_code: "市区町村コード",
    //   office_picked_place_name: "拾得場所施設名",
    //   office_picked_place_address: "拾得場所所在地",
    //   default_finder: "拾得者初期値",
    //   informant_picked_place_flg: "届出時拾得場所",
    //   updated: "最終更新日",
    //   doc_info: "帳票設定",
    //   place_count: "拾得場所 : 保管場所",
    //   modify_buttons: "",
    // },
    user: {
      title: "利用者一覧",
      detail_title: "利用者情報",
      office_id: "事業所ID",
      office_name: "事業所",
      user_id: "利用者ID",
      user_name: "利用者名",
      role_id_list: "権限",
      updated: "最終更新日",
      modify_buttons: "",
      user_password: "パスワード",
      confirm_password: "パスワード(確認)",
    },
    edit: {
      password: {
        title: "パスワード変更",
        old_password: "現在のパスワード",
        new_password: "新しいパスワード",
        confirmed_password: "新しいパスワード(確認)",
        change_success: 'パスワードを変更しました',
        change_failed: 'パスワードの変更に失敗しました',
      },
    },
  },
  // registReceipt: {
  //   search: {
  //     title: "更新対象",
  //     sequenceNo: "印刷整理番号",
  //   },
  //   edit: {
  //     title: "届出情報",
  //     staff_name: "担当者名",
  //     receipt_no: "受理番号",
  //     receipt_date: "受理日",
  //     receipt_time: "受理時刻",
  //   },
  //   list: {
  //     title: "対象一覧",
  //   },
  // },
  item: {
    list: {
      criteria: {
        title: "SCOPE3　絞り込み",
      },
    },
  },
  message: {
    noOptionsText: "該当する{0}が存在しません。",
    noChildrenText: "該当する{0}が存在しません。",
    removeInputListItem: "入力欄を取り消します。よろしいですか？",
    emissionsTooHigh: "排出量が大きすぎます。活動量を確認してください。",
    amount_used_toohigh: '使用量が大きすぎます。確認してください。',
    error: ' 申し訳ございません。エラーが発生しました。',
    contactAddress: 'システム管理者へご連絡ください。',
    addedData: 'データが追加されました。',
    savedData: '入力を保存しました。',
    updatedData: 'データが更新されました。',
    deletedDate: 'データが削除されました。',
    helpMessage1: '他人から供給された電気の使用量',
    helpMessage2: '他人から供給された熱の使用量',
  },
  api: {
    register_success: '登録されました。',
    update_success: '更新されました。',
    remove_success: '消除されました。',
    error: '何らかのエラーが発生しました。後ほどもう一度実行してください。',
    duplicated_error: '{name}はすでに存在しています。他の{name}を入力してください。',
    add_success: '追加されました。',
  },
  scope: {
    1: 'SCOPE1 温室効果ガス直接排出',
    2: 'SCOPE2 電気・熱の使用に伴う間接排出',
    3: 'SCOPE3 その他の間接排出',
  },
  'fiscal_year_info': {
    headers: {
      'fiscal_year': '年度',
      'emission_target_value': '排出目標',
      'personnel': '従業員数',
      'business_days': '営業日数',
      'category6_calculation_flag': 'カテゴリ６出張の算定方法',
      'calculation_basedon_number_of_employees': '従業員数に基づく算定',
      'calculation_basedon_distance_paymentamount': '移動距離、支給額などで算定',
    },
    remove_row_message: '年度情報を削除します。よろしいですか？',
    change_calculation_error: '既に変更前の算定方法で活動量が入力されています。',
    unit: {
      people: '人',
      day: '日',
    }
  },
  buttons: {
    add: '追加',
    edit: '修正',
    update: '更新',
    add_row: '行追加',
    regenerate_password: 'パスワード再発行',
    save: '保存',
    search: '検索',
    csvExport: 'csv出力',
    csvImport: 'csv取込',
    detail: '詳細',
    import_transport_activty: '輸送活動取込',
    import: '取込',
    setting: '設定',
    back: '戻る',
    delete: '削除',
    register: '登録',
    exportData: 'データ出力',
    change: '変更',
    movie: '動画を見る＞＞',
    imageUpload: 'アップロード画像選択',
    apply: '適用',
  },
  user: {
    remove_user: 'ユーザー（{userId}）を削除します。よろしいですか。',
    regenerate_password_confirm: 'ユーザー（{userId}）のパスワードを再発行します。よろしいですか。',
    register_success: '以下のユーザー登録が完了しました。',
    regenerate_password_success: '以下のユーザーのパスワードを再発行しました。',
    form: {
      userId: 'ユーザーID',
      password: 'パスワード',
      temp_password: '仮パスワード'
    },
    loged_in: "ログイン中のユーザーは削除できません。",
  },
  login: {
    not_authorized_error: 'IDまたはパスワードが正しくありません。',
    input_new_password: '新しいパスワードを入力してください。',
    setting: '設定',
    login: "ログイン"
  },
  master_maintenance: {
    explainText: 'マスタの更新を行う場合はcsvファイルを出力し、csvファイルの修正を行い取込を行ってください。',
    explainText1: ' ※csvファイルの内容で全件更新されます。',
    selectFirstValue: '対象マスタを選択してください',
    confirmUploadFIle: '{fileName}を取り込みます。よろしいですか？',
    retail_electricity_providers: {
      headers: {
        electricCompanyNo: '電気事業者登録番号',
        electricCompanyName: '電気事業者名',
        electricCompany: '電気事業者',
        displayOrder: '表示順',
        memo: 'メモ',
        displayFlag: '非表示',
        calculationMethod: '算定方法',
        supplyMenu: '供給メニュー',
        emissionFactor: '排出係数'
      },
      errors: {
        checkSupplyMenu: "同一電気事業者で供給メニュー無しと有りは混在できません。",
        checkDuplicate: '同一の電気事業者/算定方法/供給メニューが存在しています。',
      },

      duplicated_error: '電気事業者登録番号が重複しています。'

    },
    fileTypeCheck: 'csvファイル形式を選択してください。',
    fileNameCheck: 'ファイル名は間違っています。',
    dataFileCheck: 'csvファイルの内容がありません。ファイル内容を確認してください。',
    firtCheckData: '空データを取り込みます。マスタは全件クリアされますがよろしいですか？',
    checkNumberHeaders: 'csvの内容がマスタと一致していません。csvデータの項目数を確認してください。対象データ：1行目',
    checkNumberRecords: 'csvの内容がマスタと一致していません。csvデータの項目数を確認してください。対象データ：{countRows}行目',
    checkHeaderName: 'csvの内容がマスタと一致していません。csvのヘッダ部を確認してください。',
    checkFirts: 'ファイル取込中。。。',
    add_description: '検索時は行追加はできません。行追加を行う場合は、検索を解除してください。',
    emission_unit_value_master: {
      headers: {
        usage: '用途',
        emissionintensity: '排出原単位',
        unit: '単位',
        basis: '根拠',
        category: '使用するカテゴリ',
      },
    },
    transport_master: {
      headers: {
        transport_name: '輸送活動名',
        transport_id: '輸送活動ID',
        procurement_shipping: '調達/出荷',
        suppliers: '取引先',
        location: '発送先/発送元（場所）',
        memo: 'メモ',
        route: '経路'
      },
      dialog: {
        transport_activity: '輸送活動',
        route_detail: '経路詳細',
        transport_emission: '輸送に伴う排出量',
        route_name: '経路名',
        input_method: '入力方法',
        calculation_method: '算定方法',
        fuel_type: '燃料の種類',
        fuel_consumption: '燃料使用量',
        transport_agency: '輸送機関',
        transport_class: '輸送の区分',
        transport_distance: '輸送距離',
        cargo_volume: '貨物重量',
        car_business_private: '営業用・自家用の別',
        understand_fuel: '燃料の把握'
      }
    },
    news_providers: {
      headers: {
        news_start_date: '開始期間',
        news_end_date: '終了期間',
        news_content: '内容',
        delivery_base: '対象拠点',
        delivery_organization: '対象所属',
        division: '区分',
      },
      news_text: "お知らせ情報",
    }
  },
  news: {
    news: 'お知らせ',
    emergency: '緊急',
    important: '重要',
    normal: '通常',
    counter: '個'
  },
  emission_version_management: {
    headers: {
      tableName: 'テーブル名',
      version: 'バージョン',
      updateDate: '更新日時',
      updateName: '更新者',
    },
    update_history: '＜＜更新履歴＞＞',
    emission_intensity: '排出原単位データベース',
    retail_electric: '小売電気事業者',
    global_warming_potential: '地球温暖化係数',
    template: '業種別テンプレート',
    reference_page: '参考ページ',
    go_to_other_page: '（外部サイトへ遷移します）',
    version: 'バージョン',
    revised_version: '改訂版',
    descript1: '環境省 グリーン・バリューチェーンプラットフォーム',
    descript2: '環境省 電気事業者別排出係数関連ページ',
    emission_intensity_warning: '排出原単位データを選択したバージョンに一括置換します。よろしいですか？',
    retail_electric_warning: '小売電気事業者データを選択したバージョンに一括置換します。よろしいですか？',
    global_warming_potential_warning: '地球温暖化係数データを選択したバージョンに一括置換します。よろしいですか？',
    template_warning: 'テンプレートデータを選択したバージョンに一括置換します。よろしいですか？',
    saved_data: '{tableName}のバージョンを変更しました。'
  },
  user_registration: {
    user_id: "ユーザーID",
    user_name_kanji: "ユーザー氏名",
    user_name_kana: "ユーザー氏名カナ",
    authority: "権限",
    organization: "組織",
    email: "メールアドレス",
    update_self: '自分自身の権限を変更した場合、自動でログアウトされます。<br>再度ログインしてください。'
  },
  emissions_cal_guide: {
    title: 'タイトル',
    url: 'URL',
    overview: '概要',
    description: '【概要】',
    thumbnail: 'サムネイル画像',
    imageUpload: 'ファイル名',
    display: '表示順',
    remark: '1MB以下のjpg、pngファイルを使用可能'
  },
  industry_templates_master: {
    search_condition: {
      industry: "業種"
    },
    category_dialog: {
      template: "テンプレート",
      detail: "詳細",
      category: "カテゴリ",
    },
    headers: {
      scope3_category: "SCOPE3カテゴリ",
      activity_name: "活動名",
      calculation_method: "算定方法",
      download_target: "取込対象",
      update_name: "更新者",
    },
    change_industry: {
      confirm: 'すでに{industryName}のテンプレートが存在していますが、作業を続けますか？',
    },
    not_editable: "適用終了している拠点/所属です。テンプレートの編集はできません。",
    select_all: "取込対象：全取込",
    cancel_all: "取込対象：全解除",
  },
  product_production: {
    manufacture_month: "製造管理年月",
    products: "製品",
    headers: {
      product_name: "製品等名",
      product_production_id: "管理ID",
      production_count: "生産数",
      production_description: "（活動量の按分用）",
      procure_year: "調達年月",
      procurement_name: "調達品",
      activity_amount: "活動量",
      emission_amount: "排出量",
      activity_year: "活動年月",
      activity_name: "活動名",
      emission_rate: "排出割合",
      emissions_per_product: '1製品ごと排出量',
      unit: "生産数単位",
    },
    msg: {
      deleteConfirmMsg: '{productName}:{productControlId}を削除します。よろしいですか？',
      productDetailMsg: '詳細入力あり',
      choose: '選択',
      noData: 'データがありません',
    },
    titles: {
      procurement: '　　調　達　　',
      manufacturing: '　　製　造　　',
      distribution: '　　輸　送　　',
      utilization: '　　使　用　　',
      wasteRecycling: '廃棄リサイクル',
      procurement_first: "＜購入した製品・サービス　SCOPE3カテゴリ1＞",
      manufacturing_first: "＜直接排出　SCOPE1＞",
      manufacturing_second: "＜間接排出　SCOPE2＞",
      distribution_first: "＜輸送（上流）SCOPE3カテゴリ4＞",
      distribution_second: "＜輸送（下流）SCOPE3カテゴリ9＞",
      utilization_first: "＜製品の加工　SCOPE3カテゴリ10＞",
      utilization_second: "＜製品の使用　SCOPE3カテゴリ11＞",
      wasteRecycling_first: "＜製品の廃棄　SCOPE3カテゴリ12＞",
    },
    re_import: '再取込',
    removed: '活動削除済',
    activity_modal: {
      pattern_one: '活動量入力画面で入力したデータを参照する',
      pattern_two: 'データを直接手入力する',
      headers: {
        productActionName: '活動名',
        activityVolume: '活動量',
        evidence: '根拠',
        memo: 'メモ',
        updateDate: '入力日',
        updateName: '入力者',
      },
      button: {
        input: "入力"
      }
    },
    activity_model_title: '製品データ選択'
  },
  product_simulation: {
    headers: {
      simulationId: "シミュレーションNo",
    },
    msg: {
      deleteConfirmMsg: '{productName}:{simulationControlId}を削除します。よろしいですか？',
      dataIsExisted: '既に同一の製品等名が登録されています。変更してください。',
      addNew: '新規製品を設定'
    },
    add_details: "詳細入力",
    add: "シミュレーション",
    titles: {
      procurement: '　　調　達　　',
      manufacturing: '　　製　造　　',
      distribution: '　　輸　送　　',
      utilization: '　　使　用　　',
      wasteRecycling: '廃棄リサイクル',
      procurement_first: "＜購入した製品・サービス　SCOPE3カテゴリ1＞",
      manufacturing_first: "＜直接排出　SCOPE1＞",
      manufacturing_second: "＜間接排出　SCOPE2＞",
      distribution_first: "＜輸送（上流）SCOPE3カテゴリ4＞",
      distribution_second: "＜輸送（下流）SCOPE3カテゴリ9＞",
      utilization_first: "＜製品の加工　SCOPE3カテゴリ10＞",
      utilization_second: "＜製品の使用　SCOPE3カテゴリ11＞",
      wasteRecycling_first: "＜製品の廃棄　SCOPE3カテゴリ12＞",
    }
  },
  unauthorized: {
    description: '権限がありません。'
  },
  dashboard: {
    list: 'リスト',
    barGraph: '棒グラフ',
    yenGraph: '円グラフ',
    reductionTrend: "製品ごと",
    pie_by_chart: {
      breakdown_of_emissions: "排出量内訳",
    },
    totalEmissions: "総排出量",
    Scope1: {
      title: "自社での燃料の使用などによる直接排出",
      descript: "",
    }
    ,
    Scope2: {
      title: "電気や蒸気の使用による間接排出",
    },
    button: {
      baseDisplay: "拠点表示",
      affiliationDisplay: "所属表示",
    },
    title: {
      base: "拠点別排出量",
      affiliation: "所属別排出量",
      category: "カテゴリー別排出量",
      action: "活動別排出量",
    },
    msg: {
      noData: "活動データがありません",
    },
  },
  dashboardAnalytisc: {
    by_scope: {
      titles: {
        total_score: '全体CO2排出量累計値',
        objective_score: '全体CO2排出量目標値',
        upstream: '上流',
        downstream: '下流',
        own_company: '自社',
      },
      descriptions: {
        scope_one: '自社での燃料の使用や工業プロセスによる直接排出',
        scope_two: '自社が購入した電気・熱の排出に伴う間接排出',
      }
    },
    by_bar_chart: {
      titles: {
        rate_per_month: '全体CO2排出量の推移',
        rate_by_fuel: '燃料別排出量推移',
        rate_by_base: '拠点別排出量推移',
        breakdown_of_emissions_by_fuel: "燃料別排出量内訳推移",
      },
      checkbox_label: '推移予測を表示する',
      month: '月',
      rate_by_year: ' 年度別推移グラフを表示する',
    },
    by_pie_chart: {
      titles: {
        // chart_by_scope: 'SCOPE別内訳',
        // scope_three_all_org: '全社SCOPE3内訳',
        by_dept: '所属内訳',
        by_dept_scope: '所属別SCOPE内訳',
        by_loc: '拠点内訳',
        by_loc_scope: '拠点別SCOPE内訳',
        by_dept_table: '組織別（最上位）CO2排出量',
        by_loc_table: '拠点別CO2排出量'
      },
      checkbox_label: '推移予測を表示する',
    },
    by_product: {
      headers: {
        productName: '製品等名',
        productId: '製品ID',
        productionControlId: '管理ID',
        manufactureMonth: '製造管理月',
        productionCountUnit: '１製品CO2排出量',
        procurement: '調達',
        manufacture: '製造',
        distribution: '輸送',
        utilization: '使用',
        recycle: '廃棄/リサイクル',
      },
      display_year: '表示年度：',
      year: '年度',
      product_manufacture_month: '製造管理月',
      product_name: '製品等名',
      all_year: '全年度'
    },
    by_reduction: {
      condition: {
        dateDisplay: "年月表示",
        reductionTargetClassification: "削減目標分類",
        baseName: "拠点",
        orgName: "所属",
        productName: "製品",
        baseYear: "基準年度",
        baseMonth: "基準月度"
      },
      radioItem: {
        nendo: "年度",
        month: "月度"
      },
      classificationsItem: {
        companyWide: "全社",
        base: "拠点",
        affiliation: "所属",
        product: "製品",
      },
      chart: {
        actual: "実績",
        target: "目標",
      }
    },
    emission_amount: 'CO2排出量',
    year: '年度',
    selected_year: '表示年度',
    start_month: '表示開始月',
    end_month: '表示終了月',
    month: '月',
    no_subordinate_organizations: '配下の組織はありません',
    // list: 'リスト',
    barGraph: '月別推移グラフ',
    yenGraph: '拠点・所属別内訳グラフ',
    reductionTrend: "削減推移グラフ",
    eachProduct: "製品別排出量一覧",
    chartUnit: '単位：t-CO2',
  },
  detailScreen: {
    usage: '使用量',
    basis_for_amount_used: '使用量の根拠',
    inputdate: '入力日',
    enter_er: '入力者',
    memo: 'メモ',
    add_input_line: '＋入力行追加',
    detail_copy: '明細コピー',
    quantity: '物量',
  },
  scope3AddDialog: {
    cannot: 'できない'
  },
  pageNotFound: {
    description: '指定のページは存在しません。'
  },
  procurelabelName: '調達品名',
  SCOPE_ONE_INPUT_TABLE_HEADER: {
    productActionName: "活動名",
    activityName: "算定対象",
    calc_source: "算定方法",
    activity_values: "活動量",
    coef: "排出原単位",
    score: "排出量",
  },
  SCOPE_ONE_INPUT_TABLE_HEADER_2: {
    S1_G0003: {
      productActionName: "活動名",
      emissionActivity: '排出活動・区分',
      activity_values: "活動量",
      emissionIntensity: "排出原単位",
      gwp: "地球温暖化係数",
      emissionVolume: "排出量",
      SUBCOLUMNS: {
        calorificValue: "発熱量",
        recoveryVolume: "回収・適正処理量",
        residualVolume: "回収時残存量",
        percentageOfOneyear: "使用期間の1年間に対する比率",
        activityVolume: "排出量",
      }
    }
  },
  SCOPE_ONE_ADD_DIALOG: {
    S1_G0003: {
      productActionName: "活動名",
      greenhouseGasType: "温室効果ガス分類",
      greenhouseGas: "温室効果ガス",
      emissionActivity: "排出活動・区分",
      calculationMethod: "算定方法",
      inputField1Name: "値1の項目名",
      inputField2Name: "値2の項目名",
      memo: "メモ",
    }
  },
  SCOPE_TWO_INPUT_TABLE_HEADER: {
    activity_name: "算定対象",
    calc_source: "算定方法",
    electric_company: "電力会社/供給メニュー",
    activity_values: "活動量",
    coef: "排出原単位",
    score: "排出量",
  },
  SCOPE_THREE_INPUT_TABLE_HEADER: {
    other: {
      activity_name: "活動名",
      calc_source: "算定方法",
      activity_values: "活動量",
      coef: "排出原単位",
      score: "排出量",
    },
    DB1: {
      activityName: "活動名",
      productionControlId: "管理ID",
      calculationMethod: "算定方法",
      activity_values: "活動量",
      emissionVolume: "排出量",
    },
    DB2: {
      activityName: "活動名",
      calculationMethod: "算定方法",
      activity_values: "活動量",
      emissionIntensity: "排出原単位",
      emissionVolume: "排出量",
      remark: "実際の活動量を入力してください",
    },
    DB3: {
      activityName: "活動名",
      calculationMethod: "算定方法",
      activity_values: "活動量",
      emissionIntensity: "排出原単位",
      emissionVolume: "排出量",
      remark: "実際の活動量を入力してください",
    },
    DB4: {
      activityName: "活動名",
      calculationMethod: "算定方法",
      activity_values: "活動量",
      emissionVolume: "排出量",
      remark: "実際の活動量を入力してください",
    },
    DB5: {
      productName: "調達品名",
      departmentName: "&nbsp;&nbsp;部門名<br/>（産業連関表品目）",
      calculationMethod: "算定方法",
      activity_values: "活動量",
      emissionIntensity: "排出原単位",
      emissionVolume: "排出量",
    },
    DB0: {
      activityName: "活動名",
      calculationMethod: "算定方法",
      activity_values: "活動量",
      emissionVolume: "排出量",
    },
    DB8: {
      activityName: "活動名",
      calculationMethod: "算定方法",
      calculationTargetName: "廃棄物種別",
      activity_values: "活動量",
      emissionIntensity: "排出原単位",
      emissionVolume: "排出量",
    },
    S3_C14: {
      ACTIVITY_HEADERS: {
        productActionName: "活動名",
        calculationMethodName: "算定方法",
        activityValues: "活動量",
        emissionVolume: "排出量",
      },
      SUBCOLUMNS: {
        activityVolume: "排出量"
      },
      ADD_DIALOG: {
        productActionName: "活動名",
        calculationMethod: "入力方法",
        inputField1Name: "活動量の項目名",
        inputField2Name: "排出量の項目名",
        memo: "メモ",
      }
    },
    S3_C06: {
      ACTIVITY_HEADERS: {
        productActionName: "活動名",
        calculationMethodName: "算定方法",
        activityValues: "活動量",
        emissionUnit: "排出原単位",
        emissionVolume: "排出量",
      },
      SUBCOLUMNS: {
        activityVolume1: "旅客人キロ",
        activityVolume2: "燃料使用量",
        activityVolume3: "移動距離",
        fuelEconomy: "燃費",
        activityVolume4: "支給額",
        activityVolume5: "人泊数",
        activityVolume6: "人日数",
        activityVolume7: "従業員数",
        activityVolume8: "排出量",
      },
      ADD_DIALOG: {
        productActionName: "活動名",
        calculationMethod: "入力方法",
        calculationTarget: "算定方法（交通区分）",
        fuelMethod: "自動車算定方法",
        fuelMethodItem1: "燃料法",
        fuelMethodItem2: "燃費法",
        fuel: "燃料の種類",
        fuelConsumption: "輸送の区分",
        fuelCode: "燃費の把握",
        fuelCodeItem1: "できない",
        fuelCodeItem2: "できる",
        transExps: "算定方法（交通区分）",
        travelDays: "出張種別",
        inputField1Name: "値1の項目名",
        inputField2Name: "値2の項目名",
        memo: "メモ",
        understandingFuelConsumption: "燃費が把握",
      },
      DETAIL_DIALOG: {
        '0125': {
          createDate: "入力日",
          createName: "入力者",
          activityVolume: "人数",
          activityUnit: "人",
          transportDistance: "移動距離",
          evidence: "活動量の根拠",
          activityDetailMemo: "メモ",
          remove: "削除",
          action: "明細コピー",
        },
        '0108': {
          createDate: "入力日",
          createName: "入力者",
          activityVolume: "使用量",
          evidence: "活動量の根拠",
          activityDetailMemo: "メモ",
          remove: "削除",
          action: "明細コピー",
        },
        '0109': {
          createDate: "入力日",
          createName: "入力者",
          activityVolume: "移動距離",
          evidence: "活動量の根拠",
          activityDetailMemo: "メモ",
          remove: "削除",
          action: "明細コピー",
        },
        '0127': {
          createDate: "入力日",
          createName: "入力者",
          activityVolume: "人数",
          allowance: "支給額",
          evidence: "活動量の根拠",
          activityDetailMemo: "メモ",
          remove: "削除",
          action: "明細コピー",
        },
        '0128': {
          createDate: "入力日",
          createName: "入力者",
          activityVolume: "人数",
          dayCount: "日数",
          dayCountUnit: "日",
          evidence: "活動量の根拠",
          activityDetailMemo: "メモ",
          remove: "削除",
          action: "明細コピー",
        },
        '0129': {
          createDate: "入力日",
          createName: "入力者",
          activityVolume: "人数",
          dayCount: "日数",
          evidence: "活動量の根拠",
          activityDetailMemo: "メモ",
          remove: "削除",
          action: "明細コピー",
        }
      }
    },
    S3_C07: {
      ACTIVITY_HEADERS: {
        productActionName: "活動名",
        calculationMethodName: "算定方法",
        activityValues: "活動量",
        emissionUnit: "排出原単位",
        emissionVolume: "排出量",
      },
      SUBCOLUMNS: {
        activityVolume1: "旅客人キロ",
        activityVolume2: "燃料使用量",
        activityVolume3: "移動距離",
        fuelEconomy: "燃費",
        activityVolume4: "支給額",
        fuelActivityVolume: "燃料使用量",
        activityVolume5: "電気使用量",
        activityVolume6: "従業員数",
        businessDaysYear: "年間営業日数",
        businessDaysYearUnit: "日",
        activityVolume7: "排出量",
      },
      ADD_DIALOG: {
        productActionName: "活動名",
        calculationMethod: "入力方法",
        calculationTarget: "算定方法（交通区分）",
        fuelMethod: "自動車算定方法",
        fuelMethodItems1: "燃料法",
        fuelMethodItems2: "燃費法",
        fuel: "燃料の種類",
        fuelConsumption: "輸送の区分",
        fuelCode: "燃費の把握",
        fuelCodeItem1: "できない",
        fuelCodeItem2: "できる",
        transExps: "算定方法（交通区分）",
        telework: "電気以外の燃料の種類",
        electricCodesRes: "電気算定方法",
        retailElectricCompanies: "電力会社",
        supplyCompanies: "供給メニュー",
        inputField1Name: "値1の項目名",
        inputField2Name: "値2の項目名",
        memo: "メモ",
        understandingFuelConsumption: "燃費が把握",
        electricLabel: "電気"
      },
      DETAIL_DIALOG: {
        '0125': {
          createDate: "入力日",
          createName: "入力者",
          activityVolume: "人数",
          activityUnit: "人",
          transportDistance: "移動距離",
          evidence: "活動量の根拠",
          activityDetailMemo: "メモ",
          remove: "削除",
          action: "明細コピー",
        },
        '0108': {
          createDate: "入力日",
          createName: "入力者",
          activityVolume: "使用量",
          evidence: "活動量の根拠",
          activityDetailMemo: "メモ",
          remove: "削除",
          action: "明細コピー",
        },
        '0109': {
          createDate: "入力日",
          createName: "入力者",
          activityVolume: "移動距離",
          evidence: "活動量の根拠",
          activityDetailMemo: "メモ",
          remove: "削除",
          action: "明細コピー",
        },
        '0127': {
          createDate: "入力日",
          createName: "入力者",
          activityVolume: "人数",
          allowance: "支給額",
          evidence: "活動量の根拠",
          activityDetailMemo: "メモ",
          remove: "削除",
          action: "明細コピー",
        },
        '0131': {
          createDate: "入力日",
          createName: "入力者",
          fuelActivityVolume: "電気以外の燃料使用量",
          activityVolume: "電気使用量",
          evidence: "活動量の根拠",
          activityDetailMemo: "メモ",
          remove: "削除",
          action: "明細コピー",
        }
      }
    },
    S3_C08: {
      ACTIVITY_HEADERS: {
        productActionName: "活動名",
        calculationMethodName: "算定方法",
        activityValues: "活動量",
        emissionUnit: "排出原単位",
        emissionVolume: "排出量",
      },
      SUBCOLUMNS: {
        activityVolume1: "使用量",
        activityVolume2: "エネルギー消費量",
        activityVolume3: "建物の面積",
        distribution: "按分",
        activityVolume4: "排出量",
      },
      ADD_DIALOG: {
        productActionName: "活動名",
        calculationMethod: "入力方法",
        gasTypes: "ガスの種類",
        actTypes: "活動の種類",
        codesBuildingPurpose: "建物用途",
        electricityUser: "電気事業者の数値",
        electricityUserItem1: "代表値",
        electricityUserItem2: "個別の電気事業者",
        retailElectricCompanies: "電力会社",
        inputField1Name: "値1の項目名",
        inputField2Name: "値2の項目名",
        memo: "メモ",
      }
    },
    S3_C13: {
      ACTIVITY_HEADERS: {
        productActionName: "活動名",
        calculationMethodName: "算定方法",
        activityValues: "活動量",
        emissionUnit: "排出原単位",
        emissionVolume: "排出量",
      },
      SUBCOLUMNS: {
        activityVolume1: "使用量",
        activityVolume2: "エネルギー消費量",
        activityVolume3: "建物の面積",
        distribution: "按分",
        activityVolume4: "排出量",
      },
      ADD_DIALOG: {
        productActionName: "活動名",
        calculationMethod: "入力方法",
        gasTypes: "ガスの種類",
        actTypes: "活動の種類",
        codesBuildingPurpose: "建物用途",
        electricityUser: "電気事業者の数値",
        electricityUserItem1: "代表値",
        electricityUserItem2: "個別の電気事業者",
        retailElectricCompanies: "電力会社",
        inputField1Name: "値1の項目名",
        inputField2Name: "値2の項目名",
        memo: "メモ",
      }
    },
    S3_C02: {
      ACTIVITY_HEADERS: {
        productActionName: "活動名",
        calculationMethodName: "算定方法",
        activityValues: "活動量",
        emissionUnit: "排出原単位",
        emissionVolume: "排出量",
      },
      SUBCOLUMNS: {
        activityVolume1: "サプライヤーの資本財関連のScope1及びScope2の排出量",
        rawActivityVolume: "原材料の投入量または価格",
        rawTransportActivityVolume: "原材料の輸送量",
        capitalWasteActivityVolume: "資本財に関連した廃棄物の重量",
        activityVolume2: "排出原単位",
      },
      INTENSITY_COLUMNS: {
        rawEmissionIntensity1: "排出原単位",
        rawEmissionIntensity2: "排出原単位",
        rawTransportEmissionIntensity: "排出原単位",
        capitalWasteEmissionIntensity: "排出原単位",
      },
      ADD_DIALOG: {
        productActionName: "活動名",
        calculationMethod: "入力方法",
        goodsCriteria: "資本財の基準",
        capitals: "算定対象となる資本形成部門の業類",
        memo: "メモ",
      }
    },
    S3_C03: {
      ACTIVITY_HEADERS: {
        productActionName: "活動名",
        calculationTargetName: "燃料種別",
        calculationMethodName: "算定方法",
        activityValues: "活動量",
        emissionUnit: "排出原単位",
        emissionVolume: "排出量",
      },
      SUBCOLUMNS: {
        activityVolume: "排出量"
      },
      ADD_DIALOG: {
        productActionName: "活動名",
        calculationMethod: "入力方法",
        fuelUnits071: "燃料",
        fuelUnits070: "燃料",
        memo: "メモ",
      }
    },
    S3_C11: {
      ACTIVITY_HEADERS: {
        productActionName: "活動名",
        productionControlId: "管理ID",
        calculationMethodName: "算定方法",
        activityValues: "活動量",
        emissionVolume: "排出量",
      },
      SUBCOLUMNS: {
        activityVolume1: "想定生涯使用回数",
        expectedLifetimeUsagePer: "想定生涯使用回数の割合",
        saleCount1: "販売数",
        perLessonUsage1: "使用1回あたりの燃料使用量",
        perLessonUsage2: "使用1回あたりの電気使用量",
        emissionIntensity1: "排出原単位",
        activityVolume2: "温室効果ガス排出量",
        gwp: "地球温暖化係数",
        activityVolume3: "燃料販売量",
        emissionIntensity2: "排出原単位",
        activityVolume4: "製品あたりの温室効果ガス含有量",
        saleCount2: "販売数",
        emissionLifetimeUse: "生涯使用の排出率",
        emissionIntensity3: "排出原単位",
        activityVolume5: "排出量",
      },
      ADD_DIALOG: {
        productActionName: "活動名",
        productionControlId: "管理ID",
        calculationMethod: "入力方法",
        calculationTarget: "燃料の種類",
        inputField1Name: "値1の項目名",
        inputField2Name: "値2の項目名",
        memo: "使用シナリオ",
      }
    },
    S3_C12: {
      ACTIVITY_HEADERS: {
        productActionName: "活動名",
        productionControlId: "管理ID",
        calculationMethodName: "算定方法",
        calculationTargetName: "廃棄物の種類",
        activityValues: "活動量",
        emissionUnit: "排出原単位",
        emissionVolume: "排出量",
      },
      SUBCOLUMNS: {
        activityVolume1: "廃棄量",
        activityVolume2: "リサイクル量",
        activityVolume3: "排出量",
      },
      ADD_DIALOG: {
        productActionName: "活動名",
        productionControlId: "管理ID",
        calculationMethod: "入力方法",
        calcMethods: "算定方法（廃棄物の処理方法）",
        transNecMethods: "輸送の要否",
        secondTrashType: "廃棄物の種類",
        thirdTrashType: "廃棄物の種類",
        firstTrashType: "廃棄物の種類",
        fourthTrashType: "廃棄物の種類",
        inputField1Name: "値1の項目名",
        inputField2Name: "値2の項目名",
        memo: "メモ",
      },
      DETAIL_DIALOG: {
        '0123': {
          createDate: "入力日",
          createName: "入力者",
          activityVolume: "廃棄量",
          evidence: "廃棄量の根拠",
          activityDetailMemo: "メモ",
          remove: "削除",
          action: "明細コピー",
        },
        '0124': {
          createDate: "入力日",
          createName: "入力者",
          activityVolume: "廃棄量",
          evidence: "廃棄量の根拠",
          activityDetailMemo: "メモ",
          remove: "削除",
          action: "明細コピー",
        },
        '0121': {
          createDate: "入力日",
          createName: "入力者",
          activityVolume: "リサイクル量",
          evidence: "リサイクル量の根拠",
          activityDetailMemo: "メモ",
          remove: "削除",
          action: "明細コピー",
        }
      },
      CALCULATION_DISPOSAL_METHOD_CODE: {
        INCINERATION: "焼却処理",
        LANDFILL_DISPOSAL: "埋立処理",
        RECYLING: "リサイクル",
        UNKNOWN_PROCESSING_METHOD: "処理方法不明",
      }
    },
  },
  SCOPE_THREE_DETAIL_TABLE_HEADER: {
    FUEL_METHOD: {
      createDate: "入力日",
      createName: "入力者",
      value: "燃料使用量",
      shipperDistribution: "荷主別按分",
      evidence: "活動量の根拠",
      activityDetailMemo: "メモ",
      remove: "削除",
      action: "明細コピー",
    },
    FUEL_CONSUMPTION_METHOD: {
      createDate: "入力日",
      createName: "入力者",
      transportDistance: "輸送距離",
      shipperDistribution: "荷主別按分",
      evidence: "活動量の根拠",
      activityDetailMemo: "メモ",
      remove: "削除",
      action: "明細コピー",
    },
    TON_KILOMETER_METHOD: {
      createDate: "入力日",
      createName: "入力者",
      value: "貨物重量",
      shipperDistribution: "荷主別按分",
      transportDistance: "輸送距離",
      evidence: "活動量の根拠",
      activityDetailMemo: "メモ",
      remove: "削除",
      action: "明細コピー",
    },
    BASIC_EMISSION_COEFFICIENT: {
      createDate: "入力日",
      createName: "入力者",
      value: "燃料使用量",
      shipperDistribution: "荷主別按分",
      evidence: "活動量の根拠",
      activityDetailMemo: "メモ",
      remove: "削除",
      action: "明細コピー",
    },
    FILLING_RECOVERY: {
      createDate: "入力日",
      createName: "入力者",
      value: "充填量",
      recoveryVolume: "回収・適正処理量",
      evidence: "活動量の根拠",
      activityDetailMemo: "メモ",
      remove: "削除",
      action: "明細コピー",
    },
    LEAKAGE_RATE: {
      createDate: "入力日",
      createName: "入力者",
      value: "稼動機器に含まれる冷媒量",
      recoveryVolume: "回収・適正処理量",
      evidence: "活動量の根拠",
      activityDetailMemo: "メモ",
      remove: "削除",
      action: "明細コピー",
    },
    DB5: {
      createDate: "入力日",
      createName: "入力者",
      value: "物量",
      evidence: "活動量の根拠",
      activityDetailMemo: "メモ",
      remove: "削除",
      action: "明細コピー",
    }
  },
  PRODUCT_ACTIVITY_TABLE_HEADER: {
    procurement: {
      productName: "製品等名",
      productionControlId: "管理ID",
      activityMonth: "調達管理年月",
      productActionName: "活動名",
      activityVolume: "活動量",
      activityVolumeUnit: "単位",
      emissionVolume: "排出量",
      action_3: "活動",
    },
    manufacturing: {
      productName: "製品等名",
      productionControlId: "管理ID",
      activityMonth: "活動管理年月",
      productActionName: "活動名",
      activityVolume: "活動量",
      activityVolumeUnit: "単位",
      emissionVolume: "排出量",
      action_3_title1: "活動",
      action_3_title2: "活動",
    },
    distribution: {
      productName: "製品等名",
      productionControlId: "管理ID",
      activityMonth: "活動管理年月",
      productActionName: "活動名",
      activityVolume: "活動量",
      activityVolumeUnit: "単位",
      emissionVolume: "排出量",
      action_3: "活動",
    },
    utilization: {
      productName: "製品等名",
      productionControlId: "管理ID",
      activityMonth: "活動管理年月",
      productActionName: "活動名",
      activityVolume: "実排出量",
      emissionVolume: "排出量",
    },
    wasteRecycling: {
      productName: "製品等名",
      productionControlId: "管理ID",
      activityMonth: "活動管理年月",
      productActionName: "活動名",
      activityVolume: "実排出量",
      emissionVolume: "排出量",
    }
  },
  SIMULATION_PAGE: {
    productName: "製品等名",
    simulationButton: "シミュレーション",
    TABLE_HEADER: {
      productName: "製品等名",
      productSimulationNo: "シミュレーションNo",
      productionCount: "生産数",
      productionCountUnit: "生産数単位",
      detailButton: '詳細入力'
    }
  },
  SIMULATION_PRODUCT_ACTIVITY_TABLE_HEADER: {
    procurement: {
      HEADERS: {
        productSimulationNo: "シミュレーションNo",
        departmentName: "&nbsp;&nbsp;部門名<br/>（産業連関表品目）",
        calculationMethod: "算定方法",
        activityVolume: "活動量",
        emissionIntensity: "排出原単位",
        emissionVolume: "排出量",
      },
      SUBCOLUMNS: {
        activityVolume: "",
        emissionAmount: "排出量"
      },
      BUTTONS: {
        add: "活動"
      }
    },
    manufacturing: {
      HEADERS: {
        productSimulationNo: "シミュレーションNo",
        calculationTarget: "算定対象",
        activityVolume: "活動量",
        emissionIntensity: "排出原単位",
        emissionVolume: "排出量",
      },
      SUBCOLUMNS: {
      }
    },
    manufacturing2: {
      HEADERS: {
        productSimulationNo: "シミュレーションNo",
        calculationTarget: "算定対象",
        calculationMethod: "算定方法",
        activityVolume: "活動量",
        activityVolumeUnit: "1",
        emissionIntensity: "排出原単位",
        emissionVolume: "排出量",
      },
      SUBCOLUMNS: {
        activityVolume: ""
      }
    },
    distribution: {
      HEADERS: {
        productSimulationNo: "シミュレーションNo",
        calculationMethod: "算定方法",
        activityVolume: "活動量",
        emissionIntensity: "排出原単位",
        emissionVolume: "排出量",
      },
      SUBCOLUMNS: {
        fuelEconomy: "燃費",
        fuelEconomyBasicunit: "燃料使用原単位"
      }
    },
    utilization: {
      HEADERS: {
        productSimulationNo: "シミュレーションNo",
        calculationMethod: "算定方法",
        activityVolume: "活動量",
        emissionVolume: "排出量",
      },
      SUBCOLUMNS: {
        distribution: "按分",
      }
    },
    wasteRecycling: {
      HEADERS: {
        productSimulationNo: "シミュレーションNo",
        calculationMethod: "算定方法",
        wasteType: "廃棄物の種類",
        activityVolume: "活動量",
        emissionIntensity: "排出原単位",
        emissionVolume: "排出量",
      },
      SUBCOLUMNS: {
      }
    }
  },
  SIMULATION_ACTIVITY_INPUT_DIALOG: {
    S1_C00: {
      ADD_DIALOG: {
        calculationTarget: "算定対象",
      }
    },
    S2_C00: {
      ADD_DIALOG: {
        calculationTarget: "排出活動",
        calculationMethod: "算定方法",
        electricCompanies: "電力会社",
        electricSupplyMenu: "供給メニュー",
      }
    },
    S3_C01: {
      ADD_DIALOG: {
        calculationMethod: "入力方法",
        calculationTarget: "算定方法",
        departmentName: "部門名（産業連関表品目）",
        inputField1Name: "値1の項目名",
        inputField2Name: "値2の項目名",
      }
    },
    S3_C04: {
      ADD_DIALOG: {
        inputMethods: "入力方法",
        calculationMethods: "算定方法",
        fuelTypes: "燃料の種類",
        transportMeans: "輸送機関",
        transportKbn: "輸送の区分",
        bizDomesticKbn: "営業用・自家用の別",
        fuelGrasps: "燃費の把握",
        truckRates: "トラック積載率",
      }
    },
    S3_C10: {
      ADD_DIALOG: {
        calculationMethod: "入力方法",
        gasTypes: "ガスの種類",
        calculationTarget: "活動の種類"
      }
    },
    S3_C11: {
      ADD_DIALOG: {
        calculationMethod: "入力方法",
        calculationTarget: "燃料の種類",
        inputField1Name: "値1の項目名",
        inputField2Name: "値2の項目名",
      }
    },
    S3_C12: {
      ADD_DIALOG: {
        calculationMethod: "入力方法",
        calcMethods: "算定方法（廃棄物の処理方法）",
        transNecMethods: "輸送の要否",
        secondTrashType: "廃棄物の種類",
        thirdTrashType: "廃棄物の種類",
        firstTrashType: "廃棄物の種類",
        fourthTrashType: "廃棄物の種類",
        inputField1Name: "値1の項目名",
        inputField2Name: "値2の項目名",
      },
    },
  },
  CALCULATION_METHOD: {
    UNKNOWN_PROCESSING_METHOD: "処理方法不明",
  },
  USER_TABLE_HEADER: {
    userName: "ユーザーID",
    userNameKanji: "氏名",
    userNameKana: "氏名カナ",
    organizationName: "組織",
    userAuthorityName: "権限",
    email: "メールアドレス",
  },
  BUTTON_CHOICE: {
    YES: "はい",
    NO: "いいえ",
    CANCEL: "キャンセル"
  },
  CATEGORY_LIST: {
    "01": {
      label: "カテゴリ1",
      title: "購入した製品・サービス",
      caption: "原材料・部品、容器・包装などが製造されるまでの活動に伴う排出",
    },
    "02": {
      label: "カテゴリ2",
      title: "資本財",
      caption: "自社の資本財の建設・製造に伴う排出",
    },
    "03": {
      label: "カテゴリ3",
      title: "スコープ１、２に含まれない燃料及びエネルギー活動",
      caption: "調達している燃料の上流工程（採掘、精製など）に伴う排出調達している電力の上流工程（発電に使用する燃料の採掘、精製など）に伴う排出",
    },
    "04": {
      label: "カテゴリ4",
      title: "輸送、配送(上流)",
      caption: "①報告対象年度に購入した製品・サービスのサプライヤーから自社への物流（輸送、荷役、保管）に伴う排出 ②報告対象年度に購入した①以外の物流サービス（輸送、荷役、保管）に伴う排出（自社が費用負担している物流に伴う排出）",
    },
    "05": {
      label: "カテゴリ5",
      title: "事業から出る廃棄物",
      caption: "自社で発生した廃棄物の輸送(輸送は任意算定対象)、処理に伴う排出"
    },
    "06": {
      label: "カテゴリ6",
      title: "出張",
      caption: "従業員の出張に伴う排出",
    },
    "07": {
      label: "カテゴリ7",
      title: "雇用者の通勤",
      caption: "従業員が通勤する際の移動に伴う排出",
    },
    "08": {
      label: "カテゴリ8",
      title: "リース資産(上流)",
      caption: "自社が賃借しているリース資産の操業に伴う排出（スコープ1、2で算定する場合を除く）",
    },
    "09": {
      label: "カテゴリ9",
      title: "輸送、配送(下流)",
      caption: "自社が販売した製品の最終消費者までの物流（輸送、荷役、保管、販売）に伴う排出（自社が費用負担していないものに限る。）",
    },
    "10": {
      label: "カテゴリ10",
      title: "販売した製品の加工",
      caption: "事業者による中間製品の加工に伴う排出",
    },
    "11": {
      label: "カテゴリ11",
      title: "販売した製品の使用",
      caption: "使用者（消費者・事業者）による製品の使用に伴う排出",
    },
    "12": {
      label: "カテゴリ12",
      title: "販売した製品の廃棄",
      caption: "使用者（消費者・事業者）による製品の廃棄時の処理に伴う排出(輸送は任意算定対象)",
    },
    "13": {
      label: "カテゴリ13",
      title: "リース資産(下流)",
      caption: "自社が賃貸事業者として所有し、他者に賃貸しているリース資産の運用に伴う排出",
    },
    "14": {
      label: "カテゴリ14",
      title: "フランチャイズ",
      caption: "フランチャイズ加盟者における排出",
    },
    "15": {
      label: "カテゴリ15",
      title: "投資",
      caption: "投資の運用に伴う排出",
    }
  },
  PRODUCT_ACTIVITY: {
    TAB_LIST: {
      procurement: "調達",
      manufacturing: "製造",
      distribution: "輸送",
      utilization: "使用",
      wasteRecycling: "廃棄リサイクル",
    }
  },
  EMISSIONS_CALCULATION_GUIDE: {
    TAB_LIST: {
      video_page_link: '動画ページリンク',
      site_page_link: 'サイトページリンク'
    },
    errMessenger: {
      sizeFileCheck: '選択したファイルのサイズが大きすぎます。1MBより小さいサイズとして下さい。',
      fileTypeCheck: '使用できないファイル形式です。',
      fileNameDuplicateCheck: 'すでに使用されているファイル名です。',
      fileNameLengthCheck: 'ファイル名は100文字以内にしてください。'
    }
  },
  REDUCTION_TARGET_INPUT: {
    CATEGORY: {
      reduceTargetCategory: '削減目標設分類を選択してください：',
      dateDisplay: '年月表示：',

    },
    FILTER: {
      baseYear: '基準年度',
      targetYear: '目標年度',
      baseYearMonth: '基準月度',
      targetYearMonth: '目標月度',
      target: "対象",
    },
    VALUES: {
      year: '年度',
      month: '月度',
      baseYear: '基準年度',
      targetYear: '目標年度',
      baseYearMonth: '基準月度',
      targetYearMonth: '目標月度',
      baseAnnualEmission: '基準年度排出量',
      targetValue: '目標値',
      unit: '単位',
      reductionValue: '削減値',
      base: '拠点',
      organization: '所属',
      productName: '製品名',
    },
    msg: {
      deleteConfirmMsg: '１行削除します。よろしいですか？',
      duplicateError: '基準{timeType}・目標{timeType}{targetName}が既に入力されています。',
      baseIdName: '・拠点',
      organizationIdName: '・所属',
      productIdName: '・製品',
    },
  },
  ALERT_SETTING: {
    TAB_LIST: {
      input_status: '入力状況一覧',
      alert_setting: 'アラート設定'
    },
    INPUT_STATUS_HEADER: {
      base: "拠点",
      organization: "所属",
      scope: "Scope",
      category: "カテゴリ",
      categoryGroup: "カテゴリグループ",
      inputThisMonth: "当月\n入力件数",
      emissionThisMonth: '当月\n排出量',
      inputLastMonth: '前月\n入力件数',
      emissionLastMonth: '前月\n排出量',
      input2MonthAgo: '2か月前\n入力件数',
      emission2MonthAgo: '2か月前\n排出量',
      input3MonthAgo: '3か月前\n入力件数',
      emission3MonthsAgo: '3か月前\n排出量',
      input4MonthAgo: '4か月前\n入力件数',
      emission4MonthsAgo: '4か月前\n排出量',
      input5MonthAgo: '5か月前\n入力件数',
      emission5MonthsAgo: '5か月前\n排出量',
      input6MonthAgo: '6か月前\n入力件数',
      emission6MonthsAgo: '6か月前\n排出量',
      input7MonthAgo: '7か月前\n入力件数',
      emission7MonthsAgo: '7か月前\n排出量',
      input8MonthAgo: '8か月前\n入力件数',
      emission8MonthsAgo: '8か月前\n排出量',
      input9MonthAgo: '9か月前\n入力件数',
      emission9MonthsAgo: '9か月前\n排出量',
      input10MonthAgo: '10か月前\n入力件数',
      emission10MonthsAgo: '10か月前\n排出量',
      input11MonthAgo: '11か月前\n入力件数',
      emission11MonthsAgo: '11か月前\n排出量',
      input12MonthAgo: '12か月前\n入力件数',
      emission12MonthsAgo: '12か月前\n排出量',
    },
    ALERT_SETTING_LIST: {
      alertSet: "アラート設定",
      alertStartDay: "アラートの開始日",
      comparisonBaseMonth: "比較基準月",
      comparisonTarget: "比較対象",
      allowableValue: "許容値",
    },
    msg: {
      alertSetMsg: "アラート設定を行えば、当月の入力もれや入力件数または排出量が著しく異なる(基準月比較)場合に、お知らせとして表示します。",
      alertStartDayMsg: "設定した日以降に、アラートがお知らせに表示されます。",
      comparisonBaseMonthMsg: "アラートの基準となる月を設定します。",
      comparisonTargetMsg: "アラートの対象を選択します。",
      allowableValueMsg: "許容値以上に差異がある場合、アラートを表示します。",
      unitMsg: "件またはt-co2",
      dayMsg: "日",
    },
    BUTTON: {
      detail: "明細",
      save: "保存",
    },
    LABEL: {
      alertSetYesLabel: "あり",
      alertSetNoLabel: "なし",
      dayLabel: "1日",
      monthLabel: "前月",
      targetLabel: "入力件数",
    }
  },
  DATA_EXTRACTION: {
    TITTLE: {
      setDisplayConditions: "表示条件を設定してください",
      selectItemToDisplayed: "表示する項目、表示順を選択してください",
      selectItemtToDisplayOrderAggregationUnit: "表示する項目、表示順、集計単位を選択してください",
      subject: "対象",
      selectValue: "選択値",
      conditions: "条件",
      inputValue: "入力値",
      displayItem: "表示項目",
      displayOrder: "表示順",
      aggregationUnit: "集計単位",
      notGroupMsg: "集計単位が設定されていません。表示項目から選択してください。",
      incorrectWhere: "データが正しく取得できません。条件を見直してください。"
    },
    Button: {
      detail: "明細",
      total: "集計➝",
      clearAll: "全クリア",
      add: "追加➝",
      remove: "←削除",
      ascending: "昇順➝",
      descending: "降順➝",
      patternRegistration: "パターン登録",
      registeredPatternDisplay: "登録済みパターン表示",
      extractionResultDisplay: "抽出結果表示",
      excelExport: "Excel出力",
      pdflExport: "PDF出力",
    },
    Data_extraction_display: "データ抽出表示",
    fileName: "carbonoteデータ抽出_",
    PATTERN_DISPLAY: {
      HEADERS: {
        select: "選択",
        patternName: "パターン名",
        registeredDate: "登録日時",
      },
      msg: {
        patternListDispplay: "パターンー覧表示"
      },
      Button: {
        select: "選択",
        close: "閉じる",
      }
    },
    ORDER_BY: {
      ascending: "昇順",
      descending: "降順"
    }
  },
}

