export default class TransportMaster {
  constructor(axios) {
    this.axios = axios
  }
  
  list(page) {
    return this.axios.$get(`/transport/init?page=${page}`)
  }

  update(payload) {
    return this.axios.$post('/transport/update', payload)
  }

  initRoute() {
    return this.axios.$get(`/transport/route/init`)
  }

  filter(searchTerm, procurementShippingCode, page) {
    return this.axios.$get(`/transport/filter?filterQuote=${searchTerm}&procurementShippingCode=${procurementShippingCode}&page=${page}`)
  }

  checkDuplicate(transportId) {
    return this.axios.$get(`/transport/check/duplicate?transportId=${transportId}`)
  }
}