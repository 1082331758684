export default {
  SET_CODES_STATE: (state, payload) => {
    state[payload.key] = payload.value
  },
  SET_USER_INFO: (state, value) => (state.userInfo = value),
  SET_TARGET_MONTH: (state, value) => (state.targetMonth = value),
  SET_SCOPE_LIST: (state, value) => (state.scopeList = value),
  SET_CATEGORY_LIST: (state, value) => (state.categoryList = value),
  /** 単位一覧にセット */
  SET_UNIT_LIST: (state, value) => {
    state.unitList = value;
    var unitLabelList = {};
    for (const item of value) {
      unitLabelList[item.value] = item.label;
    }
    state.unitLabelList = unitLabelList;
  },
  /** 拠点組織一覧にセット */
  SET_BASE_ORGANIZATION_LIST: (state, value) => (state.baseOrganizationList = value),
  SET_ORGANIZATION_LIST: (state, value) => (state.organizationList = value),
  SET_BASE_LIST: (state, value) => (state.baseList = value),
  SET_EMISSION_ACTIVITY_LIST: (state, value) => (state.emissionActivityList = value),
  SET_ELECTRIC_COMPANY_LIST: (state, value) => (state.electricCompanyList = value),
  SET_EMISSION_COEFFICIENT_LIST: (state, value) => (state.emissionCoefficientList = value),
  SET_EMISSION_INTENSITY_LIST: (state, value) => (state.emissionIntensityList = value),
  SET_TKG_BASIC_UNIT_LIST: (state, value) => (state.tkgBasicUnitList = value),
  SET_CAR_FUEL_ECONOMY_LIST: (state, value) => (state.carFuelEconomyList = value),
  /** スコープ1のデータ */
  SET_EMISSION_ACTIVITY_SCOPE1_LIST: (state, value) => (state.emissionActivityScope1List = value),
  SET_CALCULATION_METHOD_SCOPE1_LIST: (state, value) => (state.calculationMethodScope1List = value),

  SET_DIVISION_TYPE_LIST: (state, value) => (state.divisionTypeList = value),
  SET_DIVISION_LIST: (state, value) => (state.divisionList = value),

  SET_LOCATION_LIST: (state, value) => (state.locationList = value),
  SET_DEPT_LIST: (state, value) => (state.deptList = value),
  SET_ELECTRIC_TABLE: (state, value) => (state.electricTable = value),
  SET_INDUSTRY_CONNECTION_TABLE_LIST: (state, value) =>
    (state.industryConnectionTableList = value),
  SET_TRANSPORT_TABLE_LIST: (state, value) =>
    (state.transportTableList = value),
  SET_CAPITAL_MONEY_TABLE: (state, value) => (state.capitalMoneyTable = value),
  SET_FUEL_SUPPLY_TABLE: (state, value) => (state.fuelSupplyTable = value),
  SET_FUEL_TYPE_USAGE_COEF_TABLE: (state, value) =>
    (state.fuelTypeUsageCoefTable = value),
  SET_FUEL_TYPE_CALORIFIC_TABLE: (state, value) =>
    (state.fuelTypeCalorificTable = value),
  SET_OPERATOR_LIST: (state, value) => (state.operatorList = value),
  SET_TRASH_RECYCLE_TABLE_LIST: (state, value) =>
    (state.trashRecycleTableList = value),
  SET_TRAFFIC_FEE_TABLE: (state, value) => (state.trafficFeeTable = value),
  SET_FUEL_ECONOMY_TABLE: (state, value) => (state.fuelEconomyTable = value),
  UPDATE_LOCATION_LIST: (state, { index, label }) =>
    (state.locationList[index].label = label),
  UPDATE_DEPT_LIST: (state, { index, label }) =>
    (state.deptList[index].label = label),
  UPDATE_LOCATION_LIST_INVALID: (state, index) =>
    (state.locationList[index].invalid = "1"),
  UPDATE_DEPT_LIST_INVALID: (state, index) =>
    (state.deptList[index].invalid = "1"),
  ADD_LOCATION_LIST: (state, value) => state.locationList.push(value),
  ADD_DEPT_LIST: (state, value) => state.deptList.push(value),
  SET_STATE: (state, payload) => state[payload.key] = payload.value,
  SET_MEISAI_UPDATE_DATA: (state, value) => (state.hasMeisaiUpdateData = value),
}
