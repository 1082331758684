export default class TemplateActivityService {
  constructor(axios) {
    this.axios = axios
  }

  init() {
    return this.axios.$get('/template-activity/init')
  }

  search(params) {
    return this.axios.$get('/template-activity/filter', { params: params });
  }

  update(payload) {
    return this.axios.$post('/template-activity/update', payload)
  }

  getExistedIndustry(params) {
    return this.axios.$get('/template-activity/industry-code', { params: params });
  }

}