export default (context, inject) => {
  var theHelp = Chart.helpers;
  const constants = {
    CHART_OPTIONS: {
      HORIZONTAL: {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: false,
        },
        legend: {
          display: false,
        },
        scales: {
          yAxes: [
            {
              ticks: {
                suggestedMin: 0,
                stepSize: 10,
                callback: (value, index, values) => {
                  return value;
                },
                fontColor: "rgba(255, 255, 255, 0.8)",
              },
              gridLines: {
                zeroLineColor: "rgba(255, 255, 255, 0.2)",
                color: "rgba(255, 255, 255, 0.2)",
                fontColor: "rgba(255, 255, 255, 0.8)",
                borderDash: [3],
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                suggestedMin: 0,
                stepSize: 10,
                callback: (value, index, values) => {
                  return value;
                },
                fontColor: "rgba(255, 255, 255, 0.8)",
              },
              gridLines: {
                zeroLineColor: "rgba(255, 255, 255, 0.2)",
                color: "rgba(255, 255, 255, 0.2)",
                borderDash: [3],
              },
            },
          ],
        },
        plugins: {
          colorschemes: {
            scheme: "tableau.Blue20",
          },
        },
      },
      BAR: {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: false,
        },
        legend: {
          display: false,
        },
        scales: {
          yAxes: [
            {
              ticks: {
                suggestedMin: 0,
                stepSize: 20000,
                callback: (value, index, values) => {
                  return value;
                },
                fontColor: "rgba(9, 31, 92, 0.8)",
              },
              gridLines: {
                zeroLineColor: "rgba(255, 255, 255, 0.2)",
                color: "rgba(96, 125, 139, 0.2)",
                fontColor: "rgba(255, 255, 255, 0.8)",
                borderDash: [3],
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                suggestedMin: 0,
                stepSize: 0,
                callback: (value, index, values) => {
                  return value;
                },
                fontColor: "rgba(9, 31, 92, 0.8)",
              },
              gridLines: {
                zeroLineColor: "rgba(255, 255, 255, 0.2)",
                color: "rgba(96, 125, 139, 0.2)",
                borderDash: [3],
              },
            },
          ],
        },
        plugins: {
          colorschemes: {
            scheme: "brewer.SetThree12",
          },
        },
      },
      STACKED_BAR: (stepSize = 50000000) => {
        const res = {
          responsive: true,
          maintainAspectRatio: false,
          title: {
            display: false,
          },
          legend: {
            display: true,
            labels: {
              fontSize: window.screen.width > 414 ? 14 : 10,
            }
          },
          scales: {
            yAxes: [
              {
                stacked: true,
                ticks: {
                  suggestedMin: 0,
                  stepSize: stepSize,
                  callback: (value, index, values) => {
                    return value;
                  },
                  fontColor: "rgba(9, 31, 92, 0.8)",
                  fontSize: window.screen.width > 414 ? 14 : 10,
                },
                gridLines: {
                  zeroLineColor: "rgba(255, 255, 255, 0.2)",
                  color: "rgba(96, 125, 139, 0.2)",
                  fontColor: "rgba(255, 255, 255, 0.8)",
                  borderDash: [3],
                },
              },
            ],
            xAxes: [
              {
                stacked: true,
                ticks: {
                  suggestedMin: 0,
                  stepSize: 0,
                  callback: (value, index, values) => {
                    return value;
                  },
                  fontColor: "rgba(9, 31, 92, 0.8)",
                  fontSize: window.screen.width > 414 ? 14 : 10,
                },
                gridLines: {
                  zeroLineColor: "rgba(255, 255, 255, 0.2)",
                  color: "rgba(96, 125, 139, 0.2)",
                  borderDash: [3],
                },
              },
            ],
          },
          plugins: {
            colorschemes: {
              scheme: "brewer.SetThree12",
            },
          },
          tooltips: {
            callbacks: {
              label: (tooltipItem, data) => {
                const prefix = data.datasets[tooltipItem.datasetIndex].label;
                const value = context.$utils.formatFixed(
                  data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index],
                  3
                );
                let label = `${prefix}: ${value} t-CO2`;
                return label;
              },
            },
          },
        }
        return res
      },
      LINE: (stepSize = 50000000, getCustomLabel) => {
        const res = {
          responsive: true,
          maintainAspectRatio: false,
          title: {
            display: false,
          },
          legend: {
            display: true,
            labels: {
              fontSize: window.screen.width > 414 ? 14 : 10,
            },
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  suggestedMin: 0,
                  stepSize: stepSize,
                  callback: (value, index, values) => {
                    return value;
                  },
                  fontColor: "rgba(9, 31, 92, 0.8)",
                  fontSize: window.screen.width > 414 ? 14 : 10,
                },
                gridLines: {
                  zeroLineColor: "rgba(255, 255, 255, 0.2)",
                  color: "rgba(96, 125, 139, 0.2)",
                  fontColor: "rgba(255, 255, 255, 0.8)",
                  borderDash: [3],
                },
              },
            ],
            xAxes: [
              {
                ticks: {
                  suggestedMin: 0,
                  stepSize: 10,
                  callback: (value, index, values) => {
                    return value;
                  },
                  fontColor: "rgba(9, 31, 92, 0.8)",
                  fontSize: window.screen.width > 414 ? 14 : 10,
                },
                gridLines: {
                  zeroLineColor: "rgba(255, 255, 255, 0.2)",
                  color: "rgba(96, 125, 139, 0.2)",
                  borderDash: [3],
                },
              },
            ],
          },
          plugins: {
            colorschemes: {
              scheme: "brewer.SetThree12",
            },
          },
          tooltips: {
            callbacks: {
              label: (tooltipItem, data) => {
                if (!getCustomLabel) {
                  const prefix = data.datasets[tooltipItem.datasetIndex].label;
                  const value = context.$utils.formatFixed(
                    data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index],
                    3
                  );
                  let label = `${prefix}: ${value} t-CO2`;
                  return label;
                } else {
                  return getCustomLabel(tooltipItem, data)
                }
              },
            },
          },
        }
        return res
      },
      PIE: (limit, lengthOfLabel, position = "right", openPieChart = () => { }, usePlugin = false, useDefaultTooltip = false) => {
        const res = {
          onClick: (event, element) => {
            if (element.length > 0) {
              openPieChart(element)
            }
          },
          responsive: true,
          maintainAspectRatio: false,
          title: {
            display: false,
          },
          legend: {
            display: usePlugin ? false : true,
            position: position,
            labels: {
              generateLabels: function (chart) {
                var data = chart.data;
                if (data.labels.length && data.datasets.length) {
                  var labels = data.labels.slice(0, limit || data.labels.length);
                  return labels.map(function (label, i) {
                    var meta = chart.getDatasetMeta(0);
                    var ds = data.datasets[0];
                    var arc = meta.data[i];
                    var custom = (arc && arc.custom) || {};
                    var getValueAtIndexOrDefault =
                      theHelp.getValueAtIndexOrDefault;
                    var arcOpts = chart.options.elements.arc;
                    var fill = custom.backgroundColor
                      ? custom.backgroundColor
                      : getValueAtIndexOrDefault(
                        ds.backgroundColor,
                        i,
                        arcOpts.backgroundColor
                      );
                    var stroke = custom.borderColor
                      ? custom.borderColor
                      : getValueAtIndexOrDefault(
                        ds.borderColor,
                        i,
                        arcOpts.borderColor
                      );
                    var bw = custom.borderWidth
                      ? custom.borderWidth
                      : getValueAtIndexOrDefault(
                        ds.borderWidth,
                        i,
                        arcOpts.borderWidth
                      );
                    return {
                      text:
                        lengthOfLabel !== undefined
                          ? label.substring(0, lengthOfLabel)
                          : label,
                      fillStyle: fill,
                      strokeStyle: stroke,
                      lineWidth: bw,
                      hidden: isNaN(ds.data[i]) || meta.data[i].hidden,
                      index: i,
                    };
                  });
                }
                return [];
              },
              filter: (item, chart) => {
                if (limit === undefined) return true;
                return item.index < limit;
              },
            },
          },
          gridLines: {
            display: false,
          },
          plugins: {
            colorschemes: {
              scheme: "brewer.SetThree12",
            },
          },
          tooltips: {
            enabled: useDefaultTooltip,
            custom: function (tooltipModel) {
              if (useDefaultTooltip) return
              // Tooltip Element
              var tooltipEl = document.getElementById('chartjs-tooltip');
              // Create element on first render
              if (!tooltipEl) {
                tooltipEl = document.createElement('div');
                tooltipEl.id = 'chartjs-tooltip';
                tooltipEl.innerHTML = '<table></table>';
                document.body.appendChild(tooltipEl);
              }

              // Hide if no tooltip
              if (tooltipModel.opacity === 0) {
                tooltipEl.style.opacity = 0;
                return;
              }

              // Set caret Position
              tooltipEl.classList.remove('above', 'below', 'no-transform');
              if (tooltipModel.yAlign) {
                tooltipEl.classList.add(tooltipModel.yAlign);
              } else {
                tooltipEl.classList.add('no-transform');
              }

              function getBody (bodyItem) {
                return bodyItem.lines;
              }

              // Set Text
              if (tooltipModel.body) {
                var titleLines = tooltipModel.title || [];
                var bodyLines = tooltipModel.body.map(getBody);

                var innerHtml = '<thead>';

                titleLines.forEach(function (title) {
                  innerHtml += '<tr><th>' + title + '</th></tr>';
                });
                innerHtml += '</thead><tbody>';

                bodyLines.forEach(function (body, i) {
                  const b = `<span>${body}</span>`
                  var colors = tooltipModel.labelColors[0];
                  var style = 'background:' + colors.backgroundColor;
                  style += '; border-color:' + colors.borderColor;
                  style += '; border: 1px solid;width: 13px; height: 13px;display: inline-flex';
                  var span = '<span style="' + style + '"></span>';
                  innerHtml += '<tr><td style="color: white;background-color: #2F3131;padding:1px 4px;border-radius: 6px"><div style="display:flex;align-items:center">' + span + '&nbsp;' + b + '</div></td></tr>';
                });
                innerHtml += '</tbody>';

                var tableRoot = tooltipEl.querySelector('table');
                tableRoot.innerHTML = innerHtml;
              }

              // `this` will be the overall tooltip
              var position = this._chart.canvas.getBoundingClientRect();

              // Display, position, and set styles for font
              tooltipEl.style.opacity = 1;
              tooltipEl.style.zIndex = 9999;
              tooltipEl.style.position = 'absolute';
              tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
              tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
              tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
              tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
              tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
              tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
              tooltipEl.style.pointerEvents = 'none';
            },
            callbacks: {
              label: (tooltipItem, data) => {
                const prefix = data.labels[tooltipItem.index];
                // use tooltips passed from component
                if (data.tooltips) {
                  return `${prefix}: ${data.tooltips[tooltipItem.index]}`
                }
                const currentValue = data.datasets[0].data[tooltipItem.index]
                const value = context.$utils.formatFixed(
                  currentValue,
                  3
                );
                const total = data.datasets[0].data.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
                const percentage = context.$utils.formatFixed(currentValue / total * 100, 1)
                let label = `${prefix}: ${value}t-CO2 (${percentage}%)`;
                return label;
              },
            },
          },
        };
        return res;
      },
    },
    SCOPE_ONE_INPUT_TABLE_HEADER: [
      {
        text: "",
        value: "buttonEdit",
        align: "right",
        width: "60px",
        action: 'editRow'
      },
      {
        text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_ONE_INPUT_TABLE_HEADER.productActionName,
        value: "productActionName",
        width: "20%",
        align: "start",
      },
      {
        text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_ONE_INPUT_TABLE_HEADER.activityName,
        value: "activityName",
        width: "15%",
        align: "start",
      },
      {
        text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_ONE_INPUT_TABLE_HEADER.calc_source,
        value: "calc_source",
        width: "15%",
        align: "start",
      },
      {
        text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_ONE_INPUT_TABLE_HEADER.activity_values,
        value: "activity_values",
        width: "25%",
        align: "center",
      },
      {
        text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_ONE_INPUT_TABLE_HEADER.coef,
        value: "coef",
        width: "10%",
        align: "right",
      },
      {
        text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_ONE_INPUT_TABLE_HEADER.score,
        value: "score",
        width: "15%",
        align: "center",
      },
      {
        align: "center",
        text: "",
        value: "action",
        // width: "20%",
      },
    ],
    SCOPE_ONE_INPUT_TABLE_HEADER_2: {
      S1_G0003: {
        ACTIVITY_HEADERS: [
          {
            text: "",
            value: "buttonEdit",
            align: "right",
            width: "60px",
            action: 'editRow'
          },
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_ONE_INPUT_TABLE_HEADER_2.S1_G0003.productActionName,
            value: "productActionName",
            align: "left",
            width: "12%",
          },
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_ONE_INPUT_TABLE_HEADER_2.S1_G0003.emissionActivity,
            value: "activityNameCodeGas",
            align: "left",
            width: "17%",
          },
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_ONE_INPUT_TABLE_HEADER_2.S1_G0003.activity_values,
            value: "activity_values",
            align: "center",
            width: "auto",
            class: "success--text",
          },
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_ONE_INPUT_TABLE_HEADER_2.S1_G0003.emissionIntensity,
            value: "emissionIntensity",
            align: "right",
            width: "115px",
          },
          {
            text: "",
            value: "gwp",
            align: "right",
            width: "100px",
          },
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_ONE_INPUT_TABLE_HEADER_2.S1_G0003.emissionVolume,
            value: "emissionVolume",
            align: "right",
            width: "10%",
            class: "success--text",
          },
          {
            text: "",
            value: "action",
            align: "right",
            width: "60px",
          },
        ],
        SUBCOLUMNS: [
          {
            name: 'calculationTargetNameCode65',
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => item.emissionActivity.code !== '0901' && item.emissionActivity.code !== '0902',
            editable: true,
            unit: 'activityVolumeUnit'
          },
          {
            name: 'inputField1Name',
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => item.emissionActivity.code === '0901',
            editable: true
          },
          {
            name: 'inputField2Name',
            model: 'emissionIntensity',
            rules: 'required|length_comma:5|number_comma_full_width:true|numeric_right:10',
            show: (item) => item.emissionActivity.code === '0901',
            editable: true
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_ONE_INPUT_TABLE_HEADER_2.S1_G0003.SUBCOLUMNS.activityVolume,
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => item.emissionActivity.code === '0902',
            editable: true
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_ONE_INPUT_TABLE_HEADER_2.S1_G0003.SUBCOLUMNS.calorificValue,
            model: 'calorificValue',
            show: (item) => item.emissionActivity.item6 && item.emissionActivity.item7,
            editable: false,
            disabled: () => true,
            unit: 'calorificValueUnit'
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_ONE_INPUT_TABLE_HEADER_2.S1_G0003.SUBCOLUMNS.residualVolume,
            model: 'residualVolume',
            rules: 'required|length_comma:13|number_comma_full_width:true|integer_only',
            show: (item) => item.emissionActivity.item3 && item.emissionActivity.item8 && item.emissionActivity.item9,
            editable: true,
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_ONE_INPUT_TABLE_HEADER_2.S1_G0003.SUBCOLUMNS.recoveryVolume,
            model: 'recoveryVolume',
            rules: 'required|length_comma:13|number_comma_full_width:true|integer_only',
            show: (item) => item.emissionActivity.item9,
            editable: true,
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_ONE_INPUT_TABLE_HEADER_2.S1_G0003.SUBCOLUMNS.percentageOfOneyear,
            model: 'percentageOfOneyear',
            rules: 'required|length_comma:1|number_comma_full_width:true|numeric_right:9',
            show: (item) => item.emissionActivity.item10,
            editable: true,
          },
        ],
        ADD_DIALOG: {
          formValues: {
            productActionName: '',
            greenhouseGasType: null,
            greenhouseGas: null,
            emissionActivity: null,
            inputField1Name: null,
            inputField2Name: null,
            memo: null,
          },
          formFields: {
            productActionName: {
              model: 'productActionName',
              rules: 'required|max:100',
              component: 'v-text-field',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_ONE_ADD_DIALOG.S1_G0003.productActionName,
                type: 'text',
              },
            },
            greenhouseGasType: {
              model: 'greenhouseGasType',
              rules: 'required',
              component: 'v-select',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_ONE_ADD_DIALOG.S1_G0003.greenhouseGasType,
                items: [],
                'item-text': 'item1',
                'return-object': true,
              },
            },
            greenhouseGas: {
              model: 'greenhouseGas',
              rules: 'required',
              component: 'v-autocomplete',
              show: (data) => data.formValues.greenhouseGasType?.code === '650' || data.formValues.greenhouseGasType?.code === '660',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_ONE_ADD_DIALOG.S1_G0003.greenhouseGas,
                items: [],
                'item-text': 'ghgName',
                'return-object': true,
              },
            },
            emissionActivity: {
              model: 'emissionActivity',
              rules: 'required',
              component: 'v-autocomplete',
              show: (data) => data.formValues.greenhouseGasType !== null,
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_ONE_ADD_DIALOG.S1_G0003.emissionActivity,
                items: [],
                'item-text': 'item1',
                'return-object': true,
              },
            },
            calculationMethod: {
              model: 'emissionActivity',
              rules: 'required',
              component: 'v-select',
              show: () => false,
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_ONE_ADD_DIALOG.S1_G0003.calculationMethod,
                items: [],
                'item-text': 'label',
                'return-object': true,
              },
            },
            inputField1Name: {
              model: 'inputField1Name',
              rules: 'required|max:100',
              component: 'v-text-field',
              show: (data) => data.formValues.emissionActivity?.code === '0901',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_ONE_ADD_DIALOG.S1_G0003.inputField1Name,
                type: 'text',
              },
            },
            inputField2Name: {
              model: 'inputField2Name',
              rules: 'required|max:100',
              component: 'v-text-field',
              show: (data) => data.formValues.emissionActivity?.code === '0901',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_ONE_ADD_DIALOG.S1_G0003.inputField2Name,
                type: 'text',
              },
            },
            memo: {
              component: 'v-text-field',
              model: 'memo',
              rules: 'max:100',
              show: (data) => data.formValues.emissionActivity?.code === '0901' || data.formValues.emissionActivity?.code === '0902',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_ONE_ADD_DIALOG.S1_G0003.memo,
                type: 'text',
              },
            },
          },
          dataMapper: {
            greenhouseGasType: 'greenhouseGasType',
            greenhouseGas: 'greenhouseGas',
            emissionActivity: 'emissionActivity',
          }
        },
        GUIDE: 'Q0011'
      },
    },
    SCOPE_TWO_INPUT_TABLE_HEADER: [
      {
        text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_TWO_INPUT_TABLE_HEADER.activity_name,
        value: "activity_name",
        width: "20%",
        align: "center",
      },
      {
        text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_TWO_INPUT_TABLE_HEADER.calc_source,
        value: "calc_source",
        width: "10%",
        align: "center",
      },
      {
        text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_TWO_INPUT_TABLE_HEADER.electric_company,
        value: "electric_company",
        width: "20%",
        align: "center",
      },
      {
        text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_TWO_INPUT_TABLE_HEADER.activity_values,
        value: "activity_values",
        width: "10%",
        align: "center",
      },
      {
        text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_TWO_INPUT_TABLE_HEADER.coef,
        value: "coef",
        width: "20%",
        align: "right",
      },
      {
        text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_TWO_INPUT_TABLE_HEADER.score,
        value: "score",
        width: "20%",
        align: "center",
      },
    ],
    SCOPE_THREE_INPUT_TABLE_HEADER: {
      other: [
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.other.activity_name,
          value: "activity_name",
          align: "center",
          width: "20%",
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.other.calc_source,
          value: "calc_source",
          align: "center",
          width: "20%",
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.other.activity_values,
          value: "activity_values",
          align: "center",
          width: "30%",
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.other.coef,
          value: "coef",
          align: "right",
          width: "20%",
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.other.score,
          value: "score",
          align: "center",
          width: "10%",
        },
      ],
      DB1: [
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.DB1.activityName,
          value: "activityName",
          align: "left",
          width: "10%",
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.DB1.productionControlId,
          value: "productionControlId",
          align: "left",
          width: "10%",
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.DB1.calculationMethod,
          value: "calculationMethod",
          align: "left",
          width: "20%",
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.DB1.activity_values,
          value: "activity_values",
          align: "center",
          width: "45%",
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.DB1.emissionVolume,
          value: "emissionVolume",
          align: "right",
          width: "15%",
          class: "success--text",
        },
        {
          text: "",
          value: "buttons",
          align: "right",
          width: "10%",
        },
      ],
      DB2: [
        {
          text: "",
          value: "buttonEdit",
          align: "right",
          width: "60px",
          action: 'editRow'
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.DB2.activityName,
          value: "activityName",
          align: "left",
          width: "10%",
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.DB2.calculationMethod,
          value: "calculationMethod",
          align: "left",
          width: "20%",
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.DB2.activity_values,
          value: "activity_values",
          align: "center",
          width: "40%",
          remark: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.DB2.remark,
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.DB2.emissionIntensity,
          value: "emissionIntensity",
          align: "right",
          width: "15%",
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.DB2.emissionVolume,
          value: "emissionVolume",
          align: "right",
          width: "15%",
          class: "success--text",
        },
        {
          text: "",
          value: "buttons",
          align: "right",
          width: "60px",
        },
      ],
      DB3: [
        {
          text: "",
          value: "buttonEdit",
          align: "right",
          width: "60px",
          action: 'editRow'
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.DB3.activityName,
          value: "activityName",
          align: "left",
          width: "10%",
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.DB3.calculationMethod,
          value: "calculationMethod",
          align: "left",
          width: "20%",
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.DB3.activity_values,
          value: "activity_values",
          align: "center",
          width: "40%",
          remark: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.DB3.remark,
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.DB3.emissionIntensity,
          value: "emissionIntensity",
          align: "right",
          width: "15%",
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.DB3.emissionVolume,
          value: "emissionVolume",
          align: "right",
          width: "15%",
          class: "success--text",
        },
        {
          text: "",
          value: "buttons",
          align: "right",
          width: "60px",
        },
      ],
      DB4: [
        {
          text: "",
          value: "buttonEdit",
          align: "right",
          width: "60px",
          action: 'editRow'
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.DB4.activityName,
          value: "activityName",
          align: "left",
          width: "8%",
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.DB4.calculationMethod,
          value: "calculationMethod",
          align: "left",
          width: "20%",
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.DB4.activity_values,
          value: "activity_values",
          align: "center",
          width: "60%",
          remark: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.DB4.remark,
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.DB4.emissionVolume,
          value: "emissionVolume",
          align: "right",
          width: "10%",
          class: "success--text",
        },
        {
          text: "",
          value: "buttons",
          align: "right",
          width: "60px",
        },
      ],
      DB5: [
        {
          text: "",
          value: "buttonEdit",
          align: "right",
          width: "60px",
          action: 'editRow'
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.DB5.productName,
          value: "productName",
          align: "left",
          width: "10%",
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.DB5.departmentName,
          value: "departmentName",
          align: "left",
          width: "15%",
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.DB5.calculationMethod,
          value: "calculationMethod",
          align: "left",
          width: "15%",
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.DB5.activity_values,
          value: "activity_values",
          align: "center",
          width: "25%",
          remark: "Q0006",
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.DB5.emissionIntensity,
          value: "emissionIntensity",
          align: "right",
          width: "10%",
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.DB5.emissionVolume,
          value: "emissionVolume",
          align: "right",
          width: "15%",
          class: "success--text",
        },
        {
          text: "",
          value: "buttons",
          align: "right",
          width: "60px",
        },
      ],
      DB0: [
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.DB0.activityName,
          value: "activityName",
          align: "left",
          width: "10%",
          remark: "",
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.DB0.calculationMethod,
          value: "calculationMethod",
          align: "left",
          width: "25%",
          remark: "",
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.DB0.activity_values,
          value: "activity_values",
          align: "center",
          width: "500px",
          remark: "Q0013",
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.DB0.emissionVolume,
          value: "emissionVolume",
          align: "right",
          width: "20%",
          class: "success--text",
        },
        {
          text: "",
          value: "buttons",
          align: "right",
          width: "30px",
        },
      ],
      DB8: [
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.DB8.activityName,
          value: "activityName",
          align: "left",
          width: "12%",
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.DB8.calculationMethod,
          value: "calculationMethod",
          align: "left",
          width: "22%",
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.DB8.calculationTargetName,
          value: "calculationTargetName",
          align: "left",
          width: "18%",
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.DB8.activity_values,
          value: "activity_values",
          align: "center",
          width: "30%",
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.DB8.emissionIntensity,
          value: "emissionIntensity",
          align: "center",
          width: "15%",
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.DB8.emissionVolume,
          value: "emissionVolume",
          align: "right",
          width: "10%",
          class: "success--text",
        },
        {
          text: "",
          value: "buttons",
          align: "right",
          width: "60px",
        },
      ],
      S3_C14: {
        ACTIVITY_HEADERS: [
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C14.ACTIVITY_HEADERS.productActionName,
            value: "productActionName",
            align: "left",
            width: "15%",
          },
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C14.ACTIVITY_HEADERS.calculationMethodName,
            value: "calculationMethodName",
            align: "left",
            width: "20%",
          },
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C14.ACTIVITY_HEADERS.activityValues,
            value: "activityValues",
            align: "center",
            width: "50%",
          },
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C14.ACTIVITY_HEADERS.emissionVolume,
            value: "emissionVolume",
            align: "right",
            width: "10%",
            class: "success--text",
          },
          {
            text: "",
            value: "action",
            align: "right",
            width: "60px",
          },
        ],
        SUBCOLUMNS: [
          {
            name: 'inputField1Name',
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => item.calculationMethodCode === '0904',
            editable: true
          },
          {
            name: 'inputField2Name',
            model: 'emissionIntensity',
            rules: 'required|length_comma:5|number_comma_full_width:true|numeric_right:10',
            show: (item) => item.calculationMethodCode === '0904',
            editable: true
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C14.SUBCOLUMNS.activityVolume,
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => item.calculationMethodCode === '0902',
            editable: true,
            unit: 'activityVolumeUnit'
          },
        ],
        ADD_DIALOG: {
          formValues: {
            productActionName: '',
            calculationMethod: null,
            inputField1Name: '',
            inputField2Name: '',
            memo: '',
            // 値2の項目名の価値
            emissionIntensity: null,
            emissionUnit: null,
          },
          formFields: {
            productActionName: {
              model: 'productActionName',
              rules: 'required|max:100',
              component: 'v-text-field',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C14.ADD_DIALOG.productActionName,
                type: 'text',
              },
            },
            calculationMethod: {
              model: 'calculationMethod',
              rules: 'required',
              component: 'v-select',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C14.ADD_DIALOG.calculationMethod,
                items: [],
                'item-text': 'name',
                'return-object': true,
              },
            },
            inputField1Name: {
              model: 'inputField1Name',
              rules: 'required|max:100',
              component: 'v-text-field',
              show: (data) => data.formValues.calculationMethod?.code === '0904',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C14.ADD_DIALOG.inputField1Name,
                type: 'text',
              },
            },
            inputField2Name: {
              model: 'inputField2Name',
              rules: 'required|max:100',
              component: 'v-combobox',
              show: (data) => data.formValues.calculationMethod?.code === '0904',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C14.ADD_DIALOG.inputField2Name,
                clearable: true,
                'return-object': true
              },
              on: {}
            },
            memo: {
              component: 'v-text-field',
              model: 'memo',
              rules: 'max:100',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C14.ADD_DIALOG.memo,
                type: 'text',
              },
            },
          },
          dataMapper: {
            calculationMethod: 'methods',
          }
        },
        GUIDE: 'Q0011'
      },
      S3_C06: {
        ACTIVITY_HEADERS: [
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.ACTIVITY_HEADERS.productActionName,
            value: "productActionName",
            align: "left",
            width: "15%",
          },
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.ACTIVITY_HEADERS.calculationMethodName,
            value: "calculationMethodName",
            align: "left",
            width: "25%",
          },
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.ACTIVITY_HEADERS.activityValues,
            value: "activityValues",
            align: "center",
            width: "30%",
          },
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.ACTIVITY_HEADERS.emissionUnit,
            value: "emissionUnit",
            align: "center",
            width: "15%",
          },
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.ACTIVITY_HEADERS.emissionVolume,
            value: "emissionVolume",
            align: "right",
            width: "10%",
            class: "success--text",
          },
          {
            text: "",
            value: "action",
            align: "right",
            width: "60px",
          },
        ],
        SUBCOLUMNS: [
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.SUBCOLUMNS.activityVolume1,
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            unit: 'activityVolumeUnit',
            show: (item) => item.calculationMethodCode === '0125',
            showDetailButton: true
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.SUBCOLUMNS.activityVolume2,
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            unit: 'activityVolumeUnit',
            show: (item) => item.calculationMethodCode === '0108',
            showDetailButton: true
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.SUBCOLUMNS.activityVolume3,
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            unit: 'activityVolumeUnit',
            show: (item) => item.calculationMethodCode === '0109',
            showDetailButton: true
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.SUBCOLUMNS.fuelEconomy,
            model: 'fuelEconomy',
            rules: 'required|length_comma:11|number_comma_full_width:true|numeric_right:2',
            unit: 'fuelUnitName',
            show: (item) => item.calculationMethodCode === '0109',
            disabled: (item) => item.fuelCode === '02',
            editable: (item) => item.fuelCode !== '02'
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.SUBCOLUMNS.activityVolume4,
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            unit: 'activityVolumeUnit',
            show: (item) => item.calculationMethodCode === '0127',
            showDetailButton: true
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.SUBCOLUMNS.activityVolume5,
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            unit: 'activityVolumeUnit',
            show: (item) => item.calculationMethodCode === '0128',
            showDetailButton: true
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.SUBCOLUMNS.activityVolume6,
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            unit: 'activityVolumeUnit',
            show: (item) => item.calculationMethodCode === '0129',
            showDetailButton: true
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.SUBCOLUMNS.activityVolume7,
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            unit: 'activityVolumeUnit',
            show: (item) => item.calculationMethodCode === '0130',
            editable: true
          },
          {
            name: 'inputField1Name',
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => item.calculationMethodCode === '0901',
            editable: true
          },
          {
            name: 'inputField2Name',
            model: 'emissionIntensity',
            rules: 'required|length_comma:5|number_comma_full_width:true|numeric_right:10',
            show: (item) => item.calculationMethodCode === '0901',
            editable: true
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.SUBCOLUMNS.activityVolume8,
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => item.calculationMethodCode === '0902',
            editable: true,
            unit: 'activityVolumeUnit'
          },
        ],
        FORMULA: {
          '0109': ['activityVolume', '/', 'fuelEconomy', '*', 0.001, '*', 'emissionIntensity'],
          // 交通費支給額に基づく
          '0127': ['activityVolume', '*', 'emissionIntensity', '*', 0.001],
          // 宿泊
          '0128': ['activityVolume', '*', 0.001, '*', 'emissionIntensity'],
        },
        ADD_DIALOG: {
          getName: {
            '0125': ['calculationTargetName2'],
            '0108': ['fuelMethodName', 'calculationTargetName1'],
            '0109': ['fuelMethodName', 'calculationTargetName1', `${context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.ADD_DIALOG.understandingFuelConsumption}-fuelName`],
            '0127': ['calculationTargetName1'],
            '0129': ['calculationTargetName1'],
          },
          formValues: {
            productActionName: '',
            calculationMethod: null,
            calculationTarget: null,
            inputField1Name: '',
            inputField2Name: '',
            memo: '',
            // 自動車算定方法
            fuelMethod: null,
            // 燃費の把握
            fuel: null,
            // 値2の項目名の価値
            emissionIntensity: null,
            emissionUnit: null,
          },
          formFields: {
            productActionName: {
              model: 'productActionName',
              rules: 'required|max:100',
              component: 'v-text-field',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.ADD_DIALOG.productActionName,
                type: 'text',
              },
            },
            calculationMethod: {
              model: 'calculationMethod',
              rules: 'required',
              component: 'v-select',
              show: (data, props) => props.catSixPersonnelAndCalcFlg.category6CalculationFlag === '2',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.ADD_DIALOG.calculationMethod,
                items: [],
                'item-text': 'name',
                'return-object': true,
              },
            },
            calculationTarget: {
              model: 'calculationTarget',
              rules: 'required',
              component: 'v-select',
              // 移動距離に基づく算定
              show: (data, props) => data.formValues.calculationMethod?.code === '0125' && props.catSixPersonnelAndCalcFlg.category6CalculationFlag === '2',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.ADD_DIALOG.calculationTarget,
                items: [],
                'item-text': 'name2',
                'return-object': true,
              },
            },
            fuelMethod: {
              model: 'fuelMethod',
              rules: 'required',
              component: 'v-radio-group',
              // 移動距離に基づく算定
              show: (data, props) => data.formValues.calculationMethod?.code === '0126' && props.catSixPersonnelAndCalcFlg.category6CalculationFlag === '2',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.ADD_DIALOG.fuelMethod,
                'return-object': true,
              },
              items: [
                {
                  component: 'v-radio',
                  props: {
                    label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.ADD_DIALOG.fuelMethodItem1,
                    value: { name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.ADD_DIALOG.fuelMethodItem1, code: '0108', codeId: '183' }
                  }
                },
                {
                  component: 'v-radio',
                  props: {
                    label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.ADD_DIALOG.fuelMethodItem2,
                    value: { name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.ADD_DIALOG.fuelMethodItem2, code: '0109', codeId: '183' }
                  }
                },
              ]
            },
            fuel: {
              model: 'calculationTarget',
              rules: 'required',
              component: 'v-select',
              // 燃料法
              show: (data, props) => data.formValues.fuelMethod?.code === '0108' && props.catSixPersonnelAndCalcFlg.category6CalculationFlag === '2',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.ADD_DIALOG.fuel,
                items: [],
                'item-text': 'name1',
                'return-object': true,
              },
            },
            fuelConsumption: {
              model: 'calculationTarget',
              rules: 'required',
              component: 'v-select',
              // 燃費法
              show: (data, props) => data.formValues.fuelMethod?.code === '0109' && props.catSixPersonnelAndCalcFlg.category6CalculationFlag === '2',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.ADD_DIALOG.fuelConsumption,
                items: [],
                'item-text': 'name1',
                'return-object': true,
              },
            },
            fuelCode: {
              model: 'fuel',
              rules: 'required',
              component: 'v-radio-group',
              guide: 'Q0003',
              // 燃費法
              show: (data, props) => data.formValues.fuelMethod?.code === '0109' && props.catSixPersonnelAndCalcFlg.category6CalculationFlag === '2',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.ADD_DIALOG.fuelCode,
                'return-object': true
              },
              items: [
                {
                  component: 'v-radio',
                  props: {
                    label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.ADD_DIALOG.fuelCodeItem1,
                    value: { name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.ADD_DIALOG.fuelCodeItem1, code: '02' }
                  }
                },
                {
                  component: 'v-radio',
                  props: {
                    label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.ADD_DIALOG.fuelCodeItem2,
                    value: { name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.ADD_DIALOG.fuelCodeItem2, code: '01' }
                  }
                },
              ]
            },
            transExps: {
              model: 'calculationTarget',
              rules: 'required',
              component: 'v-select',
              // 燃料法
              show: (data, props) => data.formValues.calculationMethod?.code === '0127' && props.catSixPersonnelAndCalcFlg.category6CalculationFlag === '2',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.ADD_DIALOG.transExps,
                items: [],
                'item-text': 'name1',
                'return-object': true,
              },
            },
            travelDays: {
              model: 'calculationTarget',
              rules: 'required',
              component: 'v-select',
              // 燃料法
              show: (data, props) => data.formValues.calculationMethod?.code === '0129' && props.catSixPersonnelAndCalcFlg.category6CalculationFlag === '2',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.ADD_DIALOG.travelDays,
                items: [],
                'item-text': 'name1',
                'return-object': true,
              },
            },
            inputField1Name: {
              model: 'inputField1Name',
              rules: 'required|max:100',
              component: 'v-text-field',
              show: (data, props) => data.formValues.calculationMethod?.code === '0901' && props.catSixPersonnelAndCalcFlg.category6CalculationFlag === '2',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.ADD_DIALOG.inputField1Name,
                type: 'text',
              },
            },
            inputField2Name: {
              model: 'inputField2Name',
              rules: 'required|max:100',
              component: 'v-combobox',
              show: (data, props) => data.formValues.calculationMethod?.code === '0901' && props.catSixPersonnelAndCalcFlg.category6CalculationFlag === '2',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.ADD_DIALOG.inputField2Name,
                clearable: true,
                'return-object': true
              },
              on: {}
            },
            memo: {
              component: 'v-text-field',
              model: 'memo',
              rules: 'max:100',
              show: (data, props) => ['0901', '0902'].includes(data.formValues.calculationMethod?.code) && props.catSixPersonnelAndCalcFlg.category6CalculationFlag === '2',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.ADD_DIALOG.memo,
                type: 'text',
              },
            },
          },
          dataMapper: {
            calculationMethod: 'codes',
            calculationTarget: 'transportDistance',
            fuel: 'fuel',
            fuelConsumption: 'fuelConsumption',
            transExps: 'transExps',
            travelDays: 'travelDays'
          }
        },
        DETAIL_DIALOG: {
          '0125': {
            headers: [
              { text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.DETAIL_DIALOG['0125'].createDate, value: 'createDate', width: '8%', align: 'left' },
              { text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.DETAIL_DIALOG['0125'].createName, value: 'createName', width: '8%', align: 'left' },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.DETAIL_DIALOG['0125'].activityVolume,
                value: 'activityVolume',
                width: '15%',
                align: 'left',
                unit: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.DETAIL_DIALOG['0125'].activityUnit,
                default: null,
                rule: 'required|number_max:10'
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.DETAIL_DIALOG['0125'].transportDistance,
                value: 'transportDistance',
                width: '15%',
                align: 'left',
                unit: 'km',
                default: null
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.DETAIL_DIALOG['0125'].evidence,
                value: 'evidence',
                width: '30%',
                align: 'left',
                default: ''
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.DETAIL_DIALOG['0125'].activityDetailMemo,
                value: 'activityDetailMemo',
                width: '26%',
                align: 'left',
                default: ''
              },
              { text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.DETAIL_DIALOG['0125'].remove, value: 'remove', width: '9%', align: 'left', default: false },
              { text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.DETAIL_DIALOG['0125'].action, value: 'action', width: '120px', align: 'left', default: false, guide: 'Q0018' },
            ],
            formula: {
              activityVolume: ['activityVolume', '*', 'transportDistance']
            }
          },
          '0108': {
            headers: [
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.DETAIL_DIALOG['0108'].createDate,
                value: 'createDate',
                width: '8%',
                align: 'left'
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.DETAIL_DIALOG['0108'].createName,
                value: 'createName',
                width: '8%',
                align: 'left'
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.DETAIL_DIALOG['0108'].activityVolume,
                value: 'activityVolume',
                width: '15%',
                align: 'left',
                default: null,
                unit: 'activityVolumeUnit'
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.DETAIL_DIALOG['0108'].evidence,
                value: 'evidence',
                width: '30%',
                align: 'left',
                default: ''
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.DETAIL_DIALOG['0108'].activityDetailMemo,
                value: 'activityDetailMemo',
                width: '26%',
                align: 'left',
                default: ''
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.DETAIL_DIALOG['0108'].remove,
                value: 'remove',
                width: '9%',
                align: 'left',
                default: false
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.DETAIL_DIALOG['0108'].action,
                value: 'action',
                width: '120px',
                align: 'left',
                default: false,
                guide: 'Q0018'
              },
            ]
          },
          '0109': {
            headers: [
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.DETAIL_DIALOG['0109'].createDate,
                value: 'createDate',
                width: '8%',
                align: 'left'
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.DETAIL_DIALOG['0109'].createName,
                value: 'createName',
                width: '8%',
                align: 'left'
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.DETAIL_DIALOG['0109'].activityVolume,
                value: 'activityVolume',
                width: '15%',
                align: 'left',
                default: null,
                unit: 'activityVolumeUnit'
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.DETAIL_DIALOG['0109'].evidence,
                value: 'evidence',
                width: '30%',
                align: 'left',
                default: ''
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.DETAIL_DIALOG['0109'].activityDetailMemo,
                value: 'activityDetailMemo',
                width: '26%',
                align: 'left',
                default: null
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.DETAIL_DIALOG['0109'].remove,
                value: 'remove',
                width: '9%',
                align: 'left',
                default: false
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.DETAIL_DIALOG['0109'].action,
                value: 'action',
                width: '120px',
                align: 'left',
                default: false,
                guide: 'Q0018'
              },
            ]
          },
          '0127': {
            headers: [
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.DETAIL_DIALOG['0127'].createDate,
                value: 'createDate',
                width: '8%',
                align: 'left'
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.DETAIL_DIALOG['0127'].createName,
                value: 'createName',
                width: '8%',
                align: 'left'
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.DETAIL_DIALOG['0127'].activityVolume,
                value: 'activityVolume',
                width: '15%',
                align: 'left',
                unit: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.DETAIL_DIALOG['0125'].activityUnit,
                default: null,
                rule: 'required|number_max:10'
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.DETAIL_DIALOG['0127'].allowance,
                value: 'allowance',
                width: '15%',
                align: 'left',
                unit: 'activityVolumeUnit',
                default: null
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.DETAIL_DIALOG['0127'].evidence,
                value: 'evidence',
                width: '30%',
                align: 'left',
                default: ''
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.DETAIL_DIALOG['0127'].activityDetailMemo,
                value: 'activityDetailMemo',
                width: '26%',
                align: 'left',
                default: ''
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.DETAIL_DIALOG['0127'].remove,
                value: 'remove',
                width: '9%',
                align: 'left',
                default: false
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.DETAIL_DIALOG['0127'].action,
                value: 'action',
                width: '120px',
                align: 'left',
                default: false,
                guide: 'Q0018'
              },
            ],
            formula: {
              activityVolume: ['activityVolume', '*', 'allowance']
            }
          },
          '0128': {
            headers: [
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.DETAIL_DIALOG['0128'].createDate,
                value: 'createDate',
                width: '8%',
                align: 'left'
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.DETAIL_DIALOG['0128'].createName,
                value: 'createName',
                width: '8%',
                align: 'left'
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.DETAIL_DIALOG['0128'].activityVolume,
                value: 'activityVolume',
                width: '15%',
                align: 'left',
                unit: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.DETAIL_DIALOG['0125'].activityUnit,
                default: null,
                rule: 'required|number_max:10'
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.DETAIL_DIALOG['0128'].dayCount,
                value: 'dayCount',
                width: '15%',
                align: 'left',
                unit: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.DETAIL_DIALOG['0128'].dayCountUnit,
                default: null
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.DETAIL_DIALOG['0128'].evidence,
                value: 'evidence',
                width: '30%',
                align: 'left',
                default: ''
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.DETAIL_DIALOG['0128'].activityDetailMemo,
                value: 'activityDetailMemo',
                width: '26%',
                align: 'left',
                default: ''
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.DETAIL_DIALOG['0128'].remove,
                value: 'remove',
                width: '9%',
                align: 'left',
                default: false
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.DETAIL_DIALOG['0128'].action,
                value: 'action',
                width: '120px',
                align: 'left',
                default: false,
                guide: 'Q0018'
              },
            ],
            formula: {
              activityVolume: ['activityVolume', '*', 'dayCount']
            }
          },
          '0129': {
            headers: [
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.DETAIL_DIALOG['0128'].createDate,
                value: 'createDate',
                width: '8%',
                align: 'left'
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.DETAIL_DIALOG['0128'].createName,
                value: 'createName',
                width: '8%',
                align: 'left'
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.DETAIL_DIALOG['0128'].activityVolume,
                value: 'activityVolume',
                width: '15%',
                align: 'left',
                unit: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.DETAIL_DIALOG['0125'].activityUnit,
                default: null,
                rule: 'required|number_max:10'
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.DETAIL_DIALOG['0128'].dayCount,
                value: 'dayCount',
                width: '15%',
                align: 'left',
                unit: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.DETAIL_DIALOG['0128'].dayCountUnit,
                default: null
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.DETAIL_DIALOG['0128'].evidence,
                value: 'evidence',
                width: '30%',
                align: 'left',
                default: ''
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.DETAIL_DIALOG['0128'].activityDetailMemo,
                value: 'activityDetailMemo',
                width: '26%',
                align: 'left',
                default: ''
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.DETAIL_DIALOG['0128'].remove,
                value: 'remove',
                width: '9%',
                align: 'left',
                default: false
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.DETAIL_DIALOG['0128'].action,
                value: 'action',
                width: '120px',
                align: 'left',
                default: false,
                guide: 'Q0018'
              },
            ],
            formula: {
              activityVolume: ['activityVolume', '*', 'dayCount']
            }
          },
        },
        GUIDE: 'Q0016'
      },
      S3_C07: {
        ACTIVITY_HEADERS: [
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.ACTIVITY_HEADERS.productActionName,
            value: "productActionName",
            align: "left",
            width: "15%",
          },
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.ACTIVITY_HEADERS.calculationMethodName,
            value: "calculationMethodName",
            align: "left",
            width: "20%",
          },
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.ACTIVITY_HEADERS.activityValues,
            value: "activityValues",
            align: "center",
            width: "40%",
          },
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.ACTIVITY_HEADERS.emissionUnit,
            value: "emissionUnit",
            align: "center",
            width: "10%",
          },
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.ACTIVITY_HEADERS.emissionVolume,
            value: "emissionVolume",
            align: "right",
            width: "10%",
            class: "success--text",
          },
          {
            text: "",
            value: "action",
            align: "right",
            width: "60px",
          },
        ],
        SUBCOLUMNS: [
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.SUBCOLUMNS.activityVolume1,
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            unit: 'activityVolumeUnit',
            show: (item) => item.calculationMethodCode === '0125',
            showDetailButton: true
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.SUBCOLUMNS.activityVolume2,
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            unit: 'activityVolumeUnit',
            show: (item) => item.calculationMethodCode === '0108',
            showDetailButton: true
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.SUBCOLUMNS.activityVolume3,
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            unit: 'activityVolumeUnit',
            show: (item) => item.calculationMethodCode === '0109',
            showDetailButton: true
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.SUBCOLUMNS.fuelEconomy,
            model: 'fuelEconomy',
            rules: 'required|length_comma:11|number_comma_full_width:true|numeric_right:2',
            unit: 'fuelUnitName',
            show: (item) => item.calculationMethodCode === '0109',
            disabled: (item) => item.fuelCode === '02',
            editable: (item) => item.fuelCode !== '02'
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.SUBCOLUMNS.activityVolume4,
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            unit: 'activityVolumeUnit',
            show: (item) => item.calculationMethodCode === '0127',
            showDetailButton: true
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.SUBCOLUMNS.fuelActivityVolume,
            model: 'fuelActivityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            unit: (item) => item.fuelActivityVolumeUnit,
            show: (item) => item.calculationMethodCode === '0131',
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.SUBCOLUMNS.activityVolume5,
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            unit: (item) => item.activityVolumeUnit,
            show: (item) => item.calculationMethodCode === '0131',
            showDetailButton: true
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.SUBCOLUMNS.activityVolume6,
            model: 'activityVolume',
            rules: 'required|number_max:10',
            unit: 'activityVolumeUnit',
            show: (item) => item.calculationMethodCode === '0132',
            editable: true
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.SUBCOLUMNS.businessDaysYear,
            model: 'businessDaysYear',
            rules: 'required|custom_min_value:-1|custom_max_value:366|number_max:3',
            // value: (props) => props['category']['fiscalYear']['businessDays'],
            unit: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.SUBCOLUMNS.businessDaysYearUnit,
            show: (item) => item.calculationMethodCode === '0132',
            editable: true
          },
          {
            name: 'inputField1Name',
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => item.calculationMethodCode === '0901',
            editable: true
          },
          {
            name: 'inputField2Name',
            model: 'emissionIntensity',
            rules: 'required|length_comma:5|number_comma_full_width:true|numeric_right:10',
            show: (item) => item.calculationMethodCode === '0901',
            editable: true
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.SUBCOLUMNS.activityVolume7,
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => item.calculationMethodCode === '0902',
            editable: true,
            unit: 'activityVolumeUnit'
          },
        ],
        FORMULA: {
          '0109': ['activityVolume', '/', 'fuelEconomy', '*', 0.001, '*', 'emissionIntensity'],
          // 交通費支給額に基づく
          '0127': ['activityVolume', '*', 'emissionIntensity', '*', 0.001],
          // テレワーク
          '0131': ['(', 'fuelActivityVolume', '*', 'fuelEmissionIntensity', ')', '+', '(', 'activityVolume', '*', 'emissionIntensity', ')'],
          '0132': ['activityVolume', '*', 'businessDaysYear', '*', 'emissionIntensity', '*', 0.001],
        },
        ADD_DIALOG: {
          getName: {
            '0125': ['calculationTargetName2'],
            '0108': ['fuelMethodName', 'calculationTargetName1'],
            '0109': ['fuelMethodName', 'calculationTargetName1', `${context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.ADD_DIALOG.understandingFuelConsumption}-fuelName`],
            '0127': ['calculationTargetName1'],
            '0131': (item) => {
              let result = []
              item.electricCalculationMethodItem1 && result.push(item.electricCalculationMethodItem1)
              item.electricCompanyElectricCompanyNo && result.push(`${item.electricCompanyElectricCompanyNo} ${item.electricCompanyElectricCompanyName}`)
              item.supplyCompanyUnit1 && result.push(item.supplyCompanyUnit1)

              let joinRes = result.join('/')
              if (joinRes) {
                joinRes = `&${context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.ADD_DIALOG.electricLabel}/` + result.join('/')
              } else {
                joinRes = `&-`
              }
              if (item.calculationTargetName1) {
                joinRes = '&' + item.calculationTargetName1 + joinRes
              } else {
                joinRes = '&-' + joinRes
              }
              return joinRes
            }
          },
          formValues: {
            productActionName: '',
            calculationMethod: null,
            calculationTarget: null,
            inputField1Name: '',
            inputField2Name: '',
            memo: '',
            // 自動車算定方法
            fuelMethod: null,
            // 燃費の把握
            fuel: null,
            // 値2の項目名の価値
            emissionIntensity: null,
            emissionUnit: null,
            electricCalculationMethod: null,
            electricCompany: null,
            supplyCompany: null,
          },
          formFields: {
            productActionName: {
              model: 'productActionName',
              rules: 'required|max:100',
              component: 'v-text-field',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.ADD_DIALOG.productActionName,
                type: 'text',
              },
            },
            calculationMethod: {
              model: 'calculationMethod',
              rules: 'required',
              component: 'v-select',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.ADD_DIALOG.calculationMethod,
                items: [],
                'item-text': 'name',
                'return-object': true,
              },
            },
            calculationTarget: {
              model: 'calculationTarget',
              rules: 'required',
              component: 'v-select',
              // 移動距離に基づく算定
              show: (data) => data.formValues.calculationMethod?.code === '0125',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.ADD_DIALOG.calculationTarget,
                items: [],
                'item-text': 'name2',
                'return-object': true,
              },
            },
            fuelMethod: {
              model: 'fuelMethod',
              rules: 'required',
              component: 'v-radio-group',
              // 移動距離に基づく算定
              show: (data) => data.formValues.calculationMethod?.code === '0126',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.ADD_DIALOG.fuelMethod,
                'return-object': true,
              },
              items: [
                {
                  component: 'v-radio',
                  props: {
                    label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.ADD_DIALOG.fuelMethodItems1,
                    value: { name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.ADD_DIALOG.fuelMethodItems1, code: '0108', codeId: '183' }
                  }
                },
                {
                  component: 'v-radio',
                  props: {
                    label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.ADD_DIALOG.fuelMethodItems2,
                    value: { name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.ADD_DIALOG.fuelMethodItems2, code: '0109', codeId: '183' }
                  }
                },
              ]
            },
            fuel: {
              model: 'calculationTarget',
              rules: 'required',
              component: 'v-select',
              // 燃料法
              show: (data) => data.formValues.fuelMethod?.code === '0108',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.ADD_DIALOG.fuel,
                items: [],
                'item-text': 'name1',
                'return-object': true,
              },
            },
            fuelConsumption: {
              model: 'calculationTarget',
              rules: 'required',
              component: 'v-select',
              // 燃費法
              show: (data) => data.formValues.fuelMethod?.code === '0109',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.ADD_DIALOG.fuelConsumption,
                items: [],
                'item-text': 'name1',
                'return-object': true,
              },
            },
            fuelCode: {
              model: 'fuel',
              rules: 'required',
              component: 'v-radio-group',
              guide: 'Q0003',
              // 燃費法
              show: (data) => data.formValues.fuelMethod?.code === '0109',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.ADD_DIALOG.fuelCode,
                'return-object': true
              },
              items: [
                {
                  component: 'v-radio',
                  props: {
                    label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.ADD_DIALOG.fuelCodeItem1,
                    value: { name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.ADD_DIALOG.fuelCodeItem1, code: '02' }
                  }
                },
                {
                  component: 'v-radio',
                  props: {
                    label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.ADD_DIALOG.fuelCodeItem2,
                    value: { name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.ADD_DIALOG.fuelCodeItem2, code: '01' }
                  }
                },
              ]
            },
            transExps: {
              model: 'calculationTarget',
              rules: 'required',
              component: 'v-select',
              // 燃料法
              show: (data) => data.formValues.calculationMethod?.code === '0127',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.ADD_DIALOG.transExps,
                items: [],
                'item-text': 'name1',
                'return-object': true,
              },
            },
            telework: {
              model: 'calculationTarget',
              rules: (item) => ({ required: item.electricCalculationMethod === null }),
              component: 'v-select',
              // 燃料法
              show: (data) => data.formValues.calculationMethod?.code === '0131',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.ADD_DIALOG.telework,
                items: [],
                'item-text': 'name1',
                'return-object': true,
              },
            },
            electricCodesRes: {
              model: 'electricCalculationMethod',
              rules: (item) => ({ required: item.calculationTarget === null }),
              component: 'v-select',
              // 燃料法
              show: (data) => data.formValues.calculationMethod?.code === '0131',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.ADD_DIALOG.electricCodesRes,
                items: [],
                'item-text': 'item1',
                'return-object': true,
              },
            },
            retailElectricCompanies: {
              model: 'electricCompany',
              rules: (item) => ({ required: !!item.electricCalculationMethod }),
              component: 'v-autocomplete',
              // 燃料法
              show: (data) => data.formValues.calculationMethod?.code === '0131',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.ADD_DIALOG.retailElectricCompanies,
                items: [],
                'item-text': (item) => item.electricCompanyNo + ' ' + item.electricCompanyName,
                'return-object': true,
              },
            },
            supplyCompanies: {
              model: 'supplyCompany',
              rules: 'required',
              component: 'v-select',
              // 燃料法
              show: (data) => !!data.formFields.supplyCompanies.props.items.length,
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.ADD_DIALOG.supplyCompanies,
                items: [],
                'item-text': 'unit_1',
                'return-object': true,
              },
            },
            inputField1Name: {
              model: 'inputField1Name',
              rules: 'required|max:100',
              component: 'v-text-field',
              show: (data) => data.formValues.calculationMethod?.code === '0901',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.ADD_DIALOG.inputField1Name,
                type: 'text',
              },
            },
            inputField2Name: {
              model: 'inputField2Name',
              rules: 'required|max:100',
              component: 'v-combobox',
              show: (data) => data.formValues.calculationMethod?.code === '0901',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.ADD_DIALOG.inputField2Name,
                clearable: true,
                'return-object': true
              },
              on: {}
            },
            memo: {
              component: 'v-text-field',
              model: 'memo',
              rules: 'max:100',
              show: (data) => ['0901', '0902'].includes(data.formValues.calculationMethod?.code),
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.ADD_DIALOG.memo,
                type: 'text',
              },
            },
          },
          dataMapper: {
            calculationMethod: 'codes',
            calculationTarget: 'transportDistance',
            fuel: 'fuel',
            fuelConsumption: 'fuelConsumption',
            transExps: 'transExps',
            telework: 'telework',
            electricCodesRes: 'calcMethods',
            retailElectricCompanies: 'retailElectricCompanies'
          }
        },
        DETAIL_DIALOG: {
          '0125': {
            headers: [
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.DETAIL_DIALOG['0125'].createDate,
                value: 'createDate',
                width: '8%',
                align: 'left'
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.DETAIL_DIALOG['0125'].createName,
                value: 'createName',
                width: '8%',
                align: 'left'
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.DETAIL_DIALOG['0125'].activityVolume,
                value: 'activityVolume',
                width: '15%',
                align: 'left',
                unit: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.DETAIL_DIALOG['0125'].activityUnit,
                default: null
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.DETAIL_DIALOG['0125'].transportDistance,
                value: 'transportDistance',
                width: '15%',
                align: 'left',
                unit: 'km',
                default: null
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.DETAIL_DIALOG['0125'].evidence,
                value: 'evidence',
                width: '30%',
                align: 'left',
                default: ''
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.DETAIL_DIALOG['0125'].activityDetailMemo,
                value: 'activityDetailMemo',
                width: '26%',
                align: 'left',
                default: ''
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.DETAIL_DIALOG['0125'].remove,
                value: 'remove',
                width: '9%',
                align: 'left',
                default: false
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.DETAIL_DIALOG['0125'].action,
                value: 'action',
                width: '120px',
                align: 'left',
                default: false,
                guide: 'Q0018'
              },
            ],
            formula: {
              activityVolume: ['activityVolume', '*', 'transportDistance']
            }
          },
          '0108': {
            headers: [
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.DETAIL_DIALOG['0108'].createDate,
                value: 'createDate',
                width: '8%',
                align: 'left'
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.DETAIL_DIALOG['0108'].createName,
                value: 'createName',
                width: '8%',
                align: 'left'
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.DETAIL_DIALOG['0108'].activityVolume,
                value: 'activityVolume',
                width: '15%',
                align: 'left',
                default: null,
                unit: 'activityVolumeUnit',
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.DETAIL_DIALOG['0108'].evidence,
                value: 'evidence',
                width: '30%',
                align: 'left',
                default: ''
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.DETAIL_DIALOG['0108'].activityDetailMemo,
                value: 'activityDetailMemo',
                width: '26%',
                align: 'left',
                default: ''
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.DETAIL_DIALOG['0108'].remove,
                value: 'remove',
                width: '9%',
                align: 'left',
                default: false
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.DETAIL_DIALOG['0108'].action,
                value: 'action',
                width: '120px',
                align: 'left',
                default: false,
                guide: 'Q0018'
              },
            ]
          },
          '0109': {
            headers: [
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.DETAIL_DIALOG['0109'].createDate,
                value: 'createDate',
                width: '8%',
                align: 'left'
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.DETAIL_DIALOG['0109'].createName,
                value: 'createName',
                width: '8%',
                align: 'left'
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.DETAIL_DIALOG['0109'].activityVolume,
                value: 'activityVolume',
                width: '15%',
                align: 'left',
                default: null,
                unit: 'activityVolumeUnit',
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.DETAIL_DIALOG['0109'].evidence,
                value: 'evidence',
                width: '30%',
                align: 'left',
                default: ''
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.DETAIL_DIALOG['0109'].activityDetailMemo,
                value: 'activityDetailMemo',
                width: '26%',
                align: 'left',
                default: null
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.DETAIL_DIALOG['0109'].remove,
                value: 'remove',
                width: '9%',
                align: 'left',
                default: false
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.DETAIL_DIALOG['0109'].action,
                value: 'action',
                width: '120px',
                align: 'left',
                default: false,
                guide: 'Q0018'
              },
            ]
          },
          '0127': {
            headers: [
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.DETAIL_DIALOG['0127'].createDate,
                value: 'createDate',
                width: '8%',
                align: 'left'
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.DETAIL_DIALOG['0127'].createName,
                value: 'createName',
                width: '8%',
                align: 'left'
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.DETAIL_DIALOG['0127'].activityVolume,
                value: 'activityVolume',
                width: '15%',
                align: 'left',
                unit: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C06.DETAIL_DIALOG['0125'].activityUnit,
                default: null
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.DETAIL_DIALOG['0127'].allowance,
                value: 'allowance',
                width: '15%',
                align: 'left',
                unit: 'activityVolumeUnit',
                default: null
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.DETAIL_DIALOG['0127'].evidence,
                value: 'evidence',
                width: '30%',
                align: 'left',
                default: ''
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.DETAIL_DIALOG['0127'].activityDetailMemo,
                value: 'activityDetailMemo',
                width: '26%',
                align: 'left',
                default: ''
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.DETAIL_DIALOG['0127'].remove,
                value: 'remove',
                width: '9%',
                align: 'left',
                default: false
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.DETAIL_DIALOG['0127'].action,
                value: 'action',
                width: '120px',
                align: 'left',
                default: false,
                guide: 'Q0018'
              },
            ],
            formula: {
              activityVolume: ['activityVolume', '*', 'allowance']
            }
          },
          '0131': {
            headers: [
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.DETAIL_DIALOG['0131'].createDate,
                value: 'createDate',
                width: '8%',
                align: 'left'
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.DETAIL_DIALOG['0131'].createName,
                value: 'createName',
                width: '8%',
                align: 'left'
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.DETAIL_DIALOG['0131'].fuelActivityVolume,
                value: 'fuelActivityVolume',
                width: '15%',
                align: 'left',
                default: null,
                unit: (item) => item.fuelActivityVolumeUnit,
                disabled: (item) => item.fuelActivityVolumeUnit === ''
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.DETAIL_DIALOG['0131'].activityVolume,
                value: 'activityVolume',
                width: '15%',
                align: 'left',
                default: null,
                unit: (item) => item.activityVolumeUnit,
                disabled: (item) => item.activityVolumeUnit === ''
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.DETAIL_DIALOG['0131'].evidence,
                value: 'evidence',
                width: '30%',
                align: 'left',
                default: ''
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.DETAIL_DIALOG['0131'].activityDetailMemo,
                value: 'activityDetailMemo',
                width: '26%',
                align: 'left',
                default: ''
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.DETAIL_DIALOG['0131'].remove,
                value: 'remove',
                width: '9%',
                align: 'left',
                default: false
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C07.DETAIL_DIALOG['0131'].action,
                value: 'action',
                width: '120px',
                align: 'left',
                default: false,
                guide: 'Q0018'
              },
            ],
            formula: {
              activityVolume: ['activityVolume'],
              fuelActivityVolume: ['fuelActivityVolume']
            }
          },
        }
      },
      S3_C08: {
        ACTIVITY_HEADERS: [
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C08.ACTIVITY_HEADERS.productActionName,
            value: "productActionName",
            align: "left",
            width: "15%",
          },
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C08.ACTIVITY_HEADERS.calculationMethodName,
            value: "calculationMethodName",
            align: "left",
            width: "25%",
          },
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C08.ACTIVITY_HEADERS.activityValues,
            value: "activityValues",
            align: "center",
            width: "35%",
          },
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C08.ACTIVITY_HEADERS.emissionUnit,
            value: "emissionUnit",
            align: "center",
            width: "15%",
          },
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C08.ACTIVITY_HEADERS.emissionVolume,
            value: "emissionVolume",
            align: "right",
            width: "10%",
            class: "success--text",
          },
          {
            text: "",
            value: "action",
            align: "right",
            width: "60px",
          },
        ],
        SUBCOLUMNS: [
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C08.SUBCOLUMNS.activityVolume1,
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            unit: 'activityVolumeUnit',
            show: (item) => item.calculationMethodCode === '0138',
            editable: true,
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C08.SUBCOLUMNS.activityVolume2,
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            unit: 'activityVolumeUnit',
            show: (item) => item.calculationMethodCode === '0139',
            editable: true
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C08.SUBCOLUMNS.activityVolume3,
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            unit: 'activityVolumeUnit',
            show: (item) => item.calculationMethodCode === '0140',
            editable: true
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C08.SUBCOLUMNS.distribution,
            model: 'distribution',
            rules: 'not_zero|fraction|fraction_determinator|fraction_numerator|fraction_value|fraction_value_2|max_digits:100',
            show: (item) => ['0139', '0140'].includes(item.calculationMethodCode),
            editable: true
          },
          {
            name: 'inputField1Name',
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => item.calculationMethodCode === '0901',
            editable: true
          },
          {
            name: 'inputField2Name',
            model: 'emissionIntensity',
            rules: 'required|length_comma:5|number_comma_full_width:true|numeric_right:10',
            show: (item) => item.calculationMethodCode === '0901',
            editable: true
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C08.SUBCOLUMNS.activityVolume4,
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => item.calculationMethodCode === '0902',
            editable: true,
            unit: 'activityVolumeUnit'
          },
        ],
        FORMULA: {
          '0139': ['activityVolume', '*', 'distribution', '*', 'emissionIntensity'],
          '0140': ['activityVolume', '*', 'distribution', '*', 'emissionIntensity'],
        },
        ADD_DIALOG: {
          getName: {
            '0138': ['calculationMethodName1', 'gasTypesName1', 'calculationTargetName1'],
            '0139': (item) => {
              let arr = ['calculationMethodName1', 'calculationTargetName1']
              // 代表値
              if (item.electricityUserCode === '01') {
                arr.push('electricityUserName')
              }
              let res = arr.map(i => item[i]).join('/')
              // 個別の電気事業者
              if (item.electricityUserCode === '02') {
                res += '/'
                res += item.retailElectricCompaniesElectricCompanyNo
                res += item.retailElectricCompaniesElectricCompanyName
              }
              return res
            },
            '0140': (item) => {
              let arr = ['calculationMethodName1', 'calculationTargetName1']
              // 代表値
              if (item.electricityUserCode === '01') {
                arr.push('electricityUserName')
              }
              let res = arr.map(i => item[i]).join('/')
              // 個別の電気事業者
              if (item.electricityUserCode === '02') {
                res += '/'
                res += item.retailElectricCompaniesElectricCompanyNo
                res += item.retailElectricCompaniesElectricCompanyName
              }
              return res
            },
            '0901': ['calculationMethodName1'],
            '0902': ['calculationMethodName1'],
          },
          formValues: {
            productActionName: '',
            calculationMethod: null,
            calculationTarget: null,
            inputField1Name: '',
            inputField2Name: '',
            memo: '',
            emissionIntensity: null,
            emissionUnit: null,
            gasTypes: null,
            electricityUser: null,
            distribution: '',
            retailElectricCompanies: null
          },
          formFields: {
            productActionName: {
              model: 'productActionName',
              rules: 'required|max:100',
              component: 'v-text-field',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C08.ADD_DIALOG.productActionName,
                type: 'text',
              },
            },
            calculationMethod: {
              model: 'calculationMethod',
              rules: 'required',
              component: 'v-select',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C08.ADD_DIALOG.calculationMethod,
                items: [],
                'item-text': 'name1',
                'return-object': true,
              },
            },
            gasTypes: {
              model: 'gasTypes',
              rules: 'required',
              component: 'v-select',
              show: (data) => data.formValues.calculationMethod?.code === '0138',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C08.ADD_DIALOG.gasTypes,
                items: [],
                'item-text': 'name1',
                'return-object': true,
              },
            },
            actTypes: {
              model: 'calculationTarget',
              rules: 'required',
              component: 'v-select',
              show: (data) => data.formValues.calculationMethod?.code === '0138' && data.formValues.gasTypes,
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C08.ADD_DIALOG.actTypes,
                items: [],
                'item-text': 'name1',
                'return-object': true,
              },
            },
            codesBuildingPurpose: {
              model: 'calculationTarget',
              rules: 'required',
              component: 'v-select',
              show: (data) => ['0139', '0140'].includes(data.formValues.calculationMethod?.code),
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C08.ADD_DIALOG.codesBuildingPurpose,
                items: [],
                'item-text': 'name1',
                'return-object': true,
              },
            },
            electricityUser: {
              model: 'electricityUser',
              rules: 'required',
              component: 'v-radio-group',
              guide: 'Q0014',
              show: (data) => ['0139', '0140'].includes(data.formValues.calculationMethod?.code),
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C08.ADD_DIALOG.electricityUser,
                'return-object': true
              },
              items: [
                {
                  component: 'v-radio',
                  props: {
                    label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C08.ADD_DIALOG.electricityUserItem1,
                    value: { name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C08.ADD_DIALOG.electricityUserItem1, code: '01' }
                  }
                },
                {
                  component: 'v-radio',
                  props: {
                    label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C08.ADD_DIALOG.electricityUserItem2,
                    value: { name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C08.ADD_DIALOG.electricityUserItem2, code: '02' }
                  }
                },
              ]
            },
            retailElectricCompanies: {
              model: 'retailElectricCompanies',
              rules: 'required',
              component: 'v-autocomplete',
              show: (data) => data.formValues.electricityUser?.code === '02',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C08.ADD_DIALOG.retailElectricCompanies,
                items: [],
                'item-text': (item) => item.electricCompanyNo + ' ' + item.electricCompanyName,
                'return-object': true,
              },
            },
            inputField1Name: {
              model: 'inputField1Name',
              rules: 'required|max:100',
              component: 'v-text-field',
              show: (data) => data.formValues.calculationMethod?.code === '0901',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C08.ADD_DIALOG.inputField1Name,
                type: 'text',
              },
            },
            inputField2Name: {
              model: 'inputField2Name',
              rules: 'required|max:100',
              component: 'v-combobox',
              show: (data) => data.formValues.calculationMethod?.code === '0901',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C08.ADD_DIALOG.inputField2Name,
                clearable: true,
                'return-object': true
              },
              on: {}
            },
            memo: {
              component: 'v-text-field',
              model: 'memo',
              rules: 'max:100',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C08.ADD_DIALOG.memo,
                type: 'text',
              },
            },
          },
          dataMapper: {
            calculationMethod: 'eightCat',
            gasTypes: 'gasTypes',
            actTypes: 'actTypes',
            codesBuildingPurpose: 'codesBuildingPurpose',
            retailElectricCompanies: 'retailElectricCompanies'
          }
        },
      },
      S3_C13: {
        ACTIVITY_HEADERS: [
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C13.ACTIVITY_HEADERS.productActionName,
            value: "productActionName",
            align: "left",
            width: "15%",
          },
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C13.ACTIVITY_HEADERS.calculationMethodName,
            value: "calculationMethodName",
            align: "left",
            width: "25%",
          },
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C13.ACTIVITY_HEADERS.activityValues,
            value: "activityValues",
            align: "center",
            width: "35%",
          },
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C13.ACTIVITY_HEADERS.emissionUnit,
            value: "emissionUnit",
            align: "center",
            width: "15%",
          },
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C13.ACTIVITY_HEADERS.emissionVolume,
            value: "emissionVolume",
            align: "right",
            width: "10%",
            class: "success--text",
          },
          {
            text: "",
            value: "action",
            align: "right",
            width: "60px",
          },
        ],
        SUBCOLUMNS: [
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C13.SUBCOLUMNS.activityVolume1,
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            unit: 'activityVolumeUnit',
            show: (item) => item.calculationMethodCode === '0138',
            editable: true,
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C13.SUBCOLUMNS.activityVolume2,
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            unit: 'activityVolumeUnit',
            show: (item) => item.calculationMethodCode === '0139',
            editable: true
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C13.SUBCOLUMNS.activityVolume3,
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            unit: 'activityVolumeUnit',
            show: (item) => item.calculationMethodCode === '0140',
            editable: true
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C13.SUBCOLUMNS.distribution,
            model: 'distribution',
            rules: 'not_zero|fraction|fraction_determinator|fraction_numerator|fraction_value|fraction_value_2|max_digits:100',
            show: (item) => ['0139', '0140'].includes(item.calculationMethodCode),
            editable: true
          },
          {
            name: 'inputField1Name',
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => item.calculationMethodCode === '0901',
            editable: true
          },
          {
            name: 'inputField2Name',
            model: 'emissionIntensity',
            rules: 'required|length_comma:5|number_comma_full_width:true|numeric_right:10',
            show: (item) => item.calculationMethodCode === '0901',
            editable: true
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C13.SUBCOLUMNS.activityVolume4,
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => item.calculationMethodCode === '0902',
            editable: true,
            unit: 'activityVolumeUnit'
          },
        ],
        FORMULA: {
          '0139': ['activityVolume', '*', 'distribution', '*', 'emissionIntensity'],
          '0140': ['activityVolume', '*', 'distribution', '*', 'emissionIntensity'],
        },
        ADD_DIALOG: {
          getName: {
            '0138': ['calculationMethodName1', 'gasTypesName1', 'calculationTargetName1'],
            '0139': (item) => {
              let arr = ['calculationMethodName1', 'calculationTargetName1']
              // 代表値
              if (item.electricityUserCode === '01') {
                arr.push('electricityUserName')
              }
              let res = arr.map(i => item[i]).join('/')
              // 個別の電気事業者
              if (item.electricityUserCode === '02') {
                res += '/'
                res += item.retailElectricCompaniesElectricCompanyNo
                res += item.retailElectricCompaniesElectricCompanyName
              }
              return res
            },
            '0140': (item) => {
              let arr = ['calculationMethodName1', 'calculationTargetName1']
              // 代表値
              if (item.electricityUserCode === '01') {
                arr.push('electricityUserName')
              }
              let res = arr.map(i => item[i]).join('/')
              // 個別の電気事業者
              if (item.electricityUserCode === '02') {
                res += '/'
                res += item.retailElectricCompaniesElectricCompanyNo
                res += item.retailElectricCompaniesElectricCompanyName
              }
              return res
            },
            '0901': ['calculationMethodName1'],
            '0902': ['calculationMethodName1'],
          },
          formValues: {
            productActionName: '',
            calculationMethod: null,
            calculationTarget: null,
            inputField1Name: '',
            inputField2Name: '',
            memo: '',
            emissionIntensity: null,
            emissionUnit: null,
            gasTypes: null,
            electricityUser: null,
            distribution: '',
            retailElectricCompanies: null
          },
          formFields: {
            productActionName: {
              model: 'productActionName',
              rules: 'required|max:100',
              component: 'v-text-field',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C13.ADD_DIALOG.productActionName,
                type: 'text',
              },
            },
            calculationMethod: {
              model: 'calculationMethod',
              rules: 'required',
              component: 'v-select',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C13.ADD_DIALOG.calculationMethod,
                items: [],
                'item-text': 'name1',
                'return-object': true,
              },
            },
            gasTypes: {
              model: 'gasTypes',
              rules: 'required',
              component: 'v-select',
              show: (data) => data.formValues.calculationMethod?.code === '0138',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C13.ADD_DIALOG.gasTypes,
                items: [],
                'item-text': 'name1',
                'return-object': true,
              },
            },
            actTypes: {
              model: 'calculationTarget',
              rules: 'required',
              component: 'v-select',
              show: (data) => data.formValues.calculationMethod?.code === '0138' && data.formValues.gasTypes,
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C13.ADD_DIALOG.actTypes,
                items: [],
                'item-text': 'name1',
                'return-object': true,
              },
            },
            codesBuildingPurpose: {
              model: 'calculationTarget',
              rules: 'required',
              component: 'v-select',
              show: (data) => ['0139', '0140'].includes(data.formValues.calculationMethod?.code),
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C13.ADD_DIALOG.codesBuildingPurpose,
                items: [],
                'item-text': 'name1',
                'return-object': true,
              },
            },
            electricityUser: {
              model: 'electricityUser',
              rules: 'required',
              component: 'v-radio-group',
              guide: 'Q0014',
              show: (data) => ['0139', '0140'].includes(data.formValues.calculationMethod?.code),
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C13.ADD_DIALOG.electricityUser,
                'return-object': true
              },
              items: [
                {
                  component: 'v-radio',
                  props: {
                    label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C13.ADD_DIALOG.electricityUserItem1,
                    value: { name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C13.ADD_DIALOG.electricityUserItem1, code: '01' }
                  }
                },
                {
                  component: 'v-radio',
                  props: {
                    label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C13.ADD_DIALOG.electricityUserItem2,
                    value: { name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C13.ADD_DIALOG.electricityUserItem2, code: '02' }
                  }
                },
              ]
            },
            retailElectricCompanies: {
              model: 'retailElectricCompanies',
              rules: 'required',
              component: 'v-autocomplete',
              show: (data) => data.formValues.electricityUser?.code === '02',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C13.ADD_DIALOG.retailElectricCompanies,
                items: [],
                'item-text': (item) => item.electricCompanyNo + ' ' + item.electricCompanyName,
                'return-object': true,
              },
            },
            inputField1Name: {
              model: 'inputField1Name',
              rules: 'required|max:100',
              component: 'v-text-field',
              show: (data) => data.formValues.calculationMethod?.code === '0901',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C13.ADD_DIALOG.inputField1Name,
                type: 'text',
              },
            },
            inputField2Name: {
              model: 'inputField2Name',
              rules: 'required|max:100',
              component: 'v-combobox',
              show: (data) => data.formValues.calculationMethod?.code === '0901',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C13.ADD_DIALOG.inputField2Name,
                clearable: true,
                'return-object': true
              },
              on: {}
            },
            memo: {
              component: 'v-text-field',
              model: 'memo',
              rules: 'max:100',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C13.ADD_DIALOG.memo,
                type: 'text',
              },
            },
          },
          dataMapper: {
            calculationMethod: 'thirteenCat',
            gasTypes: 'gasTypes',
            actTypes: 'actTypes',
            codesBuildingPurpose: 'codesBuildingPurpose',
            retailElectricCompanies: 'retailElectricCompanies'
          }
        },
      },
      S3_C02: {
        ACTIVITY_HEADERS: [
          {
            text: "",
            value: "buttonEdit",
            align: "right",
            width: "60px",
            action: 'editRow'
          },
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C02.ACTIVITY_HEADERS.productActionName,
            value: "productActionName",
            align: "left",
            width: "15%",
          },
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C02.ACTIVITY_HEADERS.calculationMethodName,
            value: "calculationMethodName",
            align: "left",
            width: "25%",
          },
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C02.ACTIVITY_HEADERS.activityValues,
            value: "activityValues",
            align: "center",
            width: "25%",
          },
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C02.ACTIVITY_HEADERS.emissionUnit,
            value: "emissionUnit",
            align: "center",
            width: "25%",
          },
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C02.ACTIVITY_HEADERS.emissionVolume,
            value: "emissionVolume",
            align: "right",
            width: "10%",
            class: "success--text",
          },
          {
            text: "",
            value: "action",
            align: "right",
            width: "60px",
          },
        ],
        SUBCOLUMNS: [
          {
            name: 'inputField1Name',
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => ['0133', '0135', '0136'].includes(item.calculationMethodCode),
            editable: true
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C02.SUBCOLUMNS.activityVolume1,
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => item.calculationMethodCode === '0134',
            editable: true
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C02.SUBCOLUMNS.rawActivityVolume,
            model: 'rawActivityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => item.calculationMethodCode === '0134',
            editable: true
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C02.SUBCOLUMNS.rawTransportActivityVolume,
            model: 'rawTransportActivityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => item.calculationMethodCode === '0134',
            editable: true
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C02.SUBCOLUMNS.capitalWasteActivityVolume,
            model: 'capitalWasteActivityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => item.calculationMethodCode === '0134',
            editable: true
          },
          {
            name: 'inputField1Name',
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => item.calculationMethodCode === '0137',
            unit: 'activityVolumeUnit',
            editable: true,
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C02.SUBCOLUMNS.activityVolume2,
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => item.calculationMethodCode === '0902',
            editable: true,
            unit: 'activityVolumeUnit'
          },
        ],
        INTENSITY_COLUMNS: [
          {
            name: 'inputField2Name',
            model: 'emissionIntensity',
            rules: 'required|length_comma:5|number_comma_full_width:true|numeric_right:10',
            show: (item) => ['0133', '0135', '0136'].includes(item.calculationMethodCode),
            editable: true,
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C02.INTENSITY_COLUMNS.rawEmissionIntensity1,
            model: 'rawEmissionIntensity',
            rules: 'required|length_comma:5|number_comma_full_width:true|numeric_right:10',
            show: (item) => item.calculationMethodCode === '0134',
            disabled: () => true,
            style: 'opacity: 0',
            precision: 10
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C02.INTENSITY_COLUMNS.rawEmissionIntensity2,
            model: 'rawEmissionIntensity',
            rules: 'required|length_comma:5|number_comma_full_width:true|numeric_right:10',
            show: (item) => item.calculationMethodCode === '0134',
            editable: true,
            precision: 10
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C02.INTENSITY_COLUMNS.rawTransportEmissionIntensity,
            model: 'rawTransportEmissionIntensity',
            rules: 'required|length_comma:5|number_comma_full_width:true|numeric_right:10',
            show: (item) => item.calculationMethodCode === '0134',
            editable: true,
            precision: 10
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C02.INTENSITY_COLUMNS.capitalWasteEmissionIntensity,
            model: 'capitalWasteEmissionIntensity',
            rules: 'required|length_comma:5|number_comma_full_width:true|numeric_right:10',
            show: (item) => item.calculationMethodCode === '0134',
            editable: true,
            precision: 10
          },
          {
            name: 'inputField2Name',
            model: 'emissionIntensity',
            rules: 'required|length_comma:5|number_comma_full_width:true|numeric_right:10',
            show: (item) => item.calculationMethodCode === '0137',
            disabled: () => true,
            unit: 'emissionUnit',
            formatFixed: true
          },
        ],
        FORMULA: {
          '0134': ['activityVolume', '+', '(', 'rawActivityVolume', '*', 'rawEmissionIntensity', ')', '+', '(', 'rawTransportActivityVolume', '*', 'rawTransportEmissionIntensity', ')', '+', '(', 'capitalWasteActivityVolume', '*', 'capitalWasteEmissionIntensity', ')'],
        },
        ADD_DIALOG: {
          getName: {
            '0133': ['calculationMethodName1'],
            '0134': ['calculationMethodName1'],
            '0135': ['calculationMethodName1'],
            '0136': ['calculationMethodName1'],
            '0137': (item) => {
              if (item.capitalCode === '00-0000') return `${item.calculationMethodName1}/${item.capitalItem1}`
              return `${item.calculationMethodName1}/${item.capitalCode} ${item.capitalItem1}`
            },
            '0902': ['calculationMethodName1'],
          },
          formValues: {
            productActionName: '',
            calculationMethod: null,
            inputField1Name: '',
            inputField2Name: '',
            memo: '',
            emissionIntensity: null,
            emissionUnit: null,
            rawActivityVolume: 0,
            rawEmissionIntensity: 0,
            rawTransportActivityVolume: 0,
            rawTransportEmissionIntensity: 0,
            capitalWasteActivityVolume: 0,
            capitalWasteEmissionIntensity: 0,
            goodsCriteria: null,
            capital: null
          },
          formFields: {
            productActionName: {
              model: 'productActionName',
              rules: 'required|max:100',
              component: 'v-text-field',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C02.ADD_DIALOG.productActionName,
                type: 'text',
              },
            },
            calculationMethod: {
              model: 'calculationMethod',
              rules: 'required',
              component: 'v-select',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C02.ADD_DIALOG.calculationMethod,
                items: [],
                'item-text': 'name1',
                'return-object': true,
              },
            },
            goodsCriteria: {
              model: 'goodsCriteria',
              rules: 'required',
              component: 'v-select',
              show: (data) => data.formValues.calculationMethod?.code === '0164',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C02.ADD_DIALOG.goodsCriteria,
                items: [],
                'item-text': 'name1',
                'return-object': true,
              },
            },
            capitals: {
              model: 'capital',
              rules: 'required',
              component: 'v-autocomplete',
              show: (data) => data.formValues.goodsCriteria?.code === '0137',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C02.ADD_DIALOG.capitals,
                items: [],
                'item-text': (item) => {
                  if (item.code === '00-0000') return item.item1
                  return item.code + ' ' + item.item1
                },
                'return-object': true,
              },
            },
            memo: {
              component: 'v-text-field',
              model: 'memo',
              rules: 'max:100',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C02.ADD_DIALOG.memo,
                type: 'text',
              },
            },
          },
          dataMapper: {
            calculationMethod: 'catTwoCodes',
            goodsCriteria: 'capitalGoodStd',
            capitals: 'capitals'
          }
        },
      },
      S3_C03: {
        ACTIVITY_HEADERS: [
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C03.ACTIVITY_HEADERS.productActionName,
            value: "productActionName",
            align: "left",
            width: "10%",
          },
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C03.ACTIVITY_HEADERS.calculationTargetName,
            value: "calculationTargetName",
            align: "left",
            width: "15%",
          },
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C03.ACTIVITY_HEADERS.calculationMethodName,
            value: "calculationMethodName",
            align: "left",
            width: "20%",
          },
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C03.ACTIVITY_HEADERS.activityValues,
            value: "activityValues",
            align: "center",
            width: "30%",
          },
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C03.ACTIVITY_HEADERS.emissionUnit,
            value: "emissionUnit",
            align: "left",
            width: "15%",
          },
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C03.ACTIVITY_HEADERS.emissionVolume,
            value: "emissionVolume",
            align: "center",
            width: "10%",
            class: "success--text",
          },
          {
            text: "",
            value: "action",
            align: "right",
            width: "60px",
          },
        ],
        SUBCOLUMNS: [
          {
            name: 'inputField1Name',
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => ['0144', '0145'].includes(item.calculationMethodCode),
            editable: true,
            unit: (item) => item.activityVolumeUnit,
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C03.SUBCOLUMNS.activityVolume,
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => item.calculationMethodCode === '0902',
            editable: true,
            unit: 'activityVolumeUnit'
          },
        ],
        FORMULA: {},
        ADD_DIALOG: {
          getName: {
            '0144': ['calculationMethodName1'],
            '0145': ['calculationMethodName1'],
            '0902': ['calculationMethodName1'],
          },
          formValues: {
            productActionName: '',
            calculationMethod: null,
            inputField1Name: '',
            inputField2Name: '',
            memo: '',
            emissionIntensity: null,
            emissionUnit: null,
            calculationTarget: null,
            fuelUnits071: null,
            fuelUnits070: null,
          },
          formFields: {
            productActionName: {
              model: 'productActionName',
              rules: 'required|max:100',
              component: 'v-text-field',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C03.ADD_DIALOG.productActionName,
                type: 'text',
              },
            },
            calculationMethod: {
              model: 'calculationMethod',
              rules: 'required',
              component: 'v-select',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C03.ADD_DIALOG.calculationMethod,
                items: [],
                'item-text': 'name1',
                'return-object': true,
              },
            },
            fuelUnits071: {
              model: 'calculationTarget',
              rules: 'required',
              component: 'v-select',
              show: (data) => data.formValues.calculationMethod?.code === '0144',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C03.ADD_DIALOG.fuelUnits071,
                items: [],
                'item-text': 'name1',
                'return-object': true,
              },
            },
            fuelUnits070: {
              model: 'calculationTarget',
              rules: 'required',
              component: 'v-select',
              show: (data) => data.formValues.calculationMethod?.code === '0145',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C03.ADD_DIALOG.fuelUnits070,
                items: [],
                'item-text': 'name1',
                'return-object': true,
              },
            },
            memo: {
              component: 'v-text-field',
              model: 'memo',
              rules: 'max:100',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C03.ADD_DIALOG.memo,
                type: 'text',
              },
            },
          },
          dataMapper: {
            calculationMethod: 'threeCds',
            fuelUnits071: 'fuelUnits071',
            fuelUnits070: 'fuelUnits070',
          }
        },
      },
      S3_C11: {
        ACTIVITY_HEADERS: [
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C11.ACTIVITY_HEADERS.productActionName,
            value: "productActionName",
            align: "left",
            width: "100px"
          },
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C11.ACTIVITY_HEADERS.productionControlId,
            value: "productionControlId",
            align: "left",
            width: "10%",
          },
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C11.ACTIVITY_HEADERS.calculationMethodName,
            value: "calculationMethodName",
            align: "left",
            width: "25%",
          },
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C11.ACTIVITY_HEADERS.activityValues,
            value: "activityValues",
            align: "center",
            width: "55%",
          },
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C11.ACTIVITY_HEADERS.emissionVolume,
            value: "emissionVolume",
            align: "right",
            width: "10%",
            class: "success--text",
          },
          {
            text: "",
            value: "action",
            align: "right",
            width: "60px",
          },
        ],
        SUBCOLUMNS: [
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C11.SUBCOLUMNS.activityVolume1,
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => ['0155', '0156', '0160', '0161'].includes(item.calculationMethodCode),
            editable: true,
            unit: 'activityVolumeUnit'
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C11.SUBCOLUMNS.expectedLifetimeUsagePer,
            model: 'expectedLifetimeUsagePer',
            rules: 'required|number_max:3',
            show: (item) => item.calculationMethodCode === '0160',
            editable: true,
            unit: '%'
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C11.SUBCOLUMNS.saleCount1,
            model: 'saleCount',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => ['0155', '0156', '0160', '0161'].includes(item.calculationMethodCode),
            editable: true,
            unit: 'saleCountUnit'
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C11.SUBCOLUMNS.perLessonUsage1,
            model: 'perLessonUsage',
            rules: 'required|length_comma:5|number_comma_full_width:true|numeric_right:10',
            show: (item) => ['0155', '0160'].includes(item.calculationMethodCode),
            editable: true,
            unit: 'perLessonUsageUnit',
            precision: 10
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C11.SUBCOLUMNS.perLessonUsage2,
            model: 'perLessonUsage',
            rules: 'required|length_comma:5|number_comma_full_width:true|numeric_right:10',
            show: (item) => ['0156', '0161'].includes(item.calculationMethodCode),
            editable: true,
            unit: 'perLessonUsageUnit',
            precision: 10
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C11.SUBCOLUMNS.emissionIntensity1,
            model: 'emissionIntensity',
            rules: 'required|length_comma:5|number_comma_full_width:true|numeric_right:10',
            show: (item) => ['0155', '0156', '0160', '0161'].includes(item.calculationMethodCode),
            editable: true
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C11.SUBCOLUMNS.activityVolume2,
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => ['0157', '0162'].includes(item.calculationMethodCode),
            editable: true
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C11.SUBCOLUMNS.gwp,
            model: 'gwp',
            rules: 'required|number_max:6',
            show: (item) => ['0157', '0162'].includes(item.calculationMethodCode),
            editable: true,
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C11.SUBCOLUMNS.activityVolume3,
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => item.calculationMethodCode === '0158',
            editable: true,
            unit: 'activityVolumeUnit'
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C11.SUBCOLUMNS.emissionIntensity2,
            model: 'emissionIntensity',
            rules: 'required|length_comma:5|number_comma_full_width:true|numeric_right:10',
            show: (item) => item.calculationMethodCode === '0158',
            disabled: () => true,
            unit: 'emissionUnit'
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C11.SUBCOLUMNS.activityVolume4,
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => item.calculationMethodCode === '0159',
            editable: true,
            unit: 'activityVolumeUnit'
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C11.SUBCOLUMNS.saleCount2,
            model: 'saleCount',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => item.calculationMethodCode === '0159',
            editable: true,
            unit: 'saleCountUnit'
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C11.SUBCOLUMNS.emissionLifetimeUse,
            model: 'emissionLifetimeUse',
            rules: 'required|length_comma:5|number_comma_full_width:true|numeric_right:10',
            show: (item) => item.calculationMethodCode === '0159',
            editable: true,
            unit: '%',
            converter: 0.01,
            precision: 10
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C11.SUBCOLUMNS.emissionIntensity3,
            model: 'emissionIntensity',
            rules: 'required|length_comma:5|number_comma_full_width:true|numeric_right:10',
            show: (item) => item.calculationMethodCode === '0159',
            editable: true,
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C11.SUBCOLUMNS.activityVolume5,
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => item.calculationMethodCode === '0902',
            editable: true,
            unit: 'activityVolumeUnit'
          },
        ],
        FORMULA: {
          '0155': ['activityVolume', '*', 'saleCount', '*', 'perLessonUsage', '*', 'emissionIntensity'],
          '0156': ['activityVolume', '*', 'saleCount', '*', 'perLessonUsage', '*', 'emissionIntensity'],
          '0157': ['activityVolume', '*', 'gwp'],
          '0158': ['activityVolume', '*', 'emissionIntensity'],
          '0159': ['activityVolume', '*', 'saleCount', '*', 'emissionLifetimeUse', '*', 'emissionIntensity'],
          '0162': ['activityVolume', '*', 'gwp'],
          '0160': ['activityVolume', '*', 'expectedLifetimeUsagePer', '*', '0.01', '*', 'saleCount', '*', 'perLessonUsage', '*', 'emissionIntensity'],
          '0161': ['activityVolume', '*', 'saleCount', '*', 'perLessonUsage', '*', 'emissionIntensity'],
        },
        ADD_DIALOG: {
          getName: {
            '0155': ['calculationMethodName1'],
            '0156': ['calculationMethodName1'],
            '0157': ['calculationMethodName1'],
            '0158': ['calculationMethodName1', 'calculationTargetName1'],
            '0159': ['calculationMethodName1'],
            '0160': ['calculationMethodName1'],
            '0161': ['calculationMethodName1'],
            '0162': ['calculationMethodName1'],
            '0902': ['calculationMethodName1'],
          },
          formValues: {
            productActionName: '',
            calculationMethod: null,
            calculationTarget: null,
            inputField1Name: '',
            inputField2Name: '',
            memo: '',
            emissionIntensity: null,
            emissionUnit: null,
            // 販売数
            saleCount: 0,
            // 販売数単位
            saleCountUnit: null,
            // 使用1回あたりの使用量
            perLessonUsage: 0,
            // 使用1回あたりの使用量単位
            perLessonUsageUnit: null,
            // 生涯使用の排出量
            emissionLifetimeUse: 0,
            // 想定生涯使用回数の割合
            expectedLifetimeUsagePer: null
          },
          formFields: {
            productActionName: {
              model: 'productActionName',
              rules: 'required|max:100',
              component: 'v-combobox',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C11.ADD_DIALOG.productActionName,
                type: 'text',
                items: [],
                clearable: true
              },
              on: {}
            },
            productionControlId: {
              model: 'productionControlId',
              rules: 'max:20',
              component: 'v-select',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C11.ADD_DIALOG.productionControlId,
                type: 'text',
                items: [],
                'item-text': 'productionControlId',
                'return-object': true,
                clearable: true
              },
            },
            calculationMethod: {
              model: 'calculationMethod',
              rules: 'required',
              component: 'v-select',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C11.ADD_DIALOG.calculationMethod,
                items: [],
                'item-text': (i) => i.name1,
                'return-object': true,
                'item-value': (i) => ({ ...i })
              },
            },
            calculationTarget: {
              model: 'calculationTarget',
              rules: 'required',
              component: 'v-select',
              show: (data) => data.formValues.calculationMethod?.code === '0158',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C11.ADD_DIALOG.calculationTarget,
                items: [],
                'item-text': (i) => i.name1,
                'return-object': true,
              },
            },
            inputField1Name: {
              model: 'inputField1Name',
              rules: 'required|max:100',
              component: 'v-text-field',
              show: (data) => data.formValues.calculationMethod?.code === '0901',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C11.ADD_DIALOG.inputField1Name,
                type: 'text',
              },
            },
            inputField2Name: {
              model: 'inputField2Name',
              rules: 'required|max:100',
              component: 'v-text-field',
              show: (data) => data.formValues.calculationMethod?.code === '0901',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C11.ADD_DIALOG.inputField2Name,
                type: 'text',
              },
            },
            memo: {
              component: 'v-text-field',
              model: 'memo',
              rules: 'max:100',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C11.ADD_DIALOG.memo,
                type: 'text',
              },
            },
          },
          dataMapper: {
            calculationMethod: 'elevens',
            calculationTarget: 'fuelTypes'
          }
        },
      },
      S3_C12: {
        ACTIVITY_HEADERS: [
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C12.ACTIVITY_HEADERS.productActionName,
            value: "productActionName",
            align: "left",
            width: "12%",
          },
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C12.ACTIVITY_HEADERS.productionControlId,
            value: "productionControlId",
            align: "left",
            width: "10%",
          },
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C12.ACTIVITY_HEADERS.calculationMethodName,
            value: "calculationMethodName",
            align: "left",
            width: "22%",
          },
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C12.ACTIVITY_HEADERS.calculationTargetName,
            value: "calculationTargetName",
            align: "left",
            width: "18%",
          },
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C12.ACTIVITY_HEADERS.activityValues,
            value: "activityValues",
            align: "center",
            width: "30%",
          },
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C12.ACTIVITY_HEADERS.emissionUnit,
            value: "emissionUnit",
            align: "center",
            width: "15%",
          },
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C12.ACTIVITY_HEADERS.emissionVolume,
            value: "emissionVolume",
            align: "right",
            width: "10%",
            class: "success--text",
          },
          {
            text: "",
            value: "action",
            align: "right",
            width: "60px",
          },
        ],
        SUBCOLUMNS: [
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C12.SUBCOLUMNS.activityVolume1,
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            unit: 'activityVolumeUnit',
            show: (item) => ['0123', '0124'].includes(item.calculationMethodCode) && item.calculationTargetCodeId !== '080',
            showDetailButton: true
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C12.SUBCOLUMNS.activityVolume2,
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            unit: 'activityVolumeUnit',
            show: (item) => item.calculationTargetCodeId === '080',
            showDetailButton: true
          },
          {
            name: 'inputField1Name',
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => item.calculationMethodCode === '0901',
            editable: true
          },
          {
            name: 'inputField2Name',
            model: 'emissionIntensity',
            rules: 'required|length_comma:5|number_comma_full_width:true|numeric_right:10',
            show: (item) => item.calculationMethodCode === '0901',
            editable: true
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C12.SUBCOLUMNS.activityVolume3,
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => item.calculationMethodCode === '0902',
            editable: true,
            unit: 'activityVolumeUnit'
          },
        ],
        FORMULA: {},
        ADD_DIALOG: {
          getName: {
            '0123': ['calcMethodsName1', 'transNecMethodsName1'],
            '0124': ['calcMethodsName1', 'transNecMethodsName1'],
            '0901': ['calculationMethodName1'],
            '0902': ['calculationMethodName1'],
          },
          formValues: {
            productActionName: '',
            calculationMethod: null,
            calculationTarget: null,
            inputField1Name: '',
            inputField2Name: '',
            memo: '',
            emissionIntensity: null,
            emissionUnit: null,
            calcMethods: null,
            transNecMethods: null
          },
          formFields: {
            productActionName: {
              model: 'productActionName',
              rules: 'required|max:100',
              component: 'v-combobox',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C12.ADD_DIALOG.productActionName,
                items: [],
                clearable: true
              },
              on: {}
            },
            productionControlId: {
              model: 'productionControlId',
              rules: 'max:20',
              component: 'v-select',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C12.ADD_DIALOG.productionControlId,
                items: [],
                'item-text': 'productionControlId',
                'return-object': true,
                clearable: true
              },
            },
            calculationMethod: {
              model: 'calculationMethod',
              rules: 'required',
              component: 'v-select',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C12.ADD_DIALOG.calculationMethod,
                items: [],
                'item-text': 'name1',
                'return-object': true,
              },
            },
            calcMethods: {
              model: 'calcMethods',
              rules: 'required',
              component: 'v-select',
              show: (data) => data.formValues.calculationMethod?.code === '0118',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C12.ADD_DIALOG.calcMethods,
                items: [],
                'item-text': 'name1',
                'return-object': true,
              },
            },
            transNecMethods: {
              model: 'transNecMethods',
              rules: 'required',
              component: 'v-radio-group',
              show: (data) => data.formValues.calculationMethod?.code === '0118',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C12.ADD_DIALOG.transNecMethods,
                'return-object': true,
              },
              items: []
            },
            // dummyTrashType: {
            //   model: 'calculationTarget',
            //   rules: 'required',
            //   component: 'v-select',
            //   show: (data) => data.formValues.calculationMethod?.code === '0118' && !data.formValues.calcMethods,
            //   props: {
            //     label: '廃棄物の種類',
            //     items: [],
            //     'item-text': 'name1',
            //     'return-object': true,
            //   },
            // },
            secondTrashType: {
              model: 'calculationTarget',
              rules: 'required',
              component: 'v-select',
              show: (data) => data.formValues.calcMethods?.code === '0119',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C12.ADD_DIALOG.secondTrashType,
                items: [],
                'item-text': 'name1',
                'return-object': true,
              },
            },
            thirdTrashType: {
              model: 'calculationTarget',
              rules: 'required',
              component: 'v-select',
              show: (data) => data.formValues.calcMethods?.code === '0120',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C12.ADD_DIALOG.thirdTrashType,
                items: [],
                'item-text': 'name1',
                'return-object': true,
              },
            },
            firstTrashType: {
              model: 'calculationTarget',
              rules: 'required',
              component: 'v-select',
              show: (data) => data.formValues.calcMethods?.code === '0121',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C12.ADD_DIALOG.firstTrashType,
                items: [],
                'item-text': 'name1',
                'return-object': true,
              },
            },
            fourthTrashType: {
              model: 'calculationTarget',
              rules: 'required',
              component: 'v-select',
              show: (data) => data.formValues.calcMethods?.code === '0122',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C12.ADD_DIALOG.fourthTrashType,
                items: [],
                'item-text': 'name1',
                'return-object': true,
              },
            },
            inputField1Name: {
              model: 'inputField1Name',
              rules: 'required|max:100',
              component: 'v-text-field',
              show: (data) => data.formValues.calculationMethod?.code === '0901',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C12.ADD_DIALOG.inputField1Name,
                type: 'text',
              },
            },
            inputField2Name: {
              model: 'inputField2Name',
              rules: 'required|max:100',
              component: 'v-combobox',
              show: (data) => data.formValues.calculationMethod?.code === '0901',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C12.ADD_DIALOG.inputField2Name,
                items: [],
                clearable: true,
                'return-object': true
              },
              on: {}
            },
            memo: {
              component: 'v-text-field',
              model: 'memo',
              rules: 'max:100',
              show: (data) => ['0901', '0902'].includes(data.formValues.calculationMethod?.code),
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C12.ADD_DIALOG.memo,
                type: 'text',
              },
            },
          },
          dataMapper: {
            calculationMethod: 'twelveCat',
            calcMethods: 'calcMethods',
            firstTrashType: 'firstTrashType',
            secondTrashType: 'secondTrashType',
            thirdTrashType: 'thirdTrashType',
            fourthTrashType: 'fourthTrashType',
          }
        },
        DETAIL_DIALOG: {
          '0123': {
            headers: [
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C12.DETAIL_DIALOG['0123'].createDate,
                value: 'createDate',
                width: '8%',
                align: 'left'
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C12.DETAIL_DIALOG['0123'].createName,
                value: 'createName',
                width: '8%',
                align: 'left'
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C12.DETAIL_DIALOG['0123'].activityVolume,
                value: 'activityVolume',
                width: '15%',
                align: 'left',
                unit: 'activityVolumeUnit',
                default: null
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C12.DETAIL_DIALOG['0123'].evidence,
                value: 'evidence',
                width: '30%',
                align: 'left',
                default: ''
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C12.DETAIL_DIALOG['0123'].activityDetailMemo,
                value: 'activityDetailMemo',
                width: '26%',
                align: 'left',
                default: ''
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C12.DETAIL_DIALOG['0123'].remove,
                value: 'remove',
                width: '9%',
                align: 'left',
                default: false
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C12.DETAIL_DIALOG['0123'].action,
                value: 'action',
                width: '120px',
                align: 'left',
                default: false,
                guide: 'Q0018'
              },
            ],
          },
          '0124': {
            headers: [
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C12.DETAIL_DIALOG['0124'].createDate,
                value: 'createDate',
                width: '8%',
                align: 'left'
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C12.DETAIL_DIALOG['0124'].createName,
                value: 'createName',
                width: '8%',
                align: 'left'
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C12.DETAIL_DIALOG['0124'].activityVolume,
                value: 'activityVolume',
                width: '15%',
                align: 'left',
                unit: 't',
                default: null
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C12.DETAIL_DIALOG['0124'].evidence,
                value: 'evidence',
                width: '30%',
                align: 'left',
                default: ''
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C12.DETAIL_DIALOG['0124'].activityDetailMemo,
                value: 'activityDetailMemo',
                width: '26%',
                align: 'left',
                default: ''
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C12.DETAIL_DIALOG['0124'].remove,
                value: 'remove',
                width: '9%',
                align: 'left',
                default: false
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C12.DETAIL_DIALOG['0124'].action,
                value: 'action',
                width: '120px',
                align: 'left',
                default: false,
                guide: 'Q0018'
              },
            ],
          },
          '0121': {
            headers: [
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C12.DETAIL_DIALOG['0121'].createDate,
                value: 'createDate',
                width: '8%',
                align: 'left'
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C12.DETAIL_DIALOG['0121'].createName,
                value: 'createName',
                width: '8%',
                align: 'left'
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C12.DETAIL_DIALOG['0121'].activityVolume,
                value: 'activityVolume',
                width: '15%',
                align: 'left',
                unit: 'activityVolumeUnit',
                default: null
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C12.DETAIL_DIALOG['0121'].evidence,
                value: 'evidence',
                width: '30%',
                align: 'left',
                default: ''
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C12.DETAIL_DIALOG['0121'].activityDetailMemo,
                value: 'activityDetailMemo',
                width: '26%',
                align: 'left',
                default: ''
              },
              {
                text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C12.DETAIL_DIALOG['0121'].remove
                , value: 'remove',
                width: '9%',
                align: 'left',
                default: false
              },
            ],
          },
        },
        //算定方法ー廃棄量の処理方法
        CALCULATION_DISPOSAL_METHOD_CODE: {
          //焼却処理
          INCINERATION: {
            code: "0119",
            item2: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C12.CALCULATION_DISPOSAL_METHOD_CODE.INCINERATION
          },
          //埋立処理
          LANDFILL_DISPOSAL: {
            code: "0120",
            item2: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C12.CALCULATION_DISPOSAL_METHOD_CODE.LANDFILL_DISPOSAL
          },
          //リサイクル
          RECYLING: {
            code: "0121",
            item2: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C12.CALCULATION_DISPOSAL_METHOD_CODE.RECYLING
          },
          //処理方法不明
          UNKNOWN_PROCESSING_METHOD: {
            code: "0122",
            item2: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C12.CALCULATION_DISPOSAL_METHOD_CODE.UNKNOWN_PROCESSING_METHOD
          }
        }
      },
      S3_C15: { GUIDE: 'Q0012' },
    },
    SCOPE_THREE_DETAIL_TABLE_HEADER: {
      // 算定方法が「燃料法」の場合
      FUEL_METHOD: [
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_DETAIL_TABLE_HEADER.FUEL_METHOD.createDate,
          value: "createDate",
          width: "8%",
          align: "left"
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_DETAIL_TABLE_HEADER.FUEL_METHOD.createName,
          value: "createName",
          width: "8%",
          align: "left"
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_DETAIL_TABLE_HEADER.FUEL_METHOD.value,
          value: "value",
          width: "15%",
          align: "left",
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_DETAIL_TABLE_HEADER.FUEL_METHOD.shipperDistribution,
          value: "shipperDistribution",
          width: "10%",
          align: "left",
          remark: "Q0005",
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_DETAIL_TABLE_HEADER.FUEL_METHOD.evidence,
          value: "evidence",
          width: "30%",
          align: "left",
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_DETAIL_TABLE_HEADER.FUEL_METHOD.activityDetailMemo,
          value: "activityDetailMemo",
          width: "26%",
          align: "left",
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_DETAIL_TABLE_HEADER.FUEL_METHOD.remove,
          value: "remove",
          width: "9%",
          align: "left"
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_DETAIL_TABLE_HEADER.FUEL_METHOD.action,
          value: 'action',
          width: '120px',
          align: 'left',
          default: false,
          guide: 'Q0018'
        },
      ],
      // 算定方法が「燃費法」の場合
      FUEL_CONSUMPTION_METHOD: [
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_DETAIL_TABLE_HEADER.FUEL_CONSUMPTION_METHOD.createDate,
          value: "createDate",
          width: "8%",
          align: "left"
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_DETAIL_TABLE_HEADER.FUEL_CONSUMPTION_METHOD.createName,
          value: "createName",
          width: "8%",
          align: "left"
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_DETAIL_TABLE_HEADER.FUEL_CONSUMPTION_METHOD.transportDistance,
          value: "transportDistance",
          width: "15%",
          align: "left",
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_DETAIL_TABLE_HEADER.FUEL_CONSUMPTION_METHOD.shipperDistribution,
          value: "shipperDistribution",
          width: "10%",
          align: "left",
          remark: "Q0005",
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_DETAIL_TABLE_HEADER.FUEL_CONSUMPTION_METHOD.evidence,
          value: "evidence",
          width: "30%",
          align: "left",
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_DETAIL_TABLE_HEADER.FUEL_CONSUMPTION_METHOD.activityDetailMemo,
          value: "activityDetailMemo",
          width: "26%",
          align: "left",
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_DETAIL_TABLE_HEADER.FUEL_CONSUMPTION_METHOD.remove,
          value: "remove",
          width: "56px",
          align: "left"
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_DETAIL_TABLE_HEADER.FUEL_CONSUMPTION_METHOD.action,
          value: 'action',
          width: '120px',
          align: 'left',
          default: false,
          guide: 'Q0018'
        },
      ],
      // 算定方法が「トンキロ法」の場合
      TON_KILOMETER_METHOD: [
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_DETAIL_TABLE_HEADER.TON_KILOMETER_METHOD.createDate,
          value: "createDate",
          width: "8%",
          align: "left"
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_DETAIL_TABLE_HEADER.TON_KILOMETER_METHOD.createName,
          value: "createName",
          width: "8%",
          align: "left"
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_DETAIL_TABLE_HEADER.TON_KILOMETER_METHOD.value,
          value: "value",
          width: "15%",
          align: "left",
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_DETAIL_TABLE_HEADER.TON_KILOMETER_METHOD.shipperDistribution,
          value: "shipperDistribution",
          width: "10%",
          align: "left",
          remark: "Q0005",
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_DETAIL_TABLE_HEADER.TON_KILOMETER_METHOD.transportDistance,
          value: "transportDistance",
          width: "15%",
          align: "left",
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_DETAIL_TABLE_HEADER.TON_KILOMETER_METHOD.evidence,
          value: "evidence",
          width: "30%",
          align: "left",
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_DETAIL_TABLE_HEADER.TON_KILOMETER_METHOD.activityDetailMemo,
          value: "activityDetailMemo",
          width: "26%",
          align: "left",
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_DETAIL_TABLE_HEADER.TON_KILOMETER_METHOD.remove,
          value: "remove",
          width: "56px",
          align: "left"
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_DETAIL_TABLE_HEADER.TON_KILOMETER_METHOD.action,
          value: 'action',
          width: '120px',
          align: 'left',
          default: false,
          guide: 'Q0018'
        },
      ],
      // 拠点(荷役、保管、販売)のエネルギー使用に伴う排出量
      BASIC_EMISSION_COEFFICIENT: [
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_DETAIL_TABLE_HEADER.BASIC_EMISSION_COEFFICIENT.createDate,
          value: "createDate",
          width: "8%",
          align: "left"
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_DETAIL_TABLE_HEADER.BASIC_EMISSION_COEFFICIENT.createName,
          value: "createName",
          width: "8%",
          align: "left"
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_DETAIL_TABLE_HEADER.BASIC_EMISSION_COEFFICIENT.value,
          value: "value",
          width: "15%",
          align: "left",
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_DETAIL_TABLE_HEADER.BASIC_EMISSION_COEFFICIENT.shipperDistribution,
          value: "shipperDistribution",
          width: "10%",
          align: "left",
          remark: "Q0005",
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_DETAIL_TABLE_HEADER.BASIC_EMISSION_COEFFICIENT.evidence,
          value: "evidence",
          width: "30%",
          align: "left",
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_DETAIL_TABLE_HEADER.BASIC_EMISSION_COEFFICIENT.activityDetailMemo,
          value: "activityDetailMemo",
          width: "26%",
          align: "left",
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_DETAIL_TABLE_HEADER.BASIC_EMISSION_COEFFICIENT.remove,
          value: "remove",
          width: "56px",
          align: "left"
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_DETAIL_TABLE_HEADER.BASIC_EMISSION_COEFFICIENT.action,
          value: 'action',
          width: '120px',
          align: 'left',
          default: false,
          guide: 'Q0018'
        },
      ],
      // 入力方法が「整備時の充填量・回収量から算定」の場合
      FILLING_RECOVERY: [
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_DETAIL_TABLE_HEADER.FILLING_RECOVERY.createDate,
          value: "createDate",
          width: "8%",
          align: "left"
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_DETAIL_TABLE_HEADER.FILLING_RECOVERY.createName,
          value: "createName",
          width: "8%",
          align: "left"
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_DETAIL_TABLE_HEADER.FILLING_RECOVERY.value,
          value: "value",
          width: "15%",
          align: "left",
          remark:
            "Q0002",
          rule: 'required|number_max:10'
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_DETAIL_TABLE_HEADER.FILLING_RECOVERY.recoveryVolume,
          value: "recoveryVolume",
          width: "10%",
          align: "left",
          remark:
            "Q0002",
          rule: 'required|number_max:13'
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_DETAIL_TABLE_HEADER.FILLING_RECOVERY.evidence,
          value: "evidence",
          width: "30%",
          align: "left",
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_DETAIL_TABLE_HEADER.FILLING_RECOVERY.activityDetailMemo,
          value: "activityDetailMemo",
          width: "26%",
          align: "left",
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_DETAIL_TABLE_HEADER.FILLING_RECOVERY.remove,
          value: "remove",
          width: "56px",
          align: "left"
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_DETAIL_TABLE_HEADER.FILLING_RECOVERY.action,
          value: 'action',
          width: '120px',
          align: 'left',
          default: false,
          guide: 'Q0018'
        },
      ],
      // 入力方法が「漏えい率から算定」の場合
      LEAKAGE_RATE: [
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_DETAIL_TABLE_HEADER.LEAKAGE_RATE.createDate,
          value: "createDate",
          width: "8%",
          align: "left"
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_DETAIL_TABLE_HEADER.LEAKAGE_RATE.createName,
          value: "createName",
          width: "8%",
          align: "left"
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_DETAIL_TABLE_HEADER.LEAKAGE_RATE.value,
          value: "value",
          width: "15%",
          align: "left",
          remark:
            "Q0002",
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_DETAIL_TABLE_HEADER.LEAKAGE_RATE.recoveryVolume,
          value: "recoveryVolume",
          width: "10%",
          align: "left",
          remark:
            "Q0002",
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_DETAIL_TABLE_HEADER.LEAKAGE_RATE.evidence,
          value: "evidence",
          width: "30%",
          align: "left",
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_DETAIL_TABLE_HEADER.LEAKAGE_RATE.activityDetailMemo,
          value: "activityDetailMemo",
          width: "26%",
          align: "left",
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_DETAIL_TABLE_HEADER.LEAKAGE_RATE.remove,
          value: "remove",
          width: "56px",
          align: "left"
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_DETAIL_TABLE_HEADER.LEAKAGE_RATE.action,
          value: 'action',
          width: '120px',
          align: 'left',
          default: false,
          guide: 'Q0018'
        },
      ],
      DB5: [
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_DETAIL_TABLE_HEADER.DB5.createDate,
          value: "createDate",
          width: "8%",
          align: "left"
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_DETAIL_TABLE_HEADER.DB5.createName,
          value: "createName",
          width: "8%",
          align: "left"
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_DETAIL_TABLE_HEADER.DB5.value,
          value: "value",
          width: "15%",
          align: "left",
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_DETAIL_TABLE_HEADER.DB5.evidence,
          value: "evidence",
          width: "30%",
          align: "left",
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_DETAIL_TABLE_HEADER.DB5.activityDetailMemo,
          value: "activityDetailMemo",
          width: "26%",
          align: "left",
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_DETAIL_TABLE_HEADER.DB5.remove,
          value: "remove",
          width: "9%",
          align: "left"
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_DETAIL_TABLE_HEADER.DB5.action,
          value: 'action',
          width: '120px',
          align: 'left',
          default: false,
          guide: 'Q0018'
        },
      ],

    },
    // 製品管理情報入力_テーブルヘッダー
    PRODUCT_ACTIVITY_TABLE_HEADER: {
      procurement: [
        {
          text: context.app.i18n.messages[context.app.i18n.locale].PRODUCT_ACTIVITY_TABLE_HEADER.procurement.productName,
          value: 'productName',
          width: '20%',
          align: 'left',
          rules: '',
          component: 'v-textarea',
          class: 'pl-4',
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].PRODUCT_ACTIVITY_TABLE_HEADER.procurement.productionControlId,
          value: 'productionControlId',
          align: 'left',
          rules: '',
          component: 'v-textarea',
          disabled: () => true,
          width: '10%',
          class: 'pl-0',
        },
        {
          text: '',
          value: 'action_edit',
          width: '40px',
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].PRODUCT_ACTIVITY_TABLE_HEADER.procurement.activityMonth,
          value: 'activityMonth',
          width: '100px',
          align: 'left',
          // rules: (item) => `${!!Number(item.activityVolume) ? 'required' : ''}`,
          component: 'v-date-picker',
          disabled: (item) => item.patternValue !== "02",
          props: {
            type: 'month',
            color: 'primary',
            locale: 'jp-ja'
          },
          class: 'pl-0',
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].PRODUCT_ACTIVITY_TABLE_HEADER.procurement.productActionName,
          value: 'productActionName',
          width: '20%',
          align: 'left',
          rules: 'max:100',
          component: 'v-text-field',
          disabled: (item) => item.patternValue !== "02",
          // props: {
          //   'item-text': 'productActionName',
          //   'item-value': 'seqNo',
          //   'return-object': true
          // },
          class: 'pl-0',
          cellClass: 'text-left'
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].PRODUCT_ACTIVITY_TABLE_HEADER.procurement.activityVolume,
          value: 'activityVolume',
          width: '155px',
          align: 'right',
          rules: 'length_comma:10|number_comma_full_width:true|numeric_right:3',
          component: 'custom-activityVolume',
          disabled: (item) => !item.patternValue,
          class: 'pl-0',
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].PRODUCT_ACTIVITY_TABLE_HEADER.procurement.activityVolumeUnit,
          value: 'activityVolumeUnit',
          width: '5%',
          align: 'right',
          rules: 'max:20',
          component: 'custom-activityVolume',
          disabled: (item) => item.patternValue !== "02",
          class: 'pl-0',
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].PRODUCT_ACTIVITY_TABLE_HEADER.procurement.emissionVolume,
          value: 'emissionVolume',
          width: '15%',
          align: 'right',
          rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
          component: 'text-field-emv',
          disabled: (item) => !item.patternValue,
          class: 'pl-0',
          unit: 't-CO2',
        },
        {
          text: '',
          value: 'action_2',
          width: '40px',
        },
        {
          text: '',
          title: context.app.i18n.messages[context.app.i18n.locale].PRODUCT_ACTIVITY_TABLE_HEADER.procurement.action_3,
          value: 'action_3',
          width: '100px',
        },
      ],
      manufacturing: [
        {
          text: context.app.i18n.messages[context.app.i18n.locale].PRODUCT_ACTIVITY_TABLE_HEADER.manufacturing.productName,
          value: 'productName',
          width: '20%',
          align: 'left',
          rules: '',
          component: 'v-textarea',
          class: 'pl-4',
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].PRODUCT_ACTIVITY_TABLE_HEADER.manufacturing.productionControlId,
          value: 'productionControlId',
          align: 'left',
          rules: '',
          component: 'v-textarea',
          disabled: () => true,
          width: '10%',
          class: 'pl-0',
        },
        {
          text: '',
          value: 'action_edit',
          width: '40px',
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].PRODUCT_ACTIVITY_TABLE_HEADER.manufacturing.activityMonth,
          value: 'activityMonth',
          width: '100px',
          align: 'left',
          // rules: (item) => `${!!Number(item.activityVolume) ? 'required' : ''}`,
          component: 'v-date-picker',
          disabled: (item) => item.patternValue !== "02",
          props: {
            type: 'month',
            color: 'primary',
            locale: 'jp-ja'
          },
          class: 'pl-0',
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].PRODUCT_ACTIVITY_TABLE_HEADER.manufacturing.productActionName,
          value: 'productActionName',
          width: '20%',
          align: 'left',
          // rules: 'required|max:100',
          component: 'v-text-field',
          disabled: (item) => item.patternValue !== "02",
          // props: {
          //   'item-text': 'productActionName',
          //   'item-value': 'seqNo',
          //   'return-object': true
          // },
          class: 'pl-0',
          cellClass: 'text-left'
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].PRODUCT_ACTIVITY_TABLE_HEADER.manufacturing.activityVolume,
          value: 'activityVolume',
          width: '155px',
          align: 'right',
          rules: 'length_comma:10|number_comma_full_width:true|numeric_right:3',
          component: 'text-field',
          disabled: (item) => !item.patternValue,
          class: 'pl-0',
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].PRODUCT_ACTIVITY_TABLE_HEADER.procurement.activityVolumeUnit,
          value: 'activityVolumeUnit',
          width: '5%',
          align: 'right',
          rules: 'max:20',
          component: 'custom-activityVolume',
          disabled: (item) => item.patternValue !== "02",
          class: 'pl-0',
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].PRODUCT_ACTIVITY_TABLE_HEADER.manufacturing.emissionVolume,
          value: 'emissionVolume',
          width: '15%',
          align: 'right',
          rules: 'length_comma:10|number_comma_full_width:true|numeric_right:3',
          component: 'text-field-emv',
          disabled: (item) => !item.patternValue,
          class: 'pl-0',
          unit: 't-CO2',
        },
        {
          text: '',
          value: 'action_2',
          width: '40px',
        },
        {
          text: '',
          title: context.app.i18n.messages[context.app.i18n.locale].PRODUCT_ACTIVITY_TABLE_HEADER.manufacturing.action_3_title1,
          title2: context.app.i18n.messages[context.app.i18n.locale].PRODUCT_ACTIVITY_TABLE_HEADER.manufacturing.action_3_title2,
          value: 'action_3',
          width: '100px',
        },
      ],
      distribution: [
        {
          text: context.app.i18n.messages[context.app.i18n.locale].PRODUCT_ACTIVITY_TABLE_HEADER.distribution.productName,
          value: 'productName',
          width: '20%',
          align: 'left',
          rules: '',
          component: 'v-textarea',
          class: 'pl-4',
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].PRODUCT_ACTIVITY_TABLE_HEADER.distribution.productionControlId,
          value: 'productionControlId',
          align: 'left',
          rules: '',
          component: 'v-textarea',
          disabled: () => true,
          width: '10%',
          class: 'pl-0',
        },
        {
          text: '',
          value: 'action_edit',
          width: '40px',
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].PRODUCT_ACTIVITY_TABLE_HEADER.distribution.activityMonth,
          value: 'activityMonth',
          width: '100px',
          align: 'left',
          // rules: (item) => `${!!Number(item.activityVolume) ? 'required' : ''}`,
          component: 'v-date-picker',
          disabled: (item) => item.patternValue !== "02",
          props: {
            type: 'month',
            color: 'primary',
            locale: 'jp-ja'
          },
          class: 'pl-0',
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].PRODUCT_ACTIVITY_TABLE_HEADER.distribution.productActionName,
          value: 'productActionName',
          width: '20%',
          align: 'left',
          rules: 'max:100',
          component: 'v-text-field',
          disabled: (item) => item.patternValue !== "02",
          // props: {
          //   'item-text': 'productActionName',
          //   'item-value': 'seqNo',
          //   'return-object': true
          // },
          class: 'pl-0',
          cellClass: 'text-left'
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].PRODUCT_ACTIVITY_TABLE_HEADER.distribution.activityVolume,
          value: 'activityVolume',
          width: '155px',
          align: 'right',
          rules: 'length_comma:10|number_comma_full_width:true|numeric_right:3',
          component: 'text-field',
          disabled: (item) => !item.patternValue,
          class: 'pl-0',
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].PRODUCT_ACTIVITY_TABLE_HEADER.procurement.activityVolumeUnit,
          value: 'activityVolumeUnit',
          width: '5%',
          align: 'right',
          rules: 'max:20',
          component: 'custom-activityVolume',
          disabled: (item) => item.patternValue !== "02",
          class: 'pl-0',
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].PRODUCT_ACTIVITY_TABLE_HEADER.distribution.emissionVolume,
          value: 'emissionVolume',
          width: '15%',
          align: 'right',
          rules: 'length_comma:10|number_comma_full_width:true|numeric_right:3',
          component: 'text-field-emv',
          disabled: (item) => !item.patternValue,
          class: 'pl-0',
          unit: 't-CO2',
        },
        {
          text: '',
          value: 'action_2',
          width: '40px',
        },
        {
          text: '',
          title: context.app.i18n.messages[context.app.i18n.locale].PRODUCT_ACTIVITY_TABLE_HEADER.distribution.action_3,
          value: 'action_3',
          width: '100px',
        },
      ],
      utilization: [
        {
          text: context.app.i18n.messages[context.app.i18n.locale].PRODUCT_ACTIVITY_TABLE_HEADER.utilization.productName,
          value: 'productName',
          width: '20%',
          align: 'left',
          rules: '',
          component: 'v-textarea',
          class: 'pl-4',
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].PRODUCT_ACTIVITY_TABLE_HEADER.utilization.productionControlId,
          value: 'productionControlId',
          align: 'left',
          rules: '',
          component: 'v-textarea',
          disabled: () => true,
          width: '10%',
          class: 'pl-0',
        },
        {
          text: '',
          value: 'action_edit',
          width: '40px',
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].PRODUCT_ACTIVITY_TABLE_HEADER.utilization.activityMonth,
          value: 'activityMonth',
          width: '100px',
          align: 'left',
          rules: '',
          component: 'v-date-picker',
          disabled: (item) => item.patternValue !== "02",
          props: {
            type: 'month',
            color: 'primary',
            locale: 'jp-ja'
          },
          class: 'pl-0',
        },
        // {
        //   text: '',
        //   value: 'action_import',
        //   width: '100px',
        // },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].PRODUCT_ACTIVITY_TABLE_HEADER.utilization.productActionName,
          value: 'productActionName',
          width: '25%',
          align: 'left',
          rules: 'max:100',
          component: 'v-text-field',
          disabled: (item) => item.patternValue !== "02",
          class: 'pl-0',
          cellClass: 'text-left'
        },
        // {
        //   text: context.app.i18n.messages[context.app.i18n.locale].PRODUCT_ACTIVITY_TABLE_HEADER.utilization.activityVolume,
        //   value: 'activityVolume',
        //   width: '155px',
        //   align: 'right',
        //   rules: 'length_comma:10|number_comma_full_width:true|numeric_right:3',
        //   component: 'text-field',
        //   class: 'pl-0',
        //   unit: 't-CO2',
        // },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].PRODUCT_ACTIVITY_TABLE_HEADER.utilization.emissionVolume,
          value: 'emissionVolume',
          width: '15%',
          align: 'right',
          rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
          component: 'text-field-emv',
          disabled: (item) => !item.patternValue,
          class: 'pl-0',
          unit: 't-CO2',
        },
        {
          text: '',
          value: 'action_2',
          width: '40px',
        },
        {
          text: '',
          title: context.app.i18n.messages[context.app.i18n.locale].PRODUCT_ACTIVITY_TABLE_HEADER.distribution.action_3,
          value: 'action_3',
          width: '100px',
        },
      ],
      wasteRecycling: [
        {
          text: context.app.i18n.messages[context.app.i18n.locale].PRODUCT_ACTIVITY_TABLE_HEADER.wasteRecycling.productName,
          value: 'productName',
          width: '20%',
          align: 'left',
          rules: '',
          component: 'v-textarea',
          class: 'pl-4',
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].PRODUCT_ACTIVITY_TABLE_HEADER.wasteRecycling.productionControlId,
          value: 'productionControlId',
          align: 'left',
          rules: '',
          component: 'v-textarea',
          disabled: () => true,
          width: '10%',
          class: 'pl-0',
        },
        {
          text: '',
          value: 'action_edit',
          width: '40px',
        },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].PRODUCT_ACTIVITY_TABLE_HEADER.wasteRecycling.activityMonth,
          value: 'activityMonth',
          width: '100px',
          align: 'left',
          rules: '',
          component: 'v-date-picker',
          disabled: (item) => item.patternValue !== "02",
          props: {
            type: 'month',
            color: 'primary',
            locale: 'jp-ja'
          },
          class: 'pl-0',
        },
        // {
        //   text: '',
        //   value: 'action_import',
        //   width: '100px',
        // },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].PRODUCT_ACTIVITY_TABLE_HEADER.wasteRecycling.productActionName,
          value: 'productActionName',
          width: 'auto',
          align: 'left',
          rules: 'max:100',
          component: 'v-text-field',
          disabled: (item) => item.patternValue !== "02",
          class: 'pl-0',
          cellClass: 'text-left'
        },
        // {
        //   text: context.app.i18n.messages[context.app.i18n.locale].PRODUCT_ACTIVITY_TABLE_HEADER.wasteRecycling.activityVolume,
        //   value: 'activityVolume',
        //   width: '155px',
        //   align: 'right',
        //   rules: 'length_comma:10|number_comma_full_width:true|numeric_right:3',
        //   component: 'text-field',
        //   class: 'pl-0',
        //   unit: 't-CO2',
        // },
        {
          text: context.app.i18n.messages[context.app.i18n.locale].PRODUCT_ACTIVITY_TABLE_HEADER.wasteRecycling.emissionVolume,
          value: 'emissionVolume',
          width: '15%',
          align: 'right',
          rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
          component: 'text-field-emv',
          disabled: (item) => !item.patternValue,
          class: 'pl-0',
          unit: 't-CO2',
        },
        {
          text: '',
          value: 'action_2',
          width: '40px',
        },
        {
          text: '',
          title: context.app.i18n.messages[context.app.i18n.locale].PRODUCT_ACTIVITY_TABLE_HEADER.distribution.action_3,
          value: 'action_3',
          width: '100px',
        },
      ],
    },
    SELECT_ACTIVITY_MODAL: {
      PATTERN_ONE: '01',
      PATTERN_TWO: '02',
    },
    // シミュレーション製品管理情報入力_テーブルヘッダー
    SIMULATION_PRODUCT_ACTIVITY_TABLE_HEADER: {
      procurement: {
        ACTIVITY_HEADERS: [
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_PRODUCT_ACTIVITY_TABLE_HEADER.procurement.HEADERS.productSimulationNo,
            value: 'productSimulationNo',
            width: '15%',
            align: 'left',
          },
          {
            text: '',
            value: 'buttonEdit',
            width: '40px',
          },
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_PRODUCT_ACTIVITY_TABLE_HEADER.procurement.HEADERS.departmentName,
            value: 'calculationTargetName',
            align: 'left',
            width: '12%',
          },
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_PRODUCT_ACTIVITY_TABLE_HEADER.procurement.HEADERS.calculationMethod,
            value: 'calculationMethodName',
            width: '15%',
            align: 'left',
          },
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_PRODUCT_ACTIVITY_TABLE_HEADER.procurement.HEADERS.activityVolume,
            value: 'activity_values',
            width: '30%',
            align: 'center',
            class: "success--text",
          },
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_PRODUCT_ACTIVITY_TABLE_HEADER.procurement.HEADERS.emissionIntensity,
            value: 'emissionUnit',
            width: '13%',
            align: 'right',
          },
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_PRODUCT_ACTIVITY_TABLE_HEADER.procurement.HEADERS.emissionVolume,
            value: 'emissionVolume',
            width: '15%',
            align: 'right',
            class: "success--text",
          },
          {
            text: '',
            value: 'action_2',
            width: '40px',
          },
          {
            text: '',
            value: 'action_3',
            width: '100px',
          },
        ],
        SUBCOLUMNS: [
          {
            name: 'calculationSourceName',
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            unit: 'activityVolumeUnit',
            disabled: (item) => false,
            editable: (item) => true,
            show: (item) => !!item.calculationMethodCode && item.calculationMethodCode !== '0901' && item.calculationMethodCode !== '0902',
          },
          {
            name: 'inputField1Name',
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            // unit: 'activityVolumeUnit',
            disabled: (item) => false,
            editable: (item) => true,
            show: (item) => item.calculationMethodCode === '0901',
          },
          {
            name: 'inputField2Name',
            model: 'emissionIntensity',
            rules: 'required|length_comma:5|number_comma_full_width:true|numeric_right:10',
            // unit: 'activityVolumeUnit',
            disabled: (item) => false,
            editable: (item) => true,
            show: (item) => item.calculationMethodCode === '0901',
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_PRODUCT_ACTIVITY_TABLE_HEADER.procurement.SUBCOLUMNS.emissionAmount,
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            unit: 'activityVolumeUnit',
            disabled: (item) => false,
            editable: (item) => true,
            show: (item) => item.calculationMethodCode === '0902',
          },
        ],
        EMISSION_UNIT: [
          {
            name: '',
            model: 'emissionIntensity',
            rules: 'required|length_comma:5|number_comma_full_width:true|numeric_right:10',
            unit: 'emissionUnit',
            disabled: (item) => false,
            editable: (item) => true,
            show: (item) => !!item.calculationMethodCode && item.calculationMethodCode !== '0901' && item.calculationMethodCode !== '0902',
          },
        ]
      },
      manufacturing: {
        ACTIVITY_HEADERS: [
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_PRODUCT_ACTIVITY_TABLE_HEADER.manufacturing.HEADERS.productSimulationNo,
            value: 'productSimulationNo',
            width: '15%',
            align: 'left',
          },
          {
            text: '',
            value: 'buttonEdit',
            width: '40px',
          },
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_PRODUCT_ACTIVITY_TABLE_HEADER.manufacturing.HEADERS.calculationTarget,
            value: "calculationTargetName",
            align: "left",
            width: "17%",
          },
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_ONE_INPUT_TABLE_HEADER_2.S1_G0003.activity_values,
            value: "activity_values",
            align: "center",
            width: "auto",
            class: "success--text",
          },
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_ONE_INPUT_TABLE_HEADER_2.S1_G0003.emissionIntensity,
            value: "emissionUnit",
            align: "right",
            width: "115px",
          },
          {
            text: "",
            value: "gwp",
            align: "right",
            width: "100px",
          },
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SCOPE_ONE_INPUT_TABLE_HEADER_2.S1_G0003.emissionVolume,
            value: "emissionVolume",
            align: "right",
            width: "10%",
            class: "success--text",
          },
          {
            text: '',
            value: 'action_2',
            width: '40px',
          },
          {
            text: '',
            value: 'action_3',
            width: '100px',
          },
        ],
        SUBCOLUMNS: [
          // Scope 1 Group 0003
          {
            name: 'calculationTargetNameCode65',
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => item.categoryGroupCode === '0003' && item.emissionActivity?.code !== '0901' && item.emissionActivity?.code !== '0902',
            editable: true,
            unit: 'activityVolumeUnit'
          },
          {
            name: 'inputField1Name',
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => item.categoryGroupCode === '0003' && item.emissionActivity?.code === '0901',
            editable: true
          },
          {
            name: 'inputField2Name',
            model: 'emissionIntensity',
            rules: 'required|length_comma:5|number_comma_full_width:true|numeric_right:10',
            show: (item) => item.categoryGroupCode === '0003' && item.emissionActivity?.code === '0901',
            editable: true
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_ONE_INPUT_TABLE_HEADER_2.S1_G0003.SUBCOLUMNS.activityVolume,
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => item.categoryGroupCode === '0003' && item.emissionActivity?.code === '0902',
            editable: true
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_ONE_INPUT_TABLE_HEADER_2.S1_G0003.SUBCOLUMNS.calorificValue,
            model: 'calorificValue',
            show: (item) => item.categoryGroupCode === '0003' && item.emissionActivity?.item6 && item.emissionActivity?.item7,
            editable: false,
            disabled: () => true,
            unit: 'calorificValueUnit'
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_ONE_INPUT_TABLE_HEADER_2.S1_G0003.SUBCOLUMNS.residualVolume,
            model: 'residualVolume',
            rules: 'required|length_comma:13|number_comma_full_width:true|integer_only',
            show: (item) => item.categoryGroupCode === '0003' && item.emissionActivity?.item3 && item.emissionActivity?.item8 && item.emissionActivity?.item9,
            editable: true,
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_ONE_INPUT_TABLE_HEADER_2.S1_G0003.SUBCOLUMNS.recoveryVolume,
            model: 'recoveryVolume',
            rules: 'required|length_comma:13|number_comma_full_width:true|integer_only',
            show: (item) => item.categoryGroupCode === '0003' && item.emissionActivity?.item9,
            editable: true,
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_ONE_INPUT_TABLE_HEADER_2.S1_G0003.SUBCOLUMNS.percentageOfOneyear,
            model: 'percentageOfOneyear',
            rules: 'required|length_comma:1|number_comma_full_width:true|numeric_right:9',
            show: (item) => item.categoryGroupCode === '0003' && item.emissionActivity?.item10,
            editable: true,
          },
          // Scope 1 Group 00
          {
            name: 'calculationSourceName',
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => item.categoryGroupCode === '00',
            editable: true,
            unit: 'activityVolumeUnit'
          },
        ],
        EMISSION_UNIT: [
          {
            name: '',
            model: 'emissionIntensity',
            rules: 'required|length_comma:5|number_comma_full_width:true|numeric_right:10',
            unit: 'emissionUnit',
            disabled: (item) => false,
            editable: (item) => true,
            show: (item) => !!item.calculationMethodCode && item.emissionIntensity !== undefined && item.emissionIntensity !== null && item.calculationTargetCodeId !== '210',
          },
        ]
      },
      manufacturing2: {
        ACTIVITY_HEADERS: [
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_PRODUCT_ACTIVITY_TABLE_HEADER.manufacturing2.HEADERS.productSimulationNo,
            value: 'productSimulationNo',
            width: '15%',
            align: 'left',
          },
          {
            text: '',
            value: 'buttonEdit',
            width: '40px',
          },
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_PRODUCT_ACTIVITY_TABLE_HEADER.manufacturing2.HEADERS.calculationTarget,
            value: 'calculationTargetName',
            width: '12%',
            align: 'left',
          },
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_PRODUCT_ACTIVITY_TABLE_HEADER.manufacturing2.HEADERS.calculationMethod,
            value: 'calculationMethodName',
            width: '15%',
            align: 'left',
          },
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_PRODUCT_ACTIVITY_TABLE_HEADER.manufacturing2.HEADERS.activityVolume,
            value: 'activity_values',
            width: 'auto',
            align: 'center',
            class: "success--text",
          },
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_PRODUCT_ACTIVITY_TABLE_HEADER.manufacturing2.HEADERS.emissionIntensity,
            value: 'emissionUnit',
            width: '13%',
            align: 'right',
          },
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_PRODUCT_ACTIVITY_TABLE_HEADER.manufacturing2.HEADERS.emissionVolume,
            value: 'emissionVolume',
            width: '15%',
            align: 'right',
            class: "success--text",
          },
          {
            text: '',
            value: 'action_2',
            width: '40px',
          },
          {
            text: '',
            value: 'action_3',
            width: '100px',
          },
        ],
        SUBCOLUMNS: [
          {
            name: '',
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            unit: 'activityVolumeUnit',
            show: (item) => !!item.calculationTargetCodeId,
            editable: true
          },
        ],
        EMISSION_UNIT: [
          {
            name: '',
            model: 'emissionIntensity',
            rules: 'required|length_comma:5|number_comma_full_width:true|numeric_right:10',
            unit: 'emissionUnit',
            disabled: (item) => false,
            editable: (item) => true,
            show: (item) => !!item.calculationTargetCodeId,
          },
        ]
      },
      distribution: {
        ACTIVITY_HEADERS: [
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_PRODUCT_ACTIVITY_TABLE_HEADER.distribution.HEADERS.productSimulationNo,
            value: 'productSimulationNo',
            width: '15%',
            align: 'left',
          },
          {
            text: '',
            value: 'buttonEdit',
            width: '40px',
          },
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_PRODUCT_ACTIVITY_TABLE_HEADER.distribution.HEADERS.calculationMethod,
            value: 'calculationMethodName',
            width: '15%',
            align: 'left',
          },
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_PRODUCT_ACTIVITY_TABLE_HEADER.distribution.HEADERS.activityVolume,
            value: 'activity_values',
            width: 'auto',
            align: 'center',
            class: "success--text",
          },
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_PRODUCT_ACTIVITY_TABLE_HEADER.distribution.HEADERS.emissionIntensity,
            value: 'emissionUnit',
            width: '13%',
            align: 'right',
          },
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_PRODUCT_ACTIVITY_TABLE_HEADER.distribution.HEADERS.emissionVolume,
            value: 'emissionVolume',
            width: '15%',
            align: 'right',
            class: "success--text",
          },
          {
            text: '',
            value: 'action_2',
            width: '40px',
          },
          {
            text: '',
            value: 'action_3',
            width: '100px',
          },
        ],
        SUBCOLUMNS: [
          {
            name: 'calculationSourceName',
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            unit: 'activityVolumeUnit',
            show: (item) => !!item.calculationMethodCode && item.calculationMethodCode !== '0109',
            disabled: (item) => false,
            editable: (item) => true
          },
          {
            name: 'calculationSourceName',
            model: 'transportDistance',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            unit: 'km',
            show: (item) => item.calculationMethodCode === '0109',
            disabled: (item) => false,
            editable: (item) => true
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_PRODUCT_ACTIVITY_TABLE_HEADER.distribution.SUBCOLUMNS.fuelEconomy,
            model: 'fuelEconomy',
            rules: 'required|length_comma:11|number_comma_full_width:true|numeric_right:2',
            unit: 'fuelUnitName',
            show: (item) => item.calculationMethodCode === '0109',
            disabled: (item) => item.fuelCode === '02',
            editable: (item) => item.fuelCode !== '02'
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_PRODUCT_ACTIVITY_TABLE_HEADER.distribution.SUBCOLUMNS.fuelEconomyBasicunit,
            model: 'fuelEconomyBasicunit',
            // rules: 'required|length_comma:11|number_comma_full_width:true|numeric_right:2',
            unit: 'fuelEconomyUnitName',
            show: (item) => item.calculationMethodCode === '0110' && item.calculationTargetCodeId === '020',
            disabled: (item) => true,
            disabled: (item) => true,
            editable: (item) => false
          },
        ],
        EMISSION_UNIT: [
          {
            name: '',
            model: 'emissionIntensity',
            rules: 'required|length_comma:5|number_comma_full_width:true|numeric_right:10',
            unit: 'emissionUnit',
            disabled: (item) => false,
            editable: (item) => true,
            show: (item) => !!item.calculationMethodCode && item.calculationMethodCode !== '0901' && item.calculationMethodCode !== '0902',
          },
        ]
      },
      utilization: {
        ACTIVITY_HEADERS: [
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_PRODUCT_ACTIVITY_TABLE_HEADER.wasteRecycling.HEADERS.productSimulationNo,
            value: 'productSimulationNo',
            width: '15%',
            align: 'left',
          },
          {
            text: '',
            value: 'buttonEdit',
            width: '40px',
          },
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_PRODUCT_ACTIVITY_TABLE_HEADER.wasteRecycling.HEADERS.calculationMethod,
            value: 'calculationMethodName',
            align: 'left',
            width: '15%',
          },
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_PRODUCT_ACTIVITY_TABLE_HEADER.wasteRecycling.HEADERS.activityVolume,
            value: 'activity_values',
            width: 'auto',
            align: 'center',
            class: "success--text",
          },
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_PRODUCT_ACTIVITY_TABLE_HEADER.wasteRecycling.HEADERS.emissionVolume,
            value: 'emissionVolume',
            width: '15%',
            align: 'right',
            class: "success--text",
          },
          {
            text: '',
            value: 'action_2',
            width: '40px',
          },
          {
            text: '',
            value: 'action_3',
            width: '100px',
          },
        ],
        SUBCOLUMNS: [
          // Category10
          {
            name: "",
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => item.calculationMethodCode === '0150',
            editable: true,
            unit: 'activityVolumeUnit'
          },
          {
            name: "",
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => item.calculationMethodCode === '0151',
            editable: true,
            unit: 'activityVolumeUnit'
          },
          {
            name: "",
            model: 'emissionIntensity',
            rules: 'required|length_comma:5|number_comma_full_width:true|numeric_right:10',
            show: (item) => item.calculationMethodCode === '0151',
            editable: false,
            disabled: (item) => true,
            unit: 'emissionUnit'
          },
          {
            name: "",
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => item.calculationMethodCode === '0152',
            editable: true,
            unit: 'activityVolumeUnit'
          },
          {
            name: "",
            model: 'emissionIntensity',
            rules: 'required|length_comma:5|number_comma_full_width:true|numeric_right:10',
            show: (item) => item.calculationMethodCode === '0152',
            editable: true,
          },
          {
            name: "",
            model: 'distribution',
            rules: 'not_zero|fraction|fraction_determinator|fraction_numerator|fraction_value|fraction_value_2|max_digits:100',
            show: (item) => item.calculationMethodCode === '0152',
            editable: true,
          },
          // Category11
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C11.SUBCOLUMNS.activityVolume1,
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => ['0155', '0156', '0160', '0161'].includes(item.calculationMethodCode),
            editable: true,
            unit: 'activityVolumeUnit'
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C11.SUBCOLUMNS.expectedLifetimeUsagePer,
            model: 'expectedLifetimeUsagePer',
            rules: 'required|number_max:3',
            show: (item) => item.calculationMethodCode === '0160',
            editable: true,
            unit: '%'
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C11.SUBCOLUMNS.saleCount1,
            model: 'saleCount',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => ['0155', '0156', '0160', '0161'].includes(item.calculationMethodCode),
            editable: true,
            unit: 'saleCountUnit'
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C11.SUBCOLUMNS.perLessonUsage1,
            model: 'perLessonUsage',
            rules: 'required|length_comma:5|number_comma_full_width:true|numeric_right:10',
            show: (item) => ['0155', '0160'].includes(item.calculationMethodCode),
            editable: true,
            unit: 'perLessonUsageUnit',
            precision: 10
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C11.SUBCOLUMNS.perLessonUsage2,
            model: 'perLessonUsage',
            rules: 'required|length_comma:5|number_comma_full_width:true|numeric_right:10',
            show: (item) => ['0156', '0161'].includes(item.calculationMethodCode),
            editable: true,
            unit: 'perLessonUsageUnit',
            precision: 10
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C11.SUBCOLUMNS.emissionIntensity1,
            model: 'emissionIntensity',
            rules: 'required|length_comma:5|number_comma_full_width:true|numeric_right:10',
            show: (item) => ['0155', '0156', '0160', '0161'].includes(item.calculationMethodCode),
            editable: true
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C11.SUBCOLUMNS.activityVolume2,
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => ['0157', '0162'].includes(item.calculationMethodCode),
            editable: true
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C11.SUBCOLUMNS.gwp,
            model: 'gwp',
            rules: 'required|number_max:6',
            show: (item) => ['0157', '0162'].includes(item.calculationMethodCode),
            editable: true,
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C11.SUBCOLUMNS.activityVolume3,
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => item.calculationMethodCode === '0158',
            editable: true,
            unit: 'activityVolumeUnit'
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C11.SUBCOLUMNS.emissionIntensity2,
            model: 'emissionIntensity',
            rules: 'required|length_comma:5|number_comma_full_width:true|numeric_right:10',
            show: (item) => item.calculationMethodCode === '0158',
            disabled: () => true,
            unit: 'emissionUnit'
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C11.SUBCOLUMNS.activityVolume4,
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => item.calculationMethodCode === '0159',
            editable: true,
            unit: 'activityVolumeUnit'
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C11.SUBCOLUMNS.saleCount2,
            model: 'saleCount',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => item.calculationMethodCode === '0159',
            editable: true,
            unit: 'saleCountUnit'
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C11.SUBCOLUMNS.emissionLifetimeUse,
            model: 'emissionLifetimeUse',
            rules: 'required|length_comma:5|number_comma_full_width:true|numeric_right:10',
            show: (item) => item.calculationMethodCode === '0159',
            editable: true,
            unit: '%',
            converter: 0.01,
            precision: 10
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C11.SUBCOLUMNS.emissionIntensity3,
            model: 'emissionIntensity',
            rules: 'required|length_comma:5|number_comma_full_width:true|numeric_right:10',
            show: (item) => item.calculationMethodCode === '0159',
            editable: true,
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C11.SUBCOLUMNS.activityVolume5,
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => item.calculationMethodCode === '0902',
            editable: true,
            unit: 'activityVolumeUnit'
          },
        ],
      },
      wasteRecycling: {
        ACTIVITY_HEADERS: [
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_PRODUCT_ACTIVITY_TABLE_HEADER.wasteRecycling.HEADERS.productSimulationNo,
            value: 'productSimulationNo',
            width: '15%',
            align: 'left',
          },
          {
            text: '',
            value: 'buttonEdit',
            width: '40px',
          },
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_PRODUCT_ACTIVITY_TABLE_HEADER.wasteRecycling.HEADERS.calculationMethod,
            value: 'calculationMethodName',
            align: 'left',
            width: '15%',
          },
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_PRODUCT_ACTIVITY_TABLE_HEADER.wasteRecycling.HEADERS.wasteType,
            value: 'calculationTargetName',
            width: '15%',
            align: 'left',
          },
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_PRODUCT_ACTIVITY_TABLE_HEADER.wasteRecycling.HEADERS.activityVolume,
            value: 'activity_values',
            width: '30%',
            align: 'center',
            class: "success--text",
          },
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_PRODUCT_ACTIVITY_TABLE_HEADER.procurement.HEADERS.emissionIntensity,
            value: 'emissionUnit',
            width: '10%',
            align: 'right',
          },
          {
            text: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_PRODUCT_ACTIVITY_TABLE_HEADER.wasteRecycling.HEADERS.emissionVolume,
            value: 'emissionVolume',
            width: '15%',
            align: 'right',
            class: "success--text",
          },
          {
            text: '',
            value: 'action_2',
            width: '40px',
          },
          {
            text: '',
            value: 'action_3',
            width: '100px',
          },
        ],
        SUBCOLUMNS: [
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C12.SUBCOLUMNS.activityVolume1,
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            unit: 'activityVolumeUnit',
            show: (item) => ['0123', '0124'].includes(item.calculationMethodCode) && item.calculationTargetCodeId !== '080',
            editable: true
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C12.SUBCOLUMNS.activityVolume2,
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            unit: 'activityVolumeUnit',
            show: (item) => item.calculationTargetCodeId === '080',
            editable: true
          },
          {
            name: 'inputField1Name',
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => item.calculationMethodCode === '0901',
            editable: true
          },
          {
            name: 'inputField2Name',
            model: 'emissionIntensity',
            rules: 'required|length_comma:5|number_comma_full_width:true|numeric_right:10',
            show: (item) => item.calculationMethodCode === '0901',
            editable: true
          },
          {
            name: context.app.i18n.messages[context.app.i18n.locale].SCOPE_THREE_INPUT_TABLE_HEADER.S3_C12.SUBCOLUMNS.activityVolume3,
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => item.calculationMethodCode === '0902',
            editable: true,
            unit: 'activityVolumeUnit'
          },
        ],
        EMISSION_UNIT: [
          {
            name: '',
            model: 'emissionIntensity',
            rules: 'required|length_comma:5|number_comma_full_width:true|numeric_right:10',
            unit: 'emissionUnit',
            disabled: (item) => false,
            editable: (item) => true,
            show: (item) => !!item.calculationMethodCode && item.calculationMethodCode !== '0901' && item.calculationMethodCode !== '0902',
          },
        ]
      },
    },
    SIMULATION_ACTIVITY_INPUT_DIALOG: {
      S1_C00: {
        ADD_DIALOG: {
          formValues: {
            groupType: null,
            calculationTarget: null,
            greenhouseGasType: null,
            greenhouseGas: null,
            emissionActivity: null,
            inputField1Name: null,
            inputField2Name: null,
          },
          formFields: {
            groupType: {
              model: 'groupType',
              rules: 'required',
              component: 'v-radio-group',
              show: (data) => true,
              props: {
                label: '',
                'return-object': true,
              },
              items: [],
            },
            calculationTarget: {
              model: 'calculationTarget',
              rules: 'required',
              component: 'v-select',
              show: (data) => data.formValues.groupType?.code === "0001",
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_ACTIVITY_INPUT_DIALOG.S1_C00.ADD_DIALOG.calculationTarget,
                items: [],
                'item-text': (i) => i.item1,
                'return-object': true,
              },
            },
            greenhouseGasType: {
              model: 'greenhouseGasType',
              rules: 'required',
              component: 'v-select',
              show: (data) => data.formValues.groupType?.code === "0003",
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_ONE_ADD_DIALOG.S1_G0003.greenhouseGasType,
                items: [],
                'item-text': 'item1',
                'return-object': true,
              },
            },
            greenhouseGas: {
              model: 'greenhouseGas',
              rules: 'required',
              component: 'v-autocomplete',
              show: (data) => data.formValues.groupType?.code === "0003" && data.formValues.greenhouseGasType?.code === '650' || data.formValues.greenhouseGasType?.code === '660',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_ONE_ADD_DIALOG.S1_G0003.greenhouseGas,
                items: [],
                'item-text': 'ghgName',
                'return-object': true,
              },
            },
            emissionActivity: {
              model: 'emissionActivity',
              rules: 'required',
              component: 'v-autocomplete',
              show: (data) => data.formValues.groupType?.code === "0003" && data.formValues.greenhouseGasType !== null,
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_ONE_ADD_DIALOG.S1_G0003.emissionActivity,
                items: [],
                'item-text': 'item1',
                'return-object': true,
              },
            },
            inputField1Name: {
              model: 'inputField1Name',
              rules: 'required|max:100',
              component: 'v-text-field',
              show: (data) => data.formValues.groupType?.code === "0003" && data.formValues.emissionActivity?.code === '0901',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_ONE_ADD_DIALOG.S1_G0003.inputField1Name,
                type: 'text',
              },
            },
            inputField2Name: {
              model: 'inputField2Name',
              rules: 'required|max:100',
              component: 'v-text-field',
              show: (data) => data.formValues.groupType?.code === "0003" && data.formValues.emissionActivity?.code === '0901',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SCOPE_ONE_ADD_DIALOG.S1_G0003.inputField2Name,
                type: 'text',
              },
            },
          },
          dataMapper: {
            groupType: 'groupType',
            greenhouseGasType: 'greenhouseGasType',
            greenhouseGas: 'greenhouseGas',
            emissionActivity: 'emissionActivity',
          }
        },
      },
      S2_C00: {
        ADD_DIALOG: {
          formValues: {
            calculationTargetScope2: null,
            calculationMethodScope2: null,
            electricCompany: null,
            electricSupplyMenu: null,
          },
          formFields: {
            emissionActivities: {
              model: 'calculationTargetScope2',
              rules: 'required',
              component: 'v-select',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_ACTIVITY_INPUT_DIALOG.S2_C00.ADD_DIALOG.calculationTarget,
                items: [],
                'item-text': (i) => i.item2,
                'return-object': true,
              },
            },
            calculationMethodScope2: {
              model: 'calculationMethodScope2',
              rules: 'required',
              component: 'v-select',
              show: (data) => data.formValues.calculationTargetScope2?.code === '09',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_ACTIVITY_INPUT_DIALOG.S2_C00.ADD_DIALOG.calculationMethod,
                items: [],
                'item-text': (i) => i.item1,
                'return-object': true,
                'item-value': (i) => ({ ...i })
              },
            },
            electricCompanies: {
              model: 'electricCompany',
              rules: 'required',
              component: 'v-autocomplete',
              show: (data) => data.formValues.calculationTargetScope2?.code === '09',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_ACTIVITY_INPUT_DIALOG.S2_C00.ADD_DIALOG.electricCompanies,
                items: [],
                'item-text': (i) => i.displayName,
                'return-object': true,
                'item-value': (i) => ({ ...i })
              },
            },
            electricSupplyMenu: {
              model: 'electricSupplyMenu',
              rules: 'required',
              component: 'v-select',
              show: (data) => data.formValues.calculationMethodScope2?.code === '02' && data.formFields.electricSupplyMenu.props.items?.length > 1,
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_ACTIVITY_INPUT_DIALOG.S2_C00.ADD_DIALOG.electricSupplyMenu,
                items: [],
                'item-text': (i) => i.label,
                'return-object': true,
                'item-value': (i) => ({ ...i })
              },
            }
          },
          dataMapper: {
            calculationMethodScope2: 'calculationMethods',
            emissionActivities: 'emissionActivities',
            electricCompanies: 'electricCompanies',
            // electricSupplyMenu: 'electricSupplyMenu',
          }
        },
      },
      S3_C01: {
        ADD_DIALOG: {
          formValues: {
            inputMethod: null,
            calculationMethodCategory1: null,
            calculationTarget: null,
            inputField1Name: '',
            inputField2Name: '',
          },
          formFields: {
            inputMethod: {
              model: 'inputMethod',
              rules: 'required',
              component: 'v-select',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_ACTIVITY_INPUT_DIALOG.S3_C01.ADD_DIALOG.calculationMethod,
                items: [],
                'item-text': (i) => i.item1,
                'return-object': true,
                'item-value': (i) => ({ ...i })
              },
            },
            calculationMethod: {
              model: 'calculationMethodCategory1',
              rules: 'required',
              component: 'v-select',
              show: (data) => data.formValues.inputMethod?.code === '0101',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_ACTIVITY_INPUT_DIALOG.S3_C01.ADD_DIALOG.calculationTarget,
                items: [],
                'item-text': (i) => i.item1,
                'return-object': true,
              },
            },
            departmentName: {
              model: 'calculationTarget',
              rules: 'required',
              component: 'v-select',
              show: (data) => !!data.formValues.calculationMethodCategory1,
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_ACTIVITY_INPUT_DIALOG.S3_C01.ADD_DIALOG.departmentName,
                items: [],
                'item-text': (i) => i.item1,
                'return-object': true,
                'item-value': (i) => ({ ...i })
              },
            },
            inputField1Name: {
              model: 'inputField1Name',
              rules: 'required|max:100',
              component: 'v-text-field',
              show: (data) => data.formValues.inputMethod?.code === '0901',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_ACTIVITY_INPUT_DIALOG.S3_C01.ADD_DIALOG.inputField1Name,
                type: 'text',
              },
            },
            inputField2Name: {
              model: 'inputField2Name',
              rules: 'required|max:100',
              component: 'v-text-field',
              show: (data) => data.formValues.inputMethod?.code === '0901',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_ACTIVITY_INPUT_DIALOG.S3_C01.ADD_DIALOG.inputField2Name,
                type: 'text',
              },
            },
          },
          dataMapper: {
            inputMethod: 'inputMethods',
            calculationMethod: 'calculationMethods'
          }
        },
      },
      S3_C04: {
        ADD_DIALOG: {
          formValues: {
            inputMethod: null,
            calculationMethod: null,
            fuelType: null,
            transportMean: null,
            transportKbn: null,
            bizDomesticKbn: null,
            fuelGrasp: null,
            truckRate: null,
          },
          formFields: {
            inputMethods: {
              model: 'inputMethod',
              rules: 'required',
              component: 'v-select',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_ACTIVITY_INPUT_DIALOG.S3_C04.ADD_DIALOG.inputMethods,
                items: [],
                'item-text': (i) => i.item1,
                'return-object': true,
                'item-value': (i) => ({ ...i })
              },
            },
            calculationMethods: {
              model: 'calculationMethod',
              rules: 'required',
              component: 'v-select',
              show: (data) => data.formValues.inputMethod?.code === "0107",
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_ACTIVITY_INPUT_DIALOG.S3_C04.ADD_DIALOG.calculationMethods,
                items: [],
                'item-text': (i) => i.item1,
                'return-object': true,
              },
            },
            fuelTypes: {
              model: 'fuelType',
              rules: 'required',
              component: 'v-select',
              show: (data) => data.formValues.calculationMethod?.code === "0108",
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_ACTIVITY_INPUT_DIALOG.S3_C04.ADD_DIALOG.fuelTypes,
                items: [],
                'item-text': (i) => i.item1,
                'return-object': true,
                'item-value': (i) => ({ ...i })
              },
            },
            transportMeans: {
              model: 'transportMean',
              rules: 'required',
              component: 'v-select',
              show: (data) => data.formValues.calculationMethod?.code === "0110",
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_ACTIVITY_INPUT_DIALOG.S3_C04.ADD_DIALOG.transportMeans,
                items: [],
                'item-text': (i) => i.item1,
                'return-object': true,
                'item-value': (i) => ({ ...i })
              },
            },
            transportKbn: {
              model: 'transportKbn',
              rules: 'required',
              component: 'v-select',
              show: (data) => data.formValues.calculationMethod?.code === "0109" || (data.formValues.calculationMethod?.code === "0110" && data.formValues.transportMean?.code === "01"),
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_ACTIVITY_INPUT_DIALOG.S3_C04.ADD_DIALOG.transportKbn,
                items: [],
                'item-text': (i) => i.item1,
                'return-object': true,
                'item-value': (i) => ({ ...i })
              },
            },
            bizDomesticKbn: {
              model: 'bizDomesticKbn',
              rules: 'required',
              component: 'v-radio-group',
              show: (data) => data.formValues.calculationMethod?.code === "0109",
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_ACTIVITY_INPUT_DIALOG.S3_C04.ADD_DIALOG.bizDomesticKbn,
                'return-object': true,
              },
              items: [],
              // guide: 'Q0001',
            },
            fuelGrasps: {
              model: 'fuelGrasp',
              rules: 'required',
              component: 'v-radio-group',
              show: (data) => data.formValues.calculationMethod?.code === "0109",
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_ACTIVITY_INPUT_DIALOG.S3_C04.ADD_DIALOG.fuelGrasps,
                'return-object': true,
              },
              items: [],
              // guide: 'Q0003',
            },
            truckRates: {
              model: 'truckRate',
              rules: 'required',
              component: 'v-select',
              show: (data) => (data.formValues.calculationMethod?.code === "0110" && data.formValues.transportMean?.code === "01"),
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_ACTIVITY_INPUT_DIALOG.S3_C04.ADD_DIALOG.truckRates,
                items: [],
                'item-text': (i) => i.item1,
                'return-object': true,
                'item-value': (i) => ({ ...i })
              },
            },
          },
          dataMapper: {
            inputMethods: "inputMethods",
            calculationMethods: "calculationMethods",
            // fuelTypes: "fuelTypes",
            transportMeans: "transportMeans",
            transportKbn: "transportKbn",
            bizDomesticKbn: "bizDomesticKbn",
            fuelGrasps: "fuelGrasps",
            truckRates: "truckRates",
          }
        },
      },
      S3_C09: {
        ADD_DIALOG: {
          formValues: {
            inputMethod: null,
            calculationMethod: null,
            fuelType: null,
            transportMean: null,
            transportKbn: null,
            bizDomesticKbn: null,
            fuelGrasp: null,
            truckRate: null,
          },
          formFields: {
            inputMethods: {
              model: 'inputMethod',
              rules: 'required',
              component: 'v-select',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_ACTIVITY_INPUT_DIALOG.S3_C04.ADD_DIALOG.inputMethods,
                items: [],
                'item-text': (i) => i.item1,
                'return-object': true,
                'item-value': (i) => ({ ...i })
              },
            },
            calculationMethods: {
              model: 'calculationMethod',
              rules: 'required',
              component: 'v-select',
              show: (data) => data.formValues.inputMethod?.code === "0107",
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_ACTIVITY_INPUT_DIALOG.S3_C04.ADD_DIALOG.calculationMethods,
                items: [],
                'item-text': (i) => i.item1,
                'return-object': true,
              },
            },
            fuelTypes: {
              model: 'fuelType',
              rules: 'required',
              component: 'v-select',
              show: (data) => data.formValues.calculationMethod?.code === "0108",
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_ACTIVITY_INPUT_DIALOG.S3_C04.ADD_DIALOG.fuelTypes,
                items: [],
                'item-text': (i) => i.item1,
                'return-object': true,
                'item-value': (i) => ({ ...i })
              },
            },
            transportMeans: {
              model: 'transportMean',
              rules: 'required',
              component: 'v-select',
              show: (data) => data.formValues.calculationMethod?.code === "0110",
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_ACTIVITY_INPUT_DIALOG.S3_C04.ADD_DIALOG.transportMeans,
                items: [],
                'item-text': (i) => i.item1,
                'return-object': true,
                'item-value': (i) => ({ ...i })
              },
            },
            transportKbn: {
              model: 'transportKbn',
              rules: 'required',
              component: 'v-select',
              show: (data) => data.formValues.calculationMethod?.code === "0109" || (data.formValues.calculationMethod?.code === "0110" && data.formValues.transportMean?.code === "01"),
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_ACTIVITY_INPUT_DIALOG.S3_C04.ADD_DIALOG.transportKbn,
                items: [],
                'item-text': (i) => i.item1,
                'return-object': true,
                'item-value': (i) => ({ ...i })
              },
            },
            bizDomesticKbn: {
              model: 'bizDomesticKbn',
              rules: 'required',
              component: 'v-radio-group',
              show: (data) => data.formValues.calculationMethod?.code === "0109",
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_ACTIVITY_INPUT_DIALOG.S3_C04.ADD_DIALOG.bizDomesticKbn,
                'return-object': true,
              },
              items: [],
              // guide: 'Q0001',
            },
            fuelGrasps: {
              model: 'fuelGrasp',
              rules: 'required',
              component: 'v-radio-group',
              show: (data) => data.formValues.calculationMethod?.code === "0109",
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_ACTIVITY_INPUT_DIALOG.S3_C04.ADD_DIALOG.fuelGrasps,
                'return-object': true,
              },
              items: [],
              // guide: 'Q0003',
            },
            truckRates: {
              model: 'truckRate',
              rules: 'required',
              component: 'v-select',
              show: (data) => (data.formValues.calculationMethod?.code === "0110" && data.formValues.transportMean?.code === "01"),
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_ACTIVITY_INPUT_DIALOG.S3_C04.ADD_DIALOG.truckRates,
                items: [],
                'item-text': (i) => i.item1,
                'return-object': true,
                'item-value': (i) => ({ ...i })
              },
            },
          },
          dataMapper: {
            inputMethods: "inputMethods",
            calculationMethods: "calculationMethods",
            fuelTypes: "fuelTypes",
            transportMeans: "transportMeans",
            transportKbn: "transportKbn",
            bizDomesticKbn: "bizDomesticKbn",
            fuelGrasps: "fuelGrasps",
            truckRates: "truckRates",
          }
        },
      },
      S3_C10: {
        ADD_DIALOG: {
          formValues: {
            calculationMethod: null,
            gasTypes: null,
            calculationTarget: null,
          },
          formFields: {
            calculationMethod: {
              model: 'calculationMethod',
              rules: 'required',
              component: 'v-select',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_ACTIVITY_INPUT_DIALOG.S3_C10.ADD_DIALOG.calculationMethod,
                items: [],
                'item-text': (i) => i.item1,
                'return-object': true,
                'item-value': (i) => ({ ...i })
              },
            },
            gasTypes: {
              model: 'gasTypes',
              rules: 'required',
              component: 'v-select',
              show: (data) => data.formValues.calculationMethod?.code === '0151',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_ACTIVITY_INPUT_DIALOG.S3_C10.ADD_DIALOG.gasTypes,
                items: [],
                'item-text': (i) => i.item1,
                'return-object': true,
                'item-value': (i) => ({ ...i })
              },
            },
            calculationTarget: {
              model: 'calculationTarget',
              rules: 'required',
              component: 'v-select',
              show: (data) => !!data.formValues.gasTypes,
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_ACTIVITY_INPUT_DIALOG.S3_C10.ADD_DIALOG.calculationTarget,
                items: [],
                'item-text': (i) => i.item1,
                'return-object': true,
              },
            },
          },
          dataMapper: {
            calculationMethod: 'inputMethods',
            gasTypes: 'gasTypes',
            // calculationTarget: 'activityTypes'
          }
        },
      },
      S3_C11: {
        ADD_DIALOG: {
          getName: {
            '0155': ['calculationMethodName1'],
            '0156': ['calculationMethodName1'],
            '0157': ['calculationMethodName1'],
            '0158': ['calculationMethodName1', 'calculationTargetName1'],
            '0159': ['calculationMethodName1'],
            '0160': ['calculationMethodName1'],
            '0161': ['calculationMethodName1'],
            '0162': ['calculationMethodName1'],
            '0902': ['calculationMethodName1'],
          },
          formValues: {
            calculationMethod: null,
            calculationTarget: null,
            emissionIntensity: null,
            emissionUnit: null,
            // 販売数
            saleCount: 0,
            // 販売数単位
            saleCountUnit: null,
            // 使用1回あたりの使用量
            perLessonUsage: 0,
            // 使用1回あたりの使用量単位
            perLessonUsageUnit: null,
            // 生涯使用の排出量
            emissionLifetimeUse: 0,
            // 想定生涯使用回数の割合
            expectedLifetimeUsagePer: null
          },
          formFields: {
            calculationMethod: {
              model: 'calculationMethod',
              rules: 'required',
              component: 'v-select',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_ACTIVITY_INPUT_DIALOG.S3_C11.ADD_DIALOG.calculationMethod,
                items: [],
                'item-text': (i) => i.name1,
                'return-object': true,
                'item-value': (i) => ({ ...i })
              },
            },
            calculationTarget: {
              model: 'calculationTarget',
              rules: 'required',
              component: 'v-select',
              show: (data) => data.formValues.calculationMethod?.code === '0158',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_ACTIVITY_INPUT_DIALOG.S3_C11.ADD_DIALOG.calculationTarget,
                items: [],
                'item-text': (i) => i.name1,
                'return-object': true,
              },
            },
          },
          dataMapper: {
            calculationMethod: 'calculationMethods',
            calculationTarget: 'fuelTypes'
          }
        },
      },
      S3_C12: {
        ADD_DIALOG: {
          getName: {
            '0123': ['calcMethodsName1', 'transNecMethodsName1'],
            '0124': ['calcMethodsName1', 'transNecMethodsName1'],
            '0901': ['calculationMethodName1'],
            '0902': ['calculationMethodName1'],
          },
          formValues: {
            calculationMethod: null,
            calculationTarget: null,
            inputField1Name: '',
            inputField2Name: '',
            emissionIntensity: null,
            emissionUnit: null,
            calcMethods: null,
            transNecMethods: null
          },
          formFields: {
            calculationMethod: {
              model: 'calculationMethod',
              rules: 'required',
              component: 'v-select',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_ACTIVITY_INPUT_DIALOG.S3_C12.ADD_DIALOG.calculationMethod,
                items: [],
                'item-text': 'name1',
                'return-object': true,
              },
            },
            calcMethods: {
              model: 'calcMethods',
              rules: 'required',
              component: 'v-select',
              show: (data) => data.formValues.calculationMethod?.code === '0118',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_ACTIVITY_INPUT_DIALOG.S3_C12.ADD_DIALOG.calcMethods,
                items: [],
                'item-text': 'name1',
                'return-object': true,
              },
            },
            transNecMethods: {
              model: 'transNecMethods',
              rules: 'required',
              component: 'v-radio-group',
              show: (data) => data.formValues.calculationMethod?.code === '0118',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_ACTIVITY_INPUT_DIALOG.S3_C12.ADD_DIALOG.transNecMethods,
                'return-object': true,
              },
              items: []
            },
            secondTrashType: {
              model: 'calculationTarget',
              rules: 'required',
              component: 'v-select',
              show: (data) => data.formValues.calcMethods?.code === '0119',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_ACTIVITY_INPUT_DIALOG.S3_C12.ADD_DIALOG.secondTrashType,
                items: [],
                'item-text': 'name1',
                'return-object': true,
              },
            },
            thirdTrashType: {
              model: 'calculationTarget',
              rules: 'required',
              component: 'v-select',
              show: (data) => data.formValues.calcMethods?.code === '0120',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_ACTIVITY_INPUT_DIALOG.S3_C12.ADD_DIALOG.thirdTrashType,
                items: [],
                'item-text': 'name1',
                'return-object': true,
              },
            },
            firstTrashType: {
              model: 'calculationTarget',
              rules: 'required',
              component: 'v-select',
              show: (data) => data.formValues.calcMethods?.code === '0121',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_ACTIVITY_INPUT_DIALOG.S3_C12.ADD_DIALOG.firstTrashType,
                items: [],
                'item-text': 'name1',
                'return-object': true,
              },
            },
            fourthTrashType: {
              model: 'calculationTarget',
              rules: 'required',
              component: 'v-select',
              show: (data) => data.formValues.calcMethods?.code === '0122',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_ACTIVITY_INPUT_DIALOG.S3_C12.ADD_DIALOG.fourthTrashType,
                items: [],
                'item-text': 'name1',
                'return-object': true,
              },
            },
            inputField1Name: {
              model: 'inputField1Name',
              rules: 'required|max:100',
              component: 'v-text-field',
              show: (data) => data.formValues.calculationMethod?.code === '0901',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_ACTIVITY_INPUT_DIALOG.S3_C12.ADD_DIALOG.inputField1Name,
                type: 'text',
              },
            },
            inputField2Name: {
              model: 'inputField2Name',
              rules: 'required|max:100',
              component: 'v-text-field',
              show: (data) => data.formValues.calculationMethod?.code === '0901',
              props: {
                label: context.app.i18n.messages[context.app.i18n.locale].SIMULATION_ACTIVITY_INPUT_DIALOG.S3_C12.ADD_DIALOG.inputField2Name,
                type: 'text',
              },
            },
          },
          dataMapper: {
            calculationMethod: 'twelveCat',
            calcMethods: 'calcMethods',
            firstTrashType: 'firstTrashType',
            secondTrashType: 'secondTrashType',
            thirdTrashType: 'thirdTrashType',
            fourthTrashType: 'fourthTrashType',
          }
        },
      }
    },
    // 質問マークのガイド
    GUIDE: {},
    /** 企業マスタ */
    BASE_ORGANIZATION: {
      WHOLE_COMPANY: "00",
      /** 企業マスタ：全社コード */
      WIDE_CODE: "00",
      /** 企業マスタ：拠点コード */
      BASE_CODE: "01",
      /** 企業マスタ：組織コード */
      ORGANIZATION_CODE: "02",
    },
    PAGE_MODE: {
      /** 初期化状態 */
      INIT: 0,
      /** 検索済状態 */
      SEARCH: 1,
    },
    ACTIVITY_SCOPE: {
      SCOPE_ONE: "1",
      SCOPE_TWO: "2",
      SCOPE_THREE: "3",
    },
    FUEL_TYPE: {
      ELECTRICITY: "09",
    },
    CALCULATION_METHOD: {
      BASE_EMISSION: "01",
      ADJUSTMENT_EMISSION: "02",
      //処理方法不明
      UNKNOWN_PROCESSING_METHOD: {
        code: "0122",
        name: context.app.i18n.messages[context.app.i18n.locale].CALCULATION_METHOD.UNKNOWN_PROCESSING_METHOD
      }
    },
    ACTIVITY_CATEGORY: {
      DEFAULT: "00",
    },
    INPUT_METHOD: {
      /** 産業連関表ベース */
      INDUSTRIAL_RELATION: "0101",
      /** 物量ベース */
      QUANTITY_BASE: "0102",
      /** 生産者価格ベース */
      PRODUCER_PRICE: "0103",
      /** 購入者価格ベース */
      PURCHASER_PRICE: "0104",
      /** 金額ベース */
      PRICE_BASE: "0105",
      /** 燃料から算出 */
      CALCULATION_FROM_FUEL: "0107",
      /** 燃料から算出（エネルギー使用に伴う排出量） */
      CALCULATION_FROM_FUEL_ENERGY: "0903",
      /** 燃料法 */
      FUEL_METHOD: "0108",
      /** 燃費法 */
      FUEL_CONSUMPTION_METHOD: "0109",
      /** トンキロ法 */
      TON_KILOMETER_METHOD: "0110",
      /** SHK制度 */
      SHK_PROGRAM: "0112",
      /** 基礎排出係数 */
      BASIC_EMISSION_COEFFICIENT: "0113",
      /** 調整後排出係数 */
      ADJUSTMENT_EMISSION_COEFFICIENT: "0114",
      /** 整備時の充填量・回収量から算定 */
      FILLING_RECOVERY: "0116",
      /** 漏えい率から算定 */
      LEAKAGE_RATE: "0117",
      /** 活動量×原単位 (値1×値2) */
      EMISSION_INPUT: "0901",
      /** 直接手入力 */
      HAND_INPUT: "0902",
      /** SCOPE1,2の排出量を投資持分比率に応じて積み上げで算出 */
      EMISSIONS_AS_INVESTMENT_INPUT: "0146",
      /** SCOPE1,2の排出量を投資持分比率に応じて積み上げで算出(任意加算あり)(With optional addition) */
      EMISSIONS_AS_INVESTMENT_WOA_INPUT: "0147",
      /** 経済データを用いて推計 */
      ESTIMATED_USING_ECONOMIC_INPUT: "0148",
      /** 経済データを用いて推計(任意加算あり)(With optional addition) */
      ESTIMATED_USING_ECONOMIC_WOA_INPUT: "0149",
      /** 廃棄量から算出 */
      CALCULATED_AMOUNT_OF_WASTE: "0118",
      /** 販売先から加工に伴う排出量データを入手できる場合 */
      PROCESSING_EMISSION: "0150",
      /** 販売先から加工に伴うエネルギー消費データを入手できる場合 */
      PROCESSING_ENERGY: "0151",
      /** 販売先から加工に伴うエネルギー消費データ等を入手できない場合 */
      NOT_PROCESSING_ENERGY: "0152",
    },
    /** 燃費の把握 */
    FUEL_GRASP: {
      /** 把握できる */
      POSSIBLE: "01",
    },
    INPUT_METHOD_GROUP: {
      /** [１]産業連関表ベースの排出原単位 */
      DB1: "DB1",
      /** [２]産業連関表ベースの排出原単位 */
      DB2: "DB2",
      DB3: "DB3",
      DB4: "DB4",
      /** [５]産業連関表ベースの排出原単位 */
      //HEAD
      DB5: "DB5",
      DB0: "DB0",
      /** 事業から出る廃棄物 */
      DB8: "DB8",
    },
    CATEGORY_GROUP_CODE: {
      DB8: "0142",
      DB2: "0143",
    },
    USER_TABLE_HEADER: [
      {
        text: '',
        value: 'action',
        width: '60px',
      },
      {
        text: context.app.i18n.messages[context.app.i18n.locale].USER_TABLE_HEADER.userName,
        value: "userName",
        width: "10%",
        align: "left",
        sortable: false,
        class: 'pl-0'
      },
      {
        text: context.app.i18n.messages[context.app.i18n.locale].USER_TABLE_HEADER.userNameKanji,
        value: "userNameKanji",
        width: "15%",
        sortable: false,
        align: 'left',
        class: 'pl-0'
      },
      {
        text: context.app.i18n.messages[context.app.i18n.locale].USER_TABLE_HEADER.userNameKana,
        value: "userNameKana",
        width: "25%",
        sortable: false,
        align: 'left',
        class: 'pl-0'
      },
      {
        text: context.app.i18n.messages[context.app.i18n.locale].USER_TABLE_HEADER.organizationName,
        value: "organizationName",
        width: "20%",
        align: "left",
        sortable: false,
        class: 'pl-0'
      },
      {
        text: context.app.i18n.messages[context.app.i18n.locale].USER_TABLE_HEADER.userAuthorityName,
        value: "userAuthorityName",
        width: "15%",
        align: "left",
        sortable: false,
        class: 'pl-0'
      },
      {
        text: context.app.i18n.messages[context.app.i18n.locale].USER_TABLE_HEADER.email,
        value: "email",
        width: "15%",
        align: "left",
        sortable: false,
        class: 'pl-0'
      },
      {
        text: '',
        value: 'action_2',
        width: '60px',
      },
    ],
    SIGNUP_FORM: {
      user_id: {
        model: 'user_id',
        rules: 'required|alpha_num|max:10',
        component: 'v-text-field',
        props: {
          label: "user_registration.user_id",
          type: 'text',
        },
      },
      user_name_kanji: {
        model: 'user_name_kanji',
        rules: 'required|max:30',
        component: 'v-text-field',
        props: {
          label: 'user_registration.user_name_kanji',
          type: 'text',
        },
      },
      user_name_kana: {
        model: 'user_name_kana',
        rules: 'kana|max:30',
        component: 'v-text-field',
        props: {
          label: 'user_registration.user_name_kana',
          type: 'text',
        },
      },
      user_authority_code: {
        model: 'user_authority_code',
        rules: 'required',
        component: 'v-select',
        props: {
          label: 'user_registration.authority',
          items: [],
          'item-text': 'item1',
          'item-value': 'code',
        },
      },
      organization_id: {
        model: 'organization_id',
        rules: 'required',
        component: 'tree-select',
        props: {
          label: 'user_registration.organization',
          openDirection: 'top',
          maxHeight: null,
          items: [],
        },
      },
      email: {
        model: 'email',
        rules: 'required|email|max:50',
        component: 'v-text-field',
        props: {
          label: 'user_registration.email',
          type: 'text',
        },
      },
    },
    SIGNUP_FORM_EMISSIONS_CAL_GUIDE_SITE: {

    },
    USER_AUTHORITY_KBN: {
      ONLY_DISPLAY: "1",
      CAN_INPUT: "2",
      SYSTEM_ADMIN: "9",
    },
    DEFAULT_STEP_UNIT: 50,
    BUTTON_CHOICE: {
      YES: context.app.i18n.messages[context.app.i18n.locale].BUTTON_CHOICE.YES,
      NO: context.app.i18n.messages[context.app.i18n.locale].BUTTON_CHOICE.NO,
      CANCEL: context.app.i18n.messages[context.app.i18n.locale].BUTTON_CHOICE.CANCEL,
    },
    HEADER_MAPPING: {
      PROCUREMENTS: {
        調達品ID: 'procurementItemId',
        調達品名: 'procurementItemName',
        産業連関表品目コード: 'industryTableCode',
        輸送活動ID: 'transportId',
      },
      PRODUCTS: {
        製品ID: 'productId',
        製品名: 'productName',
        輸送活動ID: 'transportId',
      },
      WASTES: {
        廃棄物ID: 'wasteId',
        廃棄物名: 'wasteName',
        算定方法: 'calculationMethod',
        廃棄物の種類: 'wasteType',
      },
    },
    AUTHORITY: {
      ACCEPT_VIEW_FLAG: '1',
      ACCEPT_UPDATE_FLAG: '1',

    },
    LANGUAGE: {
      COUNTRIES: [
        {
          label: 'English',
          value: 'en',
          flag: 'gb',
        },
        {
          label: '日本語',
          value: 'ja',
          flag: 'jp',
        },
        // {
        //   label: 'Tiếng Việt',
        //   value: 'vn',
        //   flag: 'vn',
        // },
      ],
    },
    EN_MONTH_LIST: {
      1: 'Jan',
      2: 'Feb',
      3: 'Mar',
      4: 'Apr',
      5: 'May',
      6: 'Jun',
      7: 'Jul',
      8: 'Aug',
      9: 'Sep',
      10: 'Oct',
      11: 'Nov',
      12: 'Dec'
    },
    ALERT_SETTING: {
      INPUT_STATUS_HEADER: [
        {
          name: 'baseName',
          text: context.app.i18n.messages[context.app.i18n.locale].ALERT_SETTING.INPUT_STATUS_HEADER.base,
          value: 'baseName',
          align: 'center',
        },
        {
          name: 'organizationName',
          text: context.app.i18n.messages[context.app.i18n.locale].ALERT_SETTING.INPUT_STATUS_HEADER.organization,
          value: 'organizationName',
          Width: '85px',
          align: 'center',
        },
        {
          name: 'scope',
          text: context.app.i18n.messages[context.app.i18n.locale].ALERT_SETTING.INPUT_STATUS_HEADER.scope,
          value: 'scope',
          align: 'center',
        },
        {
          name: 'category',
          text: context.app.i18n.messages[context.app.i18n.locale].ALERT_SETTING.INPUT_STATUS_HEADER.category,
          value: 'category',
          width: '60px',
          align: 'center',
        },
        {
          name: 'categoryGroup',
          text: context.app.i18n.messages[context.app.i18n.locale].ALERT_SETTING.INPUT_STATUS_HEADER.categoryGroup,
          value: 'categoryGroup',
          width: '100px',
          align: 'center',
        },
        {
          name: 'inputsThisMonth',
          text: context.app.i18n.messages[context.app.i18n.locale].ALERT_SETTING.INPUT_STATUS_HEADER.inputThisMonth,
          value: 'inputsThisMonth',
          align: 'center',
        },
        {
          name: 'emissionsThisMonth',
          text: context.app.i18n.messages[context.app.i18n.locale].ALERT_SETTING.INPUT_STATUS_HEADER.emissionThisMonth,
          value: 'emissionsThisMonth',
          align: 'center',
        },
        {
          name: 'inputsLastMonth',
          text: context.app.i18n.messages[context.app.i18n.locale].ALERT_SETTING.INPUT_STATUS_HEADER.inputLastMonth,
          value: 'inputsLastMonth',
          align: 'center',
        },
        {
          name: 'emissionsLastMonth',
          text: context.app.i18n.messages[context.app.i18n.locale].ALERT_SETTING.INPUT_STATUS_HEADER.emissionLastMonth,
          value: 'emissionsLastMonth',
          align: 'center',
        },
        {
          name: 'inputs2MonthsAgo',
          text: context.app.i18n.messages[context.app.i18n.locale].ALERT_SETTING.INPUT_STATUS_HEADER.input2MonthAgo,
          value: 'inputs2MonthsAgo',
          align: 'center',
        },
        {
          name: 'emissions2MonthsAgo',
          text: context.app.i18n.messages[context.app.i18n.locale].ALERT_SETTING.INPUT_STATUS_HEADER.emission2MonthAgo,
          value: 'emissions2MonthsAgo',
          align: 'center',
        },
        {
          name: 'inputs3MonthsAgo',
          text: context.app.i18n.messages[context.app.i18n.locale].ALERT_SETTING.INPUT_STATUS_HEADER.input3MonthAgo,
          value: 'inputs3MonthsAgo',
          align: 'center',
        },
        {
          name: 'emissions3MonthsAgo',
          text: context.app.i18n.messages[context.app.i18n.locale].ALERT_SETTING.INPUT_STATUS_HEADER.emission3MonthsAgo,
          value: 'emissions3MonthsAgo',
          align: 'center',
        },
        {
          text: '',
          value: 'action',
        },
      ],
      DETAIL_CHART_HEADER: {
        header1: [
          {
            name: 'inputsLastMonth',
            text: context.app.i18n.messages[context.app.i18n.locale].ALERT_SETTING.INPUT_STATUS_HEADER.inputLastMonth,
            value: 'inputsLastMonth',
            align: 'center',
            width: '80px',
          },
          {
            name: 'emissionsLastMonth',
            text: context.app.i18n.messages[context.app.i18n.locale].ALERT_SETTING.INPUT_STATUS_HEADER.emissionLastMonth,
            value: 'emissionsLastMonth',
            align: 'center',
            width: '80px',
          },
          {
            name: 'inputs2MonthsAgo',
            text: context.app.i18n.messages[context.app.i18n.locale].ALERT_SETTING.INPUT_STATUS_HEADER.input2MonthAgo,
            value: 'inputs2MonthsAgo',
            align: 'center',
            width: '80px',
          },
          {
            name: 'emissions2MonthsAgo',
            text: context.app.i18n.messages[context.app.i18n.locale].ALERT_SETTING.INPUT_STATUS_HEADER.emission2MonthAgo,
            value: 'emissions2MonthsAgo',
            align: 'center',
            width: '80px',
          },
          {
            name: 'inputs3MonthsAgo',
            text: context.app.i18n.messages[context.app.i18n.locale].ALERT_SETTING.INPUT_STATUS_HEADER.input3MonthAgo,
            value: 'inputs3MonthsAgo',
            align: 'center',
            width: '80px',
          },
          {
            name: 'emissions3MonthsAgo',
            text: context.app.i18n.messages[context.app.i18n.locale].ALERT_SETTING.INPUT_STATUS_HEADER.emission3MonthsAgo,
            value: 'emissions3MonthsAgo',
            align: 'center',
            width: '80px',
          },
          {
            name: 'inputs4MonthsAgo',
            text: context.app.i18n.messages[context.app.i18n.locale].ALERT_SETTING.INPUT_STATUS_HEADER.input4MonthAgo,
            value: 'inputs4MonthsAgo',
            align: 'center',
            width: '80px',
          },
          {
            name: 'emissions4MonthsAgo',
            text: context.app.i18n.messages[context.app.i18n.locale].ALERT_SETTING.INPUT_STATUS_HEADER.emission4MonthsAgo,
            value: 'emissions4MonthsAgo',
            align: 'center',
            width: '80px',
          },
        ],
        header2: [
          {
            name: 'inputs5MonthsAgo',
            text: context.app.i18n.messages[context.app.i18n.locale].ALERT_SETTING.INPUT_STATUS_HEADER.input5MonthAgo,
            value: 'inputs5MonthsAgo',
            align: 'center',
            width: '80px',
          },
          {
            name: 'emissions5MonthsAgo',
            text: context.app.i18n.messages[context.app.i18n.locale].ALERT_SETTING.INPUT_STATUS_HEADER.emission5MonthsAgo,
            value: 'emissions5MonthsAgo',
            align: 'center',
            width: '80px',
          },
          {
            name: 'inputs6MonthsAgo',
            text: context.app.i18n.messages[context.app.i18n.locale].ALERT_SETTING.INPUT_STATUS_HEADER.input6MonthAgo,
            value: 'inputs6MonthsAgo',
            align: 'center',
            width: '80px',
          },
          {
            name: 'emissions6MonthsAgo',
            text: context.app.i18n.messages[context.app.i18n.locale].ALERT_SETTING.INPUT_STATUS_HEADER.emission6MonthsAgo,
            value: 'emissions6MonthsAgo',
            align: 'center',
            width: '80px',
          },
          {
            name: 'inputs7MonthsAgo',
            text: context.app.i18n.messages[context.app.i18n.locale].ALERT_SETTING.INPUT_STATUS_HEADER.input7MonthAgo,
            value: 'inputs7MonthsAgo',
            align: 'center',
            width: '80px',
          },
          {
            name: 'emissions7MonthsAgo',
            text: context.app.i18n.messages[context.app.i18n.locale].ALERT_SETTING.INPUT_STATUS_HEADER.emission7MonthsAgo,
            value: 'emissions7MonthsAgo',
            align: 'center',
            width: '80px',
          },
          {
            name: 'inputs8MonthsAgo',
            text: context.app.i18n.messages[context.app.i18n.locale].ALERT_SETTING.INPUT_STATUS_HEADER.input8MonthAgo,
            value: 'inputs8MonthsAgo',
            align: 'center',
            width: '80px',
          },
          {
            name: 'emissions8MonthsAgo',
            text: context.app.i18n.messages[context.app.i18n.locale].ALERT_SETTING.INPUT_STATUS_HEADER.emission8MonthsAgo,
            value: 'emissions8MonthsAgo',
            align: 'center',
            width: '80px',
          },
        ],
        header3: [
          {
            name: 'inputs9MonthsAgo',
            text: context.app.i18n.messages[context.app.i18n.locale].ALERT_SETTING.INPUT_STATUS_HEADER.input9MonthAgo,
            value: 'inputs9MonthsAgo',
            align: 'center',
            width: '80px',
          },
          {
            name: 'emissions9MonthsAgo',
            text: context.app.i18n.messages[context.app.i18n.locale].ALERT_SETTING.INPUT_STATUS_HEADER.emission9MonthsAgo,
            value: 'emissions9MonthsAgo',
            align: 'center',
            width: '80px',
          },
          {
            name: 'inputs10MonthsAgo',
            text: context.app.i18n.messages[context.app.i18n.locale].ALERT_SETTING.INPUT_STATUS_HEADER.input10MonthAgo,
            value: 'inputs10MonthsAgo',
            align: 'center',
            width: '80px',
          },
          {
            name: 'emissions10MonthsAgo',
            text: context.app.i18n.messages[context.app.i18n.locale].ALERT_SETTING.INPUT_STATUS_HEADER.emission10MonthsAgo,
            value: 'emissions10MonthsAgo',
            align: 'center',
            width: '80px',
          },
          {
            name: 'inputs11MonthsAgo',
            text: context.app.i18n.messages[context.app.i18n.locale].ALERT_SETTING.INPUT_STATUS_HEADER.input11MonthAgo,
            value: 'inputs11MonthsAgo',
            align: 'center',
            width: '80px',
          },
          {
            name: 'emissions11MonthsAgo',
            text: context.app.i18n.messages[context.app.i18n.locale].ALERT_SETTING.INPUT_STATUS_HEADER.emission11MonthsAgo,
            value: 'emissions11MonthsAgo',
            align: 'center',
            width: '80px',
          },
          {
            name: 'inputs12MonthsAgo',
            text: context.app.i18n.messages[context.app.i18n.locale].ALERT_SETTING.INPUT_STATUS_HEADER.input12MonthAgo,
            value: 'inputs12MonthsAgo',
            align: 'center',
            width: '80px',
          },
          {
            name: 'emissions12MonthsAgo',
            text: context.app.i18n.messages[context.app.i18n.locale].ALERT_SETTING.INPUT_STATUS_HEADER.emission12MonthsAgo,
            value: 'emissions12MonthsAgo',
            align: 'center',
            width: '80px',
          },
        ],
      },
    },
    DATA_EXTRACTION: {
      DATA_PATTERN_DISPLAY: {
        header: [
          {
            text: '',
            value: 'actionOne',
            width: '82px',
            class: 'px-0',
          },
          {
            text: '',
            value: 'detailSumCodeId',
            width: '60px',
            class: 'px-0',
          },
          {
            name: 'patternName',
            text: context.app.i18n.messages[context.app.i18n.locale].DATA_EXTRACTION.PATTERN_DISPLAY.HEADERS.patternName,
            value: 'patternName',
            align: 'start',
            width: 'auto',
          },
          {
            name: 'createDate',
            text: context.app.i18n.messages[context.app.i18n.locale].DATA_EXTRACTION.PATTERN_DISPLAY.HEADERS.registeredDate,
            value: 'createDate',
            align: 'start',
            width: '155px',
          },
          {
            text: '',
            value: 'actionTwo',
            width: '82px',
            class: 'px-0',
          },
        ]
      }
    },

  };

  inject("constants", constants);
};
