export default class UserSettingService {
  constructor(axios) {
    this.axios = axios
  }

  async getUser(paginationLimit, paginationToken) {
    return await this.axios.$get('/user/registration/list', { params: { paginationLimit: paginationLimit, paginationToken: paginationToken } });
  }

  getOrgs() {
    return this.axios.$get('/user/registration/orgs')
  }

  signup(payload) {
    return this.axios.$post('/user/registration/signup', payload)
  }

  update(payload) {
    return this.axios.$post('/user/registration/update', payload)
  }

  delete(userName) {
    return this.axios.$delete('/user/registration/delete', { params: { userName: userName } })
  }

  regeneratePassword(userName) {
    return this.axios.$get('/user/registration/regenerate-password', { params: { userName: userName } })
  }
}