export default class OrganizationService {
  constructor(axios) {
    this.axios = axios
  }

  async getInit() {
    return await this.axios.$get('/organization-info/init');
  }

  async getOrgInfo(id) {
    return await this.axios.$get('/organization-info/get-organization-info', { params: { organizationId: id } });
  }

  async updateBase(param) {
    return await this.axios.$post('/organization-info/update-base', param);
  }

  async updateOrg(param) {
    return await this.axios.$post('/organization-info/update-org', param);
  }

  async search(id) {
    return await this.axios.$get('/organization-info/search-by-no-target-month', { params: { organizationId: id } });
  }

  async checkBaseIdAndEndMonthIsExisted(baseid, baseEndMonth) {
    return await this.axios.$get('/organization-info/check-baseid-act', { params: { baseId: baseid, baseEndMonth: baseEndMonth } });
  }

  async updateBaseId(param) {
    return await this.axios.$post('/organization-info/update-by-orgs', param);
  }

}
