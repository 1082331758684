import Vue from "vue"
import Vuetify from "vuetify"

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#30B57B",
        secondary: "#1167b1",
        tertiary: "#495057",
        YellowUltraLight: "#fff3c8",
        YellowDark: "#ffcc3f",
        borderUltraLight: "#f0f4f4",
        borderLight: "#dde7e7",
        error: "#f7698d",
        info: "#00b9d1",
        success: "#46ce93",
        warning: "#e667ff",
        grayout: "#778496",
        scopethree: "8BC34A",
      },
    },
  },
  icons: {
    iconfont: "mdi",
  },
})
