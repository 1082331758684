export default class NewDashboardService {
  constructor(axios) {
    this.axios = axios
  }

  getFiscalYear() {
    return this.axios.$get('/api/v2/dashboard/fiscalyears')
  }

  getTotalActivityByYear(params) {
    return this.axios.$get('/api/v2/dashboard/scopes/all', { params: params });
  }

  getRankByYear(params) {
    return this.axios.$get('/api/v2/dashboard/scopes-categories/ranking', { params: params })
  }
  
  getChartDataByCategory(params) {
    return this.axios.$get('/api/v2/dashboard/scopes-categories/each', { params: params });
  }
}